import { Component, OnInit } from '@angular/core';
import { SetupServiceService } from '../setup-service.service';

@Component({
  selector: 'app-plant-dd',
  templateUrl: './plant-dd.component.html',
  styleUrls: ['./plant-dd.component.css']
})
export class PlantDdComponent implements OnInit {

  plants: any = [];
  constructor(private _service: SetupServiceService) { }

  ngOnInit(): void {
    this._service.getPlants().subscribe((plants: any) => {
      this.plants = plants.data;
    });

  }

}
