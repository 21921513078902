<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>       
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()"  >
       
        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4"  />
                        
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>

                    <div class="col form-group"  *ngIf="page=='item-group'">
                        <span class="label_sub" style="color: #999;">Select Group </span>
                        <p-dropdown formControlName="selectedItem" [options]="sourceGroup" optionLabel="name"
                            optionValue="code" placeholder="Select Group" [ngClass]="{ 'is-invalid': submitted && f.selectedItem.errors }"></p-dropdown>

                            <div *ngIf="submitted && f.selectedItem.errors" class="invalid-feedback">
                                <div *ngIf="f.selectedItem.errors.required">Select group is required</div>
                            </div>
                    </div>


                </div>                               

                
                <div class="row">
                    <div class="btn_pan mb-4">
                            <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>    
                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                </div>

                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>Q</b>: Quantity in Tons,</span>
                    <span><b>V</b>: Value in Rs. Lakh,</span>
                    <span><b>$</b>: USD Million</span>
                    
                </div>             
               

                <div class="card" *ngIf="displayDetailedReport">
                    
                    <table #detailTable>                   
                        <p-table [value]="reportData" >
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportDetailExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="1">&nbsp;</th>
                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange" >
                                        <ng-template [ngIf]="itemVal.isDisplay">
                                            
                                            <th colspan="3">Overall</th>
                                            <th colspan="3">{{itemVal.Range}}</th>
                                        </ng-template>
                                    </ng-template>        
                                </tr>
                                <tr>
                                    <th pSortableColumn="name">Month Wise <p-sortIcon field="name"></p-sortIcon></th>
                                    
                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                        <ng-template [ngIf]="itemVal.isDisplay">
                                            <th >Q </th>
                                            <th >V </th>
                                            <th >$ </th>
                                            <th >Q </th>
                                            <th >V </th>
                                            <th >$ </th>
                                        </ng-template>
                                    </ng-template>

                                    
                                    

                                    
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{item.name}}</td>

                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange" >
                                        <ng-template [ngIf]="itemVal.isDisplay">

                                            <td>{{getTotalQty(item.items,itemVal.startYear)}}</td>
                                            <td >{{getTotalFobRs(item.items,itemVal.startYear)}}</td>
                                            <td>{{getTotalDol(item.items,itemVal.startYear)}}</td>

                                            <td>{{getQuantity(item.items,itemVal.startYear)}}</td>
                                            <td >{{getValueRs(item.items,itemVal.startYear)}}</td>
                                            <td>{{getValueDollar(item.items,itemVal.startYear)}}</td>
                                        </ng-template>
                                    </ng-template>
                                    
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="footer">
                                <tr>
                                    <td width="17%"><b>Total</b></td>
                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                        <ng-template [ngIf]="itemVal.isDisplay">
                                            
                                            <td width="10%">{{itemVal.subTotalQty.toFixed(2)}}</td>
                                            <td width="10%">{{itemVal.subTotalRs.toFixed(2)}}</td>
                                            <td width="10%">{{itemVal.subTotalFobDol.toFixed(2)}}</td>

                                            <td width="20%">{{itemVal.totalQuantity.toFixed(2)}}</td>
                                            <td width="20%">{{itemVal.totalPriceRs.toFixed(2)}}</td>
                                            <td width="20%">{{itemVal.totalPriceDol.toFixed(2)}}</td>
                                         </ng-template>
                                    </ng-template>                
                                </tr>
                            </ng-template>


                        </p-table>
                    </table>
                </div>


                                
                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                <section class="innerpage">
                    <div class="innershadow">
                        <div class="itm_gr_rprt">
                            <table #userTable> 
                                <p-table [value]="reportData" >
                                    <ng-template pTemplate="caption">
                                        <div class="p-d-flex">
                                            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th width="17%">Month Wise</th>
                                            <th width="15%">&nbsp;</th>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange" >
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    <th width="10%"> Overall</th>
                                                    <th width="20%">{{itemVal.Range}}</th>
                                                </ng-template>
                                            </ng-template>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr >

                                            <td width="17%">{{item.name}}</td>
                                            <td width="15%"><b>Q</b> </td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange" >
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    <td width="10%">{{getTotalQty(item.items,itemVal.startYear)}}</td>
                                                    <td width="20%">{{getQuantity(item.items,itemVal.startYear)}}</td>
                                                </ng-template>
                                            </ng-template>
                                        </tr>
                                        
                                        <tr >
                                            <td>&nbsp;</td>
                                            <td><b>V</b> </td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange" >
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    <td width="10%">{{getTotalFobRs(item.items,itemVal.startYear)}} </td>
                                                    <td width="20%">{{getValueRs(item.items,itemVal.startYear)}}</td>
                                                    
                                                </ng-template>
                                            </ng-template>
                                        </tr>
                                       
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td><b>$</b> </td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange" >
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    
                                                    <td width="10%">{{getTotalDol(item.items,itemVal.startYear)}}</td>
                                                    <td width="20%">{{getValueDollar(item.items,itemVal.startYear)}}</td>

                                                </ng-template>
                                            </ng-template>
                                        </tr>
                                       
                                    </ng-template>

                                    <ng-template pTemplate="footer">
                                        <tr>
                                            <td width="17%"><b>Total</b></td>
                                            <td width="15%">Q:</td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    
                                                    <td width="10%">{{itemVal.subTotalQty.toFixed(2)}}</td>
                                                    <td width="20%">{{itemVal.totalQuantity.toFixed(2)}}</td>
                                                 </ng-template>
                                            </ng-template>                
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>V:</td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    <td width="10%">{{itemVal.subTotalRs.toFixed(2)}}</td>
                                                    <td width="20%">{{itemVal.totalPriceRs.toFixed(2)}}</td>
                                                </ng-template>
                                            </ng-template>                  
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>$</td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    
                                                    <td width="10%">{{itemVal.subTotalFobDol.toFixed(2)}}</td>
                                                    <td width="20%">{{itemVal.totalPriceDol.toFixed(2)}}</td>
                                                </ng-template>
                                            </ng-template>                   
                                        </tr>
                                        
                                    </ng-template>
                                </p-table>
                            </table>                              
                            </div>
                        </div>
                    </section>
                </section>
           
        </div>

        </div>
        <input type="hidden" formControlName="reportType" />
    </form>
</div>