<!--<a (click)=downloadAsPDF()> <i class="fa fa-eye"></i> </a>
<div class="col-md-8" id="content" #htmlData >
    <table width="1000" border="0" cellspacing="0" cellpadding="0" align="center">
        <tr>
            <td>
                <table width="96%" border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
                <table width="992" border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td width="20%" align="right" valign="top">
                            <p><img width="91" height="100" src="http://mpedastat.broadwayinfotech.net.au/assets/img/logo.png"></p>
                        </td>
                        <td width="80%" align="left">
                            <span style="margin:0;margin-top:3px; font-family:'Arial', 'Helvetica'; font-size:20pt; font-weight:bold; text-align:right;">&nbsp; Marine Products Export Development Authority, Cochin</span>
                        </td>
                    </tr>
                </table>
                <table width="96%" border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td height="20">
                            &nbsp;
                        </td>
                    </tr>
                </table>
                <table width="992" border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td width="50%" valign="top">
                            <table width="100%" border="0" cellspacing="0" cellpadding="3" align="center">
                                <tr>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Exporter</span>
                                    </td>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">60004</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Consignee</span>
                                    </td>
                                    <td>
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">E10523 Submito & Co., Japan</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Buyer Other Than Consignee</span>
                                    </td>
                                    <td valign="top">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;"></span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width="5%">
                            &nbsp;
                        </td>
                        <td valign="top" width="45%" style="line-height:30px;">
                            <table width="100%" border="0" cellspacing="0" cellpadding="3" align="center">
                                <tr>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Invoice No.</span>
                                    </td>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">1987/40/0001</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Country of Origin</span>
                                    </td>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">Japan</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Country of Destination</span>
                                    </td>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">India</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Port of Origin</span>
                                    </td>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">Vizag</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Shipping Bill No.</span>
                                    </td>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">1245/25/2001</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt; font-weight:bold;">Exchange Rate</span>
                                    </td>
                                    <td width="50%">
                                        <span style="font-family:'Arial','Helvetica'; font-size:13pt;">40.12</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                
                <table width="96%" border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td height="20">
                            &nbsp;
                        </td>
                    </tr>
                </table>
                
                <table style="border-top:1px solid #cccccc; border-bottom:1px solid #cccccc;" width="100%" border="0" cellspacing="0" cellpadding="3">
                    <tr>
                        <td width="150">
                            &nbsp;
                        </td>
                        <td width="150">
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt; font-weight:bold; color:#0f147b;">Weight</span>
                        </td>
                        <td>
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt; font-weight:bold; color:#0f147b;">Value</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt;">Total :</span>
                        </td>
                        <td>
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt;">410</span>
                        </td>
                        <td>
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt;">2145647.00</span>
                        </td>
                    </tr>
                </table>
                
                <table style=" " width="100%" border="0" cellspacing="0" cellpadding="3">
                    <tr>
                        <td width="150"><span style="font-family:'Arial', 'Helvetica'; font-size:13pt; font-weight:bold; color:#0f147b;">FOB : </span></td>
                        <td width="150">
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt; font-weight:bold; ">214,1250.00</span>
                        </td>
                        <td width="150">
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt; font-weight:bold; color:#0f147b;">Freight : </span>
                        </td>
                        <td>
                            <span style="font-family:'Arial', 'Helvetica'; font-size:13pt; font-weight:bold; ">210.00 </span>
                        </td>
                    </tr>
                </table>
                            
                <table width="96%" border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
  </div>
-->
<p-toast></p-toast>

<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-5 col-md-6 text-md-left text-sm-left text-center">

                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> Shipping Bill </h1>
                </div>
            </div>

            <div class="col-xl-7 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0" *ngIf="isPermissionAdd">
                <a [routerLink]="['/addinvoicepdf']" [queryParams]=""><button type="button" class="btn btn-success"><i
                            class="fa fa-cloud-upload"></i> Upload Pdf </button></a>
                <a [routerLink]="['/addinvoice']" [queryParams]=""><button type="button" class="btn btn-success"><i
                            class="fa fa-plus"></i> Manual Entry </button></a>
                <a [routerLink]="['/uploadExcel']" [queryParams]=""><button type="button" class="btn btn-success"><i
                            class="fa fa-cloud-upload"></i> Upload Excel </button></a>

            </div>


        </div>

    </div>
</div>
<section class="innerpage">


    <div class="innershadow">

        <div class="container pt-4">
            <div class="table-responsive">
                <table width="100%">
                    <tr>
                        <td width="5%">Quantity In</td>
                        <td width="20%">
                            <select class="form-control" style="width: 20%;" (change)="getQuantityOption($event)">
                                <option value="1">KG </option>
                                <option value="2">MT </option>
                            </select>
                        </td>
                        <td width="5%">Amount In</td>
                        <td width="20%">
                            <select class="form-control" style="width: 30%;" (change)="getAmountOption($event)">
                                <option value="0">Select </option>
                                <option value="1">Lakh </option>
                                <option value="2">Crore </option>
                            </select>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                </table>
            </div>
            <div class="table-responsive">
                <p-table [value]="data" #dt [columns]="cols" [lazy]="true" (onLazyLoad)="loadInvoices($event)"
                    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [loading]="loading"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[10,25,50]">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th pSortableColumn="{{col.header}}" [ngStyle]="{'width': col.width}">
                                    <ng-template [ngIf]="col.field=='Qty(KG)'">
                                        <ng-template [ngIf]="quantityOption==1 ">{{col.field}}</ng-template>
                                        <ng-template [ngIf]="quantityOption==2">Qty(MT)
                                        </ng-template>
                                    </ng-template>
                                    <ng-template [ngIf]="col.field!='Qty(KG)'">
                                        {{col.field}}
                                    </ng-template>
                                    <p-sortIcon field="{{col.header}}">
                                    </p-sortIcon>
                                </th>
                            </ng-template>
                            <th style="width:5%"></th>
                        </tr>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th [ngStyle]="{'width': col.width}">
                                    <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, col.header, 'startsWith')"
                                        placeholder="Search by {{col.field}}" class="p-column-filter"
                                        style="width: 100%;" />
                                </th>
                            </ng-template>
                            <th style="width:5%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-columns="columns">
                        <tr style="height:34px">
                            <ng-template *ngFor="let col of columns"
                                [ngIf]="col.type!='hidden' && col.field!='Qty(MT)'">
                                <td>
                                    <ng-template [ngIf]="col.header=='status'">
                                        <ng-template [ngIf]="data['statusCode']==1">
                                            <div style="color:green">{{data[col.header]}}</div>
                                        </ng-template>
                                        <ng-template [ngIf]="data['statusCode']!=1">
                                            <strong>{{data[col.header]}}</strong>
                                        </ng-template>
                                    </ng-template>
                                    <ng-template [ngIf]="col.header=='EXP_NAME'">
                                        <div>{{data[col.header]}}-({{data['regNumber']}})</div>
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="col.header!='status' && (col.header!='EXP_NAME' &&  col.field!='Qty(KG)' &&  col.field!='Amount($)' &&  col.field!='Amount(₹)') ">
                                        {{data[col.header]}}
                                    </ng-template>
                                    <ng-template [ngIf]="col.header!='status' && col.field=='Amount($)' ">
                                        <ng-template [ngIf]="amountOption==0 ">{{data[col.header]| number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==1">{{data[col.header]/100000 | number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==2">{{data[col.header]/10000000 | number}}
                                        </ng-template>
                                    </ng-template>
                                    <ng-template [ngIf]="col.header!='status' && col.field=='Amount(₹)' ">
                                        <ng-template [ngIf]="amountOption==0 ">{{data[col.header]| number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==1">{{data[col.header]/100000 | number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==2">{{data[col.header]/10000000 | number}}
                                        </ng-template>
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="col.header!='status' && (col.header!='EXP_NAME' &&  col.field=='Qty(KG)') ">
                                        <ng-template [ngIf]="quantityOption==1 ">{{data[col.header]| number}}
                                        </ng-template>
                                        <ng-template [ngIf]="quantityOption==2">{{data[col.header]/1000 | number}}
                                        </ng-template>
                                    </ng-template>
                                </td>
                            </ng-template>
                            <td class="tableicons">
                                <ng-template [ngIf]="isPermissionEdit ">
                                    <a [routerLink]="['/viewinvoice']" [queryParams]="{id:data.id}">
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-success p-mr-2"></button>
                                    </a>
                                </ng-template>
                                <ng-template [ngIf]="isDeletePermission && data['statusCode']!=6">
                                    <a (click)=confirmDelete(data.id);>
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-success p-mr-2"></button>
                                    </a>
                                </ng-template>
                                <ng-template [ngIf]="isPermissionDownload ">
                                    <a href='https://estat.mpeda.gov.in/mpedapdf/index.php?type=pdf&id={{data.id}}'
                                        title="Download invoice" alt="Download invoice">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </a>
                                </ng-template>

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            {{totalRecords}} Shipping Bill Found.
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>



</section>

<br>