import { Component, OnInit,Input } from '@angular/core';
import { CommonServiceService } from '../../../common-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-port-graph',
  templateUrl: './port-graph.component.html',
  styleUrls: ['./port-graph.component.css']
})
export class PortGraphComponent implements OnInit {
  @Input() financialYear: string

  isloaderPort:boolean=false;
  isShowMap:boolean=false;
  dataPort:any;
  portData:any = []; 
  portGraphType:string;
  titlePort:string;
  optionsPort:any;
  constructor(private _service:CommonServiceService,private _router:Router) { }

  ngOnInit(): void {

    this.isloaderPort = true;
    
    let data = {
      "financialyear":this.financialYear
    }
    
    this._service.getPortData(data).subscribe((portData: any) => {
      this.portData = portData.data.reportObject[0].portData;
      let portArray = [];
      for(let i=0;i<this.portData.length;i++){
        let qty = (this.portData[i].totalQty/1000).toFixed(2);
        portArray[i] = [this.portData[i].port_name,parseFloat(qty)]
      }
      this.portGraphType = 'BarChart';
      this.dataPort = portArray;
      this.titlePort = "Top 5 ports";
      this.optionsPort = {
          hAxis: {
            title: 'Quantiy in tons'
          },
          vAxis:{
            title:'Port'
          }  
        };
      

      this.isShowMap = true;
      this.isloaderPort = false;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });

  }
  changeOptionPort(val){
    console.warn(val);
    if(val=="AreaChart" || val=="ColumnChart"){
      this.optionsPort = {
        hAxis: {
          title: 'Port'
        },
        vAxis:{
          title:'Quantiy in tons'
        }  
      };
    }
    else{
      this.optionsPort = {
        hAxis: {
          title: 'Quantiy in tons'
        },
        vAxis:{
          title:'Port'
        }  
      };
    }
  }

  getGraph(financialYear) {
    this.isloaderPort = true;
    
    let data = {
      "financialyear":financialYear
    }
    this._service.getPortData(data).subscribe((portData: any) => {
      this.portData = portData.data.reportObject[0].portData;
      let portArray = [];
      for(let i=0;i<this.portData.length;i++){
        let qty = (this.portData[i].totalQty/1000).toFixed(2);
        portArray[i] = [this.portData[i].port_name,parseFloat(qty)]
      }
      this.dataPort = portArray;
      this.isloaderPort = false;

    });
  }

}
