import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ReportSummaryService } from '../report-summary.service';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { flatten } from '@angular/compiler';
import { calendarRange } from 'src/environments/environment';


@Component({
  selector: 'app-report-region',
  templateUrl: './report-region.component.html',
  styleUrls: ['./report-region.component.css']
})
export class ReportRegionComponent implements OnInit {


  reportTitle: string;
  reportFrm: FormGroup;
  targetUnion: any;
  sourceUnion: any;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  data: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  reportData: any = [];
  showTextBox: boolean = false;
  calendarRange: string = calendarRange
  displayGroups: boolean = false;


  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;

  constructor(private formBuilder: FormBuilder, private _service: ReportSummaryService, private primengConfig: PrimeNGConfig, private _router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
    this.reportTitle = "Region Wise ";
    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      selectedUnion: [''],
      viewDetailReport: [''],
      reportType: ["regionwise"],
      topRecordNum: ['0'],
      topRecordNumOther: ['1'],
      includeBlankData: ['0'],
      sortColumn: ['FobDolMil'],
      sortType: ['DESC'],
    });

    this.targetUnion = [];
    this.primengConfig.ripple = true;


    this._service.getUnion().subscribe((unions: any) => {
      this.sourceUnion = unions.data;
    });
    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });
    this.reportFrm.valueChanges.subscribe(val => {

      if (val.topRecordNum == "-1") {
        this.showTextBox = true;
      }
      else {
        this.showTextBox = false;
      }
    });
    this.reportFrm.controls.topRecordNum.patchValue('favorite', { emitEvent: false });
    this.reportFrm.controls.sortColumn.patchValue('none', { emitEvent: false });
    this.reportFrm.controls.sortType.patchValue('ASC', { emitEvent: false });


  }
  addUnion(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedUnion.patchValue(this.selectedItem, { emitEvent: false });
  }

  removeUnion(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedUnion.patchValue(this.selectedItem, { emitEvent: false });
  }
  get f() { return this.reportFrm.controls; }

  getReport() {
    this.isloader = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    if (this.reportFrm.value.selectedUnion == "" && this.reportFrm.value.topRecordNum != 'favorite' ) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Region", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }
    this._service.getRegionWiseReport(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      if (items.data.reportData.length > 0) {
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }

      this.reportData = items.data.reportData;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }
  getShare(itemQty, totalQty) {
    let percentage = itemQty * 100 / totalQty;
    return percentage.toFixed(2);
  }

  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Region Data');
      // save to file
      xlsx.writeFile(workbook, "region_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Region Data');
      // save to file
      xlsx.writeFile(workbook, "region_report.xlsx");

    });
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Region Data');
      // save to file
      xlsx.writeFile(workbook, "region_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Region Data');
      // save to file
      xlsx.writeFile(workbook, "region_report_detail.csv");

    });
  }

  getRounded(number) {
    return Math.round(number).toFixed(2);
  }
  changeSortBy($event) {
    console.warn("In here");
    if ($event.target.value == "favorite") {
      this.displayGroups = false;
    }
    else {
      this.displayGroups = true;
    }
    console.warn("Duisplay Group = ", this.displayGroups);
  }
}
