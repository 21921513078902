import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../common-service.service';
import { PrimeNGConfig } from 'primeng/api';
import { apiUrl, calendarRange, fileAllowedExtensions, fileAllowedSize, poundToKg, invoiceFrezeDate } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-upload',
  templateUrl: './invoice-upload.component.html',
  styleUrls: ['./invoice-upload.component.css']
})
export class InvoiceUploadComponent implements OnInit {

  isloader: boolean = false;
  pdfDetail: any = [];
  submitted: boolean = false;
  invoiceFrm: FormGroup;
  invoiceDetail: any = [];
  totalConsignee: any = [];
  totalPort: any = [];
  totalPortDischarge: any = [];
  totalFinalPort: any = [];
  expDetail: any = [];
  expName: string = '';
  exporterAll: any = [];
  buyer: any;
  filteredConsinee: any;
  selectedExporter: any = [];
  showForm: boolean = false;
  totalExporter: any = [];
  userId: number = 0;
  branchId: number = 0;
  port: any;
  filteredPort: any;
  country: any;
  filteredCountry: any;
  totalQuantity: any;
  totalQuantityUnit: any;
  totalPrice: any = 0;
  totalAmountDollar: any;
  totalAmountRs: any;
  itemGroupDetail: any;

  itemForm: FormGroup;
  submittedItem = false;
  invoiceId: {};
  invoiceFrmData: any;

  invoiceDateTime: any;
  manufacturer: any;
  filteredExporters: any;
  keyword = 'name';
  exporters: any;
  exportername: any;
  filteredManufacturer: any;
  manufacturerPlants: any;
  plantState: string;
  displayAddItem: boolean;
  groups: any;
  itemListDD: any;
  itemGradeDD: any;
  currencies: any;
  totalFOBPrice: any;

  itemHsDD: any;
  itemSNameDD: any;

  position: string;
  deleteItemId: number = 0;
  selectedItemDelete: any = [];
  filteredGrade: any = [];
  fileName: string = "";
  added_from: string = "";
  status: string = "";
  medium: string = "";
  added_by: string = "";
  filteredItems: any = [];
  decodedToken: any = []
  comments: any = [];
  groupTotal: number = 0;
  pdfExporterName: string = "";
  pdfConsigneeName: string = "";
  pdfLoadingPort: string = "";
  pdfDischargePort: string = "";
  pdfFinalDestination: string = "";
  allConsinee: any = [];
  allExporters: any = [];
  allPorts: any = [];
  allCountry: any = [];
  allItems: any = [];
  filteredPortIndia: any = [];
  allPortsIndia: any = [];

  matchContent: any = [];
  calendarRange: string = calendarRange;

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),

    fileSource: new FormControl('', [Validators.required])
  });

  constructor(private _service: CommonServiceService, private http: HttpClient, private formBuilder: FormBuilder, private messageService: MessageService, private _router: Router, private _commonService: CommonServiceService, private primengConfig: PrimeNGConfig) { }

  ngOnInit(): void {

    //code to get all exporter
    this._service.getExporters("").subscribe((exporterList) => {
      let filtered: any[] = [];
      this.exporters = exporterList;
      for (let i = 0; i < this.exporters.length; i++) {
        let exporter = this.exporters[i];
        if (exporter.name != null) {
          var exp = {
            id: exporter.code,
            name: exporter.name,
            impExpCode: exporter.Imp_Exp_No

          }
          filtered.push(exp);
        }
      }
      this.allExporters = filtered;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

    //Code to get all consignee 
    this._service.getBuyerSearch("").subscribe((buyerList) => {
      let filtered: any[] = [];
      this.buyer = buyerList;
      for (let i = 0; i < this.buyer.length; i++) {
        let buyer = this.buyer[i];
        if (buyer.name != null) {
          var buyerData = {
            id: buyer.code,
            name: buyer.name
          }
          filtered.push(buyerData);
        }
      }
      this.allConsinee = filtered;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
    //Below code to get all port
    this._service.getPortSearch("").subscribe((portList) => {
      let filtered: any[] = [];
      this.port = portList;
      for (let i = 0; i < this.port.length; i++) {
        let port = this.port[i];
        if (port.name != null) {
          var portData = {
            id: port.code,
            name: port.name,
            countryCode: port.COUNTRY_CODE,
            countryName: port.COUNTRY_NAME,
            detail: port.name + "-" + port.code,

          }
          filtered.push(portData);
        }
      }
      this.allPorts = filtered;

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
    this._service.getIndianPortSearch("").subscribe((portList) => {
      let filteredIndia: any[] = [];
      this.port = portList;
      for (let i = 0; i < this.port.length; i++) {
        let port = this.port[i];
        if (port.name != null) {

          if (port.COUNTRY_CODE == 999) {
            var portDataIndia = {
              id: port.code,
              name: port.name,
              countryCode: port.COUNTRY_CODE,
              countryName: port.COUNTRY_NAME,
              detail: port.name + "-" + port.code,
            }
            filteredIndia.push(portDataIndia);
          }
        }
      }
      this.allPortsIndia = filteredIndia;
    });
    //Code to get all countries
    this._service.getCountrySearch("").subscribe((countryList) => {
      let filtered: any[] = [];
      this.country = countryList;
      for (let i = 0; i < this.country.length; i++) {
        let country = this.country[i];
        if (country.name != null) {
          var countryData = {
            id: country.code,
            name: country.name
          }
          filtered.push(countryData);
        }
      }
      this.allCountry = filtered;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

    this.primengConfig.ripple = true;

    let token = JSON.parse(localStorage.getItem('token'));
    this.decodedToken = jwt_decode(token);
    this.userId = this.decodedToken.result.id;
    if (this.decodedToken.result.branch_id != null) {
      this.branchId = this.decodedToken.result.branch_id;
    }

    this.invoiceFrm = this.formBuilder.group({
      SB_No: ['', [Validators.required, Validators.minLength(7)]],
      SB_Date: ['', Validators.required],
      Ent_date: ['', Validators.required],
      BILL_NO: ['', Validators.required],
      Date: ['', Validators.required],
      EXP_NAME: ['',],
      EXP_CODE: ['', Validators.required],
      BUYER_NAME: ['',],
      BUYER_CODE: ['', Validators.required],
      Plant_Code: ['', Validators.required],
      plantState: "",
      plantStateName: "",
      Vessel_No: "",
      PORT_NAME: "",
      PORT_CODE: ['', Validators.required],
      Port_DischargeName: ['', Validators.required],
      Port_Discharge: ['', Validators.required],
      Port_Final_NAME: '',
      Port_Final: ['', Validators.required],
      Country_Origin_Name: '',
      Country_Origin: '',
      Imp_Exp_No: "",
      E_Under: "",
      S_Type: "",
      Custom: "",
      Currency: ['', Validators.required],
      Nature: "",
      DOL_RATE: ['', Validators.required],
      CurToDol: ['', Validators.required],
      FOB_Value: ['', Validators.required],
      Duty_Rate: 0,
      Amount: '',
      AmountR: '',
      AP_CessR: 0,
      AP_Cess: 0,
      Duty_CessR: 0,
      Duty_Cess: 0,
      Tot_Freight: 0,
      C_Insure: 0,
      R_Insure: 0,
      C_Comm: 0,
      R_Comm: 0,
      C_Disc: 0,
      R_Disc: 0,
      C_Other: 0,
      R_Other: 0,
      Gross: [0, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
      Tot_Weight: [0, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
      Net_Weight: 0,
      R_Freight: 0,
      FKG: 0,
      COUNTRY_CODE_NAME: '',
      COUNTRY_CODE: '',
      itemDetails: '',
      R_FOB: 0,
      invoiceId: '',
      Tot_Amount: '',
      tot_amount_rs: '',
      FOB_Value_invoice: ['', Validators.required],
      totalConsigneeDD: '',
      totalPortDD: '',
      totalPortDischargeDD: '',
      totalFinalPortDD: '',
      fileName: '',
      added_from: '3',
      status: '1',
      medium: '2',
      added_by: '',
      rateIn: '',
      SBfile: [''],
      fileSource: [''],
      Invfile: [''],
      InvfileSource: [''],
      totalExporterDD: '',
      invoiceComment: [''],
      fo_declaration: false,
      pdfmatchContent: [''],
      subgroups: this.formBuilder.array([]),

    });
    this._service.getSetupsInvoice().subscribe((setupList: any) => {
      this.groups = setupList.groupDetails;
      this.currencies = setupList.curreciesDetails;
    });

    this.invoiceFrm.valueChanges.subscribe(val => {
      this.totalPrice = this.getotalAmount();
      if (val.C_Insure >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let RInsurence = val.C_Insure * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Insure.patchValue(RInsurence.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });
        }
      }
      else {
        this.invoiceFrm.controls.R_Insure.patchValue(0, { emitEvent: false });
      }

      //Code to calculate commission  

      if (val.C_Comm >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let R_Comm = val.C_Comm * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Comm.patchValue(R_Comm.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
      }
      else {
        this.invoiceFrm.controls.R_Comm.patchValue(0, { emitEvent: false });
      }
      //Code to calculate discounts  

      if (val.C_Disc >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let R_dis = val.C_Disc * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Disc.patchValue(R_dis.toFixed(2), { emitEvent: false });

          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
      }
      else {
        this.invoiceFrm.controls.R_Disc.patchValue(0, { emitEvent: false });

      }

      //Code to calculate other discount  
      if (val.C_Other >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        if (val.DOL_RATE > 0) {
          let o_dis = val.C_Other * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Other.patchValue(o_dis.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });

      }
      else {
        this.invoiceFrm.controls.R_Other.patchValue(0, { emitEvent: false });

      }
      //Code to calulate freight
      if (val.Tot_Freight > 0) {
        const totalFright = Number.parseFloat(val.Tot_Freight).toFixed(2);
        if (val.DOL_RATE > 0) {
          let totalFrightRs = parseFloat(val.Tot_Freight) * parseFloat(val.DOL_RATE)
          this.invoiceFrm.controls.R_Freight.patchValue(totalFrightRs.toFixed(2), { emitEvent: false });
        }
        this.invoiceFrm.controls.Tot_Freight.patchValue(val.Tot_Freight, { emitEvent: false });
        this.calculateItemsFOB();
      }
      else {
        this.invoiceFrm.controls.R_Freight.patchValue(0, { emitEvent: false });

      }

      //Calculate Total Freight 
      this.invoiceFrm.controls.FKG.patchValue(0, { emitEvent: false });
      if (val.Tot_Freight > 0 && val.Tot_Weight > 0) {
        let frightPerKg = val.Tot_Freight / val.Tot_Weight;
        this.invoiceFrm.controls.FKG.patchValue(frightPerKg.toFixed(2), { emitEvent: false });
      }
      if (val.SB_Date != '') {
        this.invoiceDateTime = val.SB_Date;
      }
    });
  }
  get f() {
    return this.myForm.controls;
  }

  get invf() {
    return this.invoiceFrm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }
  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.myForm.invalid) {
      return;
    }
    const formData = new FormData();
    this.isloader = true;
    formData.append('file', this.myForm.get('fileSource').value);
    this.matchContent = [];
    this._service.uploadInvoice(formData).subscribe((items: any) => {


      // console.warn("Item Detail = ", items);

      if (items.status == 0) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
        this.isloader = false;
        return;
      }

      this.invoiceDetail = items[0];

      this.pdfExporterName = items[0].exporter;
      let itemmatchExp = {
        "exporter": {
          "oldValue": items[0].exporter
        }
      }
      this.matchContent.push(itemmatchExp);
      this.pdfConsigneeName = items[0].buyer_name;
      let itemmatchBuyer = {
        "buyer": {
          "oldValue": items[0].buyer_name
        }
      }
      this.matchContent.push(itemmatchBuyer);

      //Code too push item content 
      let itemPdf = [];
      for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
        let itemmatch = {
          "item": {
            "oldValue": this.invoiceDetail.itemsDetail[i].ITEM_NAME
          }
        }
        itemPdf.push(itemmatch);

      }
      let itemPdfContent = {
        "items": itemPdf
      }
      this.matchContent.push(itemPdfContent);




      this.pdfLoadingPort = items[0].PORT_LOADING;
      this.pdfDischargePort = items[0].PORT_OF_DISCHARGE;
      this.pdfFinalDestination = items[0].PORT_OF_FINAL_DESTINATION;




      this.totalExporter = items[0].totalExporters;
      this.totalConsignee = items[0].totalBuyers;
      this.totalPort = items[0].totalPort;
      this.totalPortDischarge = items[0].totalDischargePort;
      this.totalFinalPort = items[0].totalFinalPort;


      let itemExp = {
        "id": this.expDetail.id,
        "name": this.expDetail.name,
      }
      this.subgroups().controls = [];

      this.invoiceFrm.setValue({
        SB_No: this.invoiceDetail.SB_No,
        SB_Date: this.invoiceDetail.SB_Date,
        Ent_date: this.invoiceDetail.todayDate,
        BILL_NO: this.invoiceDetail.BILL_NO,
        Date: this.invoiceDetail.Date,
        EXP_NAME: this.selectedExporter,
        EXP_CODE: this.selectedExporter,
        Plant_Code: '',
        plantState: this.invoiceDetail.STATE_OF_ORIGIN,
        plantStateName: "",
        Port_Final: '',
        Port_Final_NAME: '',
        BUYER_NAME: '',
        BUYER_CODE: '',
        Vessel_No: this.invoiceDetail.Vessel_No,
        PORT_NAME: '',
        PORT_CODE: '',
        Port_DischargeName: this.invoiceDetail.PORT_OF_DISCHARGE,
        Port_Discharge: '',
        Imp_Exp_No: this.invoiceDetail.Imp_Exp_No,
        E_Under: '',
        S_Type: '',
        Custom: this.invoiceDetail.Custom,
        Currency: this.invoiceDetail.Currency,
        Nature: '',
        DOL_RATE: this.invoiceDetail.DOL_RATE,
        CurToDol: this.invoiceDetail.CurToDol,
        FOB_Value: this.invoiceDetail.FOB_Value,
        FOB_Value_invoice: this.invoiceDetail.FOB_Value,
        Duty_Rate: 0,
        Amount: this.invoiceDetail.Amount,
        AmountR: this.invoiceDetail.AmountR,
        AP_CessR: 0,
        AP_Cess: 0,
        Duty_CessR: 0,
        Duty_Cess: 0,
        Tot_Freight: this.invoiceDetail.Tot_Freight,
        C_Insure: this.invoiceDetail.C_Insure,
        R_Insure: this.invoiceDetail.R_Insure,
        C_Comm: this.invoiceDetail.C_Comm,
        R_Comm: this.invoiceDetail.R_Comm,
        C_Disc: this.invoiceDetail.C_Disc,
        R_Disc: this.invoiceDetail.R_Disc,
        C_Other: this.invoiceDetail.C_Other,
        R_Other: this.invoiceDetail.R_Other,
        Gross: this.invoiceDetail.GROSS_WEIGHT,
        Tot_Weight: this.invoiceDetail.Tot_Weight,
        Net_Weight: this.invoiceDetail.Tot_Weight,
        R_Freight: '',
        FKG: '',
        Country_Origin_Name: '',
        Country_Origin: '',
        COUNTRY_CODE_NAME: '',
        COUNTRY_CODE: '',
        itemDetails: this.invoiceDetail.itemsDetail,
        R_FOB: '',
        invoiceId: '',
        Tot_Amount: this.invoiceDetail.Tot_Amount,
        tot_amount_rs: this.invoiceDetail.tot_amount_rs,
        totalConsigneeDD: '',
        totalPortDD: '',
        totalPortDischargeDD: '',
        totalFinalPortDD: '',
        fileName: this.invoiceDetail.fileName,
        added_from: '1',
        status: '1',
        medium: '2',
        added_by: this.userId,
        rateIn: '',
        SBfile: '',
        fileSource: '',
        Invfile: '',
        InvfileSource: '',
        totalExporterDD: '',
        invoiceComment: [''],
        fo_declaration: false,
        pdfmatchContent: [''],
        subgroups: []
      });

      this.createform();
      //Code to set the value of item drop down 
      for (let it = 0; it < this.invoiceDetail.itemsDetail.length; it++) {
        if (this.invoiceDetail.itemsDetail[it].items.length > 0) {
          let max = 0;
          let itemMatch = this.invoiceDetail.itemsDetail[it].items;
          itemMatch.forEach(item => {
            if (item.match_count > max) {
              max = item.match_count;
            }
          });
          for (let i1 = 0; i1 < itemMatch.length; i1++) {
            if (itemMatch[i1].match_count == max) {


              // console.warn("Item Match = ", itemMatch[i1]);
              let sNameArray = itemMatch[i1].scientific_name.split(",");
              this.invoiceDetail.itemsDetail[it]['ITEM_CODE'] = itemMatch[i1].ITEM_CODE;
              this.invoiceDetail.itemsDetail[it]['GROUP_CODE'] = itemMatch[i1].GROUP_CODE;
              this.invoiceDetail.itemsDetail[it]['GROUP_NAME'] = itemMatch[i1].GROUP_NAME;
              this.invoiceDetail.itemsDetail[it]['ITEM_NAME'] = itemMatch[i1].ITEM_NAME;
              this.invoiceDetail.itemsDetail[it]['scientific_name_array'] = sNameArray;
              this.itemGroupDetail = this.getItemGroupSummary();

              this.updateAIContent(itemMatch[i1].ITEM_NAME, "items", it);
              //              this.invoiceFrm.patchValue({ id : newValue });
              // this.invoiceFrm.get("itemName_0").patchValue(itemMatch[i1].ITEM_NAME);
              //  this.invoiceFrm.controls.itemName_0.patchValue(itemMatch[i1].ITEM_NAME);
              // this.invoiceFrm.controls['itemName'][0].setValue(itemMatch[i1].ITEM_NAME, {onlySelf: true});
              this.subgroups().controls[it].get('itemName').setValue(itemMatch[i1].ITEM_CODE);

            }
          }
        }
      }



      if (this.totalConsignee.length == 1) {
        let item = {
          "id": this.totalConsignee[0].BUYER_CODE,
          "name": this.totalConsignee[0].BUYER_NAME,
        }
        let otionItem = this.totalConsignee[0].BUYER_CODE + "--" + this.totalConsignee[0].BUYER_NAME;
        this.invoiceFrm.controls.BUYER_NAME.patchValue(item);
        this.invoiceFrm.controls.BUYER_CODE.patchValue(item);
        this.invoiceFrm.controls.totalConsigneeDD.setValue(otionItem);

      }
      if (this.totalConsignee.length > 1) {

        let max = 0;
        this.totalConsignee.forEach(consignee => {
          if (consignee.match_count > max) {
            max = consignee.match_count;

          }
        });
        for (let i = 0; i < this.totalConsignee.length; i++) {
          if (this.totalConsignee[i].match_count == max) {
            let item = {
              "id": this.totalConsignee[i].BUYER_CODE,
              "name": this.totalConsignee[i].BUYER_NAME,
            }
            let otionItem = this.totalConsignee[i].BUYER_CODE + "--" + this.totalConsignee[i].BUYER_NAME;
            this.invoiceFrm.controls.BUYER_NAME.patchValue(item);
            this.invoiceFrm.controls.BUYER_CODE.patchValue(item);
            this.invoiceFrm.controls.totalConsigneeDD.setValue(otionItem);
          }
        }
      }
      if (this.totalExporter.length == 1) {
        let item = {
          "id": this.totalExporter[0].EXP_CODE,
          "name": this.totalExporter[0].EXP_NAME,
        }
        let otionItem = this.totalExporter[0].EXP_CODE + "--" + this.totalExporter[0].EXP_NAME;
        this.invoiceFrm.controls.EXP_NAME.patchValue(item);
        this.invoiceFrm.controls.EXP_CODE.patchValue(item);
        this.invoiceFrm.controls.totalExporterDD.setValue(otionItem);
        this._service.getExporterPlants(this.totalExporter[0].EXP_CODE).subscribe((exporterPlants: any) => {
          this.manufacturerPlants = exporterPlants.data;
          if (this.manufacturerPlants.length == 1) {

            this.invoiceFrm.controls.Plant_Code.patchValue(this.manufacturerPlants[0].code);
            this.plantState = this.manufacturerPlants[0].Stname;
            this.invoiceFrm.controls.plantState.patchValue(this.manufacturerPlants[0].Stcode);
            this.invoiceFrm.controls.plantStateName.patchValue(this.manufacturerPlants[0].Stname);
          }
        });
      }
      if (this.totalExporter.length > 1) {

        let max = 0;
        this.totalExporter.forEach(exporter => {
          if (exporter.match_count > max) {
            max = exporter.match_count;

          }
        });
        for (let i = 0; i < this.totalExporter.length; i++) {
          if (this.totalExporter[i].match_count == max) {
            let item = {
              "id": this.totalExporter[i].EXP_CODE,
              "name": this.totalExporter[i].EXP_NAME,
            }
            let otionItem = this.totalExporter[i].EXP_CODE + "--" + this.totalExporter[i].EXP_NAME;
            this.invoiceFrm.controls.EXP_NAME.patchValue(item);
            this.invoiceFrm.controls.EXP_CODE.patchValue(item);
            this.invoiceFrm.controls.totalExporterDD.setValue(otionItem);
            this._service.getExporterPlants(this.totalExporter[i].EXP_CODE).subscribe((exporterPlants: any) => {
              this.manufacturerPlants = exporterPlants.data;
            });
          }
        }
      }
      if (this.totalPort.length == 1) {
        let item = {
          "id": this.totalPort[0].code,
          "name": this.totalPort[0].name,
        }
        let otionItem = this.totalPort[0].code + "--" + this.totalPort[0].name;
        this.invoiceFrm.controls.PORT_CODE.patchValue(item);
        this.invoiceFrm.controls.PORT_NAME.patchValue(item);

        this.invoiceFrm.controls.totalPortDD.setValue(otionItem);
        let itemCountry = {
          "id": this.totalPort[0].COUNTRY_CODE,
          "name": this.totalPort[0].COUNTRY_NAME,
        }
        this.invoiceFrm.controls.Country_Origin_Name.patchValue(this.totalPort[0].COUNTRY_NAME, { emitEvent: false });
        this.invoiceFrm.controls.Country_Origin.patchValue(itemCountry, { emitEvent: false });

      }
      if (this.totalPortDischarge.length == 1) {
        let item = {
          "id": this.totalPortDischarge[0].PORT_CODE,
          "name": this.totalPortDischarge[0].PORT_NAME,
        }
        let otionItem = this.totalPortDischarge[0].PORT_CODE + "--" + this.totalPortDischarge[0].PORT_NAME;
        this.invoiceFrm.controls.Port_Discharge.patchValue(item);
        this.invoiceFrm.controls.Port_DischargeName.patchValue(item);

        this.invoiceFrm.controls.totalPortDischargeDD.setValue(otionItem);
      }
      if (this.totalFinalPort.length == 1) {
        let item = {
          "id": this.totalFinalPort[0].code,
          "name": this.totalFinalPort[0].name,
        }
        let itemDestination = {
          "id": this.totalFinalPort[0].COUNTRY_CODE,
          "name": this.totalFinalPort[0].COUNTRY_NAME
        }
        this.invoiceFrm.controls.COUNTRY_CODE_NAME.patchValue(this.totalFinalPort[0].name, { emitEvent: false });

        this.invoiceFrm.controls.COUNTRY_CODE.patchValue(itemDestination, { emitEvent: false });


        let otionItem = this.totalFinalPort[0].code + "--" + this.totalFinalPort[0].name;
        this.invoiceFrm.controls.Port_Final.patchValue(item);
        this.invoiceFrm.controls.Port_Final_NAME.patchValue(item);


        this.invoiceFrm.controls.totalFinalPortDD.setValue(otionItem);
      }
      if (this.invoiceDetail.C_Insure > 0) {
        this.invoiceFrm.controls.Nature.setValue("CIF");
        this.invoiceFrm.controls.rateIn.setValue("C&F");

      }
      else {
        if (this.invoiceDetail.Tot_Freight > 0) {
          this.invoiceFrm.controls.Nature.setValue("CFR");
          this.invoiceFrm.controls.rateIn.setValue("C&F");
        }
        else {
          this.invoiceFrm.controls.Nature.setValue("FOB");
          this.invoiceFrm.controls.rateIn.setValue("FOB");
        }
      }
      this.showForm = true;
      this.isloader = false;
      this.checkBillNumber();
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    })
    if (this.totalExporter == 1) {
      let item = {
        "id": this.expDetail.id,
        "name": this.expDetail.name,
      }
      this.selectedExporter = item;
    }


  }
  saveInvoice() {

    this.submitted = true;


    // stop here if form is invalid
    if (this.invoiceFrm.invalid) {
      console.warn("Invalid");
      return;
    }
    if (this.invoiceFrm.value.SB_No.length != 7) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Shipping Bill number can not be greater than 7 digit ", life: 6000 }); //
      return;
    }
    let itemError = false;
    let scintificError = false;
    let pdfItems: any = [] = this.invoiceFrm.value.itemDetails;
    for (let i = 0; i < pdfItems.length; i++) {
      //   console.warn("Pdf Items = ", this.invoiceDetail.itemsDetail[i]);
      if (pdfItems[i].ITEM_CODE == "") {
        itemError = true;
        break;
      }
      if (pdfItems[i].scientific_name === undefined || pdfItems[i].scientific_name == "") {
        scintificError = true;
        break;
      }
    }
    if (itemError) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select items ", life: 6000 }); //
      return false;
    }
    if (scintificError) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select Scientific name ", life: 6000 }); //
      return false;
    }
    //Below code to add files on forms 
    if (this.invoiceFrm.value.status == 4 && !this.invoiceFrm.value.fo_declaration) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select declaration ", life: 6000 }); //
      return false;
    }

    //Below code to add files on forms 

    const formData = new FormData();
    this.isloader = false;
    formData.append('sBfile', this.invoiceFrm.get('fileSource').value);
    formData.append('invoiceFile', this.invoiceFrm.get('InvfileSource').value);


    // display form values on success
    if (parseFloat(this.invoiceFrm.value.FOB_Value_invoice) != parseFloat(this.invoiceFrm.value.FOB_Value)) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Invoice can't save beacuse FOB Value and FOB Value as per Invoice are not same  ", life: 6000 }); //success,info,warn,error
      return;

    }
    let data = {
      "sbDate": this.invoiceFrm.value.SB_Date
    }
    this._commonService.checkSBillDate(data).subscribe((checkData: any) => {
      if (checkData.data == 0) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: checkData.messagess, life: 6000 }); //
        return;
      }
      else {

        this.invoiceFrm.value.pdfmatchContent = this.matchContent;
        this._service.saveInvoice(this.invoiceFrm.value).subscribe((items: any) => {
          if (items.success) {
            let sbId = items.SbId;
            formData.append('invoiceId', sbId);
            this._service.uploadInvoiceFiles(formData).subscribe((returnData: any) => {

            });
            this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
          }
          this.submitted = false;
          return false;
        });
      }
    });
  }
  assignConsigneeDD(event) {

    if (event.target.value == 0) {
      this.totalConsignee = [];
      this.invoiceFrm.controls.BUYER_NAME.patchValue('');

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      this.invoiceFrm.controls.BUYER_CODE.patchValue(item, { emitEvent: false });
      /*
      console.warn(this.matchContent);
      this.matchContent[1]['buyer'].newValue = valueArray[1];
      */
      //Update AI 
      this.updateAIContent(valueArray[1], "buyer", '');

    }
  }
  assignPortDD(event) {

    if (event.target.value == 0) {
      this.totalPort = [];
      this.invoiceFrm.controls.PORT_NAME.patchValue('');

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      this.invoiceFrm.controls.PORT_CODE.patchValue(item, { emitEvent: false });

      let filteredData = this.totalPort.find(item => item.code == valueArray[0]);

      let itemOrigin = {
        "id": filteredData.COUNTRY_CODE,
        "name": filteredData.COUNTRY_NAME
      }
      this.invoiceFrm.controls.Country_Origin_Name.patchValue(filteredData.COUNTRY_NAME, { emitEvent: false });
      this.invoiceFrm.controls.Country_Origin.patchValue(itemOrigin, { emitEvent: false });
    }
  }
  assignPortDischargeDD(event) {

    if (event.target.value == 0) {
      this.totalPortDischarge = [];
      this.invoiceFrm.controls.Port_DischargeName.patchValue('');

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      this.invoiceFrm.controls.Port_Discharge.patchValue(item, { emitEvent: false });
    }
  }
  assignFinalPortDD(event) {

    if (event.target.value == 0) {
      this.totalFinalPort = [];
      this.invoiceFrm.controls.Port_Final_NAME.patchValue('');

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }

      let filteredData: any;

      filteredData = this.totalFinalPort.find(item => item.code == valueArray[0]);

      let itemDestination = {
        "id": filteredData.COUNTRY_CODE,
        "name": filteredData.COUNTRY_NAME
      }
      this.invoiceFrm.controls.COUNTRY_CODE_NAME.patchValue(filteredData.COUNTRY_NAME, { emitEvent: false });
      this.invoiceFrm.controls.COUNTRY_CODE.patchValue(itemDestination, { emitEvent: false });

      this.invoiceFrm.controls.Port_Final.patchValue(item, { emitEvent: false });

    }
  }
  assignExporterDD(event) {

    if (event.target.value == 0) {
      this.totalExporter = [];
      this.invoiceFrm.controls.EXP_NAME.patchValue('');
    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      //Below code to get exporter plants 
      //this.expName = decodedToken['result'].results[0].exp_name;
      //code to get exporter plants 
      this._service.getExporterPlants(valueArray[0]).subscribe((exporterPlants: any) => {
        this.manufacturerPlants = exporterPlants.data;
      });

      this.invoiceFrm.controls.EXP_CODE.patchValue(item, { emitEvent: false });
      this.updateAIContent(valueArray[1], "exporter", '');


    }
  }
  selectEventConsinee(item) {
    this.updateAIContent(item.name, "buyer", '');
    this.invoiceFrm.controls.BUYER_CODE.patchValue(item, { emitEvent: false });
  }
  onChangeSearchConsinee(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allConsinee.length; i++) {
      let country = this.allConsinee[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }

    this.filteredConsinee = filtered;
    this.filteredConsinee = this.filteredConsinee.slice(0, 10);
    /*
    this._service.getBuyerSearch(val).subscribe((buyerList) => {
      let filtered: any[] = [];
      this.buyer = buyerList;
      for (let i = 0; i < this.buyer.length; i++) {
        let buyer = this.buyer[i];
        if (buyer.name != null) {
          var buyerData = {
            id: buyer.code,
            name: buyer.name
          }
          filtered.push(buyerData);
        }
      }
      this.filteredConsinee = filtered;
    });
    */
  }
  onFocused(e) {
    // do something when input is focused
  }
  selectEventPort(item) {
    this.invoiceFrm.controls.PORT_CODE.patchValue(item, { emitEvent: false });
    let itemOrigin = {
      "id": item.countryCode,
      "name": item.countryName
    }
    this.invoiceFrm.controls.Country_Origin_Name.patchValue(item.countryName, { emitEvent: false });
    this.invoiceFrm.controls.Country_Origin.patchValue(itemOrigin, { emitEvent: false });
  }
  //Code to get Port 
  onChangeSearchPort(val: string) {


    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allPorts.length; i++) {
      let item = this.allPorts[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredPort = filtered;
    this.filteredPort = this.filteredPort.slice(0, 10);
    /*
    this._service.getPortSearch(val).subscribe((portList) => {
      let filtered: any[] = [];
      this.port = portList;
      for (let i = 0; i < this.port.length; i++) {
        let port = this.port[i];
        if (port.name != null) {
          var portData = {
            id: port.code,
            name: port.name,
            countryCode: port.COUNTRY_CODE,
            countryName: port.COUNTRY_NAME,
            detail: port.name + "-" + port.code,

          }
          filtered.push(portData);
        }
      }
      this.filteredPort = filtered;
    });
    */
  }
  selectEventPortDischarge(item) {
    this.invoiceFrm.controls.Port_Discharge.patchValue(item, { emitEvent: false });
  }
  selectEventFinalPort(item) {
    this.invoiceFrm.controls.Port_Final.patchValue(item, { emitEvent: false });
    // this.invoiceFrm.controls.COUNTRY_CODE.patchValue(item.countryName, {emitEvent: false});
    this.invoiceFrm.controls.COUNTRY_CODE_NAME.patchValue(item.countryName, { emitEvent: false });
    let itemDestination = {
      "id": item.countryCode,
      "name": item.countryName
    }
    this.invoiceFrm.controls.COUNTRY_CODE.patchValue(itemDestination, { emitEvent: false });

  }
  selectEventCountryOrigin(item) {
    this.invoiceFrm.controls.Country_Origin.patchValue(item, { emitEvent: false });
  }
  //Code to get country 
  onChangeSearchCountry(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allCountry.length; i++) {
      let item = this.allCountry[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredCountry = filtered;
    this.filteredCountry = this.filteredCountry.slice(0, 10);
    /*
    this._service.getCountrySearch(val).subscribe((countryList) => {
      let filtered: any[] = [];
      this.country = countryList;
      for (let i = 0; i < this.country.length; i++) {
        let country = this.country[i];
        if (country.name != null) {
          var countryData = {
            id: country.code,
            name: country.name
          }
          filtered.push(countryData);
        }
      }
      this.filteredCountry = filtered;
    });
    */
  }

  selectEventCountryCode(item) {
    this.invoiceFrm.controls.COUNTRY_CODE.patchValue(item, { emitEvent: false });
  }
  calculateItemsFOB() {
    //Code to calculate the Items FOB     
    if (this.invoiceDetail.itemsDetail.length > 0) {
      FobRate: Number;
      let netWeight = 0;
      let netFright = 0;
      let frightPerKg = 0;
      if (this.invoiceFrm.value.Tot_Weight > 0) {
        netWeight = this.invoiceFrm.value.Tot_Weight;
      }
      if (this.invoiceFrm.value.Tot_Weight > 0) {
        netFright = this.invoiceFrm.value.Tot_Freight;
      }
      if (netWeight > 0) {
        frightPerKg = netFright / netWeight;
      }
      frightPerKg = parseFloat(this.invoiceFrm.value.FKG);
      for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
        const FOBRateKg = this.invoiceDetail.itemsDetail[i].RATE - frightPerKg;
        const FobRate = this.invoiceDetail.itemsDetail[i].Qty * FOBRateKg;
        const FobRateRs = FobRate * this.invoiceFrm.value.DOL_RATE;

        const fobVal = FobRate * this.invoiceFrm.value.DOL_RATE;
        const ItemFright = (netFright / netWeight) * parseFloat(this.invoiceDetail.itemsDetail[i].itemQuantity);
        this.invoiceDetail.itemsDetail[i].Freight = ItemFright.toFixed(2);
        this.invoiceDetail.itemsDetail[i].TOTPRICE_RS = FobRate.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Dol = FobRate.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Rs = FobRateRs.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Value = fobVal;
      }
      //Code to get sum of quantity
      this.totalQuantity = this.getotalQuantity();
      this.totalQuantityUnit = this.getotalQuantityUnit();
      this.totalPrice = this.getotalAmount();
      this.totalAmountDollar = this.getotalAmountDollar()
      this.totalAmountRs = this.getotalAmountRs()
      //Update Total Quantity and price 

      //Coe to bind  Item Group Summary 
      this.itemGroupDetail = this.getItemGroupSummary();

      //code to update all items 
      this.updateInvoiceItem()
    }
  }
  getotalQuantity() {
    let quantity = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].TAILQTY);
    }
    return quantity.toFixed(2);
  }
  getotalQuantityUnit() {
    let quantity = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].Qty);
    }
    return quantity.toFixed(2);
  }
  getotalAmount() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
    }
    return totalprice.toFixed(2);
  }
  getotalAmountDollar() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
    }
    return totalprice.toFixed(2);
  }
  getotalAmountRs() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Rs);
    }
    return totalprice.toFixed(2);
  }
  getItemGroupSummary() {
    let groupItem: any[] = [];
    let quantity = 0;
    let amount: number = 0;
    let quantityKg = 0;
    let amountFob: number = 0;

    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      //console.warn(this.invoiceDetail.itemsDetail[i]);
      if (groupItem.find(item => item.GroupCode == this.invoiceDetail.itemsDetail[i].GROUP_CODE)) {
        // quantity = groupItem[0].GroupQuantity;
        quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].Qty);
        quantityKg = quantityKg + parseFloat(this.invoiceDetail.itemsDetail[i].TAILQTY);
        amount = parseFloat(groupItem[0].amount);
        amount = amount + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
        amountFob = amountFob + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Dol);
        groupItem[0].GroupQuantity = quantity;
        groupItem[0].QuantityKg = quantityKg;
        //    groupItem[0].quantityKg = quantityKg;
        groupItem[0].amount = amount.toFixed(2);
        groupItem[0].amountFob = amountFob.toFixed(2);

      }
      else {
        quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].Qty);
        quantityKg = quantityKg + parseFloat(this.invoiceDetail.itemsDetail[i].TAILQTY);

        amount = amount + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
        amountFob = amountFob + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Dol);
        var itemDetail = {
          "GroupCode": this.invoiceDetail.itemsDetail[i].GROUP_CODE,
          "GroupName": this.invoiceDetail.itemsDetail[i].GROUP_NAME,
          "GroupQuantity": this.invoiceDetail.itemsDetail[i].Qty,
          "GroupUnit": this.invoiceDetail.itemsDetail[i].KILLBS,
          "QuantityKg": this.invoiceDetail.itemsDetail[i].TAILQTY,
          "amount": this.invoiceDetail.itemsDetail[i].TOTPRICE,
          "amountFob": this.invoiceDetail.itemsDetail[i].FOB_Dol,
        }
        groupItem.push(itemDetail);
      }


    }
    return groupItem;
  }
  updateInvoiceItem() {
    let frightPerKg = this.invoiceFrm.value.Tot_Freight / this.invoiceFrm.value.Tot_Weight;

    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      let fob = this.invoiceDetail.itemsDetail[i].TAILQTY * frightPerKg;
      this.invoiceDetail.itemsDetail[i].Freight = fob;
      this.invoiceDetail.itemsDetail[i].FOB_Dol = this.invoiceDetail.itemsDetail[i].TOTPRICE - fob;
      this.invoiceDetail.itemsDetail[i].TOTPRICE_RS = this.invoiceDetail.itemsDetail[i].TOTPRICE - fob;
    }
  }
  onChangeSearch(val: string) {

    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allExporters.length; i++) {
      let item = this.allExporters[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredExporters = filtered;
    this.filteredExporters = this.filteredExporters.slice(0, 10);
    /*
    this._service.getExporters(val).subscribe((exporterList) => {
      let filtered: any[] = [];
      this.exporters = exporterList;
      for (let i = 0; i < this.exporters.length; i++) {
        let exporter = this.exporters[i];
        if (exporter.name != null) {
          var exp = {
            id: exporter.code,
            name: exporter.name,
            impExpCode: exporter.Imp_Exp_No

          }
          filtered.push(exp);
        }
      }
      this.filteredExporters = filtered;
    });
    */
  }
  selectEventExporter(item) {
    this.invoiceFrm.controls.EXP_CODE.patchValue(item, { emitEvent: false });
    this.updateAIContent(item.name, "exporter", '');
    this._service.getExporterPlants(item.id).subscribe((exporterPlants: any) => {
      this.manufacturerPlants = exporterPlants.data;
    });
    this.invoiceFrm.controls.Imp_Exp_No.patchValue(item.impExpCode, { emitEvent: false });
  }
  getState(event) {
    const value = event.target.value;
    let filteredData: any;
    filteredData = this.manufacturerPlants.find(item => item.code == value);
    // console.warn(this.manufacturerPlants);
    this.plantState = filteredData.Stname;
    this.invoiceFrm.controls.plantState.patchValue(filteredData.Stcode);
    this.invoiceFrm.controls.plantStateName.patchValue(filteredData.Stname);


  }
  getOtherTotal() {
    let Tot_Freight: any = 0;
    if (this.invoiceFrm.value.Tot_Freight > 0) {
      Tot_Freight = this.invoiceFrm.value.Tot_Freight;
    }
    let C_Insure: any = 0;
    if (this.invoiceFrm.value.C_Insure > 0) {
      C_Insure = this.invoiceFrm.value.C_Insure;
    }
    let C_Disc: any = 0;
    if (this.invoiceFrm.value.C_Disc > 0) {
      C_Disc = this.invoiceFrm.value.C_Disc;
    }
    let C_Comm: any = 0;
    if (this.invoiceFrm.value.C_Comm > 0) {
      C_Comm = this.invoiceFrm.value.C_Comm;
    }
    let C_Other: any = 0;
    if (this.invoiceFrm.value.C_Other > 0) {
      C_Other = this.invoiceFrm.value.C_Other;
    }
    //   let total = parseFloat(Tot_Freight) + parseFloat(C_Insure) + parseFloat(C_Disc) + parseFloat(C_Comm) + parseFloat(C_Other);
    let total = parseFloat(Tot_Freight) + parseFloat(C_Insure) + parseFloat(C_Disc) + parseFloat(C_Other);
    this.groupTotal = total;

    return total;
  }
  updateItems($event, i) {
    //this.isloader = true;
    let selectedItem = this.invoiceDetail.itemsDetail[i].items.find(item => item.ITEM_CODE == $event.target.value);

    if ($event.target.value == 0) {
      this.invoiceDetail.itemsDetail[i]['showItem'] = 0;
      this.invoiceDetail.itemsDetail[i]['ITEM_CODE'] = ''
      this.invoiceDetail.itemsDetail[i]['GROUP_CODE'] = '';
      this.invoiceDetail.itemsDetail[i]['GROUP_NAME'] = '';
      this.invoiceDetail.itemsDetail[i]['ITEM_NAME'] = '';
      this.invoiceDetail.itemsDetail[i]['scientific_name_array'] = [];
      this.itemGroupDetail = this.getItemGroupSummary();
      let hsCode = this.invoiceDetail.itemsDetail[i].hs_code;
      this.isloader = true;
      this._service.getItemSearch('', hsCode).subscribe((itemList) => {
        this.allItems = itemList;
        this.isloader = false;

      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      })
    }
    else {
      let sNameArray = selectedItem.scientific_name.split(",");
      this.invoiceDetail.itemsDetail[i]['ITEM_CODE'] = selectedItem.ITEM_CODE;
      this.invoiceDetail.itemsDetail[i]['GROUP_CODE'] = selectedItem.GROUP_CODE;
      this.invoiceDetail.itemsDetail[i]['GROUP_NAME'] = selectedItem.GROUP_NAME;
      this.invoiceDetail.itemsDetail[i]['ITEM_NAME'] = selectedItem.ITEM_NAME;
      this.invoiceDetail.itemsDetail[i]['scientific_name_array'] = sNameArray;
      this.itemGroupDetail = this.getItemGroupSummary();

      this.updateAIContent(selectedItem.ITEM_NAME, "items", i);


    }
    /*
        this._service.getItemGrades(selectedItem.ITEM_CODE).subscribe((gradeList) => {
          this.isloader = false;
          console.warn(gradeList)
        });
        */
  }
  onChangeSearchGrade(event, i) {
    //console.warn(event);
    if (this.invoiceDetail.itemsDetail[i].ITEM_CODE != "" && event != "") {
      this._service.getItemGrades(this.invoiceDetail.itemsDetail[i].ITEM_CODE, event).subscribe((gradeList) => {
        this.isloader = false;
        this.filteredGrade = gradeList[0].gradeDetail;
      });
    }

  }
  onFocusedGrade(event) {
    this.filteredGrade = [];
  }
  selectEventGrades(event, i) {
    this.invoiceDetail.itemsDetail[i]['GRADE_CODE'] = event.code;
    this.invoiceDetail.itemsDetail[i]['grade_name'] = event.name;
    this.invoiceDetail.itemsDetail[i]['Size_Name'] = event.name;

  }
  updateCartons(event, i) {
    this.invoiceDetail.itemsDetail[i]['cartons_number'] = event.target.value;

  }
  selectEventItem(item, i) {

    let sNameArray = item.scientific_name.split(",");

    this.invoiceDetail.itemsDetail[i]['ITEM_CODE'] = item.code;
    this.invoiceDetail.itemsDetail[i]['GROUP_CODE'] = item.GROUP_CODE;
    this.invoiceDetail.itemsDetail[i]['GROUP_NAME'] = item.GROUP_NAME;
    this.invoiceDetail.itemsDetail[i]['ITEM_NAME'] = item.name;
    //    this.invoiceDetail.itemsDetail[i]['scientific_name'] = item.scientific_name;
    this.invoiceDetail.itemsDetail[i]['scientific_name_array'] = sNameArray;
    this.updateAIContent(item.name, "items", i);

    this.itemGroupDetail = this.getItemGroupSummary();
  }
  onChangeSearchItems(val: string, i) {

    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allItems.length; i++) {
      let item = this.allItems[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredItems = filtered;
    this.filteredItems = this.filteredItems.slice(0, 10);
    /*    
        let hsCode = this.invoiceDetail.itemsDetail[i].hs_code;
    
        this._service.getItemSearch(val, hsCode).subscribe((itemList) => {
          this.filteredItems = itemList;
    
        })
    
        */
  }
  onFocusedItem(e) {
    this.filteredItems = [];
    // do something when input is focused
  }
  onSbFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.invoiceFrm.patchValue({
        fileSource: file
      });
    }
  }
  onInvoiceFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.invoiceFrm.patchValue({
        InvfileSource: file
      });
    }
  }
  updateSname($event, i) {
    //    console.warn($event.target.value);
    this.invoiceDetail.itemsDetail[i]['scientific_name'] = $event.target.value;
  }
  onChangeSearchPortIndia(val: string) {

    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allPortsIndia.length; i++) {
      let item = this.allPortsIndia[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredPortIndia = filtered;
    this.filteredPortIndia = this.filteredPortIndia.slice(0, 10);
  }
  checkBillNumber() {
    let data = {
      "sbNumber": this.invoiceFrm.value.SB_No,
      "id": this.invoiceFrm.value.invoiceId
    }
    if (this.invoiceFrm.value.SB_No != "") {
      this._commonService.checkSbNumber(data).subscribe((item: any) => {
        if (item.success == false) {
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: item.message, life: 6000 }); //success,info,warn,error

        }
      });
    }
  }

  updateAIContent(value, type, j) {
    //    console.warn("In function ", this.matchContent);
    //   console.warn("Length of content =", this.matchContent.length);

    for (let i = 0; i < this.matchContent.length; i++) {
      // console.warn("Array value =", this.matchContent[i]);
      for (var key in this.matchContent[i]) {
        //console.warn("key = ", key);
        if (key == type) {
          if (key == "items") {
            this.matchContent[i][key][j].item.newValue = value;
          }
          else {
            this.matchContent[i][key].newValue = value;
          }

        }
      }
    }
  }
  subgroups(): FormArray {
    return this.invoiceFrm.get("subgroups") as FormArray
  }
  newSubgroup(data): FormGroup {

    return this.formBuilder.group({
      itemName: ['']
    })
  }
  createform() {
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      this.subgroups().push(this.newSubgroup(this.invoiceDetail.itemsDetail[i]));
    }
  }

}

