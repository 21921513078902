
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ExportReviewService } from '../export-review.service';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { CommonServiceService } from '../../../common-service.service';
// import { ExcelService } from '../excel.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-all-monthly-wise-report',
  templateUrl: './all-monthly-wise-report.component.html',
  styleUrls: ['./all-monthly-wise-report.component.css']
})

export class AllMonthlyWiseReportComponent implements OnInit {

  reportTitle: string;
  reportFrm: FormGroup;
  sourceGroup: any;
  targetGroup: any;
  sourceItems: any;
  targetItems: any;
  addedGroups: any = [];
  isloader: boolean = false;
  displayReport: boolean = false;
  submitted: boolean = false;
  addedItems: any = [];
  reportData: any = [];
  reprttDate: any;
  data: any = {};
  detailReportData: any = [];
  displayDetailedReport: boolean = false;
  yearRange: any = [];
  yearRange2: any = [];
  growth: any = [];
  variationsRange: any = [];
  headerLength: any;
  headerLength2: any;
  page:any;
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;

  customers : any=[];
  frozenCols : any = [];
  scrollableCols: any[];
  
  exportTitle :any;
  exportHeader :any=[];
  exportData :any=[];

  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;

  constructor(private formBuilder: FormBuilder, private _service: ExportReviewService, private primengConfig: PrimeNGConfig, private _router: Router, private messageService: MessageService, private commonService: CommonServiceService ) { }

  ngOnInit(): void {

   


    this.frozenCols = [
      { field: 'name', header: 'Name' },
    ];

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;
    this.page =  reportType;


    this.reportTitle = "Month Wise Item";


    if(reportType=='monthly'){

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required]],
        dateEnd: ['', [Validators.required]],
        selectedGroups: [''],
        reportType: ["monthly"],
        selectedItems: [''],
        viewDetailReport: [''],
      });

    }else{

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required]],
        selectedGroups: [''],
        reportType: ["all"],
        selectedItems: [''],
        viewDetailReport: [''],
      });

    }
    
    






    this._service.getGroups().subscribe((items: any) => {
      this.sourceGroup = items.data;
    });

    this.targetGroup = [];
    this.sourceItems = [];
    this.targetItems = [];
    this.primengConfig.ripple = true;

    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }

  
  getGroupsItems() {

    if (this.addedGroups.length > 0) {
      this._service.getGroupItems(this.addedGroups).subscribe((items: any) => {
        this.sourceItems = items.data;
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });
    }
    else {
      this.sourceItems = [];
    }
  }


  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }
  
  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }



  getReport() {
    this.isloader = true;
    this.submitted = true;
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    
    if (this.reportFrm.value.selectedItems == "") {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Group Items", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }


    if(this.page=='all'){
      


      this._service.getItemwiseAllMonthlyReport(this.reportFrm.value).subscribe((items: any) => {

        this.displayReport = true;
        this.isloader = false;
  
        this.data = items.data;
        this.reportData = items.data.reportData;
        this.scrollableCols = items.data.header;
        
         console.log(items.data.header.length);
        for(let i=0;i<items.data.header.length;i++){
            this.exportHeader.push(items.data.header[i].header);
        }

        var result;
        for(let i=0;i<items.data.reportData.length;i++){
            result = items.data.reportData[i];
            this.exportData.push([
              result.name,
              result.aprilCurrentYear,
              result.aprilPreviousYear,
              result.aprilShare,
              result.aprilVariation,
              result.aprilGrowth,

              result.mayCurrentYear,
              result.mayPreviousYear,
              result.mayShare,
              result.mayVariation,
              result.mayGrowth,

              result.juneCurrentYear,
              result.junePreviousYear,
              result.juneShare,
              result.juneVariation,
              result.juneGrowth,

              result.julyCurrentYear,
              result.julyPreviousYear,
              result.julyShare,
              result.julyVariation,
              result.julyGrowth,

              result.augCurrentYear,
              result.augPreviousYear,
              result.augShare,
              result.augVariation,
              result.augGrowth,


              result.sepCurrentYear,
              result.sepPreviousYear,
              result.sepShare,
              result.sepVariation,
              result.sepGrowth,

              result.octCurrentYear,
              result.octPreviousYear,
              result.octShare,
              result.octVariation,
              result.octGrowth,

              result.novCurrentYear,
              result.novPreviousYear,
              result.novShare,
              result.novVariation,
              result.novGrowth,

              result.decCurrentYear,
              result.decPreviousYear,
              result.decShare,
              result.decVariation,
              result.decGrowth,

              result.janCurrentYear,
              result.janPreviousYear,
              result.janShare,
              result.janVariation,
              result.janGrowth,

              result.febCurrentYear,
              result.febPreviousYear,
              result.febShare,
              result.febVariation,
              result.febGrowth,

              result.marCurrentYear,
              result.marPreviousYear,
              result.marShare,
              result.marVariation,
              result.marGrowth,
            ]);
            
            
        }
  
       
       
      }, error => {
  
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });


    }else{
      

      this._service.getItemwiseAllMonthlyReport2(this.reportFrm.value).subscribe((items: any) => {
        this.data = items.data;
        if (items.data.reportData.length > 0) {
          for (let i = 0; i < items.data.reportData.length; i++) {
            for (let j = 0; j < items.data.reportData[i].groupItems.length; j++) {
              items.data.reportData[i].groupItems[j].groupName = items.data.reportData[i].groupName;
              this.detailReportData.push(items.data.reportData[i].groupItems[j]);
            }
          }
          this.displayReport = true;
          this.isloader = false;
        }
        else {
          this.displayReport = false;
          this.isloader = false;
        }
        this.reportData = items.data.reportData;
        this.yearRange = items.data.reportData[0].groupItems[0].years;
        this.yearRange2 = items.data.reportData[0].groupItems[0].yearsRange;
  
        console.log("Year Range -2", this.yearRange2);
        this.growth = items.data.reportData[0].groupItems[0].growth;
        this.variationsRange = items.data.reportData[0].groupItems[0].variationsRange;
  
        this.headerLength = parseInt(items.data.reportData[0].groupItems[0].years.length) * 3 + 2;
  
        this.headerLength2 = parseInt(items.data.reportData[0].groupItems[0].years.length) + 2;
  
        console.log("Header Length :", this.headerLength);
  
  
  
        console.log("header Length :", items.data.reportData[0].groupItems[0].years.length);
  
      }, error => {
  
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });

    }


    
  }


 



  getShare(itemQty, totalQty) {
    let percentage = itemQty * 100 / totalQty;
    return percentage ? percentage.toFixed(2) : 0;
  }

  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Region Data');
      xlsx.writeFile(workbook, "region_report.xlsx");

    });
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report_detail.csv");

    });
  }


  addItems(event) {

    for (let i = 0; i < event.items.length; i++) {
      this.addedItems.push(event.items[i]);
    }
    this.reportFrm.controls.selectedItems.patchValue(this.addedItems, { emitEvent: false });
  }
  addChecksums(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.addedGroups.push(event.items[i]);
    }
    this.reportFrm.controls.selectedGroups.patchValue(this.addedGroups, { emitEvent: false });
    this.getGroupsItems();
  }
  removeGroups(event) {
    for (let i = 0; i < event.items.length; i++) {
     
      this.addedGroups.splice(this.addedGroups.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedGroups.patchValue(this.addedGroups, { emitEvent: false });
    this.getGroupsItems();
  }

  removeItems(event) {

    for (let i = 0; i < event.items.length; i++) {
     
      this.addedItems.splice(this.addedItems.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedItems.patchValue(this.addedItems, { emitEvent: false });
  }





  generateExcel() {
    //Excel Title, Header, Data
    const title = 'Monthly Wise Report';
    // const header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"];
    const header = this.exportHeader;
    /*
    const data = [
      [2007, 1, "Volkswagen ", "Volkswagen Passat", 1267, 10],
      [2007, 1, "Toyota ", "Toyota Rav4", 819, 6.5],
    ];
    */

    const data = this.exportData;
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Month-Wise-Report');
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Verdana', family: 4, size: 16, underline: 'double', bold: true }
    worksheet.addRow([]);
    // let subTitleRow = 'test';
    //Add Image
    // let logo = 'zxczz';
    // worksheet.addImage(logo, 'E1:F3');


    worksheet.mergeCells('A1:D2');
    //Blank Row 
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
      let qty = row.getCell(5);
      let color = 'FF99FF99';
      /*
      if (+qty.value < 500) {
        color = 'FF9999'
      }
      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
      */
    }
    );
    //worksheet.getColumn(1).width = 30;
    for(let i=1;i<31;i++){
      worksheet.getColumn(i).width = 30;
    }
    //worksheet.getColumn(2).width = 30;
    worksheet.addRow([]);

    
    //Footer Row
    let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Monthly-Wise-Report.xlsx');
    })
  }




}

