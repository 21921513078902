<nav class="navbar navbar-expand-xl bgblue  border-bottom py-xl-0"
  *ngIf="checkLogin() && routerUrl!='/' && !isExporter">
  <a class="navbar-brand" [routerLink]="['/dashboard']"> <img class="img-fluid" src="assets/img/small_logo.png" alt="">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"> <i class="fa fa-bars"></i> </span>
  </button>
  <div class="collapse navbar-collapse headerlinks" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <ng-template [ngIf]="menus" *ngFor="let menu of menus">
        <li class="nav-item" *ngIf="menu.menu_type=='dd'"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><i
              class="{{menu.cssname}}"></i> {{menu.name}}</a>
          <ul class="dropdown-menu">
            <ng-template [ngIf]="menu.submenus" *ngFor="let submenu of menu.submenus">
              <li *ngIf="menu.menu_id=='6' && (submenu.link!='itemmaster' && submenu.link!='regionmaster')"><a
                  [routerLink]="['/master']" [queryParams]="{page:[submenu.link]}">{{submenu.name}}</a></li>
              <li *ngIf="menu.menu_id=='6' && (submenu.link=='itemmaster' || submenu.link=='regionmaster')"><a
                  [routerLink]="[submenu.link]">{{submenu.name}}</a></li>

              <li *ngIf="menu.menu_id!='6'"><a [routerLink]="[submenu.link]">{{submenu.name}}</a></li>
            </ng-template>

          </ul>
        </li>
        <li class="nav-item" *ngIf="menu.menu_type!='dd'"><a [routerLink]="[menu.link]"> <i
              class="{{menu.cssname}}"></i> {{menu.name}}</a> </li>
      </ng-template>
    </ul>
    <div class="profilemenu">
      <div class="avtar d-flex align-items-center position-relative">
        <!--<img src="assets/img/avtar.png" alt=""> -->&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a class="text-white dropdown-toggle" data-toggle="dropdown" href="#"> {{firstname}} {{lastname}} </a>
        <ul class="dropdown-menu w-100 profilebox">
          <!--
          <li> <a href="#"> <i class="fa fa-id-card d-inline-block"></i> Profile </a> </li>
          <li> <a href="#"> <i class="fa fa-edit d-inline-block"></i> Edit </a> </li>
          -->
          <li> <a [routerLink]="['user/change-password']"> Change Password </a> </li>
          <li> <a (click)="logOut();" href="#"> <i class="fa fa-power-off d-inline-block"></i> Logout </a> </li>
        </ul>
      </div>
    </div>

  </div>

</nav>

<!-- Code For exporter Menus -->
<nav class="navbar navbar-expand-xl bgblue  border-bottom py-xl-0"
  *ngIf="checkLogin() && routerUrl!='/exporter/login' && isExporter">
  <a class="navbar-brand" [routerLink]="['/exporter/dashboard']"> <img class="img-fluid" src="assets/img/small_logo.png"
      alt="">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"> <i class="fa fa-bars"></i> </span>
  </button>
  <div class="collapse navbar-collapse headerlinks" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item"><a [routerLink]="['/exporter/dashboard']"> <i class="icons menu-1"></i> Dashboard</a> </li>
      <li class="nav-item"><a [routerLink]="['/exporter/invoices']"> <i class="icons menu-2"></i> Shipping Bill</a>
      </li>
      <li class="nav-item"><a [routerLink]="['/exporter/reports']"><i class="icons menu-3"></i> Reports</a></li>
      <li class="nav-item"><a [routerLink]="['/exporter/requestList']"><i class="icons menu-3"></i> Request</a></li>
      <li class="nav-item"><a [routerLink]="['/exporter/reconcilation']"><i class="icons menu-3"></i> Reconcile Shipping
          Bill</a></li>

    </ul>
    <div class="profilemenu">
      <div class="avtar d-flex align-items-center position-relative">
        <a class="text-white dropdown-toggle" (click)="exporterlogOut();"> <i
            class="fa fa-power-off d-inline-block"></i> Logout </a>

      </div>
    </div>
  </div>
</nav>