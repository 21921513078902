import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SetupServiceService {

  constructor(private http: HttpClient) { }
  url = `${apiUrl}`;

  getBranches(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "branches/getBranches",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }
  getRoles(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "roles/getRoles",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }
  getUsers(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "users/getUsers",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }

  getActiveBranch() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "branches/getActiveBranches", '', options);
  }
  getUserType() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "users/getusertype", '', options);
  }

  saveBranch(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    if (data.branchId != "") {
      return this.http.post(this.url + "branches", { data: data }, options);
    }
    else {
      return this.http.post(this.url + "branches", { data: data }, options);
    }
  }

  saveUser(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    if (data.userId != "") {
      return this.http.post(this.url + "users", { data: data }, options);
    }
    else {
      return this.http.post(this.url + "users", { data: data }, options);
    }
  }

  getBranchDetail(branchId) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "branches/getBranchDetail", { id: branchId }, options);
  }

  getUserDetail(userId) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "users/getUserDetail", { id: userId }, options);
  }


  getMenus() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "roles/getMenus", '', options);
  }
  saveRoles(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    if (data.roleId != "") {
      return this.http.post(this.url + "roles", { data: data }, options);
    }
    else {
      return this.http.post(this.url + "roles", { data: data }, options);
    }


  }
  getRoleDetail(roleId) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "roles/getRoleDetail", { id: roleId }, options);
  }
  getUserSetups() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "users/getSetups", '', options);
  }
  syncExporters(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/syncExporters", { data: data }, options);

  }
  getExporters() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getMastersExpoters", '', options);
  }
  updateExporter(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/updateExporter", { data: data }, options);

  }
  syncPlants(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getPlants", { data: data }, options);
  }
  getPlants() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getPlantsMaster", options);
  }
  updatePlant(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/updatePlant", { data: data }, options);

  }

  getBranchExporters() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let data = {

    };
    return this.http.post(this.url + "setup/getBranchExporters", data, options);
  }
  getExporterInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getExporterInvoice", { data: data }, options);

  }
  saveBatch(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/saveBatch", { data: data }, options);
  }
  getBatchList(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getBatchList",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }

  getBatchDetail(batchId) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getBatchDetail", { batchId: batchId }, options);
  }
  getReconcilationDetail(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getRecocilationDetails", { data: data }, options);

  }
  saveFinalReconcilation(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/saveFinalReconcilation", { data: data }, options);

  }
  getFinalReconcileList(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getFinalRecocile",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }
  getFinalReconcileDetail(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getFinalReconcileDetail", { data: data }, options);

  }

  updatePassword(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "users/changePassword", { data: data }, options);

  }
}
