<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> Export Of Item Group In Various Form </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                            yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <p-calendar formControlName="dateEnd" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                            yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Groups </label>
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeGroups($event)"
                            (onMoveToTarget)="addChecksums($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>



                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Items </label>
                        <p-pickList [source]="sourceItems" [target]="targetItems" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeItems($event)"
                            (onMoveToTarget)="addItems($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>


                </div>
                <div class="btn_pan mb-4">
                    <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                </div>
                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>

                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span>Q: Quantity in M Tons,</span>
                    <span>V: Value in Rs. Crores,</span>
                    <span>$: USD Million</span>
                </div>
                <!--code for detailed report -->
                <div class="card" *ngIf="displayDetailedReport">
                    <table #detailTable>
                        <p-table [value]="detailReportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o"
                                        (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportDetailExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="1">&nbsp;</th>
                                    <th colspan="1">&nbsp;</th>

                                    <th colspan="3">{{data.dateStart}}</th>
                                    <th colspan="3">Share % </th>
                                    <th colspan="3">{{data.endDate}}</th>
                                    <th colspan="3">Share % </th>
                                    <!-- <th colspan="4">Growth</th>
                                <th colspan="4">Growth (%)</th> -->
                                </tr>
                                <tr>
                                    <th>Group </th>
                                    <th pSortableColumn="name">Item <p-sortIcon field="name"></p-sortIcon>
                                    </th>


                                    <th pSortableColumn="Qtyintons">Q <p-sortIcon field="Qtyintons"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobRsCr">V <p-sortIcon field="FobRsCr"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobDolMil">$ <p-sortIcon field="FobDolMil"></p-sortIcon>
                                    </th>


                                    <th pSortableColumn="QtyPreTon">Q <p-sortIcon field="QtyPreTon"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobRsPreCr">V <p-sortIcon field="FobRsPreCr"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobDolPreMil">$ <p-sortIcon field="FobDolPreMil"></p-sortIcon>
                                    </th>


                                    <th pSortableColumn="qtyVariation">Q <p-sortIcon field="qtyVariation"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="rsVar">V <p-sortIcon field="rsVar"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dolVar">$ <p-sortIcon field="dolVar"></p-sortIcon>
                                    </th>

                                    <th pSortableColumn="qtyVariation">Q <p-sortIcon field="qtyVariation"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="rsVar">V <p-sortIcon field="rsVar"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dolVar">$ <p-sortIcon field="dolVar"></p-sortIcon>
                                    </th>



                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                                <tr>
                                    <td><strong>{{item.groupName}}</strong></td>
                                    <td>{{item.name}}</td>

                                    <td>{{getNumber(item.Qtyintons,2)}}</td>
                                    <td>{{getNumber(item.FobRsCr,2)}}</td>
                                    <td>{{getNumber(item.FobDolMil,2)}}</td>

                                    <td>{{getShare(item.Qtyintons,data.totalQuantity)}}</td>
                                    <td>{{getShare(item.FobRsCr,data.totalPriceRs)}}</td>
                                    <td>{{getShare(item.FobDolMil,data.totalPriceDol)}}</td>




                                    <td>{{getNumber(item.QtyPreTon,2)}}</td>
                                    <td>{{getNumber(item.FobRsPreCr,2)}}</td>
                                    <td>{{getNumber(item.FobDolPreMil,2)}}</td>

                                    <td>{{getShare(item.QtyPreTon,data.totalQuantityPrev)}}</td>
                                    <td>{{getShare(item.FobRsPreCr,data.totalPriceRsPrev)}}</td>
                                    <td>{{getShare(item.FobDolPreMil,data.totalPriceDolPrev)}}</td>






                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr>
                                    <td>Total</td>
                                    <td>&nbsp;</td>

                                    <td>{{getNumber(data.totalQuantity,2)}}</td>
                                    <td>{{data.totalPriceRs}}</td>
                                    <td>{{data.totalPriceDol}}</td>

                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>



                                    <td>{{getNumber(data.totalQuantityPrev,2)}}</td>
                                    <td>{{data.totalPriceRsPrev}}</td>
                                    <td>{{data.totalPriceDolPrev}}</td>

                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>


                                </tr>
                            </ng-template>
                        </p-table>
                    </table>
                </div>
                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable>
                                    <p-table [value]="reportData">

                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Item</th>

                                                <th>&nbsp;</th>
                                                <th>{{data.endDate}}</th>
                                                <th>Share % </th>
                                                <th>{{data.dateStart}}</th>
                                                <th>Share % </th>


                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="6" class="grpitm_hed">
                                                    <strong>{{groupitem.groupName}}</strong>
                                                </td>
                                            </tr>
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <tr>
                                                    <td>{{item.name}}</td>

                                                    <td><b>Q</b> </td>
                                                    <td>{{getNumber(item.QtyPreTon,2)}}</td>
                                                    <td>{{getShare(item.QtyPreTon,
                                                        groupitem.groupTotalQuantityPrevious)}} </td>
                                                    <td>{{getNumber(item.Qtyintons,2)}}</td>
                                                    <td> {{getShare(item.Qtyintons, groupitem.groupTotalQuantity)}}</td>

                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>

                                                    <td><b>V</b> </td>
                                                    <td>{{getNumber(item.FobRsPreCr,2)}}</td>
                                                    <td>{{getShare(item.FobRsPreCr,groupitem.grpTotalPriceRsPrev)}}</td>
                                                    <td>{{getNumber(item.FobRsCr,2)}}</td>
                                                    <td>{{getShare(item.FobRsCr,groupitem.grpTotalPriceRs)}}</td>


                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>

                                                    <td><b>$</b> </td>
                                                    <td>{{getNumber(item.FobDolPreMil,2)}}</td>
                                                    <td>{{getShare(item.FobDolPreMil,groupitem.grpTotalPriceDolPrev)}}
                                                    </td>
                                                    <td>{{getNumber(item.FobDolMil,2)}}</td>
                                                    <td>{{getShare(item.FobDolMil,groupitem.grpTotalPriceDol)}}</td>


                                                </tr>

                                            </ng-template>
                                            <tr>
                                                <td><strong>Grand Total</strong></td>

                                                <td><b>Q</b>:</td>
                                                <td>{{groupitem.groupTotalQuantityPrevious}}</td>
                                                <td>{{getShare(groupitem.groupTotalQuantity,data.totalQuantity)}}</td>
                                                <td>{{groupitem.groupTotalQuantity}}</td>
                                                <td>{{getShare(groupitem.groupTotalQuantity,data.totalQuantity)}}</td>


                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                                <td>V:</td>
                                                <td>{{groupitem.grpTotalPriceRsPrev}}</td>
                                                <td>{{getShare(groupitem.grpTotalPriceRs,data.totalPriceRs)}}</td>
                                                <td>{{groupitem.grpTotalPriceRs}}</td>
                                                <td>{{getShare(groupitem.grpTotalPriceRs,data.totalPriceRs)}}</td>


                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                                <td>$</td>
                                                <td>{{groupitem.grpTotalPriceDolPrev}}</td>
                                                <td>{{getShare(groupitem.grpTotalPriceDol,data.totalPriceDol)}}</td>
                                                <td>{{groupitem.grpTotalPriceDol}}</td>
                                                <td>{{getShare(groupitem.grpTotalPriceDol,data.totalPriceDol)}}</td>


                                            </tr>


                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td width="17%">Total</td>

                                                <td width="15%"><b>Q</b>:</td>
                                                <td width="20%">{{data.totalQuantityPrev}}</td>
                                                <td width="100">100</td>
                                                <td width="20%">{{data.totalQuantity}}</td>
                                                <td width="100">100</td>


                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                                <td><b>V</b>:</td>
                                                <td>{{data.totalPriceRsPrev}}</td>
                                                <td>100</td>
                                                <td>{{data.totalPriceRs}}</td>
                                                <td>100</td>


                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><b>$</b></td>
                                                <td>{{data.totalPriceDolPrev}}</td>
                                                <td>100</td>
                                                <td>{{data.totalPriceDol}}</td>
                                                <td>100</td>

                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </table>



                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </div>
        <input type="hidden" formControlName="selectedGroups" />
        <input type="hidden" formControlName="selectedItems" />
        <input type="hidden" formControlName="reportType" />
    </form>
</div>