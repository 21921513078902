<div class="loader2" *ngIf="isloaderCountry"><img src="assets/img/loader.svg" alt=""></div>
<div class="db_selop">
    <span class="gr_tx">Country</span>
    <select class="form-control" #mySelectCountry [(ngModel)]="countryGraphType"
        (change)="changeOptionCountry(mySelectCountry.value)">
        <option value="BarChart">Bar Chart </option>
        <option value="PieChart">Pie Chart </option>
        <option value="AreaChart">Area Chart </option>
        <option value="ColumnChart">Column Chart </option>

    </select>
</div>
<ng-template [ngIf]="isShowMapCountry">
    <google-chart [type]="countryGraphType" [title]="titleCountry" [data]="dataCountry"
        style="width: 100%; height: 400px;" [options]="optionsCountry"></google-chart>
</ng-template>