import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ViewinvoiceService {
  url = `${apiUrl}`;
  constructor(private http: HttpClient) { }

  getInvoiceDetail(invoiceId) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/getInvoiceDetail", {
      "id": invoiceId
    }, options);
  }

  getExporters(query) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }

    return this.http.post(this.url + "invoices/getExportersSearch", { query: query }, options);
  }
  getManufacturer(query) {
    return this.http.post(this.url + "invoices/getManufacturerSearch", { query: query });
  }
  getBuyerSearch(query) {
    return this.http.post(this.url + "invoices/getBuyerSearch", { query: query });
  }
  getPortSearch(query) {
    return this.http.post(this.url + "invoices/getPortSearch", { query: query });
  }
  getCountrySearch(query) {
    return this.http.post(this.url + "invoices/getCountrySearch", { query: query });
  }

  getPlants(query) {
    return this.http.post(this.url + "invoices/getManufacturerPlants", { query: query });
  }

  saveInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/saveInvoice", { data: data }, options);
  }
  getSetups() {
    return this.http.post(this.url + "invoices/getSetups", {});
  }
  getItems(query) {
    query = query.split("--");
    return this.http.post(this.url + "invoices/getGroupItems", { query: query[0] });
  }
  getItemGrades(query) {
    query = query.split("--");
    return this.http.post(this.url + "invoices/getItemGrades", { query: query[0] });
  }
  deleteInvoiceItem(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/deleteItem", { data: data }, options);
  }
  getExporterPlants(expCode) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getExporterPlants", { "data": expCode }, options);
  }
  uploadInvoiceFiles(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadInvoiceFiles", data, options);
  }
  getIndianPortSearch(query) {
    return this.http.post(this.url + "invoices/getIndianPortSearch", { query: query });
  }
}
