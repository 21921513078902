<div class="loader2" *ngIf="isloaderItem" ><img src="assets/img/loader.svg" alt=""></div>
<div class="db_selop">
    <span class="gr_tx">Item</span>
    <select class="form-control"  #mySelectItem [(ngModel)]="itemGraphType" (change)="changeOptionItem(mySelectItem.value)">
        <option value="BarChart">Bar Chart </option>
        <option value="PieChart">Pie Chart </option>
        <option value="AreaChart">Area Chart </option>
        <option value="ColumnChart">Column Chart </option>
        
    </select>
</div>
<ng-template [ngIf]="isShowMapItem" >
    <google-chart  [type]="itemGraphType" [title]="titleItem" [data]="dataItem" [options]="optionsItem" style="width: 100%; height: 400px;"></google-chart>
</ng-template>    
