import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { ReportServiceService } from '../../report-service.service';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router,UrlTree,UrlSegment,PRIMARY_OUTLET,UrlSegmentGroup } from '@angular/router';
import { calendarRange } from 'src/environments/environment';

@Component({
  selector: 'app-item-wise-report',
  templateUrl: './item-wise-report.component.html',
  styleUrls: ['./item-wise-report.component.css']
})
export class ItemWiseReportComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle:string;
  targetGroup:any;
  sourceGroup:any;
  selectedItem:any=[];
  isloader:boolean=false;
  submitted:boolean=false;
  data:any;
  reportData:any=[];
  displayReport:boolean=false;
  displayDetailedReport:boolean=false;
  showTextBox:boolean=false;
  detailReportData:any=[];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName:string='';
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  calendarRange:string = calendarRange

  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,private _service:ReportServiceService,private primengConfig: PrimeNGConfig,private messageService: MessageService,private _router:Router) { }

  ngOnInit(): void {

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length-1].path;

    this.reportFrm = this.formBuilder.group({
      dateStart:['',Validators.required],
      dateEnd:['',Validators.required],
      selectedItem:[''],
      viewDetailReport:[''],
      reportType:[reportType],          
      topRecordNum:['0'],
      topRecordNumOther:['1'],
      includeBlankData:['0'],
      sortColumn:['FobDolMil'],
      sortType:['DESC'],
      
    }); 
    if(reportType=='market'){
      this.reportTitle = "Item Wise Market Wise";
      this.reportTypeName = "Market";
    }
    if(reportType=='item'){
      this.reportTitle = "Item Wise Port Wise";
      this.reportTypeName = "Port";
    }
    if(reportType=='buyer'){
      this.reportTitle = "Item Wise Buyer Wise";
      this.reportTypeName = "Buyer";
    }
    if(reportType=='exporter'){
      this.reportTitle = "Item Wise Exporter Wise";
      this.reportTypeName = "Exporter";
    }
    this.targetGroup = [];
    this.primengConfig.ripple = true;

    this._service.getGroups().subscribe((itemGrp: any) => {
      this.sourceGroup = itemGrp.data;
    });
    this.reportFrm.valueChanges.subscribe(val=>{
      if(this.reportData.length>0){
        if(this.reportFrm.value.viewDetailReport){      
            this.displayReport=false;
            this.displayDetailedReport=true;
        }
        else{
          this.displayReport=true;
          this.displayDetailedReport=false;
        }
      }
        
    });
  }


  addPort(event){
    for(let i=0;i<event.items.length;i++)
    {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedItem.patchValue(this.selectedItem, {emitEvent: false});
  }
  
  removePort(event){
    for(let i=0;i<event.items.length;i++)
    {
     // this.selectedItem.splice(event.items[i].code);
       // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code),1)
    }
    this.reportFrm.controls.selectedItem.patchValue(this.selectedItem, {emitEvent: false});
  }

  get f() { return this.reportFrm.controls; }


  getReport()
  {
     this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }


    if(this.reportFrm.value.selectedItem=="")
    {
      this.messageService.add({severity:'error', summary: 'Fail', detail: "Please Select Items", life: 6000}); //success,info,warn,error
      this.isloader = false;
      return false;
    }
    this._service.getItemReport(this.reportFrm.value).subscribe((items: any) =>{
      this.data = items.data;
      if(items.data.reportData.length>0)
        {
          for(let i=0;i<items.data.reportData.length;i++)
          {
            for(let j=0;j<items.data.reportData[i].groupItems.length;j++)
            {       
              items.data.reportData[i].groupItems[j].groupName=items.data.reportData[i].name;
              this.detailReportData.push(items.data.reportData[i].groupItems[j]);
            }
          }
          this.displayReport=true;
          this.displayDetailedReport=false;
          this.isloader = false;
        }
        else{
          this.displayReport=false;
          this.displayDetailedReport=false;
          this.isloader = false;
        }      
      this.reportData = items.data.reportData;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });

  }
  getShare(itemQty,totalQty){
    let percentage = itemQty*100/totalQty;
    return percentage.toFixed(2);
  } 
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
    // save to file
     xlsx.writeFile(workbook, "port_report_detail.xlsx");
 
    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
    // save to file
     xlsx.writeFile(workbook, "port_report_detail.csv");
 
    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
    // save to file
     xlsx.writeFile(workbook, "port_report.csv");
      
    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
    // save to file
     xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }
}
