<div class="pageheadingouter">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> Batch List </h1>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                <ng-template [ngIf]="branchId>0">

                    <a [routerLink]="['/setup/reconcile/add']" [queryParams]=""><button type="button"
                            class="btn btn-success"><i class="fa fa-save"></i> Add </button></a>
                </ng-template>
            </div>


        </div>

    </div>
</div>
<section class="innerpage">
    <div class="innershadow">
        <div class="container pt-4">
            <div class="table-responsive">
                <p-table [value]="data" #dt [columns]="cols" [lazy]="true" (onLazyLoad)="loadUsers($event)"
                    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [loading]="loading"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[10,25,50]">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" pSortableColumn="{{col.header}}"
                                [ngStyle]="{'width': col.width}">{{col.field}} <p-sortIcon field="{{col.header}}">
                                </p-sortIcon>
                            </th>
                            <ng-template [ngIf]="branchId>0">
                                <th style="width:5%"></th>
                            </ng-template>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">

                                <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, col.header, 'startsWith')"
                                    placeholder="Search by {{col.field}}" class="p-column-filter" style="width: 100%;"
                                    *ngIf="col.isSearch=='1'" />
                            </th>
                            <ng-template [ngIf]="branchId>0">
                                <th style="width:5%"></th>
                            </ng-template>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-columns="columns">
                        <tr style="height:34px">
                            <td *ngFor="let col of columns">
                                <span *ngIf="col.header!='status'">{{data[col.header]}}</span>
                                <span *ngIf="col.header=='status' && data[col.header]=='1'">Draft</span>
                                <span *ngIf="col.header=='status' && data[col.header]=='2'">Publish</span>
                            </td>
                            <ng-template [ngIf]="branchId>0">
                                <td class="tableicons">
                                    <a [routerLink]="['/setup/reconcile/update', data.id]">
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-success p-mr-2"></button></a>
                                </td>
                            </ng-template>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>



</section>
<br>