import { Component, OnInit } from '@angular/core';
import { ExporterServiceService } from '../exporter-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { MessageService } from 'primeng/api';
import { apiUrl, calendarRange, fileAllowedExtensions, fileAllowedSize, poundToKg, invoiceFrezeDate } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import jwt_decode from "jwt-decode";
import { CommonServiceService } from '../../common-service.service';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-exporter-viewinvoices',
  templateUrl: './exporter-viewinvoices.component.html',
  styleUrls: ['./exporter-viewinvoices.component.css']
})
export class ExporterViewinvoicesComponent implements OnInit {

  invoiceFrm: FormGroup;
  itemForm: FormGroup;
  submitted = false;
  submittedItem = false;
  invoiceId: {};
  invoiceFrmData: any;


  invoiceDetail: any = [];
  manufacturer: any;
  filteredExporters: any;
  keyword = 'name';
  exporters: any;
  exportername: any;
  filteredManufacturer: any;
  filteredConsinee: any;
  filteredPort: any;
  filteredCountry: any;
  buyer: any;
  port: any;
  country: any;
  manufacturerPlants: any;
  plantState: string;
  displayAddItem: boolean;
  groups: any;
  itemListDD: any;
  itemGradeDD: any;
  currencies: any;
  totalQuantity: number;
  totalPrice: any;
  itemGroupDetail: any;
  totalFOBPrice: any;
  invoiceDateTime: any;

  itemHsDD: any;
  itemSNameDD: any;
  isloader: boolean = false;

  msgs: Message[] = [];
  position: string;
  deleteItemId: number = 0;
  selectedItemDelete: any = [];
  totalExporter: number = 0;
  expDetail: any = [];
  expName: string = '';
  exporterAll: any = [];
  totalQuantityUnit: number;
  totalAmountDollar: any;
  totalAmountRs: any;
  selectedExporter: any = [];
  sbFileUrl: string = '';
  sbFile: string = '';
  invFileUrl: string = '';
  invFile: string = '';
  comments: any = [];
  calendarRange: string = calendarRange;
  allConsinee: any = [];
  allPorts: any = [];
  allCountry: any = [];
  filteredPortIndia: any = [];
  allPortsIndia: any = [];
  invoiceFrezeDate: any = [];


  constructor(private primengConfig: PrimeNGConfig, private route: ActivatedRoute, private _service: ExporterServiceService, private formBuilder: FormBuilder, private messageService: MessageService, private _router: Router, private _commonService: CommonServiceService, private datePipe: DatePipe) { }


  ngOnInit(): void {

    this.invoiceFrezeDate = new Date(`${invoiceFrezeDate}`).getTime() / 1000;
    //code to get all consignee 
    this._service.getBuyerSearch("").subscribe((buyerList) => {
      let filtered: any[] = [];
      this.buyer = buyerList;
      //Below code to get all ports
      this._service.getPortSearch("").subscribe((portList) => {
        let filtered: any[] = [];

        this.port = portList;
        this._commonService.getIndianPortSearch("").subscribe((portList) => {
          let filteredIndia: any[] = [];
          this.port = portList;
          //code to search country 
          this._service.getCountrySearch("").subscribe((countryList) => {
            let filtered: any[] = [];
            this.country = countryList;
            for (let i = 0; i < this.country.length; i++) {
              let country = this.country[i];
              if (country.name != null) {
                var countryData = {
                  id: country.code,
                  name: country.name
                }
                filtered.push(countryData);
              }
            }
            this.allCountry = filtered;
          });
          for (let i = 0; i < this.port.length; i++) {
            let port = this.port[i];
            if (port.name != null) {

              if (port.COUNTRY_CODE == 999) {
                var portDataIndia = {
                  id: port.code,
                  name: port.name,
                  countryCode: port.COUNTRY_CODE,
                  countryName: port.COUNTRY_NAME,
                  detail: port.name + "-" + port.code,
                }
                filteredIndia.push(portDataIndia);
              }
            }
          }
          this.allPortsIndia = filteredIndia;
        });
        for (let i = 0; i < this.port.length; i++) {
          let port = this.port[i];
          if (port.name != null) {
            var portData = {
              id: port.code,
              name: port.name,
              countryCode: port.COUNTRY_CODE,
              countryName: port.COUNTRY_NAME,
              detail: port.name + "-" + port.code,
            }
            filtered.push(portData);

          }
        }
        this.allPorts = filtered;

      });
      for (let i = 0; i < this.buyer.length; i++) {
        let buyer = this.buyer[i];
        if (buyer.name != null) {
          var buyerData = {
            id: buyer.code,
            name: buyer.name
          }
          filtered.push(buyerData);
        }
      }
      this.allConsinee = filtered;
    });





    let todayDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');

    this.primengConfig.ripple = true;
    this.totalQuantity = 0;
    //Below code to get all exporters

    let token = JSON.parse(localStorage.getItem('token'));
    let decodedToken = jwt_decode(token);
    this.totalExporter = decodedToken['result'].results.length;
    //Below code to get groups 
    this._service.getInvoiceSetups().subscribe((setupList: any) => {
      this.groups = setupList.groupDetails;
      this.currencies = setupList.curreciesDetails;
    });

    this.invoiceFrm = this.formBuilder.group({
      SB_No: ['', [Validators.required, Validators.minLength(7)]],
      SB_Date: ['', Validators.required],
      Ent_date: [todayDate, Validators.required],
      BILL_NO: ['', Validators.required],
      Date: ['', Validators.required],
      EXP_NAME: [],
      EXP_CODE: [this.expDetail, Validators.required],
      BUYER_NAME: ['', Validators.required],
      BUYER_CODE: ['', Validators.required],
      Plant_Code: ['', Validators.required],
      plantState: "",
      plantStateName: "",
      Vessel_No: "",
      PORT_NAME: "",
      PORT_CODE: "",
      Port_DischargeName: ['', Validators.required],
      Port_Discharge: ['', Validators.required],
      Port_Final_NAME: '',
      Port_Final: '',
      Country_Origin_Name: '',
      Country_Origin: '',
      Imp_Exp_No: "",
      E_Under: "",
      S_Type: "",
      Custom: "",
      Currency: "",
      Nature: "",
      DOL_RATE: ['', Validators.required],
      CurToDol: ['', Validators.required],
      FOB_Value: ['', Validators.required],
      Duty_Rate: 0,
      Amount: '',
      AmountR: '',
      AP_CessR: 0,
      AP_Cess: 0,
      Duty_CessR: 0,
      Duty_Cess: 0,
      Tot_Freight: '',
      C_Insure: 0,
      R_Insure: 0,
      C_Comm: 0,
      R_Comm: 0,
      C_Disc: 0,
      R_Disc: 0,
      C_Other: 0,
      R_Other: 0,
      Gross: [0, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
      Tot_Weight: [0, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
      Net_Weight: '',
      R_Freight: '',
      FKG: '',
      COUNTRY_CODE_NAME: '',
      COUNTRY_CODE: '',
      itemDetails: '',
      R_FOB: '',
      invoiceId: '',
      Tot_Amount: '',
      tot_amount_rs: '',
      added_from: '3',
      status: '1',
      medium: '1',
      added_by: this.expDetail.code,
      SBfile: [''],
      fileSource: [''],
      Invfile: [''],
      InvfileSource: [''],
      exporter_declaration: [false],
      invoiceComment: [''],

    });
    if (this.totalExporter == 1) {
      this.expDetail = {
        "id": decodedToken['result'].results[0].exp_code,
        "name": decodedToken['result'].results[0].exp_name,
      }
      this.expName = decodedToken['result'].results[0].exp_name;
      this._service.getExporterPlants(decodedToken['result'].results[0].exp_code).subscribe((exporterPlants: any) => {
        this.manufacturerPlants = exporterPlants.data;
      });

    }
    if (this.totalExporter > 1) {
      for (let i = 0; i < this.totalExporter; i++) {
        this.exporterAll = decodedToken['result'].results;
        //console.warn("All Exporter ",this.exporterAll)
      }
    }
    if (this.totalExporter == 1) {
      let item = {
        "id": this.expDetail.id,
        "name": this.expDetail.name,
      }
      this.selectedExporter = item;
    }
    this.invoiceFrm.controls.added_by.setValue(this.expDetail.id);
    this.invoiceFrm.controls.EXP_CODE.setValue(this.expDetail);
    this.invoiceFrm.controls.EXP_NAME.setValue(this.expDetail);


    this.itemForm = this.formBuilder.group({
      itemGroup: ['', Validators.required],
      itemProduct: ['', Validators.required],
      productGrade: ['', Validators.required],
      itemScientificName: [''],
      itemHsCode: [''],
      cartonsNumber: [''],

      itemQuantity: ['', Validators.required],
      itemUnit: ['K', Validators.required],
      itemQuantityKg: ['', Validators.required],
      itemRate: ['', Validators.required],
      itemAmount: ['', Validators.required],
      fobitemAmount: ['', Validators.required],
      itemId: [''],
      ITEM_AUTOCOMPLETE: [''],
      GRADE_AUTOCOMPLETE: [''],
      itemAmountRs: ['', Validators.required],
    });

    this.itemForm.valueChanges.subscribe(val => {
      if (val.itemQuantity > 0) {
        if (val.itemUnit == "K") {
          this.itemForm.controls.itemQuantityKg.patchValue(val.itemQuantity, { emitEvent: false });
        }
        else {
          let lbsToKg = poundToKg;
          let qtyInKg = val.itemQuantity * lbsToKg;
          this.itemForm.controls.itemQuantityKg.patchValue(qtyInKg.toFixed(2), { emitEvent: false });
        }

        if (val.itemRate > 0) {
          const amount = (val.itemQuantity * val.itemRate).toFixed(2);
          let dolRate = this.invoiceFrm.value.DOL_RATE;
          const amountRs = (val.itemQuantity * val.itemRate * dolRate).toFixed(2);
          this.itemForm.controls.itemAmount.patchValue(amount, { emitEvent: false });
          this.itemForm.controls.fobitemAmount.patchValue(amount, { emitEvent: false });
          this.itemForm.controls.itemAmountRs.patchValue(amountRs, { emitEvent: false });
        }
      }

    });


    this.invoiceFrm.valueChanges.subscribe(val => {
      this.totalPrice = this.getotalAmount();
      if (val.C_Insure >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let RInsurence = val.C_Insure * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Insure.patchValue(RInsurence.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });
        }
      }
      else {
        this.invoiceFrm.controls.R_Insure.patchValue(0, { emitEvent: false });
      }

      //Code to calculate commission  

      if (val.C_Comm >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let R_Comm = val.C_Comm * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Comm.patchValue(R_Comm.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
      }
      else {
        this.invoiceFrm.controls.R_Comm.patchValue(0, { emitEvent: false });
      }
      //Code to calculate discounts  

      if (val.C_Disc >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let R_dis = val.C_Disc * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Disc.patchValue(R_dis.toFixed(2), { emitEvent: false });

          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
      }
      else {
        this.invoiceFrm.controls.R_Disc.patchValue(0, { emitEvent: false });

      }

      //Code to calculate other discount  
      if (val.C_Other >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        if (val.DOL_RATE > 0) {
          let o_dis = val.C_Other * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Other.patchValue(o_dis.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });

      }
      else {
        this.invoiceFrm.controls.R_Other.patchValue(0, { emitEvent: false });

      }
      //Code to calulate freight
      if (val.Tot_Freight > 0) {
        const totalFright = Number.parseFloat(val.Tot_Freight).toFixed(2);
        if (val.DOL_RATE > 0) {
          let totalFrightRs = parseFloat(val.Tot_Freight) * parseFloat(val.DOL_RATE)
          this.invoiceFrm.controls.R_Freight.patchValue(totalFrightRs.toFixed(2), { emitEvent: false });
        }
        this.invoiceFrm.controls.Tot_Freight.patchValue(val.Tot_Freight, { emitEvent: false });
        this.calculateItemsFOB();
      }

      //Calculate Total Freight 
      if (val.Tot_Freight > 0 && val.Tot_Weight > 0) {

        let frightPerKg = val.Tot_Freight / val.Tot_Weight;
        this.invoiceFrm.controls.FKG.patchValue(frightPerKg.toFixed(2), { emitEvent: false });
      }
      if (val.SB_Date != '') {
        this.invoiceDateTime = val.SB_Date;
      }
    });

    this.route.queryParams.subscribe(params => {
      this.invoiceId = params.id;
      if (this.invoiceId != "" && this.invoiceId !== undefined) {
        this._service.getInvoiceDetail(this.invoiceId).subscribe((item: any) => {
          this.invoiceDetail = item.data[0];

          //         this.manufacturerPlants = item.data[0].plantDetail;
          let filteredData: any;
          let stateName = "";
          if (this.manufacturerPlants != undefined) {
            filteredData = this.manufacturerPlants.find(item => item.code === this.invoiceDetail.Plant_Code);
            if (filteredData) {
              stateName = filteredData.Stname;
            }
          }
          //   console.warn("In here", this.invoiceDetail.sb_filename)
          if (this.invoiceDetail.sb_filename != null) {
            this.sbFile = this.invoiceDetail.sb_filename;
            this.sbFileUrl = `${apiUrl}` + "exporter/forceDownloadInvoiceFile?filetype=sb&file=" + this.invoiceDetail.sb_filename;

          }
          if (this.invoiceDetail.invoice_filename != null) {
            this.invFile = this.invoiceDetail.invoice_filename;
            this.invFileUrl = `${apiUrl}` + "exporter/forceDownloadInvoiceFile?filetype=inv&file=" + this.invoiceDetail.invoice_filename;
          }
          let exporter_declaration = false;
          if (this.invoiceDetail.exporter_declaration == 1) {
            exporter_declaration = true;
          }
          this.invoiceFrm.setValue({
            SB_No: this.invoiceDetail.SB_No,
            SB_Date: this.invoiceDetail.SB_Date,
            Ent_date: this.invoiceDetail.Ent_date,
            BILL_NO: this.invoiceDetail.BILL_NO,
            Date: this.invoiceDetail.Date,
            EXP_NAME: this.invoiceDetail.exp_name,
            EXP_CODE: { "id": this.invoiceDetail.EXP_CODE, "name": this.invoiceDetail.exp_name },
            BUYER_NAME: this.invoiceDetail.buyer_name,
            BUYER_CODE: { "id": this.invoiceDetail.BUYER_CODE, "name": this.invoiceDetail.buyer_name },
            Plant_Code: this.invoiceDetail.Plant_Code,
            plantState: this.invoiceDetail.State_Code,
            plantStateName: this.invoiceDetail.stateName[0].Stname,
            Vessel_No: this.invoiceDetail.Vessel_No,
            PORT_NAME: this.invoiceDetail.port_name,
            PORT_CODE: { "id": this.invoiceDetail.PORT_CODE, "name": this.invoiceDetail.port_name },
            Port_DischargeName: this.invoiceDetail.port_discharge_name,
            Port_Discharge: { "id": this.invoiceDetail.Port_Discharge, "name": this.invoiceDetail.port_discharge_name },
            Port_Final_NAME: this.invoiceDetail.port_final_name,
            Port_Final: { "id": this.invoiceDetail.Port_Final, "name": this.invoiceDetail.port_final_name },
            Imp_Exp_No: this.invoiceDetail.Imp_Exp_No,
            E_Under: this.invoiceDetail.E_Under,
            S_Type: this.invoiceDetail.S_Type,
            Custom: this.invoiceDetail.Custom,
            Currency: this.invoiceDetail.Currency,
            Nature: this.invoiceDetail.Nature,
            DOL_RATE: this.invoiceDetail.DOL_RATE,
            CurToDol: this.invoiceDetail.CurToDol,
            FOB_Value: this.invoiceDetail.FOB_Value,
            Duty_Rate: this.invoiceDetail.Duty_Rate,
            Amount: this.invoiceDetail.Amount,
            AmountR: this.invoiceDetail.AmountR,
            AP_CessR: this.invoiceDetail.AP_CessR,
            AP_Cess: this.invoiceDetail.AP_Cess,
            Duty_CessR: this.invoiceDetail.Duty_CessR,
            Duty_Cess: this.invoiceDetail.Duty_Cess,
            Tot_Freight: this.invoiceDetail.Tot_Freight,
            C_Insure: this.invoiceDetail.C_Insure,
            R_Insure: this.invoiceDetail.R_Insure,
            C_Comm: this.invoiceDetail.C_Comm,
            R_Comm: this.invoiceDetail.R_Comm,
            C_Disc: this.invoiceDetail.C_Disc,
            R_Disc: this.invoiceDetail.R_Disc,
            C_Other: this.invoiceDetail.C_Other,
            R_Other: this.invoiceDetail.R_Other,
            Gross: this.invoiceDetail.Gross,
            Tot_Weight: this.invoiceDetail.Tot_Weight,
            Net_Weight: this.invoiceDetail.Tot_Weight,
            R_Freight: this.invoiceDetail.R_Freight.toFixed(2),
            FKG: this.invoiceDetail.FKG,
            Country_Origin_Name: this.invoiceDetail.originCountryName[0].COUNTRY_NAME,
            Country_Origin: { "id": this.invoiceDetail.originCountryName[0].COUNTRY_CODE, "name": this.invoiceDetail.originCountryName[0].COUNTRY_NAME },
            COUNTRY_CODE_NAME: this.invoiceDetail.destinationCountryName[0].COUNTRY_NAME,
            COUNTRY_CODE: { "id": this.invoiceDetail.destinationCountryName[0].COUNTRY_CODE, "name": this.invoiceDetail.destinationCountryName[0].COUNTRY_NAME },

            itemDetails: this.invoiceDetail.itemsDetail,
            R_FOB: '',
            invoiceId: this.invoiceId,
            Tot_Amount: this.invoiceDetail.Tot_Amount,
            tot_amount_rs: this.invoiceDetail.tot_amount_rs,
            added_from: '3',
            status: '1',
            added_by: this.invoiceDetail.added_by,
            SBfile: [''],
            fileSource: [''],
            Invfile: [''],
            InvfileSource: [''],
            exporter_declaration: exporter_declaration,
            invoiceComment: [''],
            medium: this.invoiceDetail.added_medium,

          });
          this.comments = this.invoiceDetail.invoiceComment;
          //Code to get sum of quantity

          this.totalQuantity = this.getotalQuantity();
          this.totalPrice = this.getotalAmount();
          //Coe to bind  Item Group Summary 
          this.itemGroupDetail = this.getItemGroupSummary();
        }, error => {

          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this._router.navigate(['./exporter/login']);
            }
          }
        });


      }
      else {
        this.invoiceDetail = {};
        this.invoiceDetail.itemsDetail = [];
        this.invoiceFrmData = "";

      }
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.invoiceFrm.controls; }
  get fItem() { return this.itemForm.controls; }
  onChangeSearch(val: string) {

    this._service.getExporters(val).subscribe((exporterList) => {
      let filtered: any[] = [];
      this.exporters = exporterList;
      for (let i = 0; i < this.exporters.length; i++) {
        let exporter = this.exporters[i];
        if (exporter.name != null) {
          var exp = {
            id: exporter.code,
            name: exporter.name,
            impExpCode: exporter.Imp_Exp_No

          }
          filtered.push(exp);
        }
      }
      this.filteredExporters = filtered;
    });


  }
  onChangeSearchManufacturer(val: string) {

    this._service.getManufacturer(val).subscribe((manufacturerList) => {
      let filtered: any[] = [];
      this.manufacturer = manufacturerList;
      for (let i = 0; i < this.manufacturer.length; i++) {
        let manufac = this.manufacturer[i];
        if (manufac.name != null) {
          var manufactur = {
            id: manufac.code,
            name: manufac.name
          }
          filtered.push(manufactur);
        }
      }
      this.filteredManufacturer = filtered;
    });
  }

  //Code to get country 
  onChangeSearchCountry(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allCountry.length; i++) {
      let item = this.allCountry[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredCountry = filtered;
    this.filteredCountry = this.filteredCountry.slice(0, 10);
    /*
    this._service.getCountrySearch(val).subscribe((countryList) => {
      let filtered: any[] = [];
      this.country = countryList;
      for (let i = 0; i < this.country.length; i++) {
        let country = this.country[i];
        if (country.name != null) {
          var countryData = {
            id: country.code,
            name: country.name
          }
          filtered.push(countryData);
        }
      }
      this.filteredCountry = filtered;
    });
    */
  }

  //Code to get Port 
  onChangeSearchPort(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allPorts.length; i++) {
      let item = this.allPorts[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredPort = filtered;
    this.filteredPort = this.filteredPort.slice(0, 10);
    //console.warn("this.filteredPort", this.filteredPort);
    /*
    this._service.getPortSearch(val).subscribe((portList) => {
      let filtered: any[] = [];
      this.port = portList;
      for (let i = 0; i < this.port.length; i++) {
        let port = this.port[i];
        if (port.name != null) {
          var portData = {
            id: port.code,
            name: port.name,
            countryCode: port.COUNTRY_CODE,
            countryName: port.COUNTRY_NAME,

          }
          filtered.push(portData);
        }
      }
      this.filteredPort = filtered;
    });
    */
  }

  onChangeSearchConsinee(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allConsinee.length; i++) {
      let country = this.allConsinee[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }

    this.filteredConsinee = filtered;
    this.filteredConsinee = this.filteredConsinee.slice(0, 10);
    /*
    this._service.getBuyerSearch(val).subscribe((buyerList) => {
      let filtered: any[] = [];
      this.buyer = buyerList;
      for (let i = 0; i < this.buyer.length; i++) {
        let buyer = this.buyer[i];
        if (buyer.name != null) {
          var buyerData = {
            id: buyer.code,
            name: buyer.name
          }
          filtered.push(buyerData);
        }
      }
      this.filteredConsinee = filtered;
    });
    */
  }

  onFocused(e) {
    // do something when input is focused
  }
  selectEvent(item) {
    // do something with selected item
  }

  selectEventManufacturer(item) {
    this.invoiceFrm.controls.MAN_CODE.patchValue(item, { emitEvent: false });
    this._service.getPlants(item.id).subscribe((plantList) => {
      this.manufacturerPlants = plantList;
    });
  }

  selectEventExporter(item) {
    this.invoiceFrm.controls.EXP_CODE.patchValue(item, { emitEvent: false });
    this.invoiceFrm.controls.Imp_Exp_No.patchValue(item.impExpCode, { emitEvent: false });

  }
  selectEventConsinee(item) {
    this.invoiceFrm.controls.BUYER_CODE.patchValue(item, { emitEvent: false });
  }
  selectEventPort(item) {
    this.invoiceFrm.controls.PORT_CODE.patchValue(item, { emitEvent: false });
    let itemOrigin = {
      "id": item.countryCode,
      "name": item.countryName
    }
    this.invoiceFrm.controls.Country_Origin_Name.patchValue(item.countryName, { emitEvent: false });
    this.invoiceFrm.controls.Country_Origin.patchValue(itemOrigin, { emitEvent: false });
  }
  selectEventPortDischarge(item) {
    this.invoiceFrm.controls.Port_Discharge.patchValue(item, { emitEvent: false });
  }
  selectEventFinalPort(item) {
    this.invoiceFrm.controls.Port_Final.patchValue(item, { emitEvent: false });
    // this.invoiceFrm.controls.COUNTRY_CODE.patchValue(item.countryName, {emitEvent: false});
    this.invoiceFrm.controls.COUNTRY_CODE_NAME.patchValue(item.countryName, { emitEvent: false });
    let itemDestination = {
      "id": item.countryCode,
      "name": item.countryName
    }
    this.invoiceFrm.controls.COUNTRY_CODE.patchValue(itemDestination, { emitEvent: false });

  }
  selectEventCountryOrigin(item) {
    this.invoiceFrm.controls.Country_Origin.patchValue(item, { emitEvent: false });
  }
  selectEventCountryCode(item) {
    this.invoiceFrm.controls.COUNTRY_CODE.patchValue(item, { emitEvent: false });
  }
  //Below function for get state name on change of plant drop down 
  getState(event) {
    const value = event.target.value;
    let filteredData: any;
    filteredData = this.manufacturerPlants.find(item => item.code == value);
    this.plantState = filteredData.REGION_NAME;
    this.invoiceFrm.controls.plantState.patchValue(filteredData.Stcode);
    this.invoiceFrm.controls.plantStateName.patchValue(filteredData.Stname);



  }
  changeStatus(statusVal) {
    this.invoiceFrm.controls.status.patchValue(statusVal);

  }

  //Below code to save invoice form 
  saveInvoice() {

    //this.messageService.add({ severity: 'success', summary: 'Success', detail: "Test Message ", life: 6000 }); //
    this.submitted = true;

    // stop here if form is invalid
    if (this.invoiceFrm.invalid) {
      //console.warn("Invlid ");
      return;
    }

    //Code for validate sb number only 7 digit of length 
    if (this.invoiceFrm.value.invoiceId == '') {

      if (this.invoiceFrm.value.SB_No.length != 7) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Shipping Bill number can not be greater than 7 digit ", life: 6000 }); //
        return;
      }
      let sbFile = this.invoiceFrm.get('fileSource').value;
      let invCopy = this.invoiceFrm.get('InvfileSource').value;

      if (sbFile['name'] == undefined && this.sbFileUrl == "") {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Shipping Bill ", life: 6000 }); //
        return false;
      }
      if (invCopy['name'] == undefined && this.invFileUrl == "") {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Invoice Copy", life: 6000 }); //
        return false;
      }

    }

    if (this.invoiceFrm.value.invoiceId != '') {

      let SDate = this.invoiceFrm.value.SB_Date;
      var parts = SDate.split("-")
      SDate = new Date(parts[2], parts[1] - 1, parts[0]).getTime() / 1000;

      if (this.invoiceFrm.value.SB_No.length != 7 && SDate > this.invoiceFrezeDate) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Shipping Bill number can not be greater than 7 digit ", life: 6000 }); //
        return;
      }

      if (this.invoiceFrm.value.medium == 3 || this.invoiceFrm.value.medium == 1) {
        let sbFile = this.invoiceFrm.get('fileSource').value;
        let invCopy = this.invoiceFrm.get('InvfileSource').value;

        if (sbFile['name'] == undefined && this.sbFileUrl == "") {
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Shipping Bill ", life: 6000 }); //
          return false;
        }
        if (invCopy['name'] == undefined && this.invFileUrl == "") {
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Invoice Copy", life: 6000 }); //
          return false;
        }
      }
    }
    if (this.invoiceFrm.value.status == 2 && !this.invoiceFrm.value.exporter_declaration) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select declaration ", life: 6000 }); //
      return false;
    }


    // display form values on success
    const formData = new FormData();
    this.isloader = false;
    formData.append('sBfile', this.invoiceFrm.get('fileSource').value);
    formData.append('invoiceFile', this.invoiceFrm.get('InvfileSource').value);

    let data = {
      "sbDate": this.invoiceFrm.value.SB_Date
    }
    this._commonService.checkSBillDate(data).subscribe((checkData: any) => {
      if (checkData.data == 0) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: checkData.messagess, life: 6000 }); //
        return;
      }
      else {
        this._service.saveInvoice(this.invoiceFrm.value).subscribe((items: any) => {
          if (items.success) {
            let sbId = items.SbId;
            formData.append('invoiceId', sbId);

            this._service.uploadInvoiceFiles(formData).subscribe((returnData: any) => {

            });


            this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
          }
          this.submitted = false;
          return false;
        });
      }
    });

  }
  showAddItem() {
    this.displayAddItem = true;
    this.itemForm.controls.itemId.patchValue('', { emitEvent: false });

  }

  addItem() {
    let itemId = '';
    itemId = this.itemForm.value.itemId;

    this.submittedItem = true;
    if (this.itemForm.invalid) {
      return;
    }
    let group = this.itemForm.value.itemGroup;
    group = group.split("--");
    let item = this.itemForm.value.itemProduct;
    item = item.split("--");
    let grade = this.itemForm.value.productGrade;
    grade = grade.split("--");

    //Calculate FOB amount for item 
    FobRate: Number;
    let netWeight = 0;
    let netFright = 0;
    let frightPerKg = 0;
    if (this.invoiceFrm.value.Tot_Weight > 0) {
      netWeight = this.invoiceFrm.value.Tot_Weight;
    }
    if (this.invoiceFrm.value.Tot_Weight > 0) {
      netFright = this.invoiceFrm.value.Tot_Freight;
    }
    if (netWeight > 0) {
      frightPerKg = netFright / netWeight;
    }

    const FOBRateKg = this.itemForm.value.itemRate - frightPerKg;
    const FobRate = this.itemForm.value.itemQuantity * FOBRateKg;
    const ItemFright = (netFright / netWeight) * parseInt(this.itemForm.value.itemQuantity);
    const FobRateRs = FobRate * this.invoiceFrm.value.DOL_RATE;
    const fobVal = FobRate * this.invoiceFrm.value.DOL_RATE;
    let FOBRate_Kg = 0;

    if (this.itemForm.value.itemUnit == "L") {
      FOBRate_Kg = this.itemForm.value.itemRate / poundToKg;
    }
    else {
      FOBRate_Kg = this.itemForm.value.itemRate;
    }
    var itemDetail = {
      GROUP_CODE: group[0],
      GROUP_NAME: group[1],
      ITEM_CODE: item[0],
      ITEM_NAME: item[1],
      scientific_name: this.itemForm.value.itemScientificName,
      hs_code: this.itemForm.value.itemHsCode,
      GRADE_CODE: grade[0],
      Size_Name: grade[1],
      TAILQTY: parseInt(this.itemForm.value.itemQuantityKg),
      Qty: parseInt(this.itemForm.value.itemQuantity),
      KILLBS: this.itemForm.value.itemUnit,
      RATE: parseFloat(this.itemForm.value.itemRate),
      TOTPRICE_RS: FobRate.toFixed(2),
      Freight: ItemFright.toFixed(2),
      TOTPRICE: parseFloat(this.itemForm.value.itemAmount),
      FOB_Dol: FobRate.toFixed(2),
      FOB_Rs: FobRateRs.toFixed(2),
      FOB_Value: fobVal,
      cartons_number: this.itemForm.value.cartonsNumber,
      FOBRate_Kg: FOBRate_Kg,
    }
    if (itemId === '') {
      this.invoiceDetail.itemsDetail.push(itemDetail);
    }
    else {
      //this.invoiceDetail.itemsDetail[itemId] = ;
      this.invoiceDetail.itemsDetail[itemId].GROUP_CODE = group[0];
      this.invoiceDetail.itemsDetail[itemId].GROUP_NAME = group[1];
      this.invoiceDetail.itemsDetail[itemId].ITEM_CODE = item[0];
      this.invoiceDetail.itemsDetail[itemId].ITEM_NAME = item[1];
      this.invoiceDetail.itemsDetail[itemId].scientific_name = this.itemForm.value.itemScientificName;
      this.invoiceDetail.itemsDetail[itemId].hs_code = this.itemForm.value.itemHsCode;
      this.invoiceDetail.itemsDetail[itemId].GRADE_CODE = grade[0];
      this.invoiceDetail.itemsDetail[itemId].Size_Name = grade[1];
      this.invoiceDetail.itemsDetail[itemId].TAILQTY = parseInt(this.itemForm.value.itemQuantityKg);
      this.invoiceDetail.itemsDetail[itemId].Qty = parseInt(this.itemForm.value.itemQuantity);
      this.invoiceDetail.itemsDetail[itemId].KILLBS = this.itemForm.value.itemUnit;
      this.invoiceDetail.itemsDetail[itemId].RATE = parseFloat(this.itemForm.value.itemRate);
      this.invoiceDetail.itemsDetail[itemId].TOTPRICE_RS = FobRate.toFixed(2);
      this.invoiceDetail.itemsDetail[itemId].Freight = ItemFright.toFixed(2);
      this.invoiceDetail.itemsDetail[itemId].TOTPRICE = parseFloat(this.itemForm.value.itemAmount);
      this.invoiceDetail.itemsDetail[itemId].FOB_Dol = FobRate.toFixed(2);
      this.invoiceDetail.itemsDetail[itemId].FOB_Rs = FobRateRs.toFixed(2);
      this.invoiceDetail.itemsDetail[itemId].FOB_Value = fobVal;
      this.invoiceDetail.itemsDetail[itemId].cartons_number = this.itemForm.value.cartonsNumber;
      this.invoiceDetail.itemsDetail[itemId].FOBRate_Kg = FOBRate_Kg;

    }


    this.invoiceFrm.controls.itemDetails.patchValue(this.invoiceDetail.itemsDetail);


    //Code to get sum of quantity
    this.totalQuantity = this.getotalQuantity();
    this.totalPrice = this.getotalAmount();
    //Update Total Quantity and price 
    const totalPriceRs = this.totalPrice * this.invoiceFrm.value.DOL_RATE;
    this.invoiceFrm.controls.Tot_Weight.patchValue(this.totalQuantity);
    this.invoiceFrm.controls.Tot_Amount.patchValue(this.totalPrice, { emitEvent: false });
    this.invoiceFrm.controls.tot_amount_rs.patchValue(totalPriceRs, { emitEvent: false });
    this.invoiceFrm.controls.Amount.patchValue(this.totalPrice, { emitEvent: false });
    this.invoiceFrm.controls.AmountR.patchValue(totalPriceRs, { emitEvent: false });





    //Coe to bind  Item Group Summary 
    this.itemGroupDetail = this.getItemGroupSummary();

    //code to update all items 
    this.updateInvoiceItem()
    this.itemForm.reset();
    for (let control in this.itemForm.controls) {
      this.itemForm.controls[control].setErrors(null);
    }    //console.warn('SUCCESS!! :-)\n\n' + JSON.stringify(this.invoiceFrm.value, null, 4));
    this.displayAddItem = false;

    //console.warn('SUCCESS!! :-)\n\n' + JSON.stringify(this.invoiceFrm.value, null, 4));
  }

  updateInvoiceItem() {
    let frightPerKg = this.invoiceFrm.value.Tot_Freight / this.invoiceFrm.value.Tot_Weight;

    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      let fob = this.invoiceDetail.itemsDetail[i].TAILQTY * frightPerKg;
      this.invoiceDetail.itemsDetail[i].Freight = fob;
      this.invoiceDetail.itemsDetail[i].FOB_Dol = this.invoiceDetail.itemsDetail[i].TOTPRICE - fob;
      this.invoiceDetail.itemsDetail[i].TOTPRICE_RS = this.invoiceDetail.itemsDetail[i].TOTPRICE - fob;
    }
  }

  //Below function for get Items when change the group drop down 
  getItems(event) {
    const value = event.target.value;
    this.isloader = true;
    this.itemForm.controls.itemProduct.patchValue('', { emitEvent: false });
    this.itemForm.controls.ITEM_AUTOCOMPLETE.patchValue('', { emitEvent: false });


    this._service.getItems(value).subscribe((itemsList) => {
      this.itemListDD = itemsList;
      this.isloader = false;
    });
  }

  getPlants(event) {
    let expValue = event.target.value.split("--");
    let exp_code = expValue[0];
    let exp_name = event.target.options[event.target.options.selectedIndex].text;
    let expDetail = { "id": exp_code, "name": exp_name };
    this.invoiceFrm.controls.EXP_CODE.setValue(expDetail);
    this.invoiceFrm.controls.Imp_Exp_No.setValue(expValue[1]);
    this._service.getExporterPlants(exp_code).subscribe((exporterPlants: any) => {
      this.manufacturerPlants = exporterPlants.data;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./exporter/login']);
        }
      }
    });
  }
  getItemGrade(event) {
    const value = event.target.value;
    this._service.getItemGrades(value).subscribe((gradeList) => {

      this.itemGradeDD = gradeList[0].gradeDetail;
      if (gradeList[0].ScientificName !== null) {
        this.itemSNameDD = gradeList[0].ScientificName.split(",");
      }
      else {
        this.itemSNameDD = [];
      }
      if (gradeList[0].ScientificName !== null) {
        this.itemHsDD = gradeList[0].hsCode.split(",");
      }
      else {
        this.itemHsDD = [];
      }

    });
  }

  getotalQuantity() {
    let quantity = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      quantity = quantity + this.invoiceDetail.itemsDetail[i].TAILQTY;
    }
    return quantity;
  }
  getotalAmount() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + this.invoiceDetail.itemsDetail[i].TOTPRICE;
    }
    return totalprice.toFixed(2);
  }
  getotalAmountFOB() {
    let totalpriceFob: number = 0;

    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalpriceFob = totalpriceFob + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Dol);
    }
    return totalpriceFob.toFixed(2);
  }

  getItemGroupSummary() {
    let groupItem: any[] = [];
    let quantity = 0;
    let quantityKg = 0;
    let amount: number = 0;
    let amountFob: number = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      if (groupItem.find(item => item.GroupCode === this.invoiceDetail.itemsDetail[i].GROUP_CODE)) {
        quantity = groupItem[0].GroupQuantity;
        quantity = quantity + this.invoiceDetail.itemsDetail[i].Qty;
        quantityKg = quantityKg + parseFloat(this.invoiceDetail.itemsDetail[i].TAILQTY);
        amount = parseFloat(groupItem[0].amount);
        amount = amount + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
        amountFob = amountFob + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Dol);
        groupItem[0].GroupQuantity = quantity;
        groupItem[0].QuantityKg = quantityKg;
        //    groupItem[0].quantityKg = quantityKg;
        groupItem[0].amount = amount.toFixed(2);
        groupItem[0].amountFob = amountFob.toFixed(2);
      }
      else {
        //quantity = groupItem[0].GroupQuantity;
        quantity = quantity + this.invoiceDetail.itemsDetail[i].Qty;
        quantityKg = quantityKg + parseFloat(this.invoiceDetail.itemsDetail[i].TAILQTY);

        amount = amount + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
        amountFob = amountFob + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Dol);
        var itemDetail = {
          "GroupCode": this.invoiceDetail.itemsDetail[i].GROUP_CODE,
          "GroupName": this.invoiceDetail.itemsDetail[i].GROUP_NAME,
          "GroupQuantity": this.invoiceDetail.itemsDetail[i].Qty,
          "GroupUnit": this.invoiceDetail.itemsDetail[i].KILLBS,
          "QuantityKg": this.invoiceDetail.itemsDetail[i].TAILQTY,
          "amount": this.invoiceDetail.itemsDetail[i].TOTPRICE,
          "amountFob": this.invoiceDetail.itemsDetail[i].FOB_Dol,
        }
        groupItem.push(itemDetail);
      }


    }
    return groupItem;
  }

  getAPCess(amount) {
    let apcess = (amount * .4845) / 100;
    return apcess.toFixed(2);
  }
  getMPCess(amount) {
    let mpcess = (amount * .2907) / 100;
    return mpcess.toFixed(2);
  }
  editItem(id) {
    let selectedItem = this.invoiceDetail.itemsDetail[id];
    const value = selectedItem.GROUP_CODE + "--" + selectedItem.GROUP_NAME;
    this._service.getItems(value).subscribe((itemsList) => {
      this.itemListDD = itemsList;
      //Code to get item grade
      const valueItem = selectedItem.ITEM_CODE + "--" + selectedItem.ITEM_NAME;
      this._service.getItemGrades(valueItem).subscribe((gradeList) => {

        this.itemGradeDD = gradeList[0].gradeDetail;
        if (gradeList[0].ScientificName !== null) {
          this.itemSNameDD = gradeList[0].ScientificName.split(",");
        }
        else {
          this.itemSNameDD = [];
        }
        if (gradeList[0].ScientificName !== null) {
          this.itemHsDD = gradeList[0].hsCode.split(",");
        }
        else {
          this.itemHsDD = [];
        }
        this.itemForm.setValue({
          "itemGroup": selectedItem.GROUP_CODE + "--" + selectedItem.GROUP_NAME,
          "itemProduct": selectedItem.ITEM_CODE + "--" + selectedItem.ITEM_NAME,
          "itemScientificName": selectedItem.scientific_name,
          "itemHsCode": selectedItem.hs_code,
          "productGrade": selectedItem.GRADE_CODE + "--" + selectedItem.Size_Name,
          "itemQuantity": selectedItem.Qty,
          "itemUnit": selectedItem.KILLBS,
          "itemQuantityKg": selectedItem.KILLBS,
          "itemRate": selectedItem.RATE,
          "itemAmount": selectedItem.TOTPRICE,
          "fobitemAmount": selectedItem.TOTPRICE,
          "cartonsNumber": selectedItem.cartons_number,
          "ITEM_AUTOCOMPLETE": selectedItem.ITEM_NAME + "-" + selectedItem.ITEM_CODE,
          "GRADE_AUTOCOMPLETE": selectedItem.Size_Name,
          "itemAmountRs": selectedItem.FOB_Rs,
          "itemId": id,
        });
        this.displayAddItem = true;

      });
    });
  }

  selectItemEvent($event) {
    let item = $event.ITEM_CODE + "--" + $event.ITEM_NAME;
    this.itemForm.controls.itemProduct.patchValue(item, { emitEvent: false });

    const value = $event.ITEM_CODE;
    this._service.getItemGrades(value).subscribe((gradeList) => {
      this.itemGradeDD = gradeList[0].gradeDetail;
      if (gradeList[0].ScientificName !== null) {
        this.itemSNameDD = gradeList[0].ScientificName.split(",");
      }
      else {
        this.itemSNameDD = [];
      }
      if (gradeList[0].ScientificName !== null) {
        this.itemHsDD = gradeList[0].hsCode.split(",");
      }
      else {
        this.itemHsDD = [];
      }
    });
  }
  onClearItem() {
    this.itemForm.controls.itemProduct.patchValue('', { emitEvent: false });
    this.itemGradeDD = '';
  }
  selectItemGrade($event) {
    let item = $event.code + "--" + $event.name;
    this.itemForm.controls.productGrade.patchValue(item, { emitEvent: false });
  }
  onClearGrade() {
    this.itemForm.controls.productGrade.patchValue('', { emitEvent: false });

  }
  calculateItemsFOB() {
    //Code to calculate the Items FOB     
    if (this.invoiceDetail.itemsDetail.length > 0) {
      FobRate: Number;
      let netWeight = 0;
      let netFright = 0;
      let frightPerKg = 0;
      if (this.invoiceFrm.value.Tot_Weight > 0) {
        netWeight = this.invoiceFrm.value.Tot_Weight;
      }
      if (this.invoiceFrm.value.Tot_Weight > 0) {
        netFright = this.invoiceFrm.value.Tot_Freight;
      }
      if (netWeight > 0) {
        frightPerKg = netFright / netWeight;
      }
      frightPerKg = parseFloat(this.invoiceFrm.value.FKG);
      for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
        const FOBRateKg = this.invoiceDetail.itemsDetail[i].RATE - frightPerKg;
        const FobRate = this.invoiceDetail.itemsDetail[i].Qty * FOBRateKg;
        const FobRateRs = FobRate * this.invoiceFrm.value.DOL_RATE;

        const fobVal = FobRate * this.invoiceFrm.value.DOL_RATE;
        const ItemFright = (netFright / netWeight) * parseFloat(this.invoiceDetail.itemsDetail[i].itemQuantity);
        this.invoiceDetail.itemsDetail[i].Freight = ItemFright.toFixed(2);
        this.invoiceDetail.itemsDetail[i].TOTPRICE_RS = FobRate.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Dol = FobRate.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Rs = FobRateRs.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Value = fobVal;
      }
      //Code to get sum of quantity
      this.totalQuantity = this.getotalQuantity();
      this.totalQuantityUnit = this.getotalQuantityUnit();
      this.totalPrice = this.getotalAmount();
      this.totalAmountDollar = this.getotalAmountDollar()
      this.totalAmountRs = this.getotalAmountRs()
      //Update Total Quantity and price 

      //Coe to bind  Item Group Summary 
      this.itemGroupDetail = this.getItemGroupSummary();

      //code to update all items 
      this.updateInvoiceItem()
    }
  }
  getotalQuantityUnit() {
    let quantity = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      quantity = quantity + this.invoiceDetail.itemsDetail[i].Qty;
    }
    return quantity;
  }
  getotalAmountDollar() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + this.invoiceDetail.itemsDetail[i].TOTPRICE;
    }
    return totalprice.toFixed(2);
  }
  getotalAmountRs() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Rs);
    }
    return totalprice.toFixed(2);
  }
  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let maxUploadSize = `${fileAllowedSize}`;
      let filesizeMb: any = 0;
      filesizeMb = (file.size / 1024) / 1024;

      let fileExtension = file.name.split('.').pop();
      let fileExtensionArray = file.name.split('.');

      let allowedExtension = `${fileAllowedExtensions}`;
      if (fileExtensionArray.length == 2) {
        if (allowedExtension.indexOf(fileExtension.toLowerCase()) > -1) {
          if (filesizeMb <= maxUploadSize) {
            this.invoiceFrm.patchValue({
              fileSource: file
            });
          }
          else {
            this.invoiceFrm.patchValue({
              fileSource: ""
            });
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: "File size is not greater than  " + maxUploadSize + " MB", life: 6000 }); //success,info,warn,error
          }
        }
        else {
          this.invoiceFrm.patchValue({
            fileSource: ""
          });
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); //success,info,warn,error
        }
      }
      else {
        this.invoiceFrm.patchValue({
          fileSource: ""
        });
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); //success,info,warn,error
      }
    }

  }
  onInvoiceFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let fileExtension = file.name.split('.').pop();

      let allowedExtension = `${fileAllowedExtensions}`;
      let maxUploadSize = `${fileAllowedSize}`;
      let filesizeMb: any = 0;
      filesizeMb = (file.size / 1024) / 1024;
      let fileExtensionArray = file.name.split('.');
      if (fileExtensionArray.length == 2) {

        if (allowedExtension.indexOf(fileExtension.toLowerCase()) > -1) {
          if (filesizeMb <= maxUploadSize) {
            this.invoiceFrm.patchValue({
              InvfileSource: file
            });
          }
          else {
            this.invoiceFrm.patchValue({
              InvfileSource: ""
            });
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: "File size is not greater than  " + maxUploadSize + " MB", life: 6000 }); //success,info,warn,error
          }
        }
        else {
          this.invoiceFrm.patchValue({
            InvfileSource: ""
          });
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); //success,info,warn,error
        }
      }
      else {
        this.invoiceFrm.patchValue({
          InvfileSource: ""
        });
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); //success,info,warn,error
      }
    }
  }
  getOtherTotal() {
    let Tot_Freight: any = 0;
    if (this.invoiceFrm.value.Tot_Freight > 0) {
      Tot_Freight = this.invoiceFrm.value.Tot_Freight;
    }
    let C_Insure: any = 0;
    if (this.invoiceFrm.value.C_Insure > 0) {
      C_Insure = this.invoiceFrm.value.C_Insure;
    }
    let C_Disc: any = 0;
    if (this.invoiceFrm.value.C_Disc > 0) {
      C_Disc = this.invoiceFrm.value.C_Disc;
    }
    let C_Comm: any = 0;
    if (this.invoiceFrm.value.C_Comm > 0) {
      C_Comm = this.invoiceFrm.value.C_Comm;
    }
    let C_Other: any = 0;
    if (this.invoiceFrm.value.C_Other > 0) {
      C_Other = this.invoiceFrm.value.C_Other;
    }
    //  let total = parseFloat(Tot_Freight) + parseFloat(C_Insure) + parseFloat(C_Disc) + parseFloat(C_Comm) + parseFloat(C_Other);
    let total = parseFloat(Tot_Freight) + parseFloat(C_Insure) + parseFloat(C_Disc) + parseFloat(C_Other);
    //let total = parseFloat(C_Insure) + parseFloat(C_Disc) + parseFloat(C_Comm) + parseFloat(C_Other);
    return total;
  }
  checkBillNumber($event) {
    let data = {
      "sbNumber": $event.target.value,
      "id": this.invoiceFrm.value.invoiceId
    }
    if ($event.target.value != "") {


      this._commonService.checkSbNumber(data).subscribe((item: any) => {
        if (item.success == false) {
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: item.message, life: 6000 }); //success,info,warn,error

        }
      });
    }
  }
  onChangeSearchPortIndia(val: string) {

    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allPortsIndia.length; i++) {
      let item = this.allPortsIndia[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredPortIndia = filtered;
    this.filteredPortIndia = this.filteredPortIndia.slice(0, 10);
  }

}
