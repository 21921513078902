import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../masters/master.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-region-master-edit',
  templateUrl: './region-master-edit.component.html',
  styleUrls: ['./region-master-edit.component.css']
})
export class RegionMasterEditComponent implements OnInit {

  pageHeading: string;
  lastEvent: any;
  datasource: any;
  totalRecords: number;
  itemDetail: any;
  loading: boolean;
  itemFrm: FormGroup;
  submitted = false;
  itemGroup: any;
  itemSubGroup: any;
  countries: any;
  id: number;
  constructor(private route: ActivatedRoute, private router: Router, private _service: MasterService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {

    this.itemFrm = this.formBuilder.group({
      ITEM_CODE: ['', [Validators.required, Validators.maxLength(8)]],
      ITEM_NAME: ['', Validators.required],
      id: [''],
      added_date: ['', Validators.required],
      subgroups: this.formBuilder.array([]),
    });
    this.route.params.subscribe(params => {

      if (params['id'] == "add") {
        this._service.getCountries().subscribe((item: any) => {
          this.countries = item.data
          this.pageHeading = "Add Region Master";
        });
      }
      if (params['id'] != "" && params['id'] != "add") {
        this.id = params['id'];
        this.pageHeading = "Edit Item Master";
        this._service.getRegionDetail(params['id']).subscribe((item: any) => {
          this.itemDetail = item.data[0].itemsDetail;
          this.itemGroup = item.data[0].groupDetail;
          this.countries = item.data[0].countryDetails;
          this.itemFrm.setValue({
            ITEM_CODE: item.data[0].UNION_CODE,
            id: item.data[0].id,
            ITEM_NAME: item.data[0].UNION_NAME,
            added_date: item.data[0].added_date,
            subgroups: [],
          });
        });
      }
    });
  }
  get f() { return this.itemFrm.controls; }

  saveItem() {
    console.warn("In here");
    this.submitted = true;

    // stop here if form is invalid
    if (this.itemFrm.invalid) {
      return;
    }

    //console.warn(this.itemFrm.value);

    this._service.saveRegion(this.itemFrm.value).subscribe((items: any) => {
      if (!items.success) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //
      }
      else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //
      }

    });

  }

  subgroups(): FormArray {

    return this.itemFrm.get("subgroups") as FormArray

  }
  newSubgroup(): FormGroup {

    return this.formBuilder.group({
      grade_code: ['', Validators.required],
      grade_name: '',
      id: '',
    })

  }
  addRow() {
    this.subgroups().push(this.newSubgroup());
  }
  remove(id: number) {
    if (id > 0) {
      this._service.removeCountry(id).subscribe((item: any) => {
        //this.itemSubGroup = subGroup.data;

        if (item.success) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: item.message, life: 6000 }); //
          // this.itemDetail = item.data[0].itemsDetail;
          this._service.getRegionDetail(this.id).subscribe((item: any) => {
            this.itemDetail = item.data[0].itemsDetail;
          });
        }
      });
    }
    return false;
  }
  removeSubgroup(i: number) {
    this.subgroups().removeAt(i);
  }
  getGradeCode(event, k) {
    let item = event.target.value;
    if (event.target.value != '') {
      let ltemArray = item.split("##");
      this.subgroups().controls[k].get('grade_name').setValue(ltemArray[1]);
    }

  }
}
