<p-toast></p-toast>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <div class="pageheadingouter">
        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> Shipping Bill </h1>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <a [routerLink]="['/exporter/invoices']"><button type="button" class="btn btn-light"> <i
                                class="fa fa-times"></i> Cancel</button></a>

                </div>
            </div>
        </div>
    </div>

    <div class="container pt-4">
        <p-accordion [multiple]="true">
            <p-accordionTab header="Upload PDF" [selected]="true">
                <div class="card-body px-0">
                    <form [formGroup]="myForm" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-xl-3 col-md-12 form-group">
                                <label for="file">Upload PDF </label>
                            </div>
                            <div class="form-group">
                                <input formControlName="sbFile" id="file" type="file" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.sbFile.errors }"
                                    (change)="onFileChange($event)">
                                <div *ngIf="submitted && f.sbFile.errors" class="invalid-feedback">
                                    <div *ngIf="f.sbFile.errors.required">File is required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input formControlName="invFile" id="file" type="file" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.invFile.errors }"
                                    (change)="oninvFileChange($event)">
                                <div *ngIf="submitted && f.invFile.errors" class="invalid-feedback">
                                    <div *ngIf="f.invFile.errors.required">File is required.</div>
                                </div>
                            </div>                            
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Shipping Bill no </label>
                                <input type="text" pInputText formControlName="SB_No" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.SB_No.errors }" />
                                <div *ngIf="submitted && f.SB_No.errors" class="invalid-feedback">
                                    <div *ngIf="f.SB_No.errors.required">Shipping Bill is required</div>
                                    <div *ngIf="f.SB_No.errors.minlength">Shipping Bill length should be 7 digits</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Invoice No.</label>
                                <input type="text" value="" formControlName="BILL_NO"
                                    [ngClass]="{ 'is-invalid': submitted && f.BILL_NO.errors }" class="form-control"
                                    name="BILL_NO">
                                <div *ngIf="submitted && f.BILL_NO.errors" class="invalid-feedback">
                                    <div *ngIf="f.BILL_NO.errors.required">Invoice No is required</div>
                                </div>
                            </div>
                            <button class="btn btn-success" type="submit">Submit</button>

                        </div>

                        <div class="row" innerHTML="{{ pdfDetail }}">

                        </div>
                    </form>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</section>