import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router,UrlTree,UrlSegment,PRIMARY_OUTLET,UrlSegmentGroup } from '@angular/router';
import { StatisticsServiceService } from '../statistics-service.service';


@Component({
  selector: 'app-statistics-average-unit',
  templateUrl: './statistics-average-unit.component.html',
  styleUrls: ['./statistics-average-unit.component.css']
})
export class StatisticsAverageUnitComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle:string;
  isloader:boolean=false;
  submitted:boolean=false;
  displayReport:boolean=false;
  displayDetailedReport:boolean=false;
  data:any;
  reportData:any=[];
  reportDateRange:any=[];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName:string;
  sourceGroup:any=[];

  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,private _service:StatisticsServiceService,private _router:Router) { }
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;

  ngOnInit(): void {

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length-1].path;
    if(reportType=="group"){
      this.reportTitle = "Average Unit Value of Item in different markets - Group-wise";
      this._service.getGroups().subscribe((itemGrp: any) => {
        this.sourceGroup = itemGrp.data;
      });
    }
    if(reportType=="item"){
      this.reportTitle = "Average Unit Value of Item in different markets - Item-wise";    
      this._service.getItems().subscribe((itemGrp: any) => {
        this.sourceGroup = itemGrp.data;
      });
    }
    this.reportFrm = this.formBuilder.group({
      dateStart:['',Validators.required],
      dateEnd:['',Validators.required],
      itemGroupId:['',Validators.required],
      viewDetailReport:[''],    
      reportType:[reportType],    
    }); 
  }

  get f() { return this.reportFrm.controls; }

  getReport(){
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    this._service.getStatisticsAverageUnit(this.reportFrm.value).subscribe((items: any) =>{
      this.data = items.data;
      if(items.data.reportData.length>0)
        {
          this.displayReport=true;
          this.displayDetailedReport=false;
          this.isloader = false;
        }
        else{
          this.displayReport=false;
          this.displayDetailedReport=false;
          this.isloader = false;
        } 
        this.reportData = items.data.reportData;
        this.reportDateRange = items.data.reportTime;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });
  }

  getValueRs(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }
    let returnValue = 0;
    if(filterObject.Qtyintons>0){
      returnValue = filterObject.FobRsCr/filterObject.Qtyintons
    }
    return returnValue.toFixed(2);
  }

  getTotalValue(year){
    let filterObject:any = [];
    let totalValue = 0;
    for(let i=0;i<this.reportData.length;i++){
      let objectItems = this.reportData[i].items;
      for(let j=0;j<objectItems.length;j++){
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if(key==year){
            filterObject = value;
            let returnValue = 0;
            if(filterObject.Qtyintons>0){
              returnValue = filterObject.FobRsCr/filterObject.Qtyintons
            }
            totalValue = totalValue+returnValue;
          }
        }
      }     
    }
    return totalValue.toFixed(2);
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.csv");
      
    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.xlsx");
    });
  }
}
