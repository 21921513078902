import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router,UrlTree,UrlSegment,PRIMARY_OUTLET,UrlSegmentGroup } from '@angular/router';
import { ReportServiceService } from '../report-service.service';
import { LazyLoadEvent } from 'primeng/api';
import * as XLSX from 'xlsx';
import { calendarRange } from 'src/environments/environment';


@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.css']
})
export class InvoiceReportComponent implements OnInit {

  reportFrm: FormGroup;
  loading:boolean=false;
  isloader:boolean=false;
  submitted:boolean=true;
  cols: any[];
  data:any;
  datasource:any;
  totalRecords: number = 0;  
  lastEvent:any;
  viewInvoice:boolean=true;
  exportRecords:any=[];
  calendarRange:string = calendarRange

  constructor(private formBuilder: FormBuilder,private _service:ReportServiceService,private _router:Router) { 

  }

  ngOnInit(): void {
    this.reportFrm = this.formBuilder.group({
      dateStart:['',Validators.required],
      dateEnd:['',Validators.required],      
    }); 
    this.cols = [
      {field: 'S.N', header: 'slno',width: '10%'},
      {field: 'Shi. Bill No.', header: 'SB_No',width: '12%'},
      {field: 'Shi. Bill Date', header: 'SB_Date',width: '12%'},
      {field: 'Invoice Number', header: 'BILL_NO',width: '12%'},
      {field: 'Invoice Date', header: 'Date',width: '10%'},
      {field: 'Exporter Name', header: 'EXP_NAME',width: '15%'},
      {field: 'Manufacturer', header: 'MAN_NAME' ,width: '15%'},
      {field: 'Country Of Destibation', header: 'COUNTRY_NAME',width: '15%'},
      {field: 'Quantity(MT)', header: 'Tot_Weight_Mt',width: '15%'},
      {field: 'Qty (kg)', header: 'Tot_Weight',width: '10%'},
      {field: 'Amount Rs(Lakh)', header: 'tot_amount_rs_lakh',width: '10%'},
      {field: 'Amount Rs(Crore)', header: 'tot_amount_rs_crore',width: '10%'},
      {field: 'Amount $', header: 'Tot_Amount_Million',width: '10%'},
      {field: 'Exchange Rate', header: 'DOL_RATE',width: '10%'},
      

      
    ];
  }

  get f() { return this.reportFrm.controls; }

  loadInvoice(event: LazyLoadEvent)
  {
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    this.lastEvent = event;
    
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    this._service.getInvoices(this.reportFrm.value,event.first,event.rows,event.sortField,event.sortOrder,event.filters).subscribe((invoices: any) =>{
      this.datasource = invoices.data.result;
      this.data = invoices.data.data;
      this.viewInvoice = true;
      this.totalRecords = invoices.data.totalRecord;
      this.isloader = false;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });
  }

  exportExcel() {

    this.loading = true;
    this._service.getInvoiceForExport(this.reportFrm.value).subscribe((items: any) => {
      this.loading = false;
      import("xlsx").then(xlsx => {
        //const worksheet = xlsx.utils.json_to_sheet(exportedData);
        const ws = xlsx.utils.json_to_sheet(items.data.data);
      // generate workbook and add the worksheet
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, ws, 'Invoice Data');
      // save to file
       xlsx.writeFile(workbook, "invoice_" + new Date().getTime()+".xls");
   
      });
    },error=>{
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });

  }

  saveExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xls';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportCSV() {
    this.loading = true;
    this._service.getInvoiceForExport(this.reportFrm.value).subscribe((items: any) => {
      this.loading = false;
      import("xlsx").then(xlsx => {
        //const worksheet = xlsx.utils.json_to_sheet(exportedData);
        const ws = xlsx.utils.json_to_sheet(items.data.data);
      // generate workbook and add the worksheet
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, ws, 'Invoice Data');
      // save to file
       xlsx.writeFile(workbook, "invoice_" + new Date().getTime()+".csv");
   
      });
    },error=>{
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });
  }
}
