import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportSummaryService {

  constructor(private http:HttpClient) { }
  url = `${apiUrl}`;
  getGroups(){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url+"reports/getGroup");
  }


  //Below code to get Unions 
  getUnion(){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url+"reports/getUnions",options);
  }

  
  getGroupItems(data){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url+"reports/getGroupItems",data,options);
  }
  getItemwiseReport(data){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let startDate = data.dateStart;
    let startDateNew  = new Date(data.dateStart);
    return this.http.post(this.url+"reports/getItemwiseReport",{data:data},options);
  }

  //Code to get Region wise report 
  getRegionWiseReport(data){

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url+"reports/getRegionwiseReport",{data:data},options);
  }

  getPortWiseReport(data){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url+"reports/getPortwiseReport",{data:data},options);
  }

  getRegionCountries(data){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url+"reports/getRegionCountry",data,options);
  }
  //Below code for country wise report

  getCountryWiseReport(data){

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url+"reports/getCountryWiseReport",{data:data},options);
  }
}
