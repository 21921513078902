import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router,UrlTree,UrlSegment,PRIMARY_OUTLET,UrlSegmentGroup } from '@angular/router';
import { StatisticsServiceService } from '../statistics-service.service';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-statistics-region',
  templateUrl: './statistics-region.component.html',
  styleUrls: ['./statistics-region.component.css']
})
export class StatisticsRegionComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle:string;
  isloader:boolean=false;
  submitted:boolean=false;
  displayReport:boolean=false;
  displayDetailedReport:boolean=false;
  data:any;
  reportData:any=[];
  reportDateRange:any=[];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName:string;
  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,private _service:StatisticsServiceService,private _router:Router,private commonService: CommonServiceService) { }
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;

  ngOnInit(): void {

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length-1].path;

    this.reportTypeName = reportType;
    if(reportType=="region"){
      this.reportTitle = "Major Markets for Indian Marine Products - Region-Wise";
    }
    if(reportType=="items"){
      this.reportTitle = "Item-Wise Exports of  Marine Products From India";    

    }
    
    this.reportFrm = this.formBuilder.group({
      dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      viewDetailReport:[''],    
      reportType:[reportType],    
    }); 
    this.reportFrm.valueChanges.subscribe(val=>{
      if(this.reportData.length>0){
        if(this.reportFrm.value.viewDetailReport){      
            this.displayReport=false;
            this.displayDetailedReport=true;
        }
        else{
          this.displayReport=true;
          this.displayDetailedReport=false;
        }
      }
        
    });
  }

  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(itemQty, totalQty) {
    return this.commonService.getShare(itemQty, totalQty);
  }

  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }

  getReport(){
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    // Make validation by common service
    this.commonService.validateCalendar(dt1, dt2);

    this._service.getStatisticsRegions(this.reportFrm.value).subscribe((items: any) =>{
      this.data = items.data;
      if(items.data.reportData.length>0)
        {
          this.displayReport=true;
          this.displayDetailedReport=false;
          this.isloader = false;
        }
        else{
          this.displayReport=false;
          this.displayDetailedReport=false;
          this.isloader = false;
        } 
        this.reportData = items.data.reportData;
        this.reportDateRange = items.data.reportTime;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });
  }

  getQuantity(itemObject,year){

    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.Qtyintons;
  }
  getQuantityGrowth(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.qtyVariationPercent.toFixed(2);
  }
  getValueRs(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.FobRsCr;
  }
  getValueRsGrowth(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.rsVarPer.toFixed(2);
  }
  getValueDollar(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.FobDolMil;
  }
  getValueDollarGrowth(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.dolVarPer.toFixed(2);
  }

  getTotalQuantity(year){
    let filterObject:any = [];
    let totalQuantity = 0;
    for(let i=0;i<this.reportData.length;i++){
      let objectItems = this.reportData[i].items;
      for(let j=0;j<objectItems.length;j++){
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if(key==year){
            filterObject = value;
            totalQuantity = totalQuantity+filterObject.Qtyintons;
          }
        }
      }     
    }
    return totalQuantity.toFixed(2);
  }
  getTotalValue(year){
    let filterObject:any = [];
    let totalValue = 0;
    for(let i=0;i<this.reportData.length;i++){
      let objectItems = this.reportData[i].items;
      for(let j=0;j<objectItems.length;j++){
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if(key==year){
            filterObject = value;
            totalValue = totalValue+filterObject.FobRsCr;
          }
        }
      }     
    }
    return totalValue.toFixed(2);
  }
  getTotalValueDoller(year){
    let filterObject:any = [];
    let totalValue = 0;
    for(let i=0;i<this.reportData.length;i++){
      let objectItems = this.reportData[i].items;
      for(let j=0;j<objectItems.length;j++){
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if(key==year){
            filterObject = value;
            totalValue = totalValue+filterObject.FobDolMil;
          }
        }
      }     
    }
    return totalValue.toFixed(2);
  }

  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report_detail.xlsx");
 
    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report_detail.csv");
 
    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.csv");
      
    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.xlsx");
    });
  }
}
