<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <form [formGroup]="searchFrm" name="searchFrm" (ngSubmit)="saveSearch()">
        <div class="pageheadingouter">
            <div class="container container2">
                <div class="row">
                    <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                        <div class="pageheading ">
                            <h1> <img src="assets/img/report-icons.png" alt=""> Create Report </h1>
                        </div>
                    </div>


                </div>

            </div>
        </div>
        <div class="container pt-4 form-inline cret_reprt">
            <table width="100%" class="table" formArrayName="searchFilters">
                <thead class="thead-light">
                    <tr>
                        <th colspan="100%">
                            <button type="button" class="btn btn-success float-right" (click)="addRow()">Add New
                                Item</button>
                            <h5 class="pt-md-2">Filters</h5>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let quantity of searchFilters().controls; let k=index" [formGroupName]="k">
                        <td>
                            {{k+1}}
                        </td>
                        <td>
                            <select formControlName="filterId" class="form-control" (change)="getFilterOption($event,k)"
                                [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterId').errors }">
                                <option value="">Select</option>
                                <option *ngFor="let filter of reportFilters" value="{{filter.id}}">
                                    {{filter.name}}</option>
                            </select>


                        </td>
                        <td>
                            <select formControlName="filterType" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterType').errors }">
                                <option value="">Select</option>
                                <option value="=">Equal</option>
                                <option value="!=">Not Equal</option>
                                <option value=">">Greater Than</option>
                                <option value=">=">Greater Than Equal</option>
                                <option value="<">Less Than</option>
                                <option value="<=">Less Than Equal</option>
                            </select>
                        </td>
                        <td class="new_blck">
                            <input type="text" class="form-control inline-block" formControlName="filterValues"
                                [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterValues').errors }" />
                            <span>
                                <button type="button" pButton pRipple label="New" icon="pi pi-plus"
                                    class="p-button-success p-mr-2" (click)="showDialog(k)"></button>
                            </span>
                        </td>
                        <td>
                            <select formControlName="filterCondition" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterCondition').errors }">
                                <option value="">Select</option>
                                <option value="AND">AND</option>
                                <option value="OR">OR</option>
                                <option value="0">None</option>
                            </select>

                        </td>
                        <td align="right">
                            <button type="button" (click)="removeSearchFilter(k)" class="btn btn-danger">Remove</button>

                        </td>
                    </tr>

                </tbody>
            </table>
            <table width="100%" class="table border">
                <tr>
                    <td>Date Range </td>
                    <td>
                        <select class="form-control" formControlName="dateRangeOption" (change)="getDateRange($event)">
                            <option value="">Select</option>
                            <option value="1">Today</option>
                            <option value="2">Yesterday</option>
                            <option value="3">This Month</option>
                            <option value="4">This Year </option>
                            <option value="5">Custom Range </option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        Available Columns
                    </td>
                    <td>
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="true"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToTarget)="addColumn($event)"
                            (onMoveToSource)="removeColumn($event)" (onTargetReorder)="columnReorder($event)">
                            <ng-template let-searchColumns pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{searchColumns.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </td>
                </tr>
                <tr>
                    <td>
                        Sort By
                    </td>
                    <td>
                        <select formControlName="sortColumn" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.sortColumn.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let sortcolumn of targetGroup" value="{{sortcolumn.column_name}}">
                                {{sortcolumn.name}}
                            </option>
                        </select>
                        &nbsp;
                        <select formControlName="sortColumnType" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.sortColumnType.errors }">
                            <option value="">Select</option>
                            <option value="ASC">Ascending</option>
                            <option value="DESC"> Descending</option>

                        </select>

                    </td>
                </tr>

                <tr>
                    <td>Search Name </td>
                    <td>
                        <input type="text" formControlName="searchName" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.searchName.errors }" />
                        <div *ngIf="submitted && f.searchName.errors" class="invalid-feedback">

                        </div>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>
                    </td>
                </tr>

            </table>
        </div>

        <input type="hidden" formControlName="selectedColumn" />
    </form>
</section>
<br>


<p-dialog [(visible)]="display" [style]="{width: '850px'}">
    <ng-template pTemplate="header">
        {{relatedDataHeader}}
    </ng-template>
    <p-table #dt [value]="filterData" [rows]="10" [columns]="tableColumns" [paginator]="true"
        [(selection)]="selectedFilters" [rowHover]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns" pSortableColumn="{{col}}">{{col}} <p-sortIcon field="{{col}}">
                    </p-sortIcon>
                </th>
            </tr>
            <tr>
                <th style="width: 3rem">&nbsp;</th>
                <th *ngFor="let col of columns">
                    <input pInputText type="text" (input)="dt.filter($event.target.value, col, 'contains')"
                        [value]="dt.filters[col]?.value" placeholder="Search by {{col}}" class="p-column-filter">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-filterRowData let-columns="columns">
            <tr>
                <td>
                    <p-tableCheckbox [value]="filterRowData"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns">{{filterRowData[col]}}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton pRipple label="Confirm" icon="pi pi-check" (click)="selectFilters()"
            [disabled]="!selectedFilters || !selectedFilters.length" class="p-button-success p-mr-2"></button>

    </p-footer>
</p-dialog>