<p-toast></p-toast>
<form [formGroup]="itemFrm" name="itemFrm" (ngSubmit)="saveItem()">
    <div class="pageheadingouter">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> {{pageHeading}} </h1>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>
                </div>


            </div>

        </div>
    </div>
    <div class="container pt-4">


        <div class="card">
            <div class="card-header" id="headingOne">
                <a> Region Details</a>
            </div>

            <div id="item_details">
                <div class="card-body px-0">

                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Code </label>
                            <input type="text" pInputText formControlName="ITEM_CODE" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.ITEM_CODE.errors }" value="" />
                            <div *ngIf="submitted && f.ITEM_CODE.errors" class="invalid-feedback">
                                <div *ngIf="f.ITEM_CODE.errors.maxlength">Code length should be less then 8 digits</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Name </label>
                            <input type="text" pInputText formControlName="ITEM_NAME" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.ITEM_NAME.errors }" value="">
                        </div>
  
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Added Date</label><br>
                            <p-calendar formControlName="added_date" dateFormat="dd/mm/yy"
                                [ngClass]="{ 'is-invalid': submitted && f.added_date.errors }"></p-calendar>
                        </div>
                        <input type="hidden" pInputText formControlName="id" class="form-control" value="">
                        <div class="col-xl-12 col-md-12 text-right">

                            <button type="button" class="btn btn-success" (click)="addRow()">Add New Item</button>


                        </div>

                        <div class="col-xl-12 col-md-12 pt-4 table-responsive">




                            <table class="table" formArrayName="subgroups">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Country Code </th>
                                        <th>Country Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let quantity of subgroups().controls; let k=index" [formGroupName]="k">
                                        <td>
                                            <select formControlName="grade_code" class="form-control"
                                                (change)="getGradeCode($event,k)">
                                                <option>Select Country</option>
                                                <option *ngFor="let country of countries" value="{{country.id}}##{{country.COUNTRY_NAME}}">
                                                    {{country.COUNTRY_CODE}}---{{country.COUNTRY_NAME}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" formControlName="grade_name" class="form-control">
                                        </td>
                                        <td>

                                            <button (click)="removeSubgroup(i)" class="btn btn-danger">Remove</button>

                                        </td>
                                    </tr>
                                    <tr *ngFor="let item of itemDetail">
                                        <td>{{item.COUNTRY_CODE}}</td>
                                        <td>{{item.COUNTRY_NAME}}</td>
                                        <td><button (click)="remove(item.id)" class="btn btn-danger">Remove</button>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>

                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</form>