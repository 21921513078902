<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} Report </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">

    

    
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details">
            <div class="card-body px-0" *ngIf="page=='monthly'">

                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                            yearRange="1995:2021" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <p-calendar formControlName="dateEnd" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                            yearRange="1995:2021" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>
                </div>

                



                <div class="row">
                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Groups </label>
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeGroups($event)"
                            (onMoveToTarget)="addChecksums($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>



                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Items </label>
                        <p-pickList [source]="sourceItems" [target]="targetItems" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeItems($event)"
                            (onMoveToTarget)="addItems($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>


                </div>
                <div class="btn_pan mb-4">
                    <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                </div>
               

                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> Show Variation and Growth Percentage </label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>

                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span>Q: Quantity in Tons,</span>
                    <span>V: Value in Rs. Crores,</span>
                    <span>$: USD Million</span>
                </div>
                <!--code for detailed report -->
                <div class="card" *ngIf="displayDetailedReport">
                    <table #detailTable>
                        <p-table [value]="reportData">

                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()"
                                        class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th width="17%">Item</th>
                                    <th width="15%">&nbsp;</th>
                                    <ng-template ngFor let-itemVal [ngForOf]="yearRange">
                                        <th width="20%">{{itemVal}}</th>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="yearRange2">
                                        <th width="20%">{{itemVal}}</th>
                                    </ng-template>


                                    <ng-template ngFor let-itemVal [ngForOf]="yearRange">
                                        <th width="20%">Share % {{itemVal}}</th>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="yearRange2">
                                        <th width="20%">Share % {{itemVal}}</th>
                                    </ng-template>




                                    <ng-template ngFor let-itemVal [ngForOf]="variationsRange">
                                        <th width="20%">Variation : {{itemVal}}</th>
                                    </ng-template>


                                    <ng-template ngFor let-itemVal [ngForOf]="growth">
                                        <th width="20%"> {{itemVal}}</th>
                                    </ng-template>


                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-groupitem>
                                <tr>
                                    <td colspan="20" class="grpitm_hed">
                                        <strong>{{groupitem.groupName}}</strong>
                                    </td>
                                </tr>

                                <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                    <tr>
                                        <td width="17%">{{item.name}}</td>
                                        <td width="15%"><b>Q</b> </td>
                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.Qtyintons,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsRange">
                                            <td width="20%">
                                                {{getNumber(itemVal.Qtyintons,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">

                                            <td> 0 </td>


                                        </ng-template>


                                        <!-- <td>0</td>
                                        <td>0</td>

                                        <td>0</td>
                                        <td>0</td> -->
                                        <td>0</td>
                                        <td>0</td>



                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.qtyVar,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.qtyVariationPercent,2)}}
                                            </td>
                                        </ng-template>




                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>

                                        <td><b>V</b> </td>
                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.FobRsCr,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsRange">
                                            <td width="20%">
                                                {{getNumber(itemVal.FobRsCr,2)}}
                                            </td>
                                        </ng-template>




                                        <td>0</td>
                                        <td>0</td>

                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>



                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.rsVar,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.rsVarPer,2)}}
                                            </td>
                                        </ng-template>




                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>

                                        <td><b>$</b> </td>
                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.FobDolMil,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsRange">
                                            <td width="20%">
                                                {{getNumber(itemVal.dolVar,2)}}
                                            </td>
                                        </ng-template>

                                        <td>0</td>
                                        <td>0</td>

                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>



                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.dolVar,2)}}
                                            </td>
                                        </ng-template>


                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.dolVarPer,2)}}
                                            </td>
                                        </ng-template>





                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>

                                        <td><b>UV$</b> </td>
                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{itemVal.uvDol}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsRange">
                                            <td width="20%">
                                                {{itemVal.uvDol}}
                                            </td>
                                        </ng-template>


                                        <td>0</td>
                                        <td>0</td>

                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>



                                        <!-- <td>0</td>
                                        <td>0</td>
                                        <td>0</td>

                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td> -->

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.uvDol,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.itemsVariation">
                                            <td width="20%">
                                                {{getNumber(itemVal.uvDolPer,2)}}
                                            </td>
                                        </ng-template>





                                    </tr>
                                </ng-template>

                                <!-- <tr>
                                    <td width="17%"><strong>Grand Total</strong></td>

                                    <td width="15%"><b>Q</b>:</td>
                                    <td width="20%">{{groupitem.groupTotalQuantity}}</td>
                                    <td width="20%">{{groupitem.groupTotalQuantityPrevious}}</td>
                                    <td width="10%">{{groupitem.groupTotalQuantityVariation}}</td>
                                    <td width="10%">{{groupitem.groupTotalQuantityVariationPer}}</td>
                                    
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>

                                    <td>V:</td>
                                    <td>{{groupitem.grpTotalPriceRs}}</td>
                                    <td>{{groupitem.grpTotalPriceRsPrev}}</td>
                                    <td>{{groupitem.grpTotalPrRsVar}}</td>
                                    <td>{{groupitem.grpTotalPrRsVarPer}}</td>

                                    
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>

                                    <td>$</td>
                                    <td>{{groupitem.grpTotalPriceDol}}</td>
                                    <td>{{groupitem.grpTotalPriceDolPrev}}</td>
                                    <td>{{groupitem.grpTotalPriceDolVar}}</td>
                                    <td>{{groupitem.grpTotalPriceDolPer}}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>UV$ </td>
                                    <td>{{getNumber(groupitem.uvDol,2)}}</td>
                                    <td>{{getNumber(groupitem.uvDolPre, 2)}}</td>
                                    <td>{{getNumber(groupitem.uvDolPreVar, 2)}}</td>
                                    <td>{{getNumber(groupitem.uvDolPreVarPer, 2)}}</td>
                                </tr> -->

                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr>
                                    <td width="17%">Total</td>
                                    <td width="15%"><b>Q</b>:</td>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.total">
                                        <td width="20%">
                                            {{itemVal.totalQuantity}}
                                        </td>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.rangetotal">
                                        <td width="20%">
                                            {{itemVal.totalQuantity}}
                                        </td>
                                    </ng-template>








                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>




                                    <td>100</td>
                                    <td>100</td>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.totalVariation">
                                        <td width="20%">
                                            {{itemVal.qtyVar}}
                                        </td>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.totalVariation">
                                        <td width="20%">
                                            {{itemVal.qtyVarPer}}
                                        </td>
                                    </ng-template>




                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><b>V</b>:</td>
                                    <ng-template ngFor let-itemVal [ngForOf]="data.total">
                                        <td width="20%">
                                            {{itemVal.totalPriceRs}}
                                        </td>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.rangetotal">
                                        <td width="20%">
                                            {{itemVal.totalPriceRs}}
                                        </td>
                                    </ng-template>



                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>




                                    <td>100</td>
                                    <td>100</td>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.totalVariation">
                                        <td width="20%">
                                            {{itemVal.rsVar}}
                                        </td>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.totalVariation">
                                        <td width="20%">
                                            {{itemVal.rsVarPer}}
                                        </td>
                                    </ng-template>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><b>$</b></td>
                                    <ng-template ngFor let-itemVal [ngForOf]="data.total">
                                        <td width="20%">
                                            {{itemVal.totalPriceDol}}
                                        </td>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.rangetotal">
                                        <td width="20%">
                                            {{itemVal.totalPriceDol}}
                                        </td>
                                    </ng-template>



                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>




                                    <td>100</td>
                                    <td>100</td>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.totalVariation">
                                        <td width="20%">
                                            {{itemVal.dolVar}}
                                        </td>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.totalVariation">
                                        <td width="20%">
                                            {{itemVal.dolVarPer}}
                                        </td>
                                    </ng-template>
                                </tr>
                            </ng-template>
                        </p-table>
                    </table>
                </div>
                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable>
                                    <p-table [value]="reportData">

                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th width="17%">Item</th>

                                                <th width="15%">&nbsp;</th>
                                                <ng-template ngFor let-itemVal [ngForOf]="yearRange">
                                                    <th width="20%">{{itemVal}}</th>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td [attr.colspan]="headerLength2" class="grpitm_hed">
                                                    <strong>{{groupitem.groupName}}</strong>
                                                </td>
                                            </tr>
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <tr>
                                                    <td width="17%">{{item.name}}</td>
                                                    <td width="15%"><b>Q</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{getNumber(itemVal.Qtyintons,2)}}
                                                        </td>
                                                    </ng-template>


                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{getNumber(itemVal.FobRsCr,2)}}
                                                        </td>
                                                    </ng-template>



                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>

                                                    <td><b>$</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{getNumber(itemVal.FobDolMil,2)}}
                                                        </td>
                                                    </ng-template>


                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>UV$</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{itemVal.uvDol}}
                                                        </td>
                                                    </ng-template>

                                                </tr>
                                            </ng-template>


                                            <!-- <tr>
                                                <td width="17%"><strong>Grand Total</strong></td>
                                              
                                                <td width="15%"><b>Q</b>:</td>
                                                <td width="20%">{{groupitem.groupTotalQuantity}}</td>
                                                <td width="20%">{{groupitem.groupTotalQuantityPrevious}}</td>
                                                <td width="10%">{{groupitem.groupTotalQuantityVariation}}</td>
                                                <td width="10%">{{groupitem.groupTotalQuantityVariationPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                               
                                                <td>V:</td>
                                                <td>{{groupitem.grpTotalPriceRs}}</td>
                                                <td>{{groupitem.grpTotalPriceRsPrev}}</td>
                                                <td>{{groupitem.grpTotalPrRsVar}}</td>
                                                <td>{{groupitem.grpTotalPrRsVarPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                               
                                                <td>$</td>
                                                <td>{{groupitem.grpTotalPriceDol}}</td>
                                                <td>{{groupitem.grpTotalPriceDolPrev}}</td>
                                                <td>{{groupitem.grpTotalPriceDolVar}}</td>
                                                <td>{{groupitem.grpTotalPriceDolPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>UV$ </td>
                                                <td>{{getNumber(groupitem.uvDol,2)}}</td>
                                                <td>{{getNumber(groupitem.uvDolPre, 2)}}</td>
                                                <td>{{getNumber(groupitem.uvDolPreVar, 2)}}</td>
                                                <td>{{getNumber(groupitem.uvDolPreVarPer, 2)}}</td>
                                            </tr> -->

                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td width="17%">Total</td>

                                                <td width="15%"><b>Q</b>:</td>

                                                <ng-template ngFor let-itemVal [ngForOf]="data.total">
                                                    <td width="20%">
                                                        {{itemVal.totalQuantity}}
                                                    </td>
                                                </ng-template>

                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                                <td><b>V</b>:</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="data.total">
                                                    <td width="20%">
                                                        {{itemVal.totalPriceRs}}
                                                    </td>
                                                </ng-template>

                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                                <td><b>$</b></td>

                                                <ng-template ngFor let-itemVal [ngForOf]="data.total">
                                                    <td width="20%">
                                                        {{itemVal.totalPriceDol}}
                                                    </td>
                                                </ng-template>


                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </table>



                            </div>
                        </div>
                    </section>
                </section>

            </div>







            <div class="card-body px-0" *ngIf="page=='all'">

                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Select Year </label>
                    </div>
                    
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">Select Year </span>
                        
                        
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                            
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Groups </label>
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeGroups($event)"
                            (onMoveToTarget)="addChecksums($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Items </label>
                        <p-pickList [source]="sourceItems" [target]="targetItems" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeItems($event)"
                            (onMoveToTarget)="addItems($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </div>
                
                <div class="btn_pan mb-4">
                    <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                </div>
               

                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> Show Variation and Growth Percentage </label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                </div> -->

                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span>Q: Quantity in Tons,</span>
                    <span>V: Value in Rs. Crores,</span>
                    <span>$: USD Million</span>
                </div>


                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">

                                <div class="card">
                                    <p-table [columns]="scrollableCols" [frozenColumns]="frozenCols" [value]="reportData" scrollHeight="400px"  [scrollable]="true"  frozenWidth="300px">


                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="generateExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                                    tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>


                                        <ng-template pTemplate="colgroup" let-columns>
                                            <colgroup>
                                                <col *ngFor="let col of columns" style="width:300px">
                                            </colgroup>
                                        </ng-template>
                                        <ng-template pTemplate="header" let-columns>
                                            <tr>
                                                <th *ngFor="let col of columns">
                                                    {{col.header}}
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-customer let-columns="columns">
                                            <tr>
                                                <td *ngFor="let col of columns">
                                                    {{customer[col.field]}}
                                                </td>
                                            </tr>
                                        </ng-template>
                
                                        
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </div>




        </div>
        <input type="hidden" formControlName="selectedGroups" />
        <input type="hidden" formControlName="selectedItems" />
        <input type="hidden" formControlName="reportType" />
    </form>
</div>