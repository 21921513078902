import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { ExportReviewService } from '../export-review.service';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-monthly-export-trents-market',
  templateUrl: './monthly-export-trents-market.component.html',
  styleUrls: ['./monthly-export-trents-market.component.css']
})
export class MonthlyExportTrentsMarketComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  targetGroup: any;
  sourceGroup: any;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  reportData: any = [];

  arrays: any = [];

  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  showTextBox: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  dateRange: number;
  fragment = '';
  reportDateRange: '';
  primary: UrlSegmentGroup;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService, private commonService: CommonServiceService) { }

  ngOnInit(): void {

    let reportType = 'export';

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;

    this.reportFrm = this.formBuilder.group({
      dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      selectedPort: [''],
      viewDetailReport: [''],
      reportType: [reportType],
      topRecordNum: ['0'],
      topRecordNumOther: ['1'],
      includeBlankData: ['0'],
      sortColumn: ['FobDolMil'],
      sortType: ['DESC'],

    });
    this.targetGroup = [];
    this.primengConfig.ripple = true;
    this.reportTitle = "Monthly Export Trends in Major Markets";

    this._service.getUnion().subscribe((unions: any) => {
      this.sourceGroup = unions.data;
    });





    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }

  addPort(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  removePort(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(itemQty, totalQty) {
    return this.commonService.getShare(itemQty, totalQty);
  }

  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }



  getReport() {





    this.isloader = true;
    this.submitted = true;

    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }



    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;

    // Make validation by common service
    this.commonService.validateCalendar2(dt1, dt2);


    if (this.reportFrm.value.selectedPort == "") {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Port", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }





    this._service.getMonthlyTrendsMarket(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;

      console.log('get monthly export trend data ', this.data);


      if (this.data.status) {
        this.isloader = false;
        this.arrays = this.data.latestData;

        this.displayReport = true;

        console.log("Get Latest Data", this.data.latestData);
      }

    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });


  }



  getQuantity(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.Qtyintons;
  }
  getQuantityGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.qtyVariationPercent.toFixed(2);
  }
  getValueRs(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobRsCr;
  }
  getValueRsGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.rsVarPer.toFixed(2);
  }
  getValueDollar(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobDolMil;
  }
  getValueDollarGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.dolVarPer.toFixed(2);
  }




  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }

}
