import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetupServiceService } from '../../setup-service.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute,Router } from '@angular/router';
import { encryptSecretKey } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
 
  userFrm: FormGroup;
  pageHeading:string="";
  submitted:boolean=false;
  branchList:any;
  userType:any;
  readonly:boolean=false;
  constructor(private formBuilder: FormBuilder,private _service:SetupServiceService,private messageService: MessageService,private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.pageHeading = "Create User";
    this.userFrm = this.formBuilder.group({
      userId:'',
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      mobile: ['', Validators.required],
      branch_id: ['', Validators.required],
      role_id: ['', Validators.required],
      password: ['', [
        Validators.required, 
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
      ]],
      cpassword: ['', Validators.required],
      status: ['', Validators.required],
      userType: ['', Validators.required],
      
    },{validator: this.passwordMatchValidator});

    this._service.getUserSetups().subscribe((setups:any) => {
      this.branchList = setups.data.Branches;
      this.userType = setups.data.roles;
     

    });

    this.route.params.subscribe(params => {
      if(params['id']!="" && typeof params['id'] !== "undefined")
      {
        this.pageHeading = "Edit User ";
        this._service.getUserDetail(params['id']).subscribe((item: any) => {
            this.userFrm.setValue({
              userId: item.data.id,
              first_name:item.data.first_name,
              last_name: item.data.last_name,
              email: item.data.email,
              mobile: item.data.mobile,
              branch_id: item.data.branch_id,
              role_id: item.data.role_id,
              password: '',
              cpassword: '',
              status: item.data.status,
              userType:item.data.userType,
            });
        });
      }
    });
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password'].value === frm.controls['cpassword'].value ? null : {'mismatch': true};
  }
   //Below code to save User form 
   get f() { return this.userFrm.controls; }

   saveUser(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.userFrm.invalid) {
        return;
    }

    
    let time = Math.floor(Date.now() / 1000);
    let encrptionKey = encryptSecretKey + "_" + time;

    let cipherText = CryptoJS.AES.encrypt(this.userFrm.value.password, encrptionKey).toString();



    let userData = {
      "branch_id":this.userFrm.value.branch_id,
      "password": cipherText,
      "cpassword": cipherText,
      "first_name": this.userFrm.value.first_name,
      "last_name": this.userFrm.value.last_name,
      "mobile": this.userFrm.value.mobile,
      "email": this.userFrm.value.email,
      "role_id": this.userFrm.value.role_id,
      "status": this.userFrm.value.status,
      "userId": this.userFrm.value.userId,
      "userType": this.userFrm.value.userType,
      "time":time,
    }
    

    // console.log("Form data:", this.userFrm.value);
    // console.log("User data:", userData);


    this._service.saveUser(userData).subscribe((items: any) =>{      
      this.submitted = false;
      console.warn(items);
      if(!items.status)
      {        
        this.messageService.add({severity:'error', summary: 'Fail', detail: items.message, life: 6000}); //
      }
      else{
        if(items.data.success){
          this.messageService.add({severity:'success', summary: 'Success', detail: items.data.message, life: 6000}); //
        }
        else{
          this.messageService.add({severity:'error', summary: 'Fail', detail: items.data.message, life: 6000}); //
        }
        
      }
      return false;
    });


  }
  validateBranch(event){
    if(event.target.value==1){
      this.userFrm.controls.branch_id.patchValue(0, {emitEvent: false});
      this.userFrm.get('branch_id').disable({onlySelf: true});
    }
    if(event.target.value==2){
      this.userFrm.controls.branch_id.patchValue('', {emitEvent: false});
      this.userFrm.get('branch_id').enable({onlySelf: true});
    }     
  }
}
