<section class="innerpage">
    <div class="pageheadingouter">
        <div class="container container2">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/report-icons.png" alt=""> Export Review </h1>
                    </div>
                </div>
<!--
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <button type="button" class="btn btn-success"> <i class="fa fa-plus"></i>  Create Report</button>
                </div>
-->

            </div>

        </div>
    </div>

    <div class="container pt-4">
        <div class="reportouter report_list my-4">
            <div class="grid-sizer"></div>
            <div class="gutter-sizer"></div>
            <ng-template [ngIf]="submenu" *ngFor="let menu of submenu">
                <div class="report_inner" >
                    <h3> {{menu.name}} </h3>
                    <ng-template [ngIf]="menu.submenus" >
                        <ul>
                            <li *ngFor="let submenu of menu.submenus" > <a [routerLink]="[submenu.link]"> {{submenu.name}} </a> </li>
                            
                        </ul>
                    </ng-template>
                </div>    
            </ng-template>
        </div>
    </div>

</section>
