import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  url = `${apiUrl}`;


  getInvoices(startRow, rowNumber, sortField, sortOrder, filters) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/getInvoices",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);


    //return response.result

  }

  deleteInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/deleteInvoice", { data: data }, options);

  }




}

