import { Component, OnInit } from '@angular/core';
import { ExporterServiceService } from '../exporter-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { MessageService } from 'primeng/api';
import { fileAllowedExtensions, fileAllowedSize, poundToKg } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import jwt_decode from "jwt-decode";


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {


  requestFrm: FormGroup;
  submitted = false;
  isloader: boolean = false;
  checkFileSelection:boolean = false;


  constructor(private primengConfig: PrimeNGConfig, private route: ActivatedRoute, private _service: ExporterServiceService, private formBuilder: FormBuilder, private messageService: MessageService, private _router: Router) { }

  ngOnInit(): void {

    this.requestFrm = this.formBuilder.group({
      description: ['', Validators.required],
      title: ['', Validators.required],
      file: [''],
      fileSource: [''],
      
    });
  }

  get f() { return this.requestFrm.controls; }


  

  saveRequest() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.requestFrm.invalid) {
      return;
    }

    

    const formData = new FormData();
    formData.append('file', this.requestFrm.get('fileSource').value);
    formData.append('title', this.requestFrm.get('title').value);
    formData.append('description', this.requestFrm.get('description').value);

    this._service.saveRequest(formData).subscribe((items: any) => {
      if (items.success) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }
        ); 
        
        this.requestFrm.reset();
        
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
      }
      this.submitted = false;
      return false;
    });
  }
   
  
  onFileChangeOLD(event) { 
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.requestFrm.patchValue({
        fileSource: file
      });
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let maxUploadSize = `${fileAllowedSize}`;
      let filesizeMb: any = 0;
      filesizeMb = (file.size / 1024) / 1024;
      let fileExtensionArray = file.name.split('.');
      

      let fileExtension = file.name.split('.').pop();
      
      let allowedExtension = `${fileAllowedExtensions}`;
      if (fileExtensionArray.length == 2) {
        if (allowedExtension.indexOf(fileExtension.toLowerCase()) > -1) {
          if (filesizeMb <= maxUploadSize) {
            this.requestFrm.patchValue({
              fileSource: file
            });
          }
          else {
            this.requestFrm.patchValue({
              fileSource: "",
              file:""
            });
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: "File size is not greater than  " + maxUploadSize + " MB", life: 6000 }); 
          }

        }
        else {
          this.requestFrm.patchValue({
            fileSource: "",
            file:""
          });
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); 
        }
      }
      else {
        this.requestFrm.patchValue({
          fileSource: "",
          file:""
        });
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); 
      }

    }
  }

}
