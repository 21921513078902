import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { SetupServiceService } from '../../setup-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from "../../../../common-service.service";


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  loading: boolean=true;
  cols: any[];
  data:any;
  datasource:any;
  totalRecords: number;  
  isLoggedIn:number;
  isPermissionAdd:boolean=false
  isPermissionEdit:boolean=false
  constructor(private _service:SetupServiceService,private _router:Router,private _commonService:CommonServiceService) { }

  ngOnInit(): void {
    this.cols = [
      {field: 'Id', header: 'id',width: '5%',isSearch:"1"},
      {field: 'Field Office', header: 'branchname',width: '15%',isSearch:"1"},
      {field: 'First Name', header: 'first_name',width: '8%',isSearch:"1"},
      {field: 'Last Name', header: 'last_name',width: '8%',isSearch:"1"},
      {field: 'Email', header: 'email',width: '25%',isSearch:"1"},
      {field: 'Mobile', header: 'mobile',width: '10%',isSearch:"1"},
      {field: 'Type', header: 'userType',width: '10%',isSearch:"1"},
      {field: 'Role', header: 'rolename',width: '10%',isSearch:"1"},
      {field: 'Status', header: 'status',width: '10%',isSearch:"0"},
      {field: 'Added Date', header: 'added_date',width: '10%',isSearch:"0"},
      {field: 'Modify Date', header: 'modify_date',width: '10%',isSearch:"0"},
    ];
    let parentName = 'Setup';
    let buttons = this._commonService.getActionButton(parentName);
    let addBtn  = buttons.filter(recordAction => recordAction.action_name === "Add");
    if(addBtn.length>0)
    {
      this.isPermissionAdd = true;
    }
    let editBtn  = buttons.filter(recordAction => recordAction.action_name === "Edit");
    if(editBtn.length>0)
    {
      this.isPermissionEdit = true;
    }
  
  }

  loadUsers(event: LazyLoadEvent) {  
    this.loading = true;
    //console.warn(event.filters);
   // return false;
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {  

      this._service.getUsers(event.first,event.rows,event.sortField,event.sortOrder,event.filters).subscribe((items: any) => {
        this.datasource = items.result;
        this.data = items.data.data;
        this.totalRecords = items.data.totalRecord;
        this.loading = false;
      },error=>{
         
        if(error instanceof HttpErrorResponse)
        {
          if(error.status===401){
            this._router.navigate(['./']);
          }
        }
      });
    }, 1000);
    
} 
}
