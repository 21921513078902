<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-5 col-md-6 text-md-left text-sm-left text-center">

                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> View Report </h1>
                </div>
            </div>

        

        </div>

    </div>
</div>
<section class="innerpage">


    <div class="innershadow">

        <div class="container pt-4">
            <div class="table-responsive">
                <p-table [value]="data" #dt [columns]="cols" [lazy]="true" (onLazyLoad)="loadInvoices($event)"
                    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [loading]="loading"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[10,25,50]">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th pSortableColumn="{{col.header}}" [ngStyle]="{'width': col.width}">
                                    <ng-template [ngIf]="col.field=='Qty(KG)'">
                                        <ng-template [ngIf]="quantityOption==1 ">{{col.field}}</ng-template>
                                        <ng-template [ngIf]="quantityOption==2">Qty(MT)
                                        </ng-template>
                                    </ng-template>
                                    <ng-template [ngIf]="col.field!='Qty(KG)'">
                                        {{col.field}}
                                    </ng-template>
                                    <p-sortIcon field="{{col.header}}">
                                    </p-sortIcon>
                                </th>
                            </ng-template>
                        </tr>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th [ngStyle]="{'width': col.width}">
                                    <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, col.header, 'startsWith')"
                                        placeholder="Search by {{col.field}}" class="p-column-filter"
                                        style="width: 100%;" />
                                </th>
                            </ng-template>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-columns="columns">
                        <tr style="height:34px">
                            <ng-template *ngFor="let col of columns"
                                [ngIf]="col.type!='hidden' && col.field!='Qty(MT)'">
                                <td>
                                    <ng-template [ngIf]="col.header=='status'">
                                        <ng-template [ngIf]="data['statusCode']==1">
                                            <div style="color:green">{{data[col.header]}}</div>
                                        </ng-template>
                                        <ng-template [ngIf]="data['statusCode']!=1">
                                            <strong>{{data[col.header]}}</strong>
                                        </ng-template>
                                    </ng-template>
                                    <ng-template [ngIf]="col.header=='EXP_NAME'">
                                        <div>{{data[col.header]}}-({{data['regNumber']}})</div>
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="col.header!='status' && (col.header!='EXP_NAME' &&  col.field!='Qty(KG)' &&  col.field!='Amount($)' &&  col.field!='Amount(₹)') ">
                                        {{data[col.header]}}
                                    </ng-template>
                                    <ng-template [ngIf]="col.header!='status' && col.field=='Amount($)' ">
                                        <ng-template [ngIf]="amountOption==0 ">{{data[col.header]| number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==1">{{data[col.header]/100000 | number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==2">{{data[col.header]/10000000 | number}}
                                        </ng-template>
                                    </ng-template>
                                    <ng-template [ngIf]="col.header!='status' && col.field=='Amount(₹)' ">
                                        <ng-template [ngIf]="amountOption==0 ">{{data[col.header]| number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==1">{{data[col.header]/100000 | number}}
                                        </ng-template>
                                        <ng-template [ngIf]="amountOption==2">{{data[col.header]/10000000 | number}}
                                        </ng-template>
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="col.header!='status' && (col.header!='EXP_NAME' &&  col.field=='Qty(KG)') ">
                                        <ng-template [ngIf]="quantityOption==1 ">{{data[col.header]| number}}
                                        </ng-template>
                                        <ng-template [ngIf]="quantityOption==2">{{data[col.header]/1000 | number}}
                                        </ng-template>
                                    </ng-template>
                                </td>
                            </ng-template>
                            <td class="tableicons">
                                <ng-template [ngIf]="isPermissionEdit ">
                                    <a [routerLink]="['/viewinvoice']" [queryParams]="{id:data.id}">
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-success p-mr-2"></button>
                                    </a>
                                </ng-template>
                                <ng-template [ngIf]="isPermissionDownload">
                                    <a href='http://mpedastat.broadwayinfotech.net.au/mpedapdf/index.php?type=pdf&id={{data.id}}'
                                        title="Download invoice" alt="Download invoice">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </a>
                                </ng-template>

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            {{totalRecords}} Shipping Bill Found.
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>



</section>

<br>