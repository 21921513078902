<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} Report </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>

                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors}" class="form-control"
                            maxlength="4" />

                            <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                                <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                            </div>
                    </div>


                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                            <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                                <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                            </div>
                    </div>

                    <!-- <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                            yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <p-calendar formControlName="dateEnd" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                            yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div> -->


                    <div class="col form-group" *ngIf="page=='region-wise'">
                        <span class="label_sub" style="color: #999;">Select Group </span>
                        <p-dropdown formControlName="selectedItem" [options]="sourceGroup" optionLabel="name"
                            optionValue="code" placeholder="Select Group"
                            [ngClass]="{ 'is-invalid': submitted && f.selectedItem.errors }"></p-dropdown>

                        <div *ngIf="submitted && f.selectedItem.errors" class="invalid-feedback">
                            <div *ngIf="f.selectedItem.errors.required">Select group is required</div>
                        </div>
                    </div>









                </div>




                <div class="row" *ngIf="page=='country'">
                    <div class="col-xl-12 col-md-12 form-group">
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removePort($event)"
                            (onMoveToTarget)="addPort($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </div>



                <div class="row">
                    <div class="btn_pan mb-4">
                        <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                    
                </div> -->
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>Q</b>: Unit Value Rs / Kg </span>


                </div>

                <div class="card" *ngIf="displayDetailedReport">
                    <table #detailTable>
                        <p-table [value]="detailReportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o"
                                        (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportDetailExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="1">&nbsp;</th>
                                    <th colspan="1">&nbsp;</th>

                                    <th colspan="3">{{data.dateRange1}}</th>

                                    <th colspan="3">{{data.dateRange2}}</th>


                                </tr>
                                <tr>
                                    <th>Region </th>
                                    <th pSortableColumn="name">Country <p-sortIcon field="name"></p-sortIcon>
                                    </th>
                                    <th>Q </th>
                                    <th>V </th>
                                    <th>$ </th>

                                    <th pSortableColumn="Qtyintons">Q <p-sortIcon field="Qtyintons"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobRsCr">V <p-sortIcon field="FobRsCr"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobDolMil">$ <p-sortIcon field="FobDolMil"></p-sortIcon>
                                    </th>



                            </ng-template>

                            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                                <tr>
                                    <td><strong>{{item.groupName}}</strong></td>
                                    <td>{{item.itemName}}</td>

                                    <td>{{getNumber(item.Qtyintons,2)}}</td>
                                    <td>{{getNumber(item.FobRsCr,2)}}</td>
                                    <td>{{getNumber(item.FobDolMil,2)}}</td>
                                    <td>{{getNumber(item.QtyPreTon,2)}}</td>
                                    <td>{{getNumber(item.FobRsPreCr,2)}}</td>
                                    <td>{{getNumber(item.FobDolPreMil,2)}}</td>

                                </tr>
                            </ng-template>


                            <ng-template pTemplate="footer">
                                <tr>
                                    <td>Sub Total</td>
                                    <td>&nbsp;</td>

                                    <td>{{getNumber(data.totalQuantity,2)}}</td>
                                    <td>{{data.totalPriceRs}}</td>
                                    <td>{{data.totalPriceDol}}</td>

                                    <td>{{getNumber(data.totalQuantityPrev, 2)}}</td>
                                    <td>{{data.totalPriceRsPrev}}</td>
                                    <td>{{data.totalPriceDolPrev}}</td>

                                </tr>
                            </ng-template>
                        </p-table>
                    </table>
                </div>




                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">

                                <table #userTable *ngIf="dateRange==0">
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>



                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Country </th>
                                                <th>&nbsp;</th>
                                                <th>{{data.dateRange1}}</th>
                                            </tr>
                                        </ng-template>


                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="3" class="grpitm_hed"><strong>{{groupitem.name}}</strong>
                                                </td>
                                            </tr>
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td>{{item.itemName}} </td>
                                                    <td><b>Q</b> </td>
                                                    <td>{{getNumber(item.Qtyintons,2)}}</td>
                                                </tr>
                                            </ng-template>


                                            <!-- <tr>
                                                <td><strong>Total</strong></td>
                                                <td>Q:</td>
                                                <td>{{groupitem.groupTotalQuantity}}</td>
                                            </tr> -->

                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td><b>Grand Total</b></td>
                                                <td>Q:</td>
                                                <td>{{getNumber(data.totalQuantity, 2)}}</td>

                                            </tr>

                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==1">
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>



                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Country </th>
                                                <th>&nbsp;</th>
                                                <th>{{data.dateRange1}}</th>
                                                <th>{{data.dateRange2}}</th>

                                            </tr>
                                        </ng-template>


                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="4" class="grpitm_hed"><strong>{{groupitem.name}}</strong>
                                                </td>
                                            </tr>
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td>{{item.itemName}} </td>

                                                    <td><b>Q</b> </td>
                                                    <td>{{getNumber(item.Qtyintons, 2)}}</td>
                                                    <td>{{getNumber(item.QtyPreTon, 2)}}</td>

                                                </tr>


                                            </ng-template>


                                            <!-- <tr>
                                                <td><strong>Total</strong></td>

                                                <td>Q:</td>

                                                <td>{{groupitem.groupTotalQuantity}}</td>
                                                <td>{{groupitem.groupTotalQuantityPrevious}}</td>

                                            </tr> -->

                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td><b>Grand Total</b></td>

                                                <td>Q:</td>

                                                <td>{{getNumber(data.totalQuantityUnitRs,2)}}</td>
                                                <td>{{getNumber(data.totalQuantityUnitRsPrev,2)}}</td>

                                            </tr>

                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==2">
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>



                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Item </th>
                                                <th>&nbsp;</th>
                                                <th>{{data.dateRange1}}</th>
                                                <th>{{data.dateRange2}}</th>
                                                <th>{{data.dateRange3}}</th>

                                            </tr>
                                        </ng-template>


                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="5" class="grpitm_hed"><strong>{{groupitem.name}}</strong>
                                                </td>
                                            </tr>
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">

                                                <tr>
                                                    <td>{{item.itemName}} </td>

                                                    <td><b>Q</b> </td>
                                                    <td>{{getNumber(item.Qtyintons, 2)}}</td>
                                                    <td>{{getNumber(item.QtyPreTon, 2)}}</td>
                                                    <td>{{getNumber(item.QtyPre2Ton, 2)}}</td>

                                                </tr>



                                            </ng-template>


                                            <!-- <tr>
                                                <td><strong>Total</strong></td>

                                                <td>Q:</td>

                                                <td>{{groupitem.groupTotalQuantity}}</td>
                                                <td>{{groupitem.groupTotalQuantityPrevious}}</td>
                                                <td> {{groupitem.groupTotalQuantityPrevious2}}</td>

                                            </tr> -->


                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td><b>Grand Total</b></td>

                                                <td>Q:</td>
                                                <td>{{getNumber(data.totalQuantityUnitRs,2)}}</td>
                                                <td>{{getNumber(data.totalQuantityUnitRsPrev,2)}}</td>
                                                <td>{{getNumber(data.totalQuantityUnitRsPrev2,2)}} </td>

                                            </tr>

                                        </ng-template>
                                    </p-table>
                                </table>


                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </div>
        <input type="hidden" formControlName="selectedPort" />
        <input type="hidden" formControlName="reportType" />

    </form>
</div>