<p-toast></p-toast>
<form [formGroup]="changePassword" name="changePassword" (ngSubmit)="updateNewPassword()"  >
    <div class="pageheadingouter">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> {{pageHeading}} </h1>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <a  [routerLink]="['/setup/user']"><button type="button" class="btn btn-light"> <i class="fa fa-times"></i> Cancel</button></a>
                    <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>
                </div>


            </div>

        </div>
    </div>
    <div class="container pt-4">
        <div class="card">
            <div class="card-header" id="headingOne">
                <a > Update new password</a>
            </div>
            <div id="item_details" >
                <div class="card-body px-0">
                    <div class="row">

                        <div class="col-xl-12 col-md-12 form-group">
                            <label> Old Password</label>
                            <input type="password" pInputText formControlName="oldPassword" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" value="" />
                           
                            
                           <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Old Password  is required</div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-md-12 form-group">
                            <label> Password </label>
                            <input type="password" pInputText formControlName="password" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" value="" />
                            <span class="label_note">Note : Password should be combination of lowercase,uppercase,numeric,special characters and minimum length should be 8</span>
                            
                           <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password  is required</div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 form-group">
                            <label> Confirm Password </label>
                            <input type="password" pInputText formControlName="cpassword" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.cpassword.errors }" value="">
                            <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.cpassword.errors.required">Please confirm password </div>
                            </div>
                            <div *ngIf="submitted && (f.cpassword.errors || changePassword.errors?.mismatch)" >
                                Password doesn't match
                            </div>
                        </div>                     
                    </div>
                </div>
            </div>
        </div>

    </div>
    
</form>