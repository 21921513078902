<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Year is required</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Year is required</div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Select Group </label>
                    </div>
                    <div class="col form-group">
                        <select class="form-control" formControlName="itemGroupId"
                            [ngClass]="{'is-invalid': submitted && f.itemGroupId.errors }" class="form-control">
                            <option value="">Select</option>
                            <option *ngFor="let group of sourceGroup; let i = index" value="{{group.code}}">
                                {{group.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.itemGroupId.errors" class="invalid-feedback">
                            <div *ngIf="f.itemGroupId.errors.required">Group is required</div>
                        </div>

                    </div>
                </div>


                <div class="row">
                    <div class="btn_pan mb-4">
                        <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>V</b>: Value in Rs. Lakhs,</span>
                </div>

            </div>
            <!-- Report Block -->
            <section class="wrapper" *ngIf="displayReport">
                <section class="innerpage">
                    <div class="innershadow">
                        <div class="itm_gr_rprt">
                            <table #userTable>
                                <p-table [value]="reportData">
                                    <ng-template pTemplate="caption">
                                        <div class="p-d-flex">
                                            <button type="button" pButton pRipple icon="pi pi-file-o"
                                                (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                tooltipPosition="bottom"></button>
                                            <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                                tooltipPosition="bottom"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th width="17%">Items</th>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    <th width="20%">{{itemVal.Range}}</th>
                                                </ng-template>
                                            </ng-template>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr>
                                            <td width="17%">{{item.name}}</td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    <td width="20%">{{getValueRs(item.items,itemVal.startYear)}}</td>
                                                </ng-template>
                                            </ng-template>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="footer">

                                        <tr>
                                            <td><b>Total</b></td>
                                            <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                <ng-template [ngIf]="itemVal.isDisplay">
                                                    <td width="20%">{{getTotalValue(itemVal.startYear)}}</td>
                                                </ng-template>
                                            </ng-template>
                                        </tr>

                                    </ng-template>
                                </p-table>
                            </table>
                        </div>
                    </div>
                </section>
            </section>
        </div>
        <input type="hidden" formControlName="reportType" />
    </form>
</div>
<br>