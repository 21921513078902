<p-toast></p-toast>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <form [formGroup]="requestFrm" name="requestFrm" (ngSubmit)="saveRequest()">

        <div class="pageheadingouter">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                        <div class="pageheading">
                            <h1> <img src="assets/img/file_icons.png" alt=""> Request / Edit </h1>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                        <a [routerLink]="['/exporter/requestList']"><button type="button" class="btn btn-light"> <i
                                    class="fa fa-times"></i> Back </button></a>
                        <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>

                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-4">
            <p-accordion>
                <p-accordionTab header="Request" [selected]="true">

                    <div class="card-body px-0">
                        <div class="row">


                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Title </label>
                                <input type="text" formControlName="title" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.title.errors }" maxlength="100">


                                <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                    <div *ngIf="f.title.errors.required">The Title field is required </div>
                                </div>
                            </div>


                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Description </label>
                                <textarea formControlName="description" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.description.errors }" maxlength="2000">
                            </textarea>

                                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                    <div *ngIf="f.description.errors.required">The Description field is required </div>
                                </div>
                            </div>


                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Status </label>

                                <select class="form-control" id="status" formControlName="status" name="status"
                                    [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                    <option value="">Select Status</option>
                                    <option [value]="1" [selected]="statusValue ==1">Open</option>
                                    <option [value]="2" [selected]="statusValue ==2">In-progress</option>
                                    <option [value]="3" [selected]="statusValue ==3">Close</option>

                                </select>
                                <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                    <div *ngIf="f.status.errors.required">The Status is required</div>
                                </div>
                            </div>


                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Attachment </label>
                                <input formControlName="file" id="file" type="file" class="form-control"
                                    (change)="onFileChange($event)">
                            </div>







                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>

        </div>

    </form>
</section>