<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <div class="pageheadingouter">
        <div class="container container2">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/report-icons.png" alt=""> Dashboard </h1>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <ng-template [ngIf]="isShowMap" >
        <div class="container container2">
            <div class="row my-4">
                <div class="col-sm-12 text-center dsb">
                    <span class="tx01">Financial Year &nbsp;</span>
                    <select class="form-control d-inline-block form2"  #mySelectYear  [(ngModel)]="financialYear" (change)="childPort.getGraph(mySelectYear.value);childItem.getGraph(mySelectYear.value);childCountry.getGraph(mySelectYear.value);childExport.getGraph(mySelectYear.value)"> 
                        <option *ngFor="let fYear of finnancialYear; let i = index" value="{{fYear.year}}">{{fYear.year}}</option>
                    </select>
                </div>
                
            </div>
            <div class="row db_grph">
                <div class="col-md-6 text-center" >
                    <div class="dbox">
                        <app-port-graph #childPort [financialYear]="financialYear" ></app-port-graph>
                    </div>
                </div>
                <div class="col-md-6 text-center">
                    <div class="dbox">
                        <app-item-graph #childItem [financialYear]="financialYear" ></app-item-graph>
                    </div>
                </div>
            </div>
            <div class="row db_grph my-4">
                <div class="col-md-6 text-center">
                    <div class="dbox">
                        <app-country-graph #childCountry [financialYear]="financialYear" ></app-country-graph>
                    </div>
                </div>
            </div>
            <div class="row db_grph my-4">
                <div class="col-md-12">
                    <div class="dbox">
                        <app-export-graph #childExport [financialYear]="financialYear" ></app-export-graph>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


    <p-dialog [(visible)]="masterDialog" [style]="{width: '650px'}" header="Change Password " [modal]="true"
        styleClass="p-fluid" [closable]="false">
        <ng-template pTemplate="content">
            <form #masterFrm="ngForm" [formGroup]="registerForm" (ngSubmit)="saveMaster(masterFrm)">
                <input type="hidden" name="action" formControlName="action" [(ngModel)]="masterFrmData.action" />
                <div class="p-field form-group" *ngFor="let col of cols">
                    <ng-template [ngIf]="col.type!='hidden' && col.type!='hideForm'">
                        <label for="{{col.header}}">{{col.field}} </label>
                    </ng-template>
                    <ng-template [ngIf]="col.field=='New Password'" >
                       <span class="label_note"> ( lowercase+uppercase+numeric+special characters and minimum length should be 8 ) 
                        </span>
                    </ng-template>
                    <ng-template [ngIf]="col.type=='text'">
                        <input type="password" class="form-control" formControlName="{{col.header}}"
                            
                            [ngClass]="{ 'is-invalid': submitted}"
                            [(ngModel)]="masterFrmData[col.header]"/>
                            
                   
                    </ng-template>


                    <ng-template [ngIf]="col.type=='hidden'">
                        <input type="hidden" class="form-control" formControlName="{{col.header}}" name="{{col.header}}"
                            [(ngModel)]="masterFrmData[col.header]" value="{{id}}" />
                    </ng-template>
                   
                </div>


                <div class="col-xl-12 col-md-12 xl-text-right py-xl-5 text-center pt-4 pb-3">
                    <!-- <button type="button" class="btn btn-light" (click)="closeForm()"> <i class="fa fa-times"></i>
                        Cancel</button> -->
                    <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
                </div>

            </form>
        </ng-template>
    </p-dialog>
</section>