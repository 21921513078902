<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{pageHeading}} </h1>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                <a [routerLink]="['/itemmaster','add']">
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2"></button>
                </a>
            </div>


        </div>

    </div>
</div>
<section class="innerpage">


    <div class="innershadow">
        <div class="container pt-4">
            <!--
            <p-button icon="fa fa-refresh" iconPos="left" (click)="reset()"></p-button>
            -->
            <div class="table-responsive">
                <p-table [value]="data" [style]="{'width':'100%'}" [columns]="cols" #dt [lazy]="true"
                    (onLazyLoad)="loadData($event)" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
                    [loading]="loading" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[10,25,50]">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th pSortableColumn="{{col.header}}" [ngStyle]="{'width': col.width}">{{col.field}}
                                    <p-sortIcon field="{{col.header}}"></p-sortIcon>
                                </th>
                            </ng-template>
                            <th style="width:5%"></th>
                        </tr>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th>

                                    <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, col.header, 'startsWith')"
                                        placeholder="Search by {{col.field}}" class="p-column-filter"
                                        style="width:100%" />
                                </th>
                            </ng-template>
                            <th style="width:5%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-columns="columns">
                        <tr style="height:34px">
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <td>
                                    {{data[col.header]}}
                                </td>
                            </ng-template>
                            <td>
                                <a [routerLink]="['/itemmaster', data['ID']]">
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-success p-mr-2"></button></a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>

</section>
<br>