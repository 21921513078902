import { Component } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { AuthService } from '../app/components/user-login/auth.service';
import jwt_decode from "jwt-decode";
import { Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Mpeda';
  year : number = new Date(). getFullYear();
  isAdminLogin = localStorage.getItem('isLogin');
  constructor(private bnIdle: BnNgIdleService, private authService: AuthService, private router: Router) {
  }

  // initiate it in your component OnInit
  ngOnInit(): void {
    //console.warn("Vikas Mathur App component init ");
    
    this.bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        //console.log('session expired');
        this.logOut();

      }
    });
  }
  logOut() {
    //  this.isloaded = false;
    //  this.authService.logOut();
    var token = JSON.parse(localStorage.getItem('token'));
    var decoded = jwt_decode(token);
    var data = {
      'email': decoded['result'].email
    };

    this.authService.logOut(data).subscribe((items: any) => {
      localStorage.removeItem("token");
      // this.isLogin = 0;
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userLevel");
      this.router.navigate([""]);

    });

  }
}
