import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportReviewService } from '../../export-review.service';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { CommonServiceService } from '../../../../common-service.service';


@Component({
  selector: 'app-frozen-crab',
  templateUrl: './frozen-crab.component.html',
  styleUrls: ['./frozen-crab.component.css']
})


export class FrozenCrabComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName: string;
  reportDateRange: any = [];
  sourceGroup: any = [];
  isGroupDd: boolean = false;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService, private commonService: CommonServiceService) { }

  ngOnInit(): void {

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;


    console.warn(reportType);

    // this.reportTitle = "Market Wise Export of Marine Products ";
    if (reportType == "frozen-crab") {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        viewDetailReport: [''],
        reportType: [reportType],
        //itemGrouop: ['', Validators.required],
      });

      this.reportTitle = " Frozen Crab ";
    }
    else if (reportType == "live-items") {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        viewDetailReport: [''],
        reportType: [reportType],
      });

      this.reportTitle = " Live-Items ";
    }



    this.primengConfig.ripple = true;

    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });
  }

  get f() { return this.reportFrm.controls; }


  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(itemQty, totalQty) {
    return this.commonService.getShare(itemQty, totalQty);
  }

  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }




  getReport() {

    this.isloader = true;

    this.submitted = true;

    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;

    // Make validation by common service
    this.commonService.validateCalendar2(dt1, dt2);


    this._service.getExportMarketFrozenCrab(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      console.log("--get object--", this.data);
      if (items.data.reportData.length > 0) {

        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
      console.log("get reportData", this.reportData);
      this.reportDateRange = items.data.reportData[0].items;


    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }

  // getShare(itemQty,totalQty){
  //   let percentage = itemQty*100/totalQty;
  //   return percentage.toFixed(2);
  // }
}
