import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SummaryReportService {

  constructor(private http:HttpClient) { }
  url = `${apiUrl}`;
  getGroups(){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url+"reports/getGroup");
  }

  getReport(data){
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let startDate = data.dateStart;
    let startDateNew  = new Date(data.dateStart);
    

    console.warn("Date",startDate);
    console.warn(startDateNew);
    return this.http.post(this.url+"reports/getReport",{data:data},options);
  }
}
