<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} Report </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>

                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">Select Month </span>
                        <p-calendar formControlName="month" view="month" dateFormat="MM" [yearNavigator]="false"
                            yearRange="yearRange" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.month.errors }"></p-calendar>

                        <div *ngIf="submitted && f.month.errors" class="invalid-feedback">
                            <div *ngIf="f.month.errors.required">Month is required</div>
                        </div>
                    </div>

                    <!-- <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart" class="form-control" maxlength="4">

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd" class="form-control" maxlength="4">
                    </div> -->


                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">{{ getCustomMessage('dateStart.required')}}
                            </div>
                            <div *ngIf="f.dateStart.errors.pattern">{{ getCustomMessage('dateStart.pattern') }}</div>
                            <div *ngIf="f.dateStart.errors.min">{{ getCustomMessage('dateStart.min') }}</div>
                            <div *ngIf="f.dateStart.errors.max">{{ getCustomMessage('dateStart.max') }}</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">{{ getCustomMessage('dateEnd.required')}}</div>
                            <div *ngIf="f.dateEnd.errors.pattern">{{ getCustomMessage('dateEnd.pattern')}}</div>
                            <div *ngIf="f.dateEnd.errors.min">{{ getCustomMessage('dateEnd.min')}}</div>
                            <div *ngIf="f.dateEnd.errors.max">{{ getCustomMessage('dateEnd.max')}}
                            </div>
                        </div>
                    </div>









                </div>
                <div class="row">
                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Groups </label>
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeGroups($event)"
                            (onMoveToTarget)="addChecksums($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>



                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Items </label>
                        <p-pickList [source]="sourceItems" [target]="targetItems" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeItems($event)"
                            (onMoveToTarget)="addItems($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>


                </div>
                <div class="btn_pan mb-4">
                    <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                </div>
                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>

                </div> -->

                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> Show Variation </label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>

                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span>Q: Quantity in Tons,</span>
                    <span>V: Value in Rs. Crores,</span>
                    <span>$: USD Million</span>
                </div>
                <!--code for detailed report -->
                <div class="card" *ngIf="displayDetailedReport">
                    <table #detailTable>
                        <p-table [value]="reportData">

                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()"
                                        class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th width="17%">Item</th>
                                    <!-- <th width="100">Share % </th> -->
                                    <th width="15%">&nbsp;</th>
                                    <!-- <th width="20%">{{data.dateStart}}</th>
                                    <th width="20%">{{data.endDate}}</th> -->
                                    <ng-template ngFor let-itemVal [ngForOf]="yearRange">
                                        <th width="20%">{{itemVal}}</th>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="variationsRange">
                                        <th width="20%">Variation : {{itemVal}}</th>
                                    </ng-template>

                                    <ng-template ngFor let-itemVal [ngForOf]="yearRange">
                                        <th width="20%">(%) - {{itemVal}}</th>
                                    </ng-template>

                                    <!-- <th width="10%">Growth</th>
                                    <th width="10%">(%)</th> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-groupitem>
                                <tr>
                                    <td [attr.colspan]="headerLength" class="grpitm_hed">
                                        <strong>{{groupitem.groupName}}</strong>
                                    </td>
                                </tr>
                                <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                    <tr>
                                        <td width="17%">{{item.name}}</td>
                                        <!-- <td width="100">{{getShare(item.Qtyintons,data.totalQuantity)}}</td> -->
                                        <td width="15%"><b>Q</b> </td>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.Qtyintons,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.qtyVariation,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.qtyVariationPercent,2)}}
                                            </td>
                                        </ng-template>

                                        <!-- <td width="20%">{{item.Qtyintons.toFixed(2)}}</td>
                                            <td width="20%">{{item.QtyPreTon.toFixed(2)}}</td> -->
                                        <!-- <td width="10%">{{item.qtyVariation.toFixed(2)}}</td>
                                            <td width="10%">{{item.qtyVariationPercent.toFixed(2)}}</td> -->



                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <!-- <td>{{getShare(item.FobRsCr,data.totalPriceRs)}}</td> -->
                                        <td><b>V</b> </td>
                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.FobRsCr,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.rsVar,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.rsVarPer,2)}}
                                            </td>
                                        </ng-template>
                                        <!-- <td>{{item.FobRsCr.toFixed(2)}}</td>
                                            <td>{{item.FobRsPreCr.toFixed(2)}}</td>
                                            <td>{{item.rsVar.toFixed(2)}}</td>
                                            <td>{{item.rsVarPer.toFixed(2)}}</td>                      -->
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <!-- <td>{{getShare(item.FobDolMil,data.totalPriceDol)}}</td> -->
                                        <td><b>$</b> </td>
                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.FobDolMil,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.dolVar,2)}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{getNumber(itemVal.dolVarPer,2)}}
                                            </td>
                                        </ng-template>

                                        <!-- <td>{{item.FobDolMil.toFixed(2)}}</td>
                                            <td>{{item.FobDolPreMil.toFixed(2)}}</td>
                                            <td>{{item.dolVar.toFixed(2)}}</td>
                                            <td>{{item.dolVarPer.toFixed(2)}}</td>                      -->
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <!-- <td>&nbsp;</td> -->
                                        <td><b>UV$</b> </td>
                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{itemVal.uvDol}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{itemVal.uvDolPreVar}}
                                            </td>
                                        </ng-template>

                                        <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                            <td width="20%">
                                                {{itemVal.uvDolPreVarPer}}
                                            </td>
                                        </ng-template>

                                        <!-- <td>{{item.uvDol}}</td>
                                            <td>{{item.uvDolPre}}</td>
                                            <td>{{item.uvDolPreVar}}</td>
                                            <td>{{item.uvDolPreVarPer}}</td>                      -->
                                    </tr>
                                </ng-template>
                                <!-- <tr>
                                    <td width="17%"><strong>Grand Total</strong></td>
                                  
                                    <td width="15%"><b>Q</b>:</td>
                                    <td width="20%">{{groupitem.groupTotalQuantity}}</td>
                                    <td width="20%">{{groupitem.groupTotalQuantityPrevious}}</td>
                                    <td width="10%">{{groupitem.groupTotalQuantityVariation}}</td>
                                    <td width="10%">{{groupitem.groupTotalQuantityVariationPer}}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                   
                                    <td>V:</td>
                                    <td>{{groupitem.grpTotalPriceRs}}</td>
                                    <td>{{groupitem.grpTotalPriceRsPrev}}</td>
                                    <td>{{groupitem.grpTotalPrRsVar}}</td>
                                    <td>{{groupitem.grpTotalPrRsVarPer}}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                   
                                    <td>$</td>
                                    <td>{{groupitem.grpTotalPriceDol}}</td>
                                    <td>{{groupitem.grpTotalPriceDolPrev}}</td>
                                    <td>{{groupitem.grpTotalPriceDolVar}}</td>
                                    <td>{{groupitem.grpTotalPriceDolPer}}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>UV$ </td>
                                    <td>{{getNumber(groupitem.uvDol,2)}}</td>
                                    <td>{{getNumber(groupitem.uvDolPre, 2)}}</td>
                                    <td>{{getNumber(groupitem.uvDolPreVar, 2)}}</td>
                                    <td>{{getNumber(groupitem.uvDolPreVarPer, 2)}}</td>
                                </tr> -->

                            </ng-template>
                            <!-- <ng-template pTemplate="footer">
                                <tr>
                                    <td width="17%">Total</td>

                                    <td width="15%"><b>Q</b>:</td>
                                    <td width="20%">{{data.totalQuantity}}</td>
                                    <td width="20%">{{data.totalQuantityPrev}}</td>
                                    <td width="10%">{{data.totalQtyVar}}</td>
                                    <td width="10%">{{data.totalQtyPer}}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>

                                    <td><b>V</b>:</td>
                                    <td>{{data.totalPriceRs}}</td>
                                    <td>{{data.totalPriceRsPrev}}</td>
                                    <td>{{data.totalPrRsVar}}</td>
                                    <td>{{data.totalPrRsVarPer}}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>

                                    <td><b>$</b></td>
                                    <td>{{data.totalPriceDol}}</td>
                                    <td>{{data.totalPriceDolPrev}}</td>
                                    <td>{{data.totalPrDolVar}}</td>
                                    <td>{{data.totalPrDolPer}}</td>
                                </tr>
                            </ng-template> -->
                        </p-table>
                    </table>
                </div>
                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable>
                                    <p-table [value]="reportData">

                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th width="17%">Item</th>

                                                <th width="15%">&nbsp;</th>
                                                <ng-template ngFor let-itemVal [ngForOf]="yearRange">
                                                    <th width="20%">{{itemVal}}</th>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td [attr.colspan]="headerLength2" class="grpitm_hed">
                                                    <strong>{{groupitem.groupName}}</strong>
                                                </td>
                                            </tr>
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <tr>
                                                    <td width="17%">{{item.name}}</td>
                                                    <td width="15%"><b>Q</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{getNumber(itemVal.Qtyintons,2)}}
                                                        </td>
                                                    </ng-template>


                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{getNumber(itemVal.FobRsCr,2)}}
                                                        </td>
                                                    </ng-template>



                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>

                                                    <td><b>$</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{getNumber(itemVal.FobDolMil,2)}}
                                                        </td>
                                                    </ng-template>


                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>UV$</b> </td>
                                                    <ng-template ngFor let-itemVal [ngForOf]="item.items">
                                                        <td width="20%">
                                                            {{itemVal.uvDol}}
                                                        </td>
                                                    </ng-template>

                                                </tr>
                                            </ng-template>


                                            <!-- <tr>
                                                <td width="17%"><strong>Grand Total</strong></td>
                                              
                                                <td width="15%"><b>Q</b>:</td>
                                                <td width="20%">{{groupitem.groupTotalQuantity}}</td>
                                                <td width="20%">{{groupitem.groupTotalQuantityPrevious}}</td>
                                                <td width="10%">{{groupitem.groupTotalQuantityVariation}}</td>
                                                <td width="10%">{{groupitem.groupTotalQuantityVariationPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                               
                                                <td>V:</td>
                                                <td>{{groupitem.grpTotalPriceRs}}</td>
                                                <td>{{groupitem.grpTotalPriceRsPrev}}</td>
                                                <td>{{groupitem.grpTotalPrRsVar}}</td>
                                                <td>{{groupitem.grpTotalPrRsVarPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                               
                                                <td>$</td>
                                                <td>{{groupitem.grpTotalPriceDol}}</td>
                                                <td>{{groupitem.grpTotalPriceDolPrev}}</td>
                                                <td>{{groupitem.grpTotalPriceDolVar}}</td>
                                                <td>{{groupitem.grpTotalPriceDolPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>UV$ </td>
                                                <td>{{getNumber(groupitem.uvDol,2)}}</td>
                                                <td>{{getNumber(groupitem.uvDolPre, 2)}}</td>
                                                <td>{{getNumber(groupitem.uvDolPreVar, 2)}}</td>
                                                <td>{{getNumber(groupitem.uvDolPreVarPer, 2)}}</td>
                                            </tr> -->

                                        </ng-template>
                                        <!-- <ng-template pTemplate="footer">
                                            <tr>
                                                <td width="17%">Total</td>

                                                <td width="15%"><b>Q</b>:</td>
                                                <td width="20%">{{data.totalQuantity}}</td>
                                                <td width="20%">{{data.totalQuantityPrev}}</td>
                                                <td width="10%">{{data.totalQtyVar}}</td>
                                                <td width="10%">{{data.totalQtyPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                                <td><b>V</b>:</td>
                                                <td>{{data.totalPriceRs}}</td>
                                                <td>{{data.totalPriceRsPrev}}</td>
                                                <td>{{data.totalPrRsVar}}</td>
                                                <td>{{data.totalPrRsVarPer}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                                <td><b>$</b></td>
                                                <td>{{data.totalPriceDol}}</td>
                                                <td>{{data.totalPriceDolPrev}}</td>
                                                <td>{{data.totalPrDolVar}}</td>
                                                <td>{{data.totalPrDolPer}}</td>
                                            </tr>
                                        </ng-template> -->
                                    </p-table>
                                </table>



                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </div>
        <input type="hidden" formControlName="selectedGroups" />
        <input type="hidden" formControlName="selectedItems" />
        <input type="hidden" formControlName="reportType" />
    </form>
</div>