import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from "../../../common-service.service";

@Component({
  selector: 'app-export-review-list',
  templateUrl: './export-review-list.component.html',
  styleUrls: ['./export-review-list.component.css']
})
export class ExportReviewListComponent implements OnInit {
  
  submenu:any = [];

  constructor(private _commonService:CommonServiceService) { }

  ngOnInit(): void {
    this.submenu = this._commonService.getsubMenus();

  }

}
