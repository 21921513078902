import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../masters/master.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-region-master-list',
  templateUrl: './region-master-list.component.html',
  styleUrls: ['./region-master-list.component.css']
})
export class RegionMasterListComponent implements OnInit {

  constructor(private route: ActivatedRoute, private _service: MasterService, private router: Router) { }

  pageHeading: string;
  lastEvent: any;
  datasource: any;
  totalRecords: number;
  data: any;
  loading: boolean;
  cols: any[];
  ngOnInit(): void {
    this.pageHeading = "Region Master";
    this.cols = [
      { field: 'Id', header: 'ID', type: 'text', width: '8%' },
      { field: 'Code ', header: 'UNION_CODE', type: 'text', width: '10%' },
      { field: 'NAME ', header: 'UNION_NAME', type: 'text', width: '10%' },
      { field: 'Added Date', header: 'added_date', type: 'calender', width: '10%' },
      { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '10%' }
    ];
  }

  loadData(event: LazyLoadEvent) {
    //this.loading = true;
    this.lastEvent = event;
    // console.log(event.filters);
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network

    setTimeout(() => {

      this._service.getRegion(event.first, event.rows, event.sortField, event.sortOrder, event.filters).subscribe((items: any) => {
        //console.log(items);
        this.datasource = items.result;
        this.data = items.data;
        this.totalRecords = items.totalRecord;
        this.loading = false;
      });




    }, 1000);
  }

}
