import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ExporterServiceService } from '../exporter-service.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.css']
})
export class RequestListComponent implements OnInit {

  loading: boolean = true;
  cols: any[];
  data: any;
  datasource: any;
  totalRecords: number;
  message: any;
  userType: any;

  constructor(private _service: ExporterServiceService, private _router: Router, private messageService: MessageService) { }

  ngOnInit(): void {

  /*
    this.cols = [
      { field: 'S.N.', header: 'id', width: '10%' },
      { field: 'Title', header: 'title', width: '12%' },
      { field: 'Description', header: 'description', width: '12%' },
      { field: 'Status', header: 'status', width: '12%' },
      { field: 'Date', header: 'date', width: '10%' },
      { field: 'Name', header: 'name', width: '10%' },

    ];
    */
  }

  loadRequestList(event: LazyLoadEvent) {
    this.loading = true;

    setTimeout(() => {

      this._service.getExporterRequest(event.first, event.rows, event.sortField, event.sortOrder, event.filters).subscribe((items: any) => {
        console.warn(items);

        this.data = items.data.data;
        this.userType = items.data.userType;

        console.log("---------get request data--------", this.data);
        this.totalRecords = items.data.totalRecord;
        this.loading = false;



        if (this.userType == 'HOMODULE') {


          this.cols = [
            { field: 'S.N.', header: 'id', width: '10%' },
            { field: 'Title', header: 'title', width: '12%' },
            { field: 'Description', header: 'description', width: '12%' },
            { field: 'Status', header: 'status', width: '12%' },
            { field: 'Date', header: 'date', width: '10%' },
            { field: 'Exporter Name', header: 'name', width: '10%' },
    
          ];
    
        } else {
    
          this.cols = [
            { field: 'S.N.', header: 'id', width: '10%' },
            { field: 'Title', header: 'title', width: '12%' },
            { field: 'Description', header: 'description', width: '12%' },
            { field: 'Status', header: 'status', width: '12%' },
            { field: 'Date', header: 'date', width: '10%' },
    
    
          ];
    
        }

      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });
    }, 1000);

  }


  getExporterList() {
    console.log("get exporter");
    let exporterId = 1;
    this._service.refereshRequestList(exporterId).subscribe((items: any) => {
      console.warn(items);

      this.data = items.data.data;

      console.log("---------get request data--------", this.data);
      this.totalRecords = items.data.totalRecord;
      this.loading = false;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
  }

  delete(requestId) {
    let id = requestId;

    this._service.getDeleteReply(id).subscribe((item: any) => {
      if (item.data.status) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Record delete successfully', life: 6000 }
        ); //
        // referesh the page  
        this.getExporterList();
      }
      // console.log("deleted", item.data.status);
    });


  }

}
