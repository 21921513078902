import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { EditItemMasterComponent } from './components/edit-item-master/edit-item-master.component';
import { CountryWiseMarketTrendComponent } from './components/export-review/country-wise-market-trend/country-wise-market-trend.component';
import { ExportGrowthComponent } from './components/export-review/export-growth/export-growth.component';
import { ExportReviewListComponent } from './components/export-review/export-review-list/export-review-list.component';
import { MarketGrowthComponent } from './components/export-review/market-growth/market-growth.component';
import { MonthlyExportTrendsComponent } from './components/export-review/monthly-export-trends/monthly-export-trends.component';
import { CountryWiseExportOfFrozenFinFishComponent } from './components/export-review/country-wise-export-of-frozen-fin-fish/country-wise-export-of-frozen-fin-fish.component';

// Arvind Component
import { MonthlyExportTrentsMarketComponent } from './components/export-review/monthly-export-trents-market/monthly-export-trents-market.component';
import { OtherComponent } from './components/export-review/other/other.component';
import { VarietyWiseReportComponent } from './components/export-review/variety-wise-report/variety-wise-report.component';
import { ExportInSrimpInDifferentProductFormsComponent } from './components/export-review/variety-wise-report/export-in-srimp-in-different-product-forms/export-in-srimp-in-different-product-forms.component';
import { ExportOfFrozenScampiComponent } from './components/export-review/variety-wise-report/export-of-frozen-scampi/export-of-frozen-scampi.component';
import { VarietyWiseExportDetailOfShrimpComponent } from './components/export-review/variety-wise-report/variety-wise-export-detail-of-shrimp/variety-wise-export-detail-of-shrimp.component';
import { ExportGrowthOfCephalopodsComponent } from './components/export-review/variety-wise-report/export-growth-of-cephalopods/export-growth-of-cephalopods.component';
import { ExportOfLobsterInDifferentProductFormComponent } from './components/export-review/variety-wise-report/export-of-lobster-in-different-product-form/export-of-lobster-in-different-product-form.component';
import { ExportGrowthOfItemGroupsComponent } from './components/export-review/export-growth-of-item-groups/export-growth-of-item-groups.component';
import { FrozenFinFishInDifferentFormsComponent } from './components/export-review/frozen-fin-fish-in-different-forms/frozen-fin-fish-in-different-forms.component';
import { AquariumFishComponent } from './components/export-review/export-growth-of-varieties/aquarium-fish/aquarium-fish.component';
import { MarketWiseAquariumFishComponent } from './components/export-review/market-wise-export/market-wise-aquarium-fish/market-wise-aquarium-fish.component';
import { FrozenCrabComponent } from './components/export-review/market-wise-export/frozen-crab/frozen-crab.component';
import { FrozenShrimpToContriesComponent } from './components/export-review/export-growth-of-varieties/frozen-shrimp-to-contries/frozen-shrimp-to-contries.component';
import { ExportOfItemGroupsVariousFormComponent } from './components/export-review/export-of-item-groups-various-form/export-of-item-groups-various-form.component';
import { GrowthOfItemGroupToRegionsComponent } from './components/export-review/growth-of-item-group-to-regions/growth-of-item-group-to-regions.component';
import { UnitValueOfMarinProductComponent } from './components/export-review/annexure/unit-value-of-marin-product/unit-value-of-marin-product.component';
import { MonthlyExportItemsComponent } from './components/export-review/monthly-export-items/monthly-export-items.component';
import { AllMonthlyWiseReportComponent } from './components/export-review/all-monthly-wise-report/all-monthly-wise-report.component';
import { YearlyWiseReportComponent } from './components/export-review/yearly-wise-report/yearly-wise-report.component';

// Statistics Component
import { MarinProductEportFromIndiaComponent } from './components/statistics/marin-product-eport-from-india/marin-product-eport-from-india.component';
import { ShareOfShrimpInMarinProductComponent } from './components/statistics/share-of-shrimp-in-marin-product/share-of-shrimp-in-marin-product.component';
import { MonthlyExportTrendComponent } from './components/statistics/monthly-export-trend/monthly-export-trend.component';
import { PortItemWiseExportMarinProductComponent } from './components/statistics/port-item-wise-export-marin-product/port-item-wise-export-marin-product.component';


import { InvoicesComponent } from './components/invoices/invoices.component';
import { ItemmasterComponent } from './components/itemmaster/itemmaster.component';
import { MastersComponent } from './components/masters/masters.component';
import { BuyerWiseReportComponent } from './components/reports/buyer-wise/buyer-wise-report/buyer-wise-report.component';
import { CreateReportComponent } from './components/reports/create-report/create-report.component';
import { DynamicRepotComponent } from './components/reports/dynamic-repot/dynamic-repot.component';
import { ExpoterWiseReportComponent } from './components/reports/expoter-wise/expoter-wise-report/expoter-wise-report.component';
import { InvoiceReportComponent } from './components/reports/invoice-report/invoice-report.component';
import { ItemWiseReportComponent } from './components/reports/item-wise/item-wise-report/item-wise-report.component';
import { ListComponent } from './components/reports/list/list.component';
import { ManufacturerWiseReportComponent } from './components/reports/manufacturer-wise/manufacturer-wise-report/manufacturer-wise-report.component';
import { MarketWiseReportComponent } from './components/reports/market-wise/market-wise-report/market-wise-report.component';
import { PlantWiseReportComponent } from './components/reports/plant-wise/plant-wise-report/plant-wise-report.component';
import { PortItemComponent } from './components/reports/port-wise/port-item/port-item.component';
import { PortMarketComponent } from './components/reports/port-wise/port-market/port-market.component';
import { StateWiseReportComponent } from './components/reports/state-wise/state-wise-report/state-wise-report.component';
import { SummaryReportComponent } from './components/reports/summary-report/summary-report.component';
import { ReportCountryComponent } from './components/reports/summary/report-country/report-country.component';
import { ReportItemComponent } from './components/reports/summary/report-item/report-item.component';
import { ReportPortComponent } from './components/reports/summary/report-port/report-port.component';
import { ReportRegionComponent } from './components/reports/summary/report-region/report-region.component';
import { BranchCreateComponent } from './components/setup/branch/branch-create/branch-create.component';
import { BranchListComponent } from './components/setup/branch/branch-list/branch-list.component';
import { RoleListComponent } from './components/setup/roles/role-list/role-list.component';
import { RolePermissionComponent } from './components/setup/roles/role-permission/role-permission.component';
import { UserCreateComponent } from './components/setup/user/user-create/user-create.component';
import { UserListComponent } from './components/setup/user/user-list/user-list.component';
import { AnnualGrowthRateComponent } from './components/statistics/annual-growth-rate/annual-growth-rate.component';
import { ListStatisticsComponent } from './components/statistics/list-statistics/list-statistics.component';
import { StatisticsAverageUnitComponent } from './components/statistics/statistics-average-unit/statistics-average-unit.component';
import { StatisticsCountryComponent } from './components/statistics/statistics-country/statistics-country.component';
import { StatisticsItemGroupWiseComponent } from './components/statistics/statistics-item-group-wise/statistics-item-group-wise.component';
import { StatisticsItemWiseComponent } from './components/statistics/statistics-item-wise/statistics-item-wise.component';
import { StatisticsPortExportComponent } from './components/statistics/statistics-port-export/statistics-port-export.component';
import { StatisticsPortWiseComponent } from './components/statistics/statistics-port-wise/statistics-port-wise.component';
import { StatisticsRegionWiseComponent } from './components/statistics/statistics-region-wise/statistics-region-wise.component';
import { StatisticsRegionComponent } from './components/statistics/statistics-region/statistics-region.component';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { ViewinvoiceComponent } from './components/viewinvoice/viewinvoice.component';
import { InvoiceUploadComponent } from './components/invoice-upload/invoice-upload.component';
import { ExporterLoginComponent } from './exporter/exporter-login/exporter-login.component';
import { ExporterDashboardComponent } from './exporter/exporter-dashboard/exporter-dashboard.component';
import { ExporterInvoicesComponent } from './exporter/exporter-invoices/exporter-invoices.component';
import { ExporterViewinvoicesComponent } from './exporter/exporter-viewinvoices/exporter-viewinvoices.component';
import { ExporterReportListComponent } from './exporter/reports/exporter-report-list/exporter-report-list.component';
import { ExporterSyncComponent } from './components/setup/exporter-sync/exporter-sync.component';
import { PlantSyncComponent } from './components/setup/plant-sync/plant-sync.component';
import { ExporterUploadInvoiceComponent } from './exporter/exporter-upload-invoice/exporter-upload-invoice.component';
import { ExporterUploadExcelComponent } from './exporter/exporter-upload-excel/exporter-upload-excel.component';
import { ExporterUploadSezpdfComponent } from './exporter/exporter-upload-sezpdf/exporter-upload-sezpdf.component';
import { RequestComponent } from './exporter/request/request.component';
import { RequestListComponent } from './exporter/request-list/request-list.component';
import { RequestReplyComponent } from './exporter/request-reply/request-reply.component';
import { InvoiceUploadExcelComponent } from './components/invoice-upload-excel/invoice-upload-excel.component';
import { RequestEditComponent } from './exporter/request-edit/request-edit.component';
import { ViewReportsComponent } from './components/reports/view-reports/view-reports.component';
import { BranchReconcileComponent } from './components/setup/branch-reconcile/branch-reconcile.component';
import { BranchReconcileListComponent } from './components/setup/branch-reconcile-list/branch-reconcile-list.component';
import { ReconcileFinalListComponent } from './components/setup/reconcile-final-list/reconcile-final-list.component';
import { ReconcileFinalAddComponent } from './components/setup/reconcile-final-add/reconcile-final-add.component';
import { RegionMasterListComponent } from './components/region-master/region-master-list/region-master-list.component';
import { RegionMasterEditComponent } from './components/region-master/region-master-edit/region-master-edit.component';
import { ReconcilationListComponent } from './exporter/reconcilation/reconcilation-list/reconcilation-list.component';
import { ReconcilationAddComponent } from './exporter/reconcilation/reconcilation-add/reconcilation-add.component';
import { UsersComponent } from './components/logs/users/users.component';
import { BlockedIpComponent } from './components/logs/blocked-ip/blocked-ip.component';
import { ChangePasswordComponent } from './components/setup/user/change-password/change-password.component';

const routes: Routes = [
  { path: 'reports/logs/userslist', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'reports/logs/blockedIp', component: BlockedIpComponent, canActivate: [AuthGuard] },

  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'master', component: MastersComponent, canActivate: [AuthGuard] },
  { path: 'itemmaster', component: ItemmasterComponent, canActivate: [AuthGuard] },
  { path: "itemmaster/:id", component: EditItemMasterComponent, canActivate: [AuthGuard] },
  { path: "invoices", component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'uploadExcel', component: InvoiceUploadExcelComponent, canActivate: [AuthGuard] },

  { path: "viewinvoice", component: ViewinvoiceComponent, canActivate: [AuthGuard] },
  { path: "reports/summary/item-group", component: SummaryReportComponent, canActivate: [AuthGuard] },
  { path: "reports/summary/items", component: ReportItemComponent, canActivate: [AuthGuard] },
  { path: "reports/summary/port", component: ReportPortComponent, canActivate: [AuthGuard] },
  { path: "reports/summary/region", component: ReportRegionComponent, canActivate: [AuthGuard] },
  { path: "reports/summary/country", component: ReportCountryComponent, canActivate: [AuthGuard] },
  { path: "addinvoice", component: ViewinvoiceComponent, canActivate: [AuthGuard] },
  { path: "setup/branch", component: BranchListComponent, canActivate: [AuthGuard] },
  { path: "setup/branch/addbranch", component: BranchCreateComponent, canActivate: [AuthGuard] },
  { path: "setup/branch/update/:id", component: BranchCreateComponent, canActivate: [AuthGuard] },
  { path: "setup/user", component: UserListComponent, canActivate: [AuthGuard] },
  { path: "setup/user/add", component: UserCreateComponent, canActivate: [AuthGuard] },
  { path: "setup/user/update/:id", component: UserCreateComponent, canActivate: [AuthGuard] },
  { path: "reports", component: ListComponent, canActivate: [AuthGuard] },
  { path: "setup/roles", component: RoleListComponent, canActivate: [AuthGuard] },
  { path: "setup/roles/add", component: RolePermissionComponent, canActivate: [AuthGuard] },
  { path: "setup/roles/update/:id", component: RolePermissionComponent, canActivate: [AuthGuard] },
  { path: "reports/port-wise/market", component: PortMarketComponent, canActivate: [AuthGuard] },
  { path: "reports/port-wise/item", component: PortMarketComponent, canActivate: [AuthGuard] },
  { path: "reports/port-wise/buyer", component: PortMarketComponent, canActivate: [AuthGuard] },
  { path: "reports/port-wise/exporter", component: PortMarketComponent, canActivate: [AuthGuard] },

  { path: "reports/item-wise/market", component: ItemWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/item-wise/port", component: ItemWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/item-wise/buyer", component: ItemWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/item-wise/exporter", component: ItemWiseReportComponent, canActivate: [AuthGuard] },

  { path: "reports/market-wise/port", component: MarketWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/market-wise/item", component: MarketWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/market-wise/buyer", component: MarketWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/market-wise/exporter", component: MarketWiseReportComponent, canActivate: [AuthGuard] },

  { path: "reports/exporter-wise/item", component: ExpoterWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/exporter-wise/market", component: ExpoterWiseReportComponent, canActivate: [AuthGuard] },

  { path: "reports/buyer-wise/item", component: BuyerWiseReportComponent, canActivate: [AuthGuard] },

  { path: "reports/manufacturer-wise/item", component: ManufacturerWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/manufacturer-wise/market", component: ManufacturerWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/manufacturer-wise/port", component: ManufacturerWiseReportComponent, canActivate: [AuthGuard] },

  { path: "reports/plant-wise/item", component: PlantWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/plant-wise/market", component: PlantWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/plant-wise/port", component: PlantWiseReportComponent, canActivate: [AuthGuard] },
  { path: "reports/state-wise/market", component: StateWiseReportComponent, canActivate: [AuthGuard] },

  { path: "export-review", component: ExportReviewListComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-growth", component: ExportGrowthComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-growth/market", component: MarketGrowthComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-growth/port", component: MarketGrowthComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-growth/item", component: MarketGrowthComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-growth/item-group", component: MarketGrowthComponent, canActivate: [AuthGuard] },

  { path: "export-review/monthly-export-trend/export", component: MonthlyExportTrendsComponent, canActivate: [AuthGuard] },
  { path: "export-review/monthly-export-trend/shrimp", component: MonthlyExportTrendsComponent, canActivate: [AuthGuard] },
  { path: "export-review/monthly-export-trend/country", component: CountryWiseMarketTrendComponent, canActivate: [AuthGuard] },

  { path: "reports/invoice", component: InvoiceReportComponent, canActivate: [AuthGuard] },
  { path: "reports/create-report", component: CreateReportComponent, canActivate: [AuthGuard] },

  { path: "statistics", component: ListStatisticsComponent, canActivate: [AuthGuard] },
  { path: "statistics/annual-growth", component: AnnualGrowthRateComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-report/region", component: StatisticsRegionComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-report/items", component: StatisticsRegionComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-country", component: StatisticsCountryComponent, canActivate: [AuthGuard] },

  { path: "statistics/statistics-item/region", component: StatisticsItemWiseComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-item/country", component: StatisticsItemWiseComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-port/port-export", component: StatisticsPortExportComponent, canActivate: [AuthGuard] },

  { path: "statistics/statistics-average-unit/group", component: StatisticsAverageUnitComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-average-unit/item", component: StatisticsAverageUnitComponent, canActivate: [AuthGuard] },

  { path: "statistics/region", component: StatisticsRegionWiseComponent, canActivate: [AuthGuard] },
  { path: "statistics/port", component: StatisticsPortWiseComponent, canActivate: [AuthGuard] },

  { path: "reports/dynamic-report", component: DynamicRepotComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-report/items-group", component: StatisticsItemGroupWiseComponent, canActivate: [AuthGuard] },

  { path: "export-review/monthly-export-trend/export", component: MonthlyExportTrendsComponent, canActivate: [AuthGuard] },

  { path: "export-review/monthly-export-trend/major-market", component: MonthlyExportTrentsMarketComponent, canActivate: [AuthGuard] },


  { path: "export-review/monthly-export-trend/shrimp", component: MonthlyExportTrendsComponent, canActivate: [AuthGuard] },
  { path: "export-review/monthly-export-trend/country", component: CountryWiseMarketTrendComponent, canActivate: [AuthGuard] },

  { path: "export-review/other/other-items", component: OtherComponent, canActivate: [AuthGuard] },
  { path: "export-review/varity-wise-report/product-analysis-of-shrimp-export", component: VarietyWiseReportComponent, canActivate: [AuthGuard] },
  { path: "export-review/varity-wise-report/export-in-shrimp-in-different-product-forms", component: ExportInSrimpInDifferentProductFormsComponent, canActivate: [AuthGuard] },
  { path: "export-review/varity-wise-report/export-of-frozen-scampi", component: ExportOfFrozenScampiComponent, canActivate: [AuthGuard] },
  { path: "export-review/varity-wise-report/variety-wise-export-detail-of-shrimp", component: VarietyWiseExportDetailOfShrimpComponent, canActivate: [AuthGuard] },
  { path: "export-review/varity-wise-report/export-growth-of-cephalopods", component: ExportGrowthOfCephalopodsComponent, canActivate: [AuthGuard] },
  { path: "export-review/varity-wise-report/export-of-lobster-in-different-product-form", component: ExportOfLobsterInDifferentProductFormComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-growth-of-item-group", component: ExportGrowthOfItemGroupsComponent, canActivate: [AuthGuard] },
  { path: "export-review/frozen-fin-fish-in-different-forms", component: FrozenFinFishInDifferentFormsComponent, canActivate: [AuthGuard] },

  { path: "export-review/export-growth-of-varieties/aquarium-fish", component: AquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-growth-of-varieties/frozen-clam-meat", component: AquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/annexure/export-growth-of-indian-marin-product", component: AquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-wise-export/frozen-fin-fish-In-Diff-Market", component: MarketWiseAquariumFishComponent, canActivate: [AuthGuard] },

  { path: "export-review/market-wise-export/aquarium-fish", component: MarketWiseAquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-wise-export/frozen-clam-meat", component: MarketWiseAquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-growth-of-varieties/frozen-crab", component: MarketWiseAquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-wise-export/frozen-crab", component: FrozenCrabComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-wise-export/live-items", component: FrozenCrabComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-growth-of-varieties/frozen-shrimp-to-countries", component: FrozenShrimpToContriesComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-of-item-group-in-various-form", component: ExportOfItemGroupsVariousFormComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-growth-of-item-group-regions", component: GrowthOfItemGroupToRegionsComponent, canActivate: [AuthGuard] },
  { path: "export-review/export-item-country-wise", component: MarketWiseAquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/market-wise-export-of-item-group", component: MarketWiseAquariumFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/variety-wise-report/export-shrimp-market", component: OtherComponent, canActivate: [AuthGuard] },
  { path: "export-review/varity-wise-report/frozen-shrimp-in-different-product-to-market", component: FrozenShrimpToContriesComponent, canActivate: [AuthGuard] },
  { path: "export-review/monthly-export-trend/region-wise", component: CountryWiseMarketTrendComponent, canActivate: [AuthGuard] },
  { path: "export-review/annexure/changing-pattern-of-major-market", component: FrozenFinFishInDifferentFormsComponent, canActivate: [AuthGuard] },
  { path: "export-review/annexure/unit-value-marin-product", component: UnitValueOfMarinProductComponent, canActivate: [AuthGuard] },
  { path: "export-review/monthly-export-trend/shrimp", component: MonthlyExportTrendsComponent, canActivate: [AuthGuard] },

  { path: "setup/sync/exporter", component: ExporterSyncComponent, canActivate: [AuthGuard] },
  { path: "setup/sync/plant", component: PlantSyncComponent, canActivate: [AuthGuard] },


  { path: 'regionmaster', component: RegionMasterListComponent, canActivate: [AuthGuard] },
  { path: "regionmaster/:id", component: RegionMasterEditComponent, canActivate: [AuthGuard] },

  { path: "addinvoicepdf", component: InvoiceUploadComponent, canActivate: [AuthGuard] },

  { path: "login", component: UserLoginComponent },
  { path: "", component: UserLoginComponent },
  // Statatistics routes
  { path: "statistics/marin-product-export-from-india", component: MarinProductEportFromIndiaComponent, canActivate: [AuthGuard] },
  { path: "statistics/percentage-marin-product-export-from-india", component: MarinProductEportFromIndiaComponent, canActivate: [AuthGuard] },
  { path: "statistics/average-unit-value-realization", component: MarinProductEportFromIndiaComponent, canActivate: [AuthGuard] },
  { path: "statistics/marin-product-share-in-our-export", component: MarinProductEportFromIndiaComponent, canActivate: [AuthGuard] },
  { path: "statistics/shrimp-of-marin-items", component: ShareOfShrimpInMarinProductComponent, canActivate: [AuthGuard] },
  { path: "statistics/monthly-export-trend/item-group", component: MonthlyExportTrendComponent, canActivate: [AuthGuard] },
  { path: "statistics/monthly-export-trend/cephalopods", component: MonthlyExportTrendComponent, canActivate: [AuthGuard] },
  { path: "statistics/port-item-wise-export", component: PortItemWiseExportMarinProductComponent, canActivate: [AuthGuard] },
  { path: "statistics/country-item-wise-marin-product-export", component: PortItemWiseExportMarinProductComponent, canActivate: [AuthGuard] },
  { path: "statistics/item-country-wise-marin-product-export", component: PortItemWiseExportMarinProductComponent, canActivate: [AuthGuard] },
  { path: "export-review/monthly-export-trend/item-wise-export", component: CountryWiseMarketTrendComponent, canActivate: [AuthGuard] },
  { path: "export-review/country-wise-export-frozen-fin-fish", component: CountryWiseExportOfFrozenFinFishComponent, canActivate: [AuthGuard] },
  { path: "export-review/monthly-report-items", component: MonthlyExportItemsComponent, canActivate: [AuthGuard] },
  /* { path: "export-review/all-monthly-report-items", component: AllMonthlyWiseReportComponent, canActivate: [AuthGuard] }, */
  { path: "export-review/all-monthly-report-items/all", component: AllMonthlyWiseReportComponent, canActivate: [AuthGuard] },
  { path: "export-review/all-monthly-report-items/monthly", component: AllMonthlyWiseReportComponent, canActivate: [AuthGuard] },
  { path: "statistics/statistics-item/state-wise-annual-marine-product", component: StatisticsItemWiseComponent, canActivate: [AuthGuard] },
  { path: "export-review/yearly-wise-report", component: YearlyWiseReportComponent, canActivate: [AuthGuard] },

  { path: "requestList", component: RequestListComponent, canActivate: [AuthGuard] },
  { path: "request/reply/:id", component: RequestReplyComponent, canActivate: [AuthGuard] },
  { path: "request/edit/:id", component: RequestEditComponent, canActivate: [AuthGuard] },

  { path: "viewreport/:id", component: ViewReportsComponent, canActivate: [AuthGuard] },
  { path: "setup/reconcile", component: BranchReconcileListComponent, canActivate: [AuthGuard] },
  { path: "setup/reconcile/add", component: BranchReconcileComponent, canActivate: [AuthGuard] },
  { path: "setup/reconcile/update/:id", component: BranchReconcileComponent, canActivate: [AuthGuard] },
  { path: "setup/final-reconcile", component: ReconcileFinalListComponent, canActivate: [AuthGuard] },
  { path: "setup/final-reconcile/add", component: ReconcileFinalAddComponent, canActivate: [AuthGuard] },
  { path: "setup/final-reconcile/update/:id", component: ReconcileFinalAddComponent, canActivate: [AuthGuard] },
  { path: "user/change-password", component: ChangePasswordComponent, canActivate: [AuthGuard] },



  //exporter routing 
  { path: "exporter/login", component: ExporterLoginComponent },
  { path: "exporter/dashboard", component: ExporterDashboardComponent, canActivate: [AuthGuard] },
  { path: "exporter/invoices", component: ExporterInvoicesComponent, canActivate: [AuthGuard] },
  { path: "exporter/addinvoice", component: ExporterViewinvoicesComponent, canActivate: [AuthGuard] },
  { path: "exporter/reports", component: ExporterReportListComponent, canActivate: [AuthGuard] },
  { path: "exporter/reports/summary/port", component: ReportPortComponent, canActivate: [AuthGuard] },
  { path: "exporter/reports/summary/region", component: ReportRegionComponent, canActivate: [AuthGuard] },
  { path: "exporter/reports/summary/country", component: ReportCountryComponent, canActivate: [AuthGuard] },
  { path: "exporter/reports/summary/item-group", component: SummaryReportComponent, canActivate: [AuthGuard] },
  { path: "exporter/reports/summary/items", component: ReportItemComponent, canActivate: [AuthGuard] },
  { path: "exporter/uploadPdf", component: ExporterUploadInvoiceComponent, canActivate: [AuthGuard] },
  { path: "exporter/uploadPdfSez", component: ExporterUploadSezpdfComponent, canActivate: [AuthGuard] },

  { path: "exporter/viewinvoice", component: ExporterViewinvoicesComponent, canActivate: [AuthGuard] },
  { path: "exporter/uploadExcel", component: ExporterUploadExcelComponent, canActivate: [AuthGuard] },

  { path: "exporter/requestList", component: RequestListComponent, canActivate: [AuthGuard] },
  { path: "exporter/request", component: RequestComponent, canActivate: [AuthGuard] },
  { path: "exporter/requestReply", component: RequestReplyComponent, canActivate: [AuthGuard] },
  { path: "exporter/request/reply/:id", component: RequestReplyComponent, canActivate: [AuthGuard] },
  { path: "exporter/request/edit/:id", component: RequestEditComponent, canActivate: [AuthGuard] },
  { path: "exporter/reconcilation", component: ReconcilationListComponent, canActivate: [AuthGuard] },
  { path: "exporter/reconcilation/add", component: ReconcilationAddComponent, canActivate: [AuthGuard] },


];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
