<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>       
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()" >
       
        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4"  />
                        
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>

                </div> 


             


                
                <div class="row">
                    <div class="btn_pan mb-4">
                            <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>    
                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                </div>

                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>Q</b>: Quantity in Tons,</span>
                    <span><b>V</b>: Value in Rs. 1000,</span>
                    <span><b>$</b>: USD Million</span>
                </div>             
               

                <div class="card" *ngIf="displayDetailedReport">
                    
                    <table #detailTable>                   
                        <p-table [value]="reportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportDetailExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header"> 
                                <tr>
                                    <th colspan="2"> Year</th>
                                    <th colspan="3"> Frozen</th>
                                    <th colspan="3"> Dried</th>
                                    <th colspan="3"> Total</th>
                                    <th colspan="3"> Total Marin</th>
                                </tr>
                                <tr>
                                    <th colspan="2"></th>
                                    
                                    <th>Q</th>
                                    <th>V</th>
                                    <th>$</th>

                                    <th>Q</th>
                                    <th>V</th>
                                    <th>$</th>

                                    <th>Q</th>
                                    <th>V</th>
                                    <th>$</th>

                                    <th>Q</th>
                                    <th>V</th>
                                    <th>$</th>

                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-items>
                                <tr>
                                                <td colspan="2">{{items[0].range}}</td>
                                            
                                                <td> {{items[0].totalQty.toFixed()}}</td>
                                                <td> {{items[0].fobRs.toFixed(2)}}</td>
                                                <td> {{items[0].fobDol.toFixed(2)}}</td>

                                                <td> {{items[1].totalQty.toFixed()}}</td>
                                                <td> {{items[1].fobRs.toFixed(2)}}</td>
                                                <td> {{items[1].fobDol.toFixed(2)}}</td>

                                                <td> {{items[2].totalQty.toFixed()}}</td>
                                                <td> {{items[2].fobRs.toFixed(2)}}</td>
                                                <td> {{items[2].fobDol.toFixed(2)}}</td>

                                                <td> {{items[3].subTotalQty.toFixed()}}</td>
                                                <td> {{items[3].subTotalFobRs.toFixed(2)}}</td>
                                                <td> {{items[3].subTotalFobDol.toFixed(2)}}</td>

                                                

                                </tr>
                            </ng-template>
                        </p-table>
                    </table>
                </div>


                                
                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                 <section class="innerpage">
                    <div class="innershadow">
                        <div class="itm_gr_rprt">
                            <table #userTable> 
                                <p-table [value]="reportData">
                                    <ng-template pTemplate="caption">
                                        <div class="p-d-flex">
                                            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th >Year</th>
                                            <th></th>
                                            <th >Frozen</th>
                                            <th >Dried</th>
                                            <th >Total</th>
                                            <th >Total Marin</th>
                                        </tr>
                                    </ng-template>

                                    
                                   
                                    <ng-template pTemplate="body" let-items>
                                                <tr>
                                                    <td >{{items[0].range}}</td>
                                                    <td > Q</td>
                                                    <td >{{items[0].totalQty.toFixed()}}  {{getShare(items[0].totalQty, items[3].subTotalQty)}}</td>
                                                    <td >{{items[1].totalQty.toFixed()}}  {{getShare(items[1].totalQty, items[3].subTotalQty)}}</td>
                                                    <td >{{items[2].totalQty.toFixed()}}  {{getShare(items[2].totalQty, items[3].subTotalQty)}}</td>
                                                    <td >{{items[3].subTotalQty.toFixed()}}  </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td > </td>
                                                    <td >V </td>
                                                    <td >{{items[0].fobRs.toFixed()}}  {{getShare(items[0].fobRs, items[3].subTotalFobRs)}}</td>
                                                    <td >{{items[1].fobRs.toFixed()}}  {{getShare(items[1].fobRs, items[3].subTotalFobRs)}}</td>
                                                    <td >{{items[2].fobRs.toFixed()}}  {{getShare(items[2].fobRs, items[3].subTotalFobRs)}}</td>
                                                    <td >{{items[3].subTotalFobRs.toFixed()}} </td>
                                                    
                                                   
                                                </tr>
                                                <tr>
                                                    <td > </td>
                                                    <td >$ </td>
                                                    <td >{{items[0].fobDol.toFixed()}}  {{getShare(items[0].fobDol, items[3].subTotalFobDol)}} </td>
                                                    <td >{{items[1].fobDol.toFixed()}}  {{getShare(items[1].fobDol, items[3].subTotalFobDol)}}</td>
                                                    <td >{{items[2].fobDol.toFixed()}}  {{getShare(items[2].fobDol, items[3].subTotalFobDol)}}</td>
                                                    <td >{{items[3].subTotalFobDol.toFixed(2)}} </td>
                                                    
                                                    
                                                </tr>
                                                <tr>
                                                    <td colspan="6"></td>
                                                </tr>
                                    </ng-template>

                                    


                                   
                                </p-table>
                            </table>                              
                        </div>

                        
                    </div>
                 </section>
                </section>
           
        </div>

        </div>

    </form>
</div>