import { Component, OnInit, Input } from '@angular/core';
import { CommonServiceService } from "../../../common-service.service";
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  submenu: any = [];
  saveSearch: any;
  constructor(private _commonService: CommonServiceService, private _router: Router) { }

  ngOnInit(): void {

    this.submenu = this._commonService.getsubMenus();

    this._commonService.getSaveReports().subscribe((items: any) => {
      this.saveSearch = items.data;

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }

}
