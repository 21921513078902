import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ActivatedRoute,Router } from '@angular/router';
import { encryptSecretKey } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";
import { SetupServiceService } from '../../setup-service.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
 
  changePassword: FormGroup;
  pageHeading:string="";
  submitted:boolean=false;
  userType:any;
  readonly:boolean=false;
  id;any;
  constructor(private formBuilder: FormBuilder,private _service:SetupServiceService,private messageService: MessageService,private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.pageHeading = "Change Password";
    var token = JSON.parse(localStorage.getItem('token'));
    var decoded = jwt_decode(token);
    this.id = decoded['result'].id;
        
      this.changePassword = this.formBuilder.group({
        oldPassword: ['', Validators.required],
        password: ['', [
          Validators.required, 
          Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
        ]],
        cpassword: ['', Validators.required],
        ID: [''],
      },{validator: this.passwordMatchValidator});
  
  }



  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password'].value === frm.controls['cpassword'].value ? null : {'mismatch': true};
  }

   get f() { return this.changePassword.controls; }

   

   updateNewPassword(){
      
      this.submitted = true;
      // stop here if form is invalid
      if (this.changePassword.invalid) {
          return;
      }

      let time = Math.floor(Date.now() / 1000);
      let encrptionKey = encryptSecretKey + "_" + time;

      let oldPassword = CryptoJS.AES.encrypt(this.changePassword.value.oldPassword, encrptionKey).toString();
      let newPassword = CryptoJS.AES.encrypt(this.changePassword.value.password, encrptionKey).toString();
      

      let data = {
        "oldPassword": oldPassword,
        "password": newPassword,
        "confirmPassword": newPassword,
        "ID": this.id,
        "time":time,
      }

      this._service.updatePassword(data).subscribe((items: any) =>{      
        this.submitted = false;
        console.warn(items);
        if(items.data.success==false)
        { 
          // alert(items.data.message);
          this.messageService.add({severity:'error', summary: 'Fail', detail: items.data.message, life: 6000}); 
          return false;
        
        }
        else{
          if(items.data.success){
            // alert("Password updated successfully");
            
            this.messageService.add({severity:'success', summary: 'Success', detail: items.data.message, life: 6000});
          }
          else{
            this.messageService.add({severity:'error', summary: 'Fail', detail: items.data.message, life: 6000}); 
          }
          
        }
        return false;
      });

      
    }
  
}

