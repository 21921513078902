import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportReviewService } from '../../export-review.service';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { CommonServiceService } from '../../../../common-service.service';


@Component({
  selector: 'app-market-wise-aquarium-fish',
  templateUrl: './market-wise-aquarium-fish.component.html',
  styleUrls: ['./market-wise-aquarium-fish.component.css']
})

export class MarketWiseAquariumFishComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  isloader: boolean = false;
  submitted: boolean = false;
  dataNotFound:boolean = false;
  data: any;
  page: any;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName: string;
  reportDateRange: any = [];
  sourceGroup: any = [];
  isGroupDd: boolean = false;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService, private commonService: CommonServiceService) { }

  ngOnInit(): void {

    this._service.getGroups().subscribe((groups: any) => {
      this.sourceGroup = groups.data;
    });

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;


    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;


    console.warn(reportType);

    // this.reportTitle = "Market Wise Export of Marine Products ";


    if (reportType == "aquarium-fish") {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        viewDetailReport: [''],
        reportType: [reportType],
      });


      this.reportTitle = "Market Wise Export Of Aquarium Fish ";

    }
    else if (reportType == 'frozen-clam-meat') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        viewDetailReport: [''],
        reportType: [reportType],
        //itemGrouop: ['', Validators.required],
      });
      this.reportTitle = "Market Wise Export Of Frozen Clam Meat";
    }

    else if (reportType == 'frozen-crab') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        viewDetailReport: [''],
        reportType: [reportType],
      });
      this.reportTitle = "Frozen Crab in different product form";
    }

    else if (reportType == 'export-item-country-wise') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        selectedItem: ['', Validators.required],
        viewDetailReport: [''],
        reportType: [reportType],
      });
      this.reportTitle = "Export Of Item Group To Countries";
      this.page = reportType;
    }
    else if (reportType == 'market-wise-export-of-item-group') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        selectedItem: ['', Validators.required],
        viewDetailReport: [''],
        reportType: [reportType],
      });
      this.reportTitle = "Market Wise Export Of Item Group";
      this.page = reportType;
    }

    else if (reportType == 'frozen-fin-fish-In-Diff-Market') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        selectedItem: ['', Validators.required],


        viewDetailReport: [''],
        reportType: [reportType],
      });
      this.reportTitle = "Average Unit Value Realised For Frozen Fin Fish In Different Market";
      this.page = reportType;

      this._service.getGroups().subscribe((itemGrp: any) => {
        this.sourceGroup = itemGrp.data;
      });
      this.isGroupDd = true;
    }




    this.primengConfig.ripple = true;

    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });
  }

  get f() { return this.reportFrm.controls; }



  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }



  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }


  getRsInCrore(item) {

    let result = item / 100;
    return result.toFixed(2);
  }

  getReport() {

    this.isloader = true;

    this.submitted = true;

    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    var yearDifferent = parseInt(dt2) - parseInt(dt1);
    if (yearDifferent > 2) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3 ", life: 6000 });
      this.isloader = false;
      return false;
    }


    this._service.getExportMarketGrowth(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      console.log("--get object--", this.data);
      if (items.data.reportData.length > 0) {

        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
        this.dataNotFound = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
        this.dataNotFound = true;
      }
      this.reportData = items.data.reportData;
      this.reportDateRange = items.data.reportTime;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }

  getShare(itemQty, totalQty) {
    let percentage = itemQty * 100 / totalQty;
    return percentage ? percentage.toFixed(2) : 0;
  }
}
