import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { SummaryReportService } from './summary-report.service';
import { HttpErrorResponse } from '@angular/common/http';


import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { calendarRange } from 'src/environments/environment';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.css']
})
export class SummaryReportComponent implements OnInit {

  reportName: string;
  sourceGroup: any;
  targetGroup: any;
  reportTitle: string;
  reportFrm: FormGroup;
  selectedItem: any = [];
  submitted: boolean;
  reportData: any = [];
  reprttDate: any;
  data: any = {};
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  isloader: boolean = false;
  showTextBox: boolean = false;
  calendarRange: string = calendarRange;
  displayGroups: boolean = false;
  allGroup: any = [];
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private primengConfig: PrimeNGConfig, private _service: SummaryReportService, private formBuilder: FormBuilder, private messageService: MessageService, private _router: Router) { }


  ngOnInit(): void {

    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      selectedGroups: [''],
      reportType: ["item-group"],
      viewDetailReport: [''],
      topRecordNum: ['0'],
      topRecordNumOther: ['1'],
      includeBlankData: ['0'],
      sortColumn: ['FobDolMil'],
      sortType: ['DESC'],
      allGroup: [''],

    });
    this.reportTitle = "Item Group ";
    this.reportName = "item-group"
    this._service.getGroups().subscribe((items: any) => {
      this.sourceGroup = items.data;
      this.reportFrm.controls.allGroup.patchValue(items.data, { emitEvent: false });

    });
    this.targetGroup = [];
    this.primengConfig.ripple = true;


    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });
    this.reportFrm.valueChanges.subscribe(val => {
      if (val.topRecordNum == "-1") {
        this.showTextBox = true;
      }
      else {
        this.showTextBox = false;
      }
    });
    this.reportFrm.controls.topRecordNum.patchValue('favorite', { emitEvent: false });
    this.reportFrm.controls.sortColumn.patchValue('none', { emitEvent: false });
    this.reportFrm.controls.sortType.patchValue('ASC', { emitEvent: false });

  }

  get f() { return this.reportFrm.controls; }
  getReport() {
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    if (this.reportFrm.value.selectedGroups == "" && this.reportFrm.value.topRecordNum != 'favorite') {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Item Group", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }
    this._service.getReport(this.reportFrm.value).subscribe((items: any) => {
      //  this.reprttDate = items.data[0].dateRange;
      this.data = items.data;

      if (items.data.reportData.length > 0) {
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }
  removeitems(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedGroups.patchValue(this.selectedItem, { emitEvent: false });
  }
  addChecksums(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedGroups.patchValue(this.selectedItem, { emitEvent: false });
  }

  getShare(itemQty, totalQty) {
    let percentage = itemQty * 100 / totalQty;
    return percentage.toFixed(2);
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report.xlsx");

    });
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report_detail.csv");

    });
  }
  getRounded(number) {
    return Math.round(number).toFixed(2);
  }
  changeSortBy($event) {
    if ($event.target.value == "favorite") {
      this.displayGroups = false;
    }
    else {
      this.displayGroups = true;
    }
  }
}
