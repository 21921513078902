<p-toast></p-toast>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <div class="pageheadingouter">
        <div class="container container2">

            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> Shipping Bill </h1>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <a [routerLink]="['/exporter/invoices']"><button type="button" class="btn btn-light"> <i
                                class="fa fa-times"></i> Cancel</button></a>

                </div>
            </div>
        </div>
    </div>

    <div class="container pt-4">
        <p-accordion [multiple]="true">
            <p-accordionTab header="Upload PDF" [selected]="true">
                <div class="card-body px-0">
                    <div class="row">
                        <div class="col-xl-3 col-md-12 form-group">
                            <label for="file"><strong>Upload PDF</strong> </label>
                        </div>
                        <form [formGroup]="myForm" (ngSubmit)="submit()">
                            <div class="form-group">
                                <input formControlName="file" id="file" type="file" class="input-file"
                                    [ngClass]="{ 'is-invalid': submitted && f.file.errors }"
                                    (change)="onFileChange($event)">
                                <div *ngIf="submitted && f.file.errors" class="invalid-feedback">
                                    <div *ngIf="f.file.errors.required">File is required.</div>
                                </div>
                            </div>
                            <button class="btn btn-success" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </p-accordionTab>
            <div>
                <form [formGroup]="invoiceFrm" name="invoiceFrm" (ngSubmit)="saveInvoice()">
                    <div *ngIf="showForm">
                        <p-accordionTab header="Summary" [selected]="true">
                            <div class="card-body px-0">
                                <div class="row">
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Shipping Bill no </label>
                                        <input type="text" pInputText formControlName="SB_No" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && invf.SB_No.errors }" />
                                        <div *ngIf="submitted && invf.SB_No.errors" class="invalid-feedback">
                                            <div *ngIf="invf.SB_No.errors.required">Shipping Bill is required</div>
                                            <div *ngIf="invf.SB_No.errors.minlength">Shipping Bill length should be 7
                                                digits
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Shipping Bill Date </label><br>
                                        <p-calendar formControlName="SB_Date"
                                            [ngClass]="{ 'is-invalid': submitted && invf.SB_Date.errors }"
                                            dateFormat="dd-mm-yy">
                                        </p-calendar>
                                        <div *ngIf="submitted && invf.SB_Date.errors" class="invalid-feedback">
                                            <div *ngIf="invf.SB_Date.errors.required">Shipping Bill Date is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Entry date </label><br>
                                        <p-calendar formControlName="Ent_date" [readonlyInput]="true"
                                            [showOnFocus]="false"
                                            [ngClass]="{ 'is-invalid': submitted && invf.Ent_date.errors }"
                                            dateFormat="dd-mm-yy">
                                        </p-calendar>
                                        <div *ngIf="submitted && invf.Ent_date.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Ent_date.errors.required">Shipping Entry date is required
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Invoice No.</label>
                                        <input type="text" value="" formControlName="BILL_NO"
                                            [ngClass]="{ 'is-invalid': submitted && invf.BILL_NO.errors }"
                                            class="form-control" name="BILL_NO">
                                        <div *ngIf="submitted && invf.BILL_NO.errors" class="invalid-feedback">
                                            <div *ngIf="invf.BILL_NO.errors.required">Invoice No is required</div>
                                        </div>
                                    </div>



                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Invoice Date</label><br>
                                        <p-calendar name="Date" formControlName="Date"
                                            [ngClass]="{ 'is-invalid': submitted && invf.Date.errors }"
                                            dateFormat="dd-mm-yy">
                                        </p-calendar>
                                        <div *ngIf="submitted && invf.Date.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Date.errors.required">Date is required</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Exporter</label>
                                        <div class="el-pan">
                                            <input type="hidden" formControlName="EXP_CODE" />
                                            <div *ngIf="totalExporter==1"><strong>{{expName}}</strong></div>
                                            <div *ngIf="totalExporter>1">
                                                <select class="form-control" formControlName="EXP_NAME"
                                                    [ngClass]="{ 'is-invalid': submitted && invf.EXP_NAME.errors }"
                                                    (change)="assignExporterDD($event)">
                                                    <option value="">Select </option>
                                                    <option *ngFor="let exp of exporterAll; let i = index"
                                                        value="{{exp.exp_code}}--{{exp.exp_name}}">
                                                        {{exp.exp_name}}</option>
                                                </select>
                                                <div *ngIf="submitted && invf.EXP_NAME.errors" class="invalid-feedback">
                                                    <div *ngIf="invf.EXP_NAME.errors.required">Please select
                                                        Manufacturer
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Consignee</label>
                                        <input type="hidden" formControlName="BUYER_CODE" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && invf.BUYER_CODE.errors }" />
                                        <div class="el-pan" *ngIf="totalConsignee.length > 0 ">
                                            <select class="form-control"
                                                [ngClass]="{'match' : totalConsignee.length==1,'partialnomatch' : totalConsignee.length>1}"
                                                formControlName="totalConsigneeDD" (change)="assignConsigneeDD($event)">
                                                <option value="">Select Consignee </option>
                                                <option *ngFor="let consignee of totalConsignee; let i = index"
                                                    value="{{consignee.BUYER_CODE}}--{{consignee.BUYER_NAME}}">
                                                    {{consignee.BUYER_NAME}}</option>
                                                <option value="0">Not In above</option>
                                            </select>
                                        </div>
                                        <div class="el-pan" [ngClass]="{'nomatch' : totalConsignee.length==0}"
                                            *ngIf="totalConsignee.length == 0 ">
                                            <ng-autocomplete [data]="filteredConsinee" [searchKeyword]="keyword"
                                                (selected)='selectEventConsinee($event)' formControlName="BUYER_NAME"
                                                (inputChanged)='onChangeSearchConsinee($event)'
                                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="submitted && invf.BUYER_CODE.errors" class="invalid-feedback">
                                            <div *ngIf="invf.BUYER_CODE.errors.required">Please select consignee</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Processing Plant</label>
                                        <select class="form-control" (change)="getState($event)" name="Plant_Code"
                                            formControlName="Plant_Code"
                                            [ngClass]="{ 'is-invalid': submitted && invf.Plant_Code.errors }">
                                            <option value="">Select Plant </option>
                                            <option *ngFor="let plant of manufacturerPlants; let i = index"
                                                value="{{plant.code}}">{{plant.name}}</option>
                                        </select>
                                    </div>

                                    <div class="col-xl-6 col-md-12 form-group">
                                        <div class="row px-xl-0">
                                            <div class="col-md-6 form-group">
                                                <label> State of Origin</label>
                                                <input type="hidden" formControlName="plantState" class="form-control">
                                                <input type="text" formControlName="plantStateName" value=""
                                                    class="form-control">

                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label> Container No </label>
                                                <input type="text" formControlName="Vessel_No" value=""
                                                    class="form-control" name="Vessel_No">

                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Port of Loading</label>
                                        <input type="hidden" formControlName="PORT_CODE" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && invf.PORT_CODE.errors }" />
                                        <div class="el-pan" *ngIf="totalPort.length > 0 ">
                                            <select class="form-control"
                                                [ngClass]="{'match' : totalPort.length==1,'partialnomatch' : totalPort.length>1}"
                                                formControlName="totalPortDD" (change)="assignPortDD($event)">
                                                <option value="">Select Port </option>
                                                <option *ngFor="let port of totalPort; let i = index"
                                                    value="{{port.code}}--{{port.name}}">
                                                    {{port.name}}</option>
                                                <option value="0">Not In above</option>
                                            </select>
                                        </div>
                                        <div class="el-pan" [ngClass]="{'nomatch' : totalPort.length==0}"
                                            *ngIf="totalPort.length == 0 ">

                                            <ng-autocomplete [data]="filteredPortIndia" [searchKeyword]="keyword"
                                                formControlName="PORT_NAME" (selected)='selectEventPort($event)'
                                                (inputChanged)='onChangeSearchPortIndia($event)'
                                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.detail"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="submitted && invf.PORT_CODE.errors" class="invalid-feedback">
                                            <div *ngIf="invf.PORT_CODE.errors.required">Please select port of loading
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Port of Discharge</label>
                                        <input type="hidden" formControlName="Port_Discharge" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && invf.Port_Discharge.errors }" />
                                        <div class="el-pan" *ngIf="totalPortDischarge.length > 0 ">
                                            <select class="form-control"
                                                [ngClass]="{'match' : totalPortDischarge.length==1,'partialnomatch' : totalPortDischarge.length>1}"
                                                formControlName="totalPortDischargeDD"
                                                (change)="assignPortDischargeDD($event)">
                                                <option value="">Select Port </option>
                                                <option *ngFor="let port of totalPortDischarge; let i = index"
                                                    value="{{port.PORT_CODE}}--{{port.PORT_NAME}}">
                                                    {{port.PORT_NAME}}</option>
                                                <option value="0">Not In above</option>
                                            </select>
                                        </div>
                                        <div class="el-pan" [ngClass]="{'nomatch' : totalPortDischarge.length==0}"
                                            *ngIf="totalPortDischarge.length == 0 ">

                                            <ng-autocomplete [data]="filteredPort" [searchKeyword]="keyword"
                                                formControlName="Port_DischargeName"
                                                (selected)='selectEventPortDischarge($event)'
                                                (inputChanged)='onChangeSearchPort($event)'
                                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="submitted && invf.Port_Discharge.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Port_Discharge.errors.required">Please select port of
                                                discharge
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Final Destination</label>
                                        <input type="hidden" formControlName="Port_Final" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && invf.Port_Final.errors }" />
                                        <div class="el-pan" *ngIf="totalFinalPort.length > 0 ">
                                            <select class="form-control"
                                                [ngClass]="{'match' : totalFinalPort.length==1,'partialnomatch' : totalFinalPort.length>1}"
                                                formControlName="totalFinalPortDD" (change)="assignFinalPortDD($event)">
                                                <option value="">Select Final Port </option>
                                                <option *ngFor="let port of totalFinalPort; let i = index"
                                                    value="{{port.code}}--{{port.name}}">
                                                    {{port.name}}</option>
                                                <option value="0">Not In above</option>
                                            </select>
                                        </div>
                                        <div class="el-pan" [ngClass]="{'nomatch' : totalFinalPort.length==0}"
                                            *ngIf="totalFinalPort.length == 0 ">

                                            <ng-autocomplete [data]="filteredPort" [searchKeyword]="keyword"
                                                formControlName="Port_Final_NAME"
                                                (selected)='selectEventFinalPort($event)'
                                                (inputChanged)='onChangeSearchPort($event)'
                                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="submitted && invf.Port_Final.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Port_Final.errors.required">Please select final destination
                                            </div>
                                        </div>
                                    </div>




                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Country of origin of Goods</label>
                                        <div class="el-pan">
                                            <input type="hidden" formControlName="Country_Origin" />
                                            <ng-autocomplete [data]="filteredCountry" [searchKeyword]="keyword"
                                                formControlName="Country_Origin_Name"
                                                (selected)='selectEventCountryOrigin($event)'
                                                (inputChanged)='onChangeSearchCountry($event)'
                                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Country of Destination</label>
                                        <div class="el-pan">
                                            <input type="hidden" formControlName="COUNTRY_CODE" />
                                            <ng-autocomplete [data]="filteredCountry" [searchKeyword]="keyword"
                                                formControlName="COUNTRY_CODE_NAME"
                                                (selected)='selectEventCountryCode($event)'
                                                (inputChanged)='onChangeSearchCountry($event)'
                                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Import Export Code no. </label>
                                        <input type="text" formControlName="Imp_Exp_No" value="" class="form-control"
                                            name="Imp_Exp_No" />
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Exporter Under </label>
                                        <select class="form-control partialnomatch" name="E_Under"
                                            formControlName="E_Under">
                                            <option value="">Exporter Under </option>
                                            <option value="Deferred Credit">Deferred Credit</option>
                                            <option value="Joint Ventures">Joint Ventures</option>
                                            <option value="Rupees Credit">Rupees Credit</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Type of Shipment</label>
                                        <select class="form-control partialnomatch" name="S_Type"
                                            formControlName="S_Type">
                                            <option value="">Type of Shipment </option>
                                            <option value="Consignment Export">Consignment Export</option>
                                            <option value="Outright Sale">Outright Sale</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>




                                    <div class="col-xl-3 col-6 form-group">

                                        <label> Nature of Contract</label>
                                        <select class="form-control" name="Nature" formControlName="Nature">
                                            <option value="">Nature of Contract </option>
                                            <option value="FOB">FOB</option>
                                            <option value="CIF">CIF</option>
                                            <option value="CFR">CFR</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Custom House Agent</label>
                                        <textarea formControlName="Custom" value="" class="form-control"
                                            name="Custom"></textarea>
                                    </div>

                                </div>
                            </div>

                        </p-accordionTab>
                        <p-accordionTab header="Item Details">
                            <div class="card-body px-0">

                                <div class="row">
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Gross Weight </label>
                                        <input type="text" formControlName="Gross" value="" class="form-control"
                                            name="Gross" [ngClass]="{ 'is-invalid': submitted && invf.Gross.errors }" />
                                        <div *ngIf="submitted && invf.Gross.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Gross.errors.required">Please enter gross Weight</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Net Weight </label>
                                        <input type="text" formControlName="Tot_Weight" value="" class="form-control"
                                            name="Tot_Weight" [ngClass]="{ 'is-invalid': submitted && invf.Gross.errors }" />
                                        <div *ngIf="submitted && invf.Tot_Weight.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Tot_Weight.errors.required">Please enter net Weight</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-6 form-group">
                                        <label> Currency</label>
                                        <select class="form-control" name="Currency" formControlName="Currency"
                                            [ngClass]="{ 'is-invalid': submitted && invf.Currency.errors }">
                                            <option value="">Currency </option>
                                            <option *ngFor="let currency of currencies" value="{{currency.Currency}}">
                                                {{currency.Description}}</option>
                                        </select>
                                        <div *ngIf="submitted && invf.Currency.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Currency.errors.required">Please select currency</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <div class="row px-xl-0">
                                            <div class="col-md-6 form-group">

                                                <label>Ex. Rate to RS</label>
                                                <input type="text" formControlName="DOL_RATE" value=""
                                                    class="form-control" name="DOL_RATE"
                                                    [ngClass]="{ 'is-invalid': submitted && invf.DOL_RATE.errors }" />
                                                <div *ngIf="submitted && invf.DOL_RATE.errors" class="invalid-feedback">
                                                    <div *ngIf="invf.DOL_RATE.errors.required">Please Enter Ex. rate to
                                                        Rs
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 form-group">
                                                <label> Ex. Rate to Dollar </label>
                                                <input type="text" formControlName="CurToDol" value=""
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && invf.CurToDol.errors }"
                                                    name="CurToDol" />
                                                <div *ngIf="submitted && invf.CurToDol.errors" class="invalid-feedback">
                                                    <div *ngIf="invf.CurToDol.errors.required">Please Enter Ex. rate to
                                                        Dollar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Total Freight (US $) </label>
                                        <input type="text" formControlName="Tot_Freight" value="" class="form-control"
                                            name="Tot_Freight" (change)="calculateItemsFOB();" />
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Total Freight (Rs)</label>
                                        <input type="text" formControlName="R_Freight" value="" class="form-control"
                                            name="R_Freight" readonly />
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Freight / Kg</label>
                                        <input type="text" formControlName="FKG" value="" class="form-control"
                                            name="FKG" readonly />
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label class="d-block"> Rate In</label>
                                        <select class="form-control" name="rateIn" formControlName="rateIn">
                                            <option value="">Rate In </option>
                                            <option value="FOB">FOB</option>
                                            <option value="C&F">C&F</option>
                                        </select>


                                    </div>

                                    <!--
                        <div class="col-xl-3 col-md-12 form-group">

                            <label>Discount</label>
                            <input type="text" value="" class="form-control">
                        </div>
                        -->
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label>FOB Value as per Invoice  in USD</label>
                                        <input type="text" value="" formControlName="FOB_Value_invoice"
                                            class="form-control" name="FOB_Value_invoice"
                                            [ngClass]="{ 'is-invalid': submitted && invf.FOB_Value_invoice.errors }" />
                                        <div *ngIf="submitted && invf.FOB_Value_invoice.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="invf.FOB_Value_invoice.errors.required">Please enter FOB Value
                                                as
                                                per
                                                Invoice</div>
                                        </div>
                                    </div>
                                    <input type="hidden" formControlName="itemDetails" class="form-control"
                                        name="itemDetails" />
                                    <div class="col-xl-12 col-md-12 pt-4 table-responsive">
                                        <table class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>S.N.</th>
                                                    <th>HS Code</th>
                                                    <th>Item name</th>
                                                    <th>Item Code</th>
                                                    <th>Group</th>
                                                    <th>Group Name </th>
                                                    <th>Scientific name</th>
                                                    <th>Cartons</th>
                                                    <th>Grade</th>
                                                    <th>Size</th>
                                                    <th>Unit</th>
                                                    <th>Rate</th>
                                                    <th>Quantity</th>
                                                    <th>Qty ( kg)</th>
                                                    <th>Amount($)</th>
                                                    <th>Amount(Rs)</th>
                                                    <th>FOB Item Total</th>
                                                    <!-- <th>Action</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let invoiceItem of invoiceDetail.itemsDetail  let i = index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{invoiceItem.hs_code}}</td>
                                                    <td *ngIf="invoiceItem.showItem=='1'">
                                                        <select name="itemcode" class="form-control"
                                                            (change)="updateItems($event,i);"
                                                            [ngClass]="{'match' : invoiceDetail.invoiceItems.length==1,'partialnomatch' : invoiceDetail.invoiceItems.length>1}">
                                                            <option value="">Select Item </option>
                                                            <option *ngFor="let item of invoiceDetail.invoiceItems"
                                                                value="{{item.ITEM_CODE}}">{{item.ITEM_NAME}}
                                                            </option>
                                                            <option value="0">Not In above</option>
                                                        </select>

                                                        <!--
                                                    {{invoiceItem.ITEM_NAME}}
                                                    -->
                                                    </td>
                                                    <td *ngIf="invoiceItem.showItem=='0'">
                                                        <ng-autocomplete [data]="filteredItems"
                                                            [searchKeyword]="keyword"
                                                            (selected)='selectEventItem($event,i)'
                                                            (inputChanged)='onChangeSearchItems($event,i)'
                                                            (inputFocused)='onFocusedItem($event)'
                                                            [itemTemplate]="itemTemplateItems" minQueryLength="3"
                                                            [notFoundTemplate]="notFoundTemplateItems">
                                                        </ng-autocomplete>
                                                        <ng-template #itemTemplateItems let-item>
                                                            <a [innerHTML]="item.name"></a>
                                                        </ng-template>

                                                        <ng-template #notFoundTemplateItems let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </td>
                                                    <td>{{invoiceItem.ITEM_CODE}}</td>
                                                    <td>{{invoiceItem.GROUP_CODE}}</td>
                                                    <td>{{invoiceItem.GROUP_NAME}}</td>
                                                    <td>{{invoiceItem.scientific_name}}</td>
                                                    <td><input type="text" class="form-control"
                                                            (change)="updateCartons($event,i);"
                                                            value="{{invoiceItem.cartons_number}}" /></td>

                                                    <td>
                                                        <ng-autocomplete [data]="filteredGrade"
                                                            [searchKeyword]="keyword"
                                                            (selected)='selectEventGrades($event,i)'
                                                            (inputChanged)='onChangeSearchGrade($event,i)'
                                                            (inputFocused)='onFocusedGrade($event)'
                                                            [itemTemplate]="itemTemplate" minQueryLength="1"
                                                            [notFoundTemplate]="notFoundTemplate">
                                                        </ng-autocomplete>
                                                    </td>
                                                    <td>{{invoiceItem.Size_Name}}</td>
                                                    <td>{{invoiceItem.KILLBS}}</td>
                                                    <td>{{invoiceItem.RATE}}</td>
                                                    <td>{{invoiceItem.Qty}}</td>
                                                    <td>{{invoiceItem.TAILQTY}}</td>
                                                    <td>{{invoiceItem.TOTPRICE}}</td>
                                                    <td>{{invoiceItem.FOB_Rs}}</td>
                                                    <td>{{invoiceItem.FOB_Dol | number:'1.0-2' }}</td>
                                                    <!--
                                                <td> <a style="cursor:pointer" (click)="editItem(i)">Edit</a> | <a
                                                        style="cursor:pointer" (click)="deleteItem(i)">Delete</a>


                                                </td>
-->
                                                </tr>
                                                <tr>
                                                    <td colspan="10"></td>
                                                    <td>{{totalQuantityUnit}}</td>
                                                    <td><b>{{totalQuantity}}</b></td>
                                                    <td>{{totalAmountDollar}}</td>
                                                    <td>{{totalAmountRs}}</td>
                                                    <td *ngIf="totalPrice != 0"> <b>{{totalPrice}}</b> </td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-xl-6 col-md-12">
                                    </div>
                                    <div class="col-xl-6 col-md-12">
                                        <h4> Export Value Analysis </h4>


                                        <div class="form-group row pt-3">
                                            <label class="col-sm-2 col-form-label pt-xl-4 mt-xl-3">FOB Value</label>
                                            <div class="col-sm-5">
                                                <label>Currency</label>
                                                <input type="text" class="form-control" formControlName="FOB_Value"
                                                    name="FOB_Value" />
                                            </div>
                                            <div class="col-sm-5">
                                                <label>Amount in Rs.</label>
                                                <input type="text" class="form-control" formControlName="R_FOB"
                                                    name="R_FOB" />
                                            </div>

                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">Freight</label>
                                            <div class="col-sm-5">
                                                <input type="text" formControlName="Tot_Freight" class="form-control"
                                                    name="Tot_Freight" (change)="calculateItemsFOB();" />
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" formControlName="R_Freight"
                                                    formControlName="R_Freight" readonly />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">Insurance</label>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" formControlName="C_Insure"
                                                    name="C_Insure" />
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" formControlName="R_Insure"
                                                    readonly />
                                            </div>
                                        </div>


                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">Commission</label>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" formControlName="C_Comm"
                                                    name="C_Comm" />
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" formControlName="R_Comm"
                                                    readonly />
                                            </div>
                                        </div>


                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">Discount</label>
                                            <div class="col-sm-5">
                                                <input type="text" formControlName="C_Disc" class="form-control"
                                                    name="C_Disc" />
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" formControlName="R_Disc" class="form-control"
                                                    readonly />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">Other Deduction</label>
                                            <div class="col-sm-5">
                                                <input type="text" formControlName="C_Other" class="form-control"
                                                    name="C_Other" />
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" formControlName="R_Other"
                                                    readonly />
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>
                        </p-accordionTab>
                        <p-accordionTab header=" Item Group Summary">
                            <div class="card-body px-0">
                                <div class="row">

                                    <div class="col-xl-12 col-md-12 pt-4 table-responsive">

                                        <table class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Group</th>
                                                    <th>Group Name </th>
                                                    <th>Quantity</th>
                                                    <th>Unit</th>
                                                    <th>Qty ( kg)</th>
                                                    <th>Value</th>
                                                    <th>FOB Item Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let group of itemGroupDetail">
                                                    <td>{{group.GroupCode}}</td>
                                                    <td>{{group.GroupName}}</td>
                                                    <td>{{group.GroupQuantity}}</td>
                                                    <td>{{group.GroupUnit}}</td>
                                                    <td>{{group.QuantityKg}}</td>
                                                    <td>{{group.amount}}</td>
                                                    <td>{{group.amountFob}}</td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>


                                </div>

                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="Shipping Bill Details">
                            <div class="card-body px-0">
                                <div class="row">
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Upload Shipping Bill Copy</label>
                                        <input formControlName="SBfile" id="file" type="file" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && invf.SBfile.errors }"
                                            (change)="onSbFileChange($event)">
                                        <div *ngIf="submitted && invf.SBfile.errors" class="invalid-feedback">
                                            <div *ngIf="invf.SBfile.errors.required">File is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 form-group">
                                        <label> Upload Invoice Copy</label>
                                        <input formControlName="Invfile" id="Invfile" type="file" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && invf.Invfile.errors }"
                                            (change)="onInvoiceFileChange($event)">
                                        <div *ngIf="submitted && invf.Invfile.errors" class="invalid-feedback">
                                            <div *ngIf="invf.Invfile.errors.required">File is required.</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-xl-12 col-md-12 form-group">
                                        <label> Comment </label>
                                        <textarea class="form-control" formControlName="invoiceComment"></textarea>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-12 col-md-12 form-group">
                                        <h4> Comments </h4>
                                    </div>
                                    <div *ngFor="let item of comments" class="col-xl-12 col-md-12 form-group">
                                        <div class="message exporter">
                                            <span class="replyBy">
                                                <strong>{{item.addedFrom}}</strong>
                                            </span>
                                            <p>{{item.comment}}</p>
                                            <span class="date">Date : {{item.date_added}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-12 col-md-12 form-group">
                                        &nbsp;
                                    </div>
                                    <div class="col-xl-12 col-md-12 form-group">
                                        <input type="checkbox" formControlName="exporter_declaration" value="1" /> I
                                        declare
                                        that the information added is correct
                                    </div>

                                </div>
                            </div>
                        </p-accordionTab>
                        <div class="pageheadingouter">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-xl-5 col-md-6 text-md-left text-sm-left text-center">
                                    </div>
                                    <div
                                        class="col-xl-7 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0 ng-star-inserted">
                                        <a [routerLink]="['/exporter/invoices']"><button type="button"
                                                class="btn btn-light"> <i class="fa fa-times"></i> Cancel</button></a>
                                        <button class="btn btn-success" (click)="changeStatus('2')"><i
                                                class="fa fa-save"></i>Save &
                                            Submit</button>
                                        &nbsp; <button class="btn btn-success" (click)="changeStatus('1')"><i
                                                class="fa fa-save"></i>Save As Draft</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" formControlName="fileName" class="form-control" />
                    <input type="hidden" formControlName="added_from" class="form-control" />
                    <input type="hidden" formControlName="status" class="form-control" />
                    <input type="hidden" formControlName="medium" class="form-control" />
                    <input type="hidden" formControlName="added_by" class="form-control" />

                </form>

            </div>
        </p-accordion>
    </div>
</section>
<br>