<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">{{ getCustomMessage('dateStart.required')}}
                            </div>
                            <div *ngIf="f.dateStart.errors.pattern">{{ getCustomMessage('dateStart.pattern') }}</div>
                            <div *ngIf="f.dateStart.errors.min">{{ getCustomMessage('dateStart.min') }}</div>
                            <div *ngIf="f.dateStart.errors.max">{{ getCustomMessage('dateStart.max') }}</div>
                        </div>
                    </div>

                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">{{ getCustomMessage('dateEnd.required')}}</div>
                            <div *ngIf="f.dateEnd.errors.pattern">{{ getCustomMessage('dateEnd.pattern')}}</div>
                            <div *ngIf="f.dateEnd.errors.min">{{ getCustomMessage('dateEnd.min')}}</div>
                            <div *ngIf="f.dateEnd.errors.max">{{ getCustomMessage('dateEnd.max')}}
                            </div>
                        </div>
                    </div>

                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">Select Group </span>
                        <p-dropdown formControlName="selectedPort" [options]="sourceGroup" optionLabel="name"
                            optionValue="code" placeholder="Select Group"></p-dropdown>
                    </div>

                </div>


                <div class="col form-group" *ngIf="page=='export-shrimp-market'">
                    <span class="label_sub" style="color: #999;"> Select Region {{reportType}} </span>
                    <p-dropdown formControlName="selectedRegion" [options]="sourceUnion" optionLabel="name"
                        optionValue="code" placeholder="Select Region "> </p-dropdown>
                </div>























                <div class="row">
                    <div class="btn_pan mb-4">
                        <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                </div> -->


                <div *ngIf="page=='other-items'">
                    <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                        <span><b>Q</b>: Quantity in M Tons,</span>
                        <span><b>V</b>: Value in Rs. Crores,</span>
                        <span><b>$</b>: USD Million</span>
                    </div>
                </div>

                <div *ngIf="page=='export-shrimp-market'">
                    <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                        <span><b>Q</b>: Quantity in Tons,</span>
                    </div>
                </div>



                <div class="card" *ngIf="displayDetailedReport">



                    <table #detailTable>
                        <p-table [value]="reportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o"
                                        (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportDetailExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="1">Group</th>
                                    <th colspan="3">{{data.range1}}</th>
                                    <th colspan="3">{{data.range2}}</th>
                                    <th colspan="3">{{data.range3}}</th>
                                    <th colspan="3">Sub Total</th>

                                </tr>
                                <tr>
                                    <th></th>


                                    <th>Q </th>
                                    <th>V </th>
                                    <th>$ </th>

                                    <th>Q </th>
                                    <th>V </th>
                                    <th>$ </th>

                                    <th>Q </th>
                                    <th>V </th>
                                    <th>$ </th>

                                    <th>Q </th>
                                    <th>V </th>
                                    <th>$ </th>






                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td></td>

                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    <td></td>
                                    <td></td>
                                    <td></td>


                                </tr>
                            </ng-template>
                        </p-table>


                    </table>
                </div>



                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable *ngIf="page=='other-items'">
                                    <p-table [value]="arrays">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Group Name</th>
                                                <th>&nbsp;</th>
                                                <th>{{data.range1}}</th>
                                                <th>{{data.range2}}</th>
                                                <th *ngIf="dateRange >1 ">{{data.range3}}</th>
                                            </tr>
                                        </ng-template>



                                        <ng-template pTemplate="body" let-groupitem>

                                            <tr>
                                                <td>{{data.groupName}} </td>
                                                <td><b>Q</b> </td>
                                                <td>{{getNumber(groupitem.totalQty, 1)}}</td>
                                                <td>{{getNumber(groupitem.totalQty2, 1)}}</td>
                                                <td *ngIf="dateRange >1 ">{{getNumber(groupitem.totalQty3, 2)}}</td>
                                            </tr>
                                            <tr>
                                                <td> </td>
                                                <td><b>V</b> </td>
                                                <td>{{getNumber(groupitem.FobRs, 2)}}</td>
                                                <td>{{getNumber(groupitem.FobRs2, 2)}}</td>
                                                <td *ngIf="dateRange >1 ">{{getNumber(groupitem.FobRs3, 2)}}</td>
                                            </tr>
                                            <tr>
                                                <td> </td>
                                                <td><b>$</b> </td>
                                                <td>{{getNumber(groupitem.fobDol, 2)}}</td>
                                                <td>{{getNumber(groupitem.fobDol2, 2)}}</td>
                                                <td *ngIf="dateRange >1 ">{{getNumber(groupitem.FobRs3, 2)}}</td>
                                            </tr>


                                            <tr class="p-datatable-thead">
                                                <td>SUB TOTAL </td>
                                                <td><b>Q</b> </td>
                                                <td>{{getNumber(groupitem.totalQty, 1)}}</td>
                                                <td>{{getNumber(groupitem.totalQty2, 1)}}</td>
                                                <td *ngIf="dateRange >1 ">{{getNumber(groupitem.totalQty3, 2)}}</td>
                                            </tr>
                                            <tr>
                                                <td> </td>
                                                <td><b>V</b> </td>
                                                <td>{{getNumber(groupitem.FobRs, 2)}}</td>
                                                <td>{{getNumber(groupitem.FobRs2, 2)}}</td>
                                                <td *ngIf="dateRange >1 ">{{getNumber(groupitem.FobRs3, 2)}}</td>
                                            </tr>
                                            <tr>
                                                <td> </td>
                                                <td><b>$</b> </td>
                                                <td>{{getNumber(groupitem.fobDol, 2)}}</td>
                                                <td>{{getNumber(groupitem.fobDol2, 2)}}</td>
                                                <td *ngIf="dateRange >1 ">{{getNumber(groupitem.FobRs3, 2)}}</td>
                                            </tr>


                                        </ng-template>





                                    </p-table>
                                </table>

                                <table #userTable *ngIf="page=='export-shrimp-market'">
                                    <p-table [value]="arrays">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Group Name</th>
                                                <th>&nbsp;</th>
                                                <th>{{data.range1}}</th>
                                                <th>{{data.range2}}</th>
                                                <th *ngIf="dateRange >1 ">{{data.range3}}</th>
                                            </tr>
                                        </ng-template>



                                        <ng-template pTemplate="body" let-groupitem>

                                            <tr>
                                                <td>{{data.groupName}} </td>
                                                <td><b>Q</b> </td>
                                                <td>{{groupitem.totalQty.toFixed()}}</td>
                                                <td>{{groupitem.totalQty2.toFixed()}}</td>
                                                <td *ngIf="dateRange >1 ">{{groupitem.totalQty3.toFixed(2)}}</td>
                                            </tr>




                                            <tr class="p-datatable-thead">
                                                <td>SUB TOTAL </td>
                                                <td><b>Q</b> </td>
                                                <td>{{groupitem.totalQty.toFixed()}}</td>
                                                <td>{{groupitem.totalQty2.toFixed()}}</td>
                                                <td *ngIf="dateRange >1 ">{{groupitem.totalQty3.toFixed(2)}}</td>
                                            </tr>



                                        </ng-template>





                                    </p-table>
                                </table>


                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </div>

        <!-- <input type="text" formControlName="reportType" /> -->

    </form>
</div>