<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>

<section class="innerpage">
    <form [formGroup]="invoiceFrm" name="invoiceFrm" (ngSubmit)="saveInvoice()">
        <input type="hidden" formControlName="invoiceId" />
        <div class="pageheadingouter">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                        <div class="pageheading ">
                            <h1> <img src="assets/img/file_icons.png" alt=""> Shipping Bill </h1>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                        <a [routerLink]="['/invoices']"><button type="button" class="btn btn-light"> <i
                                    class="fa fa-times"></i> Cancel</button></a>
                        <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>

                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-4">

            <p-accordion [multiple]="true">
                <p-accordionTab header="Summary" [selected]="true">
                    <div class="card-body px-0">
                        <div class="row">
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Shipping Bill no </label>
                                <input type="text" pInputText formControlName="SB_No" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.SB_No.errors }"
                                    (blur)="checkBillNumber($event)" />
                                <div *ngIf="submitted && f.SB_No.errors" class="invalid-feedback">
                                    <div *ngIf="f.SB_No.errors.required">Shipping Bill is required</div>
                                    <div *ngIf="f.SB_No.errors.minlength">Shipping Bill length should be 7 digits</div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Shipping Bill Date </label><br>
                                <p-calendar formControlName="SB_Date"
                                    [ngClass]="{ 'is-invalid': submitted && f.SB_Date.errors }" [yearNavigator]="true"
                                    yearRange="{{calendarRange}}" [monthNavigator]="true" dateFormat="dd-mm-yy"
                                    (onSelect)="getExchangeRate()">
                                </p-calendar>
                                <div *ngIf="submitted && f.SB_Date.errors" class="invalid-feedback">
                                    <div *ngIf="f.SB_Date.errors.required">Shipping Bill Date is required</div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Entry date </label><br>
                                <p-calendar formControlName="Ent_date" [readonlyInput]="true" [showOnFocus]="false"
                                    [ngClass]="{ 'is-invalid': submitted && f.Ent_date.errors }" dateFormat="dd-mm-yy">
                                </p-calendar>
                                <div *ngIf="submitted && f.Ent_date.errors" class="invalid-feedback">
                                    <div *ngIf="f.Ent_date.errors.required">Shipping Entry date is required</div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Invoice No.</label>
                                <input type="text" value="" formControlName="BILL_NO"
                                    [ngClass]="{ 'is-invalid': submitted && f.BILL_NO.errors }" class="form-control"
                                    name="BILL_NO">
                                <div *ngIf="submitted && f.BILL_NO.errors" class="invalid-feedback">
                                    <div *ngIf="f.BILL_NO.errors.required">Invoice No is required</div>
                                </div>
                            </div>



                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Invoice Date</label><br>
                                <p-calendar name="Date" formControlName="Date"
                                    [ngClass]="{ 'is-invalid': submitted && f.Date.errors }" [yearNavigator]="true"
                                    yearRange="{{calendarRange}}" [monthNavigator]="true" [maxDate]="invoiceDateTime"
                                    dateFormat="dd-mm-yy">
                                </p-calendar>
                                <div *ngIf="submitted && f.Date.errors" class="invalid-feedback">
                                    <div *ngIf="f.Date.errors.required">Date is required</div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Exporter</label>
                                <div class="el-pan">

                                    <input type="hidden" formControlName="EXP_CODE" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.EXP_CODE.errors }" />
                                    <ng-autocomplete [data]="filteredExporters" [searchKeyword]="keyword"
                                        formControlName="EXP_NAME" (selected)='selectEventExporter($event)'
                                        (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                        [itemTemplate]="itemTemplateEXP" minQueryLength="3"
                                        [notFoundTemplate]="notFoundTemplateEXP">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateEXP let-item>
                                        <a [innerHTML]="item.expDetail"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplateEXP let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                    <div *ngIf="submitted && f.EXP_CODE.errors" class="invalid-feedback">
                                        <div *ngIf="f.EXP_CODE.errors.required">Please select exporter</div>
                                    </div>
                                </div>

                            </div>
                            <!--
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Manufacturer</label>
                                <input type="hidden" formControlName="MAN_CODE" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.MAN_CODE.errors }" />
                                <div class="el-pan">
                                    <ng-autocomplete [data]="filteredManufacturer" [searchKeyword]="keyword"
                                        (selected)='selectEventManufacturer($event)' formControlName="MAN_NAME"
                                        (inputChanged)='onChangeSearchManufacturer($event)'
                                        (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                        minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <div *ngIf="submitted && f.MAN_CODE.errors" class="invalid-feedback">
                                    <div *ngIf="f.MAN_CODE.errors.required">Please select manufacturer</div>
                                </div>
                            </div>
-->
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Consignee</label>
                                <input type="hidden" formControlName="BUYER_CODE" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.BUYER_CODE.errors }" />

                                <div class="el-pan">

                                    <!--
                               
                                    <ng-autocomplete 
                                    [data]="filteredConsinee"
                                    [searchKeyword]="keyword"
                                    (selected)='selectEvent($event)'
                                    (inputChanged)='onChangeSearch($event)'
                                    (inputFocused)='onFocused($event)'
                                    [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate">                                 
                                  </ng-autocomplete>
                                -->
                                    <ng-autocomplete [data]="filteredConsinee" [searchKeyword]="keyword"
                                        (selected)='selectEventConsinee($event)'
                                        (inputChanged)='onChangeSearchConsinee($event)' formControlName="BUYER_NAME"
                                        (inputFocused)='onFocused($event)' [itemTemplate]="conTemplate"
                                        [notFoundTemplate]="notFoundTemplateCon" minQueryLength="3">
                                    </ng-autocomplete>

                                    <ng-template #conTemplate let-item>
                                        <a [innerHTML]="item.consigneeDetail"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplateCon let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>

                                </div>
                                <div *ngIf="submitted && f.BUYER_CODE.errors" class="invalid-feedback">
                                    <div *ngIf="f.BUYER_CODE.errors.required">Please select consignee</div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Processing Plant</label>
                                <select class="form-control" (change)="getState($event)" name="Plant_Code"
                                    formControlName="Plant_Code"
                                    [ngClass]="{ 'is-invalid': submitted && f.Plant_Code.errors }">
                                    <option value="">Select Plant </option>
                                    <option *ngFor="let plant of manufacturerPlants; let i = index"
                                        value="{{plant.code}}">{{plant.name}}</option>
                                </select>
                            </div>

                            <div class="col-xl-6 col-md-12 form-group">
                                <div class="row px-xl-0">
                                    <div class="col-md-6 form-group">
                                        <label> State of Origin</label>
                                        <input type="hidden" formControlName="plantState" class="form-control">
                                        <input type="text" formControlName="plantStateName" value=""
                                            class="form-control">
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label> Vessel /Flight No. </label>
                                        <input type="text" formControlName="Vessel_No" value="" class="form-control"
                                            name="Vessel_No">

                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Port of Loading</label>
                                <input type="hidden" formControlName="PORT_CODE" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.PORT_CODE.errors }" />
                                <div class="el-pan">

                                    <ng-autocomplete [data]="filteredPortIndia" [searchKeyword]="keyword"
                                        formControlName="PORT_NAME" (selected)='selectEventPort($event)'
                                        (inputChanged)='onChangeSearchPortIndia($event)'
                                        (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateLoading"
                                        minQueryLength="3" [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateLoading let-item>
                                        <a [innerHTML]="item.detail"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <div *ngIf="submitted && f.PORT_CODE.errors" class="invalid-feedback">
                                    <div *ngIf="f.PORT_CODE.errors.required">Please select port of loading</div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Port of Discharge</label>
                                <input type="hidden" formControlName="Port_Discharge" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.Port_Discharge.errors }" />
                                <div class="el-pan">

                                    <ng-autocomplete [data]="filteredPort" [searchKeyword]="keyword"
                                        formControlName="Port_DischargeName"
                                        (selected)='selectEventPortDischarge($event)'
                                        (inputChanged)='onChangeSearchPort($event)' (inputFocused)='onFocused($event)'
                                        [itemTemplate]="itemTemplateDischarge" minQueryLength="3"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateDischarge let-item>
                                        <a [innerHTML]="item.detail"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <div *ngIf="submitted && f.Port_Discharge.errors" class="invalid-feedback">
                                    <div *ngIf="f.Port_Discharge.errors.required">Please select port of discharge</div>
                                </div>
                            </div>



                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Final Destination</label>
                                <input type="hidden" formControlName="Port_Final" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.Port_Final.errors }" />
                                <div class="el-pan">

                                    <ng-autocomplete [data]="filteredPort" [searchKeyword]="keyword"
                                        formControlName="Port_Final_NAME" (selected)='selectEventFinalPort($event)'
                                        (inputChanged)='onChangeSearchPort($event)' (inputFocused)='onFocused($event)'
                                        [itemTemplate]="itemTemplateFinal" minQueryLength="3"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateFinal let-item>
                                        <a [innerHTML]="item.detail"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <div *ngIf="submitted && f.Port_Final.errors" class="invalid-feedback">
                                    <div *ngIf="f.Port_Final.errors.required">Please select final destination</div>
                                </div>
                            </div>




                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Country of origin of Goods</label>
                                <div class="el-pan">
                                    <input type="hidden" formControlName="Country_Origin" />
                                    <ng-autocomplete [data]="filteredCountry" [searchKeyword]="keyword"
                                        formControlName="Country_Origin_Name"
                                        (selected)='selectEventCountryOrigin($event)'
                                        (inputChanged)='onChangeSearchCountry($event)'
                                        (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateCountryOrigin"
                                        minQueryLength="3" [notFoundTemplate]="notFoundTemplateCountryOrigin">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateCountryOrigin let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplateCountryOrigin let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Country of Destination</label>
                                <div class="el-pan">
                                    <input type="hidden" formControlName="COUNTRY_CODE" />
                                    <ng-autocomplete [data]="filteredCountry" [searchKeyword]="keyword"
                                        formControlName="COUNTRY_CODE_NAME" (selected)='selectEventCountryCode($event)'
                                        (inputChanged)='onChangeSearchCountry($event)'
                                        (inputFocused)='onFocusedCounDes($event)' [itemTemplate]="itemTemplateCoDes"
                                        minQueryLength="3" [notFoundTemplate]="notFoundTemplateCoDes">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateCoDes let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplateCoDes let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Import Export Code no. </label>
                                <input type="text" formControlName="Imp_Exp_No" value="" class="form-control"
                                    name="Imp_Exp_No" />
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Exporter Under </label>
                                <select class="form-control" name="E_Under" formControlName="E_Under">
                                    <option value="">Exporter Under </option>
                                    <option value="Deferred Credit">Deferred Credit</option>
                                    <option value="Joint Ventures">Joint Ventures</option>
                                    <option value="Rupees Credit">Rupees Credit</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Type of Shipment</label>
                                <select class="form-control" name="S_Type" formControlName="S_Type">
                                    <option value="">Exporter Under </option>
                                    <option value="Consignment Export">Consignment Export</option>
                                    <option value="Outright Sale">Outright Sale</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>




                            <div class="col-xl-3 col-6 form-group">

                                <label> Nature of Contract</label>
                                <select class="form-control" name="Nature" formControlName="Nature">
                                    <option value="">Nature of Contract </option>
                                    <option value="FOB">FOB</option>
                                    <option value="CIF">CIF</option>
                                    <option value="CFR">CFR</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Custom House Agent</label>
                                <textarea formControlName="Custom" value="" class="form-control"
                                    name="Custom"></textarea>
                            </div>

                        </div>
                    </div>

                </p-accordionTab>
                <!---- 
                <p-accordionTab header="Shipping Bill Details">
                    <div class="card-body px-0">

                        <div class="row">

                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Import Export Code no. </label>
                                <input type="text" formControlName="Imp_Exp_No" value="" class="form-control"
                                    name="Imp_Exp_No" />
                            </div>
                            <!-
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> RBI Code No. </label>
                                <input type="text"  formControlName="RBI_No" value="" class="form-control" name="RBI_No"  />
                            </div>
                            ->
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Exporter Under </label>
                                <select class="form-control" name="E_Under" formControlName="E_Under">
                                    <option value="">Exporter Under </option>
                                    <option value="Deferred Credit">Deferred Credit</option>
                                    <option value="Joint Ventures">Joint Ventures</option>
                                    <option value="Rupees Credit">Rupees Credit</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>


                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Type of Shipment</label>
                                <select class="form-control" name="S_Type" formControlName="S_Type">
                                    <option value="">Exporter Under </option>
                                    <option value="Consignment Export">Consignment Export</option>
                                    <option value="Outright Sale">Outright Sale</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>


                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Custom House Agent</label>
                                <input type="text" formControlName="Custom" value="" class="form-control"
                                    name="Custom" />
                            </div>

                            <div class="col-xl-3 col-6 form-group">

                                <label> Nature of Contract</label>
                                <select class="form-control" name="Nature" formControlName="Nature">
                                    <option value="">Nature of Contract </option>
                                    <option value="FOB">FOB</option>
                                    <option value="CIF">CIF</option>
                                    <option value="CFR">CFR</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            <div class="col-xl-3 col-6 form-group">
                                <label> Currency</label>
                                <select class="form-control" name="Currency" formControlName="Currency">
                                    <option value="">Currency </option>
                                    <option *ngFor="let currency of currencies" value="{{currency.Currency}}">
                                        {{currency.Description}}</option>
                                </select>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <div class="row px-xl-0">
                                    <div class="col-md-6 form-group">

                                        <label>Ex. Rate to RS</label>
                                        <input type="text" formControlName="DOL_RATE" value="" class="form-control"
                                            name="DOL_RATE" />
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label> Ex. Rate to Dollar </label>
                                        <input type="text" formControlName="CurToDol" value="" class="form-control"
                                            name="CurToDol" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-12">
                                <!-
                                <h4> Value FOB </h4>
                                <div class="row px-xl-0 pt-4">
                                    <div class="col-md-6 form-group">
                                        <label> Value FOB</label>
                                        <input type="text" formControlName="FOB_Value" value="" class="form-control"
                                            name="FOB_Value" />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label> Duty Rate % </label>
                                        <input type="text" value="" formControlName="Duty_Rate" class="form-control"
                                            name="Duty_Rate" />
                                    </div>
                                </div>
                                ->
                                <!-
                                <div class="form-group row">
                                    <label  class="col-sm-2 col-form-label pt-xl-4 mt-xl-3">RV Amount</label>
                                    <div class="col-sm-5">
                                        <label>Currency</label>
                                        <input type="text" formControlName="Amount" class="form-control" name="Amount"  />
                                    </div>
                                    <div class="col-sm-5">
                                        <label>Amount in Rs.</label>
                                        <input type="text" class="form-control" formControlName="AmountR" name="AmountR"  />
                                    </div>

                                </div>


                                <div class="form-group row">
                                    <label  class="col-sm-2 col-form-label">AP Cess</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="AP_CessR" class="form-control" name="AP_CessR"  />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="AP_Cess" class="form-control" name="AP_Cess"  />
                                    </div>
                                </div>


                                <div class="form-group row">
                                    <label  class="col-sm-2 col-form-label">MP Cess</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="AP_CessR" class="form-control" name="AP_CessR"  />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text"  formControlName="AP_Cess" class="form-control" name="AP_Cess"  />
                                    </div>
                                </div>


                                <div class="form-group row">
                                    <label  class="col-sm-2 col-form-label">Duty & Cess</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="Duty_CessR" class="form-control" name="Duty_CessR"  />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="Duty_Cess" class="form-control" name="Duty_Cess" />
                                    </div>
                                </div>
                            ->



                            </div>
                        </div>

                    </div>
                </p-accordionTab>

            -->
                <p-accordionTab header="Item Details">
                    <div class="card-body px-0">

                        <div class="row">
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Gross Weight </label>
                                <input type="text" formControlName="Gross" value="" class="form-control" name="Gross"
                                    [ngClass]="{ 'is-invalid': submitted && f.Gross.errors }" />
                                <div *ngIf="submitted && f.Gross.errors" class="invalid-feedback">
                                    <div *ngIf="f.Gross.errors.required">Please enter gross Weight</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Net Weight </label>
                                <input type="text" formControlName="Tot_Weight" value="" class="form-control"
                                    name="Tot_Weight" [ngClass]="{ 'is-invalid': submitted && f.Tot_Weight.errors }" />
                                <div *ngIf="submitted && f.Tot_Weight.errors" class="invalid-feedback">
                                    <div *ngIf="f.Tot_Weight.errors.required">Please enter net Weight</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-6 form-group">
                                <label> Currency</label>
                                <select class="form-control" name="Currency" formControlName="Currency"
                                    [ngClass]="{ 'is-invalid': submitted && f.Currency.errors }"
                                    (change)="getExchangeRate()">
                                    <option value="">Currency </option>
                                    <option *ngFor="let currency of currencies" value="{{currency.Currency}}">
                                        {{currency.Description}}</option>
                                </select>
                                <div *ngIf="submitted && f.Currency.errors" class="invalid-feedback">
                                    <div *ngIf="f.Currency.errors.required">Please select currency</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <div class="row px-xl-0">
                                    <div class="col-md-6 form-group">

                                        <label>Ex. Rate to RS</label>
                                        <input type="text" formControlName="DOL_RATE" value="" class="form-control"
                                            name="DOL_RATE"
                                            [ngClass]="{ 'is-invalid': submitted && f.DOL_RATE.errors }" />
                                        <div *ngIf="submitted && f.DOL_RATE.errors" class="invalid-feedback">
                                            <div *ngIf="f.DOL_RATE.errors.required">Please Enter Ex. rate to Rs</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label> Ex. Rate to Dollar </label>
                                        <input type="text" formControlName="CurToDol" value="" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.CurToDol.errors }"
                                            name="CurToDol" />
                                        <div *ngIf="submitted && f.CurToDol.errors" class="invalid-feedback">
                                            <div *ngIf="f.CurToDol.errors.required">Please Enter Ex. rate to Dollar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Total Freight (US $) </label>
                                <input type="text" formControlName="Tot_Freight" value="" class="form-control"
                                    name="Tot_Freight" (change)="calculateItemsFOB();" />
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Total Freight (Rs)</label>
                                <input type="text" formControlName="R_Freight" value="" class="form-control"
                                    name="R_Freight" readonly />
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Freight / Kg</label>
                                <input type="text" formControlName="FKG" value="" class="form-control" name="FKG"
                                    readonly />
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label class="d-block"> Rate In</label>
                                <select class="form-control" name="Nature" formControlName="Nature">
                                    <option value="">Rate In </option>
                                    <option value="FOB">FOB</option>
                                    <option value="C&F">C&F</option>
                                </select>


                            </div>

                            <!--
                            <div class="col-xl-3 col-md-12 form-group">

                                <label>Discount</label>
                                <input type="text" value="" class="form-control">
                            </div>
                            -->
                            <div class="col-xl-3 col-md-12 form-group">
                                <label>FOB Value as per Invoice in USD</label>
                                <input type="text" value="" formControlName="FOB_Value_invoice" class="form-control"
                                    name="FOB_Value_invoice"
                                    [ngClass]="{ 'is-invalid': submitted && f.FOB_Value_invoice.errors }" />
                                <div *ngIf="submitted && f.FOB_Value_invoice.errors" class="invalid-feedback">
                                    <div *ngIf="f.FOB_Value_invoice.errors.required">Please enter FOB Value as per
                                        Invoice</div>
                                </div>
                            </div>
                            <input type="hidden" formControlName="itemDetails" class="form-control"
                                name="itemDetails" />
                            <div class="col-xl-12 col-md-12 text-right">
                                <button type="button" class="btn btn-success" (click)="showAddItem()">Add New
                                    Item</button>

                            </div>
                            <div class="col-xl-12 col-md-12 pt-4 table-responsive">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Group</th>
                                            <th>Group Name </th>
                                            <th>Item Code</th>
                                            <th>Item name</th>
                                            <th>Scientific name</th>
                                            <th>HS Code</th>
                                            <th>Grade</th>
                                            <th>Size</th>
                                            <th>Unit</th>
                                            <th>Rate</th>
                                            <th>Quantity</th>
                                            <th>Qty ( kg)</th>
                                            <th>Amount($)</th>
                                            <th>Amount(Rs)</th>
                                            <th>FOB Item Total</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let invoiceItem of invoiceDetail.itemsDetail  let i = index">
                                            <td>{{invoiceItem.GROUP_CODE}}</td>
                                            <td>{{invoiceItem.GROUP_NAME}}</td>
                                            <td>{{invoiceItem.ITEM_CODE}}</td>
                                            <td>{{invoiceItem.ITEM_NAME}}</td>
                                            <td>{{invoiceItem.scientific_name}}</td>
                                            <td>{{invoiceItem.hs_code}}</td>
                                            <td>{{invoiceItem.GRADE_CODE}} </td>
                                            <td>{{invoiceItem.Size_Name}}</td>
                                            <td>{{invoiceItem.KILLBS}}</td>
                                            <td>{{invoiceItem.RATE}}</td>
                                            <td>{{invoiceItem.Qty}}</td>
                                            <td>{{invoiceItem.TAILQTY}}</td>
                                            <td>{{invoiceItem.TOTPRICE}}</td>
                                            <td>{{invoiceItem.FOB_Rs}}</td>
                                            <td>{{invoiceItem.FOB_Dol | number:'1.0-2' }}</td>

                                            <td> <a style="cursor:pointer" (click)="editItem(i)">Edit</a> | <a
                                                    style="cursor:pointer" (click)="deleteItem(i)">Delete</a>


                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="10"></td>
                                            <td>{{totalQuantityUnit}}</td>
                                            <td><b>{{totalQuantity}}</b></td>
                                            <td>{{totalAmountDollar}}</td>
                                            <td>{{totalAmountRs}}</td>
                                            <td *ngIf="totalPrice != 0"> <b>{{totalPrice}}</b> </td>
                                            <td></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="col-xl-6 col-md-12">
                            </div>
                            <div class="col-xl-6 col-md-12">
                                <h4> Export Value Analysis </h4>


                                <div class="form-group row pt-3">
                                    <label class="col-sm-2 col-form-label pt-xl-4 mt-xl-3">FOB Value</label>
                                    <div class="col-sm-5">
                                        <label>Currency</label>
                                        <input type="text" class="form-control" formControlName="FOB_Value"
                                            name="FOB_Value" />
                                    </div>
                                    <div class="col-sm-5">
                                        <label>Amount in Rs.</label>
                                        <input type="text" class="form-control" formControlName="R_FOB" name="R_FOB" />
                                    </div>

                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Freight</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="Tot_Freight" class="form-control"
                                            name="Tot_Freight" (change)="calculateItemsFOB();" />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" formControlName="R_Freight"
                                            formControlName="R_Freight" readonly />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Insurance</label>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" formControlName="C_Insure"
                                            name="C_Insure" />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" formControlName="R_Insure" readonly />
                                    </div>
                                </div>


                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Commission</label>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" formControlName="C_Comm"
                                            name="C_Comm" />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" formControlName="R_Comm" readonly />
                                    </div>
                                </div>


                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Discount</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="C_Disc" class="form-control"
                                            name="C_Disc" />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="R_Disc" class="form-control" readonly />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Other Deduction</label>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="C_Other" class="form-control"
                                            name="C_Other" />
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" formControlName="R_Other" readonly />
                                    </div>
                                </div>


                            </div>


                        </div>

                    </div>
                </p-accordionTab>
                <p-accordionTab header=" Item Group Summary">
                    <div class="card-body px-0">
                        <div class="row">

                            <div class="col-xl-12 col-md-12 pt-4 table-responsive">

                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Group</th>
                                            <th>Group Name </th>
                                            <th>Quantity</th>
                                            <th>Unit</th>
                                            <th>Qty ( kg)</th>
                                            <th>Value US$</th>
                                            <th>FOB Item Total Rs.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let group of itemGroupDetail">
                                            <td>{{group.GroupCode}}</td>
                                            <td>{{group.GroupName}}</td>
                                            <td>{{group.GroupQuantity}}</td>
                                            <td>{{group.GroupUnit}}</td>
                                            <td>{{group.QuantityKg}}</td>
                                            <td>{{group.amount}}</td>
                                            <td>{{(group.amount - groupTotal/itemGroupDetail.length).toFixed(2)}}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>


                        </div>

                    </div>
                </p-accordionTab>
                <p-accordionTab header="Shipping Bill Details">
                    <div class="card-body px-0">
                        <div class="row">
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Upload Shipping Bill Copy</label>
                                <input formControlName="SBfile" id="file" type="file" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.SBfile.errors }"
                                    (change)="onFileChange($event)">
                                <div *ngIf="sbFile!=''">
                                    <div><a href="{{sbFileUrl}}" target="_blank">View File</a></div>
                                </div>
                                <div *ngIf="submitted && f.SBfile.errors" class="invalid-feedback">
                                    <div *ngIf="f.SBfile.errors.required">File is required.</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Upload Invoice Copy</label>
                                <input formControlName="Invfile" id="Invfile" type="file" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.Invfile.errors }"
                                    (change)="onInvoiceFileChange($event)">
                                <div *ngIf="invFile!=''">
                                    <div><a href="{{invFileUrl}}" target="_blank">View File</a></div>
                                </div>
                                <div *ngIf="submitted && f.Invfile.errors" class="invalid-feedback">
                                    <div *ngIf="f.Invfile.errors.required">File is required.</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-12 form-group" *ngIf="uploadedFile!=''">
                                <label> Imported File</label>
                                <div><a href="{{uploadedFileUrl}}" target="_blank">View File</a></div>

                            </div>
                            <div class="col-xl-3 col-md-12 form-group">
                                <label> Status</label>
                                <select class="form-control" formControlName="status" *ngIf="branchId!=0">
                                    <option value="">Select Status</option>
                                    <option value="1">Saved</option>
                                    <option value="2">Submitted</option>
                                    <option value="3">Returned to Exporter</option>
                                    <option value="4">Approved and reconciled</option>
                                </select>
                                <select class="form-control" formControlName="status" *ngIf="branchId==0">
                                    <option value="">Select Status</option>
                                    <option value="1">Saved</option>
                                    <option value="2">Submitted</option>
                                    <option value="3">Returned to Exporter</option>
                                    <option value="4">Reconciled</option>
                                    <option value="5">Returned to Field Office</option>
                                    <option value="6">FINAL CONFIRMED</option>

                                </select>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Comment </label>
                                <textarea class="form-control" formControlName="invoiceComment"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-12 col-md-12 form-group">
                                <h4> Comments </h4>
                            </div>
                            <div *ngFor="let item of comments" class="col-xl-12 col-md-12 form-group">
                                <div class="message exporter">
                                    <span class="replyBy">
                                        <strong>{{item.addedFrom}}</strong>
                                    </span>
                                    <p>{{item.comment}}</p>
                                    <span class="date">Date : {{item.date_added}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="branchId!=0">
                            <div class="col-xl-12 col-md-12 form-group">
                                &nbsp;
                            </div>
                            <div class="col-xl-12 col-md-12 form-group">
                                <input type="checkbox" formControlName="fo_declaration" value="1" /> I have reviewed
                                it and it is found correct as per supporting document submitted by Exporter
                            </div>

                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>

        </div>
        <br>

        <div class="pageheadingouter">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    </div>
                    <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                        <a [routerLink]="['/invoices']"><button type="button" class="btn btn-light"> <i
                                    class="fa fa-times"></i> Cancel</button></a>
                        <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>

                    </div>
                </div>
            </div>
        </div>
        <input type="hidden" formControlName="medium" />
    </form>
    <p-dialog header="Add/Edit Item" [(visible)]="displayAddItem" [style]="{width: '50vw'}" [baseZIndex]="10000">
        <form [formGroup]="itemForm" name="itemForm" (ngSubmit)="addItem()">

            <div class="row">
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Group </label>
                    <select class="form-control" (change)="getItems($event)" name="itemGroup"
                        formControlName="itemGroup"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.itemGroup.errors }">
                        <option value="">Select Group </option>
                        <option *ngFor="let group of groups" value="{{group.GROUP_CODE}}--{{group.GROUP_NAME}}">
                            {{group.GROUP_CODE}}--{{group.GROUP_NAME}}</option>
                    </select>
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Item </label>
                    <input type="hidden" class="form-control" name="itemProduct" formControlName="itemProduct"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.itemProduct.errors }" />
                    <ng-autocomplete [data]="itemListDD" [searchKeyword]="keyword" (selected)='selectItemEvent($event)'
                        (inputCleared)='onClearItem()' (inputFocused)='onFocused($event)'
                        formControlName="ITEM_AUTOCOMPLETE" [itemTemplate]="itemTemplateItem"
                        [notFoundTemplate]="notFoundTemplateItem">
                    </ng-autocomplete>
                    <ng-template #itemTemplateItem let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplateItem let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <div *ngIf="submittedItem && fItem.itemProduct.errors" class="invalid-feedback">
                        <div *ngIf="fItem.itemProduct.errors.required">Please Select Item</div>
                    </div>
                    <!--
                    <select class="form-control" (change)="getItemGrade($event)"  name="itemProduct" formControlName="itemProduct" [ngClass]="{ 'is-invalid': submittedItem && fItem.itemProduct.errors }" >
                            <option value="">Select Item </option>
                            <option *ngFor="let item of itemListDD" value="{{item.ITEM_CODE}}--{{item.ITEM_NAME}}">{{item.ITEM_CODE}}--{{item.ITEM_NAME}}</option>
                    </select>                    
                    -->
                </div>

                <div class="col-xl-6 col-md-12 form-group">
                    <label> Scientific Name </label>
                    <select class="form-control" name="itemScientificName" formControlName="itemScientificName"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.itemScientificName.errors }">
                        <option value="">Select Scientific Name </option>
                        <option *ngFor="let sname of itemSNameDD" value="{{sname}}">{{sname}}</option>
                    </select>

                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> HS Code </label>
                    <select class="form-control" name="itemHsCode" formControlName="itemHsCode">
                        <option value="">Select HS Code </option>
                        <option *ngFor="let hscode of itemHsDD" value="{{hscode}}">{{hscode}}</option>
                    </select>
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Grade </label>
                    <input type="hidden" name="productGrade" class="form-control" formControlName="productGrade"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.productGrade.errors }" />
                    <ng-autocomplete [data]="itemGradeDD" [searchKeyword]="keyword" (selected)='selectItemGrade($event)'
                        formControlName="GRADE_AUTOCOMPLETE" (inputCleared)='onClearGrade()'
                        [itemTemplate]="itemTemplateGrade" [notFoundTemplate]="notFoundTemplateGrade">
                    </ng-autocomplete>
                    <ng-template #itemTemplateGrade let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplateGrade let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <div *ngIf="submittedItem && fItem.productGrade.errors" class="invalid-feedback">
                        <div *ngIf="fItem.productGrade.errors.required">Please Select Item Grade</div>
                    </div>
                    <!--
                    <select class="form-control" name="productGrade" formControlName="productGrade" [ngClass]="{ 'is-invalid': submittedItem && fItem.productGrade.errors }" >
                            <option value="">Select Grade </option>
                            <option *ngFor="let grade of itemGradeDD" value="{{grade.code}}--{{grade.name}}">{{grade.code}}--{{grade.name}}</option>
                    </select>
                -->
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Quantity </label>
                    <input type="text" value="" formControlName="itemQuantity" class="form-control" name="itemQuantity"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.itemQuantity.errors }" />
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Unit </label>
                    <select name="" formControlName="itemUnit" class="form-control"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.itemUnit.errors }">
                        <option value="K">Kg</option>
                        <option value="L">LBS</option>
                    </select>

                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Quantity(Kg) </label>
                    <input type="text" readonly value="" formControlName="itemQuantityKg" class="form-control"
                        name="itemQuantityKg"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.itemQuantityKg.errors }" />
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Rate </label>
                    <input type="text" value="" formControlName="itemRate" class="form-control" name="itemRate"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.itemRate.errors }" />
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Amount($) </label>
                    <input type="text" readonly value="" formControlName="itemAmount" class="form-control"
                        name="itemAmount" [ngClass]="{ 'is-invalid': submittedItem && fItem.itemAmount.errors }" />
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> Amount(Rs) </label>
                    <input type="text" readonly value="" formControlName="itemAmountRs" class="form-control"
                        name="itemAmountRs" [ngClass]="{ 'is-invalid': submittedItem && fItem.itemAmount.errors }" />
                </div>

                <div class="col-xl-6 col-md-12 form-group">
                    <label> FOB Item Total </label>
                    <input type="text" value="" formControlName="fobitemAmount" class="form-control"
                        name="fobitemAmount"
                        [ngClass]="{ 'is-invalid': submittedItem && fItem.fobitemAmount.errors }" />
                </div>
                <div class="col-xl-6 col-md-12 form-group">
                    <label> No of cartons </label>
                    <input type="text" value="" formControlName="cartonsNumber" class="form-control"
                        name="cartonsNumber" />
                </div>
            </div>
            <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>
            <input type="hidden" value="" formControlName="itemId" class="form-control" name="itemId" />
        </form>
    </p-dialog>
</section> <br>