import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { ExportReviewService } from '../../export-review.service';
import { CommonServiceService } from '../../../../common-service.service';


@Component({
    selector: 'app-export-of-frozen-scampi',
    templateUrl: './export-of-frozen-scampi.component.html',
    styleUrls: ['./export-of-frozen-scampi.component.css']
  })


export class ExportOfFrozenScampiComponent implements OnInit {


  reportFrm: FormGroup;
  reportTitle: string;
  page :string;
  targetGroup: any;
  sourceGroup: any;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  reportData: any = [];

  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  showTextBox: boolean = false;
  detailReportData: any = [];
  dateRange: number;
  reportDateRange: any = [];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService, private commonService: CommonServiceService) { }

  ngOnInit(): void {






    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;

    console.warn("get page name ", reportType);
  
    this.reportTitle = "Export Of Frozen Scampi";
    // this.page = 'Clam-Meat';
    
    
    

    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      reportType:[reportType],
      viewDetailReport: [''],
      topRecordNum: ['0'],
      topRecordNumOther: ['1'],
      includeBlankData: ['0'],
      sortColumn: ['FobDolMil'],
      sortType: ['DESC'],

    });


    this.targetGroup = [];
    this.primengConfig.ripple = true;








    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }



  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }



  getReport() {

    this.isloader = true;
    this.submitted = true;

    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }



    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    var yearDifferent = parseInt(dt2) - parseInt(dt1);
    // this.dateRange= yearDifferent;

    // if (dt1 == "") {
    //   this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Start Date", life: 6000 }); //success,info,warn,error
    //   this.isloader = false;
    //   return false;
    // }

    if (yearDifferent > 10) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3 year", life: 6000 });
      this.isloader = false;
      return false;
    }


    this._service.getExportGrowth(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      console.log('get srimp export  ', this.data);


      if (this.data.status) {
        this.isloader = false;
        this.reportData = this.data.parentArrays[0].reportItems;
        this.displayReport = true;
      }

    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });



  }





  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }

}
