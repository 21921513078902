import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { HttpHeaders } from '@angular/common/http';
import { apiUrl } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ExporterServiceService {
  url = `${apiUrl}`;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private http: HttpClient) { }

  getExporterPortDeskTopData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getDashboardPortData", { "data": data }, options);
  }

  getExporterDashboardItemData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getDashboardItemData", { "data": data }, options);
  }
  getExporterCountryDashboardData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getDashboardCountryData", { "data": data }, options);
  }
  getExoprterDashboardExportData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getDashboardExportData", { "data": data }, options);
  }
  getExporterInvoices(startRow, rowNumber, sortField, sortOrder, filters) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getExporterInvoices",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);


    //return response.result

  }
  getInvoiceSetups() {
    return this.http.post(this.url + "invoices/getSetups", {});
  }
  getInvoiceDetail(invoiceId) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/getInvoiceDetail", {
      "id": invoiceId
    }, options);
  }
  getExporters(query) {

    return this.http.post(this.url + "invoices/getExportersSearch", { query: query });
  }
  getManufacturer(query) {
    return this.http.post(this.url + "invoices/getManufacturerSearch", { query: query });
  }
  getCountrySearch(query) {
    return this.http.post(this.url + "invoices/getCountrySearch", { query: query });
  }
  getPortSearch(query) {
    return this.http.post(this.url + "invoices/getPortSearch", { query: query });
  }
  getBuyerSearch(query) {
    return this.http.post(this.url + "invoices/getBuyerSearch", { query: query });
  }
  getPlants(query) {
    return this.http.post(this.url + "invoices/getManufacturerPlants", { query: query });
  }
  saveInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/saveInvoice", { data: data }, options);
  }
  getItems(query) {
    query = query.split("--");
    return this.http.post(this.url + "invoices/getGroupItems", { query: query[0] });
  }
  getItemGrades(query) {

    return this.http.post(this.url + "invoices/getItemGrades", { query: query });
  }
  deleteInvoiceItem(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/deleteItem", { data: data }, options);
  }
  uploadInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadInvoice", data, options);
  }
  getExporterPlants(expCode) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getExporterPlants", { "data": expCode }, options);
  }
  downloadExcel() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/downloadExcel", '', options);

  }
  uploadExcel(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadExcel", data, options);
  }
  forceDownload() {
    return this.http.get(this.url + "exporter/forceDownload");

  }
  uploadSezInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadSezInvoice", data, options);
  }

  uploadInvoiceFiles(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadInvoiceFiles", data, options);
  }


  getItemSearch(val, hscode) {
    let data = {
      "hsCode": hscode,
      "query": val
    }
    console.warn("Data =  ", data);
    return this.http.post(this.url + "invoices/getItemSearch", { query: data });
  }
  getExporterRequest(startRow, rowNumber, sortField, sortOrder, filters) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "request/getExporterRequest",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);


    //return response.result

  }
  saveRequest(formData) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token }),
    }
    return this.http.post(this.url + "request/saveRequest",
      formData, options);
  }
  updateRequest(formData) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token }),
    }
    return this.http.post(this.url + "request/updateRequest",
      formData, options);
  }
  getRequestDetail(Id) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "request/getBranchDetail", { id: Id }, options);
  }
  /*
  saveReplyRequestOld(data) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "request/saveReplyRequest", { data: data }, options);
  }

  */


  saveReplyRequest(formData) {
    console.log("Get Form Data ", formData);
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "request/saveReplyRequest", formData, options);


  }


  getReplyesDetail(id) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "request/getReplyesDetail",
      { id: id }, options);
  }


  refereshRequestList(exporterId) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "request/refereshRequestList",
      {
        "id": exporterId,
      }, options);
  }

  getDeleteReply(Id) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "request/getDeleteReply", { id: Id }, options);
  }

  deleteInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/deleteInvoice", { data: data }, options);

  }


  getExporterBatchList(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getBatchList",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }
  getExporterInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/getExporterInvoice", { data: data }, options);

  }
  saveBatch(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "setup/saveBatch", { data: data }, options);
  }

}
