import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { ExportReviewService } from '../export-review.service';

@Component({
  selector: 'app-country-wise-export-of-frozen-fin-fish',
  templateUrl: './country-wise-export-of-frozen-fin-fish.component.html',
  styleUrls: ['./country-wise-export-of-frozen-fin-fish.component.css']
})
export class CountryWiseExportOfFrozenFinFishComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  targetGroup: any;
  sourceGroup: any;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  page: string;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  showTextBox: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  dateRange: number;
  fragment = '';
  primary: UrlSegmentGroup;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService) { }


  ngOnInit(): void {

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;
    this.page = reportType;

    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      viewDetailReport: [''],
      reportType: [reportType],


    });
    this.targetGroup = [];
    this.primengConfig.ripple = true;
    this.reportTitle = "Country Wise Export Of Frozen fin fish In Value Added Forms";







    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }

  addPort(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  removePort(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  get f() { return this.reportFrm.controls; }


  getReport() {


    this.isloader = true;
    this.submitted = true;

    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = new Date(this.reportFrm.value.dateStart).getFullYear();
    var dt2 = new Date(this.reportFrm.value.dateEnd).getFullYear();
    var yearDifferent = dt2 - dt1;
    this.dateRange = yearDifferent;

    if (yearDifferent > 2) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }

    this._service.getCountryWiseMarineProduct(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;

      console.log(' get country data in console ', items.data);
      if (items.data.reportData.length > 0) {
        for (let i = 0; i < items.data.reportData.length; i++) {
          for (let j = 0; j < items.data.reportData[i].groupItems.length; j++) {
            items.data.reportData[i].groupItems[j].groupName = items.data.reportData[i].name;
            this.detailReportData.push(items.data.reportData[i].groupItems[j]);
          }
        }
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }

      this.reportData = items.data.reportData;

      console.log("----get report data 2 ----", this.reportData);
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }


  // getShare(itemQty,totalQty){
  //   let percentage = itemQty*100/totalQty;
  //   return percentage.toFixed(2);
  // }


  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }

}





