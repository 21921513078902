<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> Invoice Report </h1>
                </div>
            </div>       
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="loadInvoice(lastEvent)"  >
       
        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }" ></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <p-calendar  formControlName="dateEnd"  view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="btn_pan mb-4">
                            <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<section class="innerpage">       

    <div class="innershadow" *ngIf="viewInvoice" >
        <div class="container pt-4">
            <div class="table-responsive">            
            <p-table [value]="data" #dt [columns]="cols" [lazy]="true" (onLazyLoad)="loadInvoice($event)" [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [loading]="loading"  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" pSortableColumn="{{col.header}}" [ngStyle]="{'width': col.width}" >{{col.field}} <p-sortIcon field="{{col.header}}"></p-sortIcon></th>
                        <th style="width:5%"></th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.header, 'startsWith')" placeholder="Search by {{col.field}}" class="p-column-filter" style="width: 100%;" />
                        </th>
                        <th style="width:5%"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns">
                    <tr style="height:34px">
                        <td *ngFor="let col of columns">
                            {{data[col.header]}}
                        </td>
                        <td class="tableicons">                         
                        
                          <a href='http://mpedastat.broadwayinfotech.net.au/mpedapdf/index.php?type=pdf&id={{data.id}}' title="Download invoice" alt="Download invoice">
                            <i class="fa fa-file-pdf-o"></i>
                            </a>
                          </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                         Total  {{totalRecords}} Invoices.
                    </div>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>



</section>
<br>