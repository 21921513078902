import { Component, OnInit } from '@angular/core';
import { SetupServiceService } from '../../setup-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonServiceService } from "../../../../common-service.service";

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent implements OnInit {

  loading: boolean=true;
  data:any;
  datasource:any;

  cols: any[];

  totalRecords: number;
  isPermissionAdd:boolean=false
  isPermissionEdit:boolean=false  
  constructor(private _service:SetupServiceService,private _router:Router,private _commonService:CommonServiceService) { }

  ngOnInit(): void {
    this.cols = [
      {field: 'Id', header: 'id',width: '5%',isSearch:"1"},
      {field: 'Name', header: 'name',width: '8%',isSearch:"1"},
      {field: 'Description', header: 'description',width: '8%',isSearch:"1"},
      {field: 'Status', header: 'status',width: '8%',isSearch:"0"},
      {field: 'Added Date', header: 'added_date',width: '10%',isSearch:"0"},
      {field: 'Modify Date', header: 'modify_date',width: '10%',isSearch:"0"},
    ];
    let parentName = 'Setup';
    let buttons = this._commonService.getActionButton(parentName);
    let addBtn  = buttons.filter(recordAction => recordAction.action_name === "Add");
    if(addBtn.length>0)
    {
      this.isPermissionAdd = true;
    }
    let editBtn  = buttons.filter(recordAction => recordAction.action_name === "Edit");
    if(editBtn.length>0)
    {
      this.isPermissionEdit = true;
    }
  }
  
  loadRoles(event: LazyLoadEvent) {  
    this.loading = true;
    //console.warn(event.filters);
   // return false;
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {  

      this._service.getRoles(event.first,event.rows,event.sortField,event.sortOrder,event.filters).subscribe((items: any) => {
        this.datasource = items.result;
        this.data = items.data;
        this.totalRecords = items.totalRecord;
        this.loading = false;
      },error=>{
         
        if(error instanceof HttpErrorResponse)
        {
          if(error.status===401){
            this._router.navigate(['./']);
          }
        }
      });
    }, 1000);
    
} 
}
