<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">{{ getCustomMessage('dateStart.required')}}
                            </div>
                            <div *ngIf="f.dateStart.errors.pattern">{{ getCustomMessage('dateStart.pattern') }}</div>
                            <div *ngIf="f.dateStart.errors.min">{{ getCustomMessage('dateStart.min') }}</div>
                            <div *ngIf="f.dateStart.errors.max">{{ getCustomMessage('dateStart.max') }}</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">{{ getCustomMessage('dateEnd.required')}}</div>
                            <div *ngIf="f.dateEnd.errors.pattern">{{ getCustomMessage('dateEnd.pattern')}}</div>
                            <div *ngIf="f.dateEnd.errors.min">{{ getCustomMessage('dateEnd.min')}}</div>
                            <div *ngIf="f.dateEnd.errors.max">{{ getCustomMessage('dateEnd.max')}}
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-xl-12 col-md-12 form-group">
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removePort($event)"
                            (onMoveToTarget)="addPort($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </div>













                <div class="row">
                    <div class="btn_pan mb-4">
                        <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>

                </div> -->







                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable>
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Items</th>
                                                <th>{{data[0].range[0]}}</th>
                                                <th *ngIf="dateRange > 0"> {{data[0].range[1]}}</th>
                                                <th *ngIf="dateRange > 1"> {{data[0].range[2]}}</th>
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-items>

                                            <ng-template ngFor let-item [ngForOf]="items.reportItems">
                                                <tr>
                                                    <td>{{item.name}}</td>
                                                    <td>{{getNumber(item.totalQty, 1)}}</td>
                                                    <td *ngIf="dateRange > 0">{{ getNumber(item.totalQty2,1) }} </td>
                                                    <td *ngIf="dateRange > 1">{{ getNumber(item.totalQty3,1) }} </td>
                                                </tr>

                                                <tr>
                                                    <td>Total</td>
                                                    <td>{{item.totalQty.toFixed()}}</td>
                                                    <td *ngIf="dateRange > 0">{{ getNumber(item.totalQty2, 1) }} </td>
                                                    <td *ngIf="dateRange > 1 ">{{getNumber(item.totalQty3,1) }} </td>
                                                </tr>

                                            </ng-template>

                                        </ng-template>

                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td>SubTotal</td>
                                                <td>{{getNumber(data[0].SubTotal[0].SubTotalQty,1)}}</td>
                                                <td *ngIf="dateRange > 0">
                                                    {{getNumber(data[0].SubTotal[0].SubTotalQty2,1)}} </td>
                                                <td *ngIf="dateRange > 1">
                                                    {{getNumber(data[0].SubTotal[0].SubTotalQty3,1)}}
                                                </td>
                                            </tr>
                                        </ng-template>



                                    </p-table>
                                </table>
                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </div>

        <input type="hidden" formControlName="selectedPort" />

    </form>
</div>