import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exporter-dashboard',
  templateUrl: './exporter-dashboard.component.html',
  styleUrls: ['./exporter-dashboard.component.css']
})
export class ExporterDashboardComponent implements OnInit {

  isloader: boolean = false;
  isShowMap: boolean = false;
  finnancialYear: any = [];
  financialYear: string;

  constructor() { }

  ngOnInit(): void {
    this.isloader = false;
    this.isShowMap = true;
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      let diffYear = currentYear - i;
      let lastYear = diffYear - 1;

      let item = {
        "year": lastYear + "-" + diffYear
      }
      if (i == 0) {
        this.financialYear = lastYear + "-" + diffYear;
      }
      this.finnancialYear.push(item);
    }
  }

}
