import { Component, OnInit, Input } from '@angular/core';
import { ExporterServiceService } from '../../exporter-service.service';

@Component({
  selector: 'app-exporter-item-graph',
  templateUrl: './exporter-item-graph.component.html',
  styleUrls: ['./exporter-item-graph.component.css']
})
export class ExporterItemGraphComponent implements OnInit {

  @Input() financialYear: string;
  isloaderItem: boolean = false;
  isShowMapItem: boolean = false;
  dataItem: any;
  itemData: any = [];
  itemGraphType: string;
  titleItem: string;
  optionsItem: any;

  constructor(private _service: ExporterServiceService) { }

  ngOnInit(): void {
    let data = {
      "financialyear": this.financialYear
    }
    this.isloaderItem = true;
    this._service.getExporterDashboardItemData(data).subscribe((itemData: any) => {
      this.itemData = itemData.data.reportObject[0].itemData;
      let itemArray = [];
      for (let i = 0; i < this.itemData.length; i++) {
        let qty = (this.itemData[i].totalQty / 1000).toFixed(2);

        itemArray[i] = [this.itemData[i].GROUP_NAME, parseFloat(qty)]
      }
      this.itemGraphType = 'BarChart';
      this.dataItem = itemArray;
      this.titleItem = "Top 5 Item";
      this.optionsItem = {
        hAxis: {
          title: 'Quantiy in tons'
        },
        vAxis: {
          title: 'Item'
        }
      };


      this.isShowMapItem = true;
      this.isloaderItem = false;
    });
  }
  changeOptionItem(val) {
    if (val == "AreaChart" || val == "ColumnChart") {
      this.optionsItem = {
        hAxis: {
          title: 'Item'
        },
        vAxis: {
          title: 'Quantiy in tons'
        }
      };
    }
    else {
      this.optionsItem = {
        hAxis: {
          title: 'Quantiy in tons'
        },
        vAxis: {
          title: 'Item'
        }
      };
    }
  }
  getGraph(financialYear) {
    this.isloaderItem = true;
    let data = {
      "financialyear": financialYear
    }
    this._service.getExporterDashboardItemData(data).subscribe((itemData: any) => {
      this.itemData = itemData.data.reportObject[0].itemData;
      let itemArray = [];
      for (let i = 0; i < this.itemData.length; i++) {
        let qty = (this.itemData[i].totalQty / 1000).toFixed(2);

        itemArray[i] = [this.itemData[i].GROUP_NAME, parseFloat(qty)]
      }
      this.dataItem = itemArray;
      this.isloaderItem = false;

    });
  }
}
