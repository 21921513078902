import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { PrimeNGConfig,MessageService } from 'primeng/api';
import { ReportSummaryService } from '../report-summary.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { calendarRange } from 'src/environments/environment';

@Component({
  selector: 'app-report-country',
  templateUrl: './report-country.component.html',
  styleUrls: ['./report-country.component.css']
})
export class ReportCountryComponent implements OnInit {

  reportTitle:string;
  reportFrm: FormGroup;
  targetUnion:any;
  sourceUnion:any;
  addedGroups:any=[];
  sourceItems:any;
  addedItems:any=[];
  targetItems:any;
  isloader:boolean=false;
  submitted:boolean=false;
  data:any;
  displayReport:boolean=false;
  reportData:any=[];
  detailReportData:any=[];
  displayDetailedReport:boolean=false;
  calendarRange:string = calendarRange;

  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private formBuilder: FormBuilder,private primengConfig: PrimeNGConfig,private _service:ReportSummaryService,private _router:Router,private messageService: MessageService) { }


  ngOnInit(): void {
    this.reportTitle = "Country Wise ";
    this.reportFrm = this.formBuilder.group({
      dateStart:['',Validators.required],
      dateEnd:['',Validators.required],
      selectedUnion:[''],
      selectedCountry:[''],
      reportType:["regionwise"],
      viewDetailReport:[''],
    }); 

    this.targetUnion = [];
    this.sourceItems = [];
    this.targetItems = [];


    this.primengConfig.ripple = true;
    this._service.getUnion().subscribe((unions: any) => {
      this.sourceUnion = unions.data;
    });

    this.reportFrm.valueChanges.subscribe(val=>{
      if(this.reportData.length>0){
        if(this.reportFrm.value.viewDetailReport){      
            this.displayReport=false;
            this.displayDetailedReport=true;
        }
        else{
          this.displayReport=true;
          this.displayDetailedReport=false;
        }
      }
        
    });
  }


  addUnion(event){
    for(let i=0;i<event.items.length;i++)
    {
      this.addedGroups.push(event.items[i]);
    }
    this.reportFrm.controls.selectedUnion.patchValue(this.addedGroups, {emitEvent: false});
    this.getGroupsItems();
  }

  removeUnion(event){
    for(let i=0;i<event.items.length;i++)
    {
     // this.selectedItem.splice(event.items[i].code);
       // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.addedGroups.splice(this.addedGroups.findIndex(item => item.code == event.items[i].code),1)
    }
    this.reportFrm.controls.selectedUnion.patchValue(this.addedGroups, {emitEvent: false});
    this.getGroupsItems();
  }

  getGroupsItems()
  {
    if(this.addedGroups.length>0)
    {
      this._service.getRegionCountries(this.addedGroups).subscribe((items: any) => {
        this.sourceItems = items.data;
      },error=>{
        
          if(error instanceof HttpErrorResponse)
          {
            if(error.status===401){
              this._router.navigate(['./']);
            }
          }
        });
    }
    else{
      this.sourceItems = [];
    }
  }
  
  addCountry(event){

    for(let i=0;i<event.items.length;i++)
    {
      this.addedItems.push(event.items[i]);
    }
    this.reportFrm.controls.selectedCountry.patchValue(this.addedItems, {emitEvent: false});
  }

  removeCountry(event){

    for(let i=0;i<event.items.length;i++)
    {
     // this.selectedItem.splice(event.items[i].code);
       // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.addedItems.splice(this.addedItems.findIndex(item => item.code == event.items[i].code),1)
    }
    this.reportFrm.controls.selectedCountry.patchValue(this.addedItems, {emitEvent: false});
  }
  
  getShare(itemQty,totalQty){
    let percentage = itemQty*100/totalQty;
    return percentage.toFixed(2);
  }


  get f() { return this.reportFrm.controls; }
  getReport()
  {
      this.isloader = true;
      this.submitted = true;
      // stop here if form is invalid
      if (this.reportFrm.invalid) {
        this.isloader = false;
        return;
      }
      if(this.reportFrm.value.selectedCountry=="")
      {
        this.messageService.add({severity:'error', summary: 'Fail', detail: "Please Select Country", life: 6000}); //success,info,warn,error
        this.isloader = false;
        return false;
      }
      this._service.getCountryWiseReport(this.reportFrm.value).subscribe((items: any) =>{
          this.data = items.data;
          if(items.data.reportData.length>0)
          {
            for(let i=0;i<items.data.reportData.length;i++)
            {
              for(let j=0;j<items.data.reportData[i].groupItems.length;j++)
              {       
                items.data.reportData[i].groupItems[j].groupName=items.data.reportData[i].groupName;
                this.detailReportData.push(items.data.reportData[i].groupItems[j]);
              }
            }
            this.displayReport=true;
            this.isloader = false;
          }
          else{
            this.displayReport=false;
            this.isloader = false;
          }
          this.reportData = items.data.reportData;
          
      });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Country');
    // save to file
     xlsx.writeFile(workbook, "country_report.csv");
      
    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Country');
    // save to file
     xlsx.writeFile(workbook, "country_report.xlsx");
     
    });
  }

  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Country');
    // save to file
     xlsx.writeFile(workbook, "country_report_detail.xlsx");
 
    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Country');
    // save to file
     xlsx.writeFile(workbook, "country_report_detail.csv");
 
    });
  }
}
