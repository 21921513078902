<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt="">Exporter Sync</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="syncExporters()">


        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" yearRange="{{calendarRange}}"
                            dateFormat="mm/yy" [yearNavigator]="true" [readonlyInput]="true" inputId="monthpicker"
                            [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>

                    </div>
                </div>
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Sort By </label>
                    </div>
                    <div class="col form-group">
                        <select class="form-control" formControlName="category"
                            [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                            <option value="">Select</option>
                            <option value="NED">NED</option>
                            <option value="UED">UED</option>
                            <option value="DED">DED</option>

                        </select>
                        <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                            <div *ngIf="f.category.errors.required">Please select category
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="btn_pan mb-4">

                        <button type="button" class="btn btn-success" (click)="getExporters()"><i
                                class="fa fa-save"></i>Get
                            Exporter</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<section class="innerpage">
    <div class="innershadow">
        <div class="container pt-4">

            <p-table [value]="exporterData" dataKey="RegNo" editMode="row">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Registration #</th>
                        <th>Registration Date</th>
                        <th>Expiry Date</th>
                        <th>Exporter Name</th>
                        <th>Contact Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>IE Code</th>
                        <th>Record Found</th>
                        <th>Exporter Master </th>
                        <th style="width:8rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-exporter let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="exporter">
                        <td>
                            {{exporter.RegNo}}
                        </td>
                        <td>
                            {{exporter.RegDate}}
                        </td>
                        <td>{{exporter.ExpDate}}</td>
                        <td>{{exporter.ExporterName}}</td>
                        <td>{{exporter.contactName}}</td>
                        <td>{{exporter.mobile}}</td>
                        <td>{{exporter.email}}</td>
                        <td>{{exporter.IECode}}</td>
                        <td *ngIf="exporter.exporterDetail"><strong>Matched</strong></td>
                        <td *ngIf="!exporter.exporterDetail">No</td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <select [(ngModel)]="exporter.id" class="form-control">
                                        <option value=''>Select</option>
                                        <option *ngFor="let exp of exporters; let i = index" value='{{exp.id}}'>
                                            {{exp.name}}</option>
                                    </select>
                                </ng-template>
                                <ng-template pTemplate="output" *ngIf="exporter.exporterDetail">
                                    {{exporter.exporterDetail.EXP_NAME}}
                                </ng-template>
                                <ng-template pTemplate="output" *ngIf="!exporter.exporterDetail">
                                    &nbsp;
                                </ng-template>
                            </p-cellEditor>
                        </td>


                        <td style="text-align:center">
                            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                                (click)="onRowEditInit(exporter)" class="p-button-rounded p-button-text"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                (click)="onRowEditSave(exporter)"
                                class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                                (click)="onRowEditCancel(exporter, ri)"
                                class="p-button-rounded p-button-text p-button-danger"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</section>

<br>