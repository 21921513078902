import { Component, OnInit, Input } from '@angular/core';
import { ExporterServiceService } from '../../exporter-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-exporter-export-graph',
  templateUrl: './exporter-export-graph.component.html',
  styleUrls: ['./exporter-export-graph.component.css']
})
export class ExporterExportGraphComponent implements OnInit {

  @Input() financialYear: string

  isloaderExport: boolean = false;
  isShowMapExport: boolean = false;
  dataExport: any;
  exportData: any = [];
  exportGraphType: string;
  titleExport: string;
  optionsExport: any;

  constructor(private _service: ExporterServiceService, private _router: Router) { }

  ngOnInit(): void {
    this.isloaderExport = true;
    let data = {
      "financialyear": this.financialYear
    }


    this._service.getExoprterDashboardExportData(data).subscribe((portData: any) => {
      this.exportData = portData.data.reportObject[0].exportData;
      let exportArray = [];
      for (let i = 0; i < this.exportData.length; i++) {
        let qty = (this.exportData[i].monthData[0].totalQty / 1000).toFixed(2);
        exportArray[i] = [this.exportData[i].monthName, parseFloat(qty)]
      }
      this.exportGraphType = 'LineChart';
      this.dataExport = exportArray;
      this.titleExport = "Monthwise Export Quantity";
      this.optionsExport = {
        hAxis: {
          title: 'Quantiy in tons'
        },
        vAxis: {
          title: 'Month'
        }
      };


      this.isShowMapExport = true;
      this.isloaderExport = false;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./exporter/login']);
        }
      }
    });
  }
  changeOptionExport(val) {
    if (val == "AreaChart" || val == "ColumnChart") {
      this.optionsExport = {
        hAxis: {
          title: 'Month'
        },
        vAxis: {
          title: 'Quantiy in tons'
        }
      };
    }
    else {
      this.optionsExport = {
        hAxis: {
          title: 'Quantiy in tons'
        },
        vAxis: {
          title: 'Month'
        }
      };
    }
  }
  getGraph(financialYear) {
    this.isloaderExport = true;
    let data = {
      "financialyear": financialYear
    }
    this._service.getExoprterDashboardExportData(data).subscribe((portData: any) => {
      this.exportData = portData.data.reportObject[0].exportData;
      let exportArray = [];
      for (let i = 0; i < this.exportData.length; i++) {
        let qty = (this.exportData[i].monthData[0].totalQty / 1000).toFixed(2);
        exportArray[i] = [this.exportData[i].monthName, parseFloat(qty)]
      }
      this.dataExport = exportArray;
      this.isloaderExport = false;

    });
  }
}
