import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { SetupServiceService } from '../setup-service.service';
import { calendarRange } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reconcile-final-add',
  templateUrl: './reconcile-final-add.component.html',
  styleUrls: ['./reconcile-final-add.component.css']
})
export class ReconcileFinalAddComponent implements OnInit {

  cols: any[];

  recocileBatchFrm: FormGroup;
  calendarRange: string = calendarRange;
  submitted: boolean = false;
  isloader: boolean = false;
  data: any;
  totalRecords: any;
  quantityOption: number = 1;
  amountOption: number = 0;
  quantityTotal: any;
  amountTotal: any;
  amountTotalRs: any;
  exportedData: any;
  branchDate: any;
  constructor(private formBuilder: FormBuilder, private _service: SetupServiceService, private messageService: MessageService, private route: ActivatedRoute, private _router: Router) { }

  ngOnInit(): void {

    this.cols = [
      { field: 'Branch Id', header: 'branchId', width: '12%', type: 'text' },
      { field: 'Branch Name', header: 'branchName', width: '12%', type: 'text' },
      { field: 'Branch Code', header: 'branchCode', width: '12%', type: 'text' },
      { field: 'branch Email', header: 'branchEmail', width: '10%', type: 'text' },
      { field: 'Qty(KG)', header: 'branchTotalQty', width: '10%', type: 'text' },
      { field: 'Amount($)', header: 'totalAmountDollar', width: '10%', type: 'text' },
      { field: 'Amount(₹)', header: 'totalAmountRs', width: '10%', type: 'text' },

    ];

    this.recocileBatchFrm = this.formBuilder.group({
      batchId: '',
      batchName: ['', Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      totalQuantity: [''],
      totaAmountDollar: [''],
      totaAmountRs: [''],
      status: '1',
      invoiceIds: [''],
      declaration: [false],
      branchData: [''],

    });

    this.route.params.subscribe(params => {
      if (params['id'] != "" && typeof params['id'] !== "undefined") {
        this._service.getFinalReconcileDetail(params['id']).subscribe((item: any) => {
          this.recocileBatchFrm.setValue({
            batchId: item.data.id,
            batchName: item.data.title,
            dateStart: item.data.startDate,
            dateEnd: item.data.endDate,
            totalQuantity: [''],
            totaAmountDollar: [''],
            totaAmountRs: [''],
            status: '1',
            invoiceIds: [''],
            declaration: false,
            branchData: [''],

          });
          this.getDetail();
        });

      }
    });
  }
  get f() { return this.recocileBatchFrm.controls; }

  getDetail() {
    this.totalRecords = 0;
    this.submitted = true;

    // stop here if form is invalid
    if (this.recocileBatchFrm.invalid) {
      return;
    }
    this.isloader = true;

    this._service.getReconcilationDetail(this.recocileBatchFrm.value).subscribe((item: any) => {
      this.isloader = false;
      this.totalRecords = item.data.length;
      this.data = item.data;
      this.quantityTotal = 0;
      this.amountTotal = 0;
      this.amountTotalRs = 0;
      this.exportedData = [];
      for (let i = 0; i < this.data.length; i++) {
        this.quantityTotal = this.quantityTotal + this.data[i].branchTotalQty;
        this.amountTotal = this.amountTotal + this.data[i].totalAmountDollar;
        this.amountTotalRs = this.amountTotalRs + this.data[i].totalAmountRs;

        let item = {
          "Branch Id": this.data[i].branchId,
          "Branch Name": this.data[i].branchName,
          "Branch Code": this.data[i].branchCode,
          "Branch Email": this.data[i].branchEmail,
          "Qty(KG)": this.data[i].branchTotalQty,
          "Amount($)": this.data[i].totalAmountDollar,
          "Amount(₹)": this.data[i].totalAmountRs,
        }
        this.exportedData.push(item);
      }

      this.recocileBatchFrm.controls.totalQuantity.patchValue(this.quantityTotal);
      this.recocileBatchFrm.controls.totaAmountDollar.patchValue(this.quantityTotal);
      this.recocileBatchFrm.controls.totaAmountRs.patchValue(this.amountTotalRs);
      this.recocileBatchFrm.controls.branchData.patchValue(this.data);


    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }
  getQuantityOption($event) {
    this.quantityOption = $event.target.value;
  }
  getAmountOption($event) {
    this.amountOption = $event.target.value;
  }
  exportShippingBillCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      // const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      const ws = xlsx.utils.json_to_sheet(this.exportedData);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "reconcileSB.csv");

    });
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.json_to_sheet(this.exportedData);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "reconcileSB.xlsx");

    });
  }
  saveRecocile(statusVal) {
    this.recocileBatchFrm.controls.status.patchValue(statusVal);
    // stop here if form is invalid
    this.isloader = false;

    if (this.recocileBatchFrm.invalid) {
      this.isloader = false;
      return;
    }
    if (this.recocileBatchFrm.value.status == 2 && !this.recocileBatchFrm.value.declaration) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select declaration ", life: 6000 }); //
      this.isloader = false;

      return false;
    }
    this._service.saveFinalReconcilation(this.recocileBatchFrm.value).subscribe((items: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //
      this.isloader = false;



    })
  }
}
