import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetupServiceService } from '../../setup-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.css']
})
export class RolePermissionComponent implements OnInit {

  rolesPermissionFrm: FormGroup;
  pageHeading: string;
  submitted: boolean = false;
  menus: any = [];
  selectedItem: any = [];
  selectedAction: any = [];
  constructor(private formBuilder: FormBuilder, private _service: SetupServiceService, private route: ActivatedRoute, private messageService: MessageService, private _router: Router) { }

  ngOnInit(): void {


    this.route.params.subscribe(params => {
      if (params['id'] != "" && typeof params['id'] !== "undefined") {
        this.pageHeading = "Edit Role And Permission  ";
        this._service.getRoleDetail(params['id']).subscribe((item: any) => {
          let roles = item.data[0].roles;
          let menus = roles.filter(record => record.action_id === 0);
          for (let i = 0; i < menus.length; i++) {
            this.selectedItem.push(menus[i].menu_id);
          }
          let action = roles.filter(record => record.action_id !== 0);

          for (let i = 0; i < action.length; i++) {
            let checkboxVal = action[i].menu_id + "-" + action[i].action_id;
            this.selectedAction.push(checkboxVal);
          }
          this.rolesPermissionFrm.setValue({
            roleId: item.data[0].id,
            name: item.data[0].name,
            description: item.data[0].description,
            status: item.data[0].status,
            selectedMenus: this.selectedItem,
            selectedActions: this.selectedAction,
          });
          this.getMenus();
        });
      }
      else {
        this.getMenus();
      }
    });

    this.pageHeading = "Add Roles And Permission";
    this.rolesPermissionFrm = this.formBuilder.group({
      roleId: '',
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.required, Validators.maxLength(255)]],
      status: ['', Validators.required],
      selectedMenus: [],
      selectedActions: [],
    });





  }

  getMenus() {
    this._service.getMenus().subscribe((responseData: any) => {
      let menusList = responseData.data;
      let actionList = responseData.data.actionData;
      for (let i = 0; i < menusList.data.length; i++) {

        if (menusList.data[i].parent_id == 0) {
          let item = menusList.data.filter(record => record.parent_id === menusList.data[i].id);
          let actionItem = actionList.filter(recordAction => recordAction.menu_id === menusList.data[i].id);


          for (let j = 0; j < item.length; j++) {
            let submenuItems = menusList.data.filter(record => record.parent_id === item[j].id);

            let subActionItem = actionList.filter(recordAction => recordAction.menu_id === item[j].id);
            item[j].actions = subActionItem;
            item[j].submenus = submenuItems;
          }


          menusList.data[i].submenus = item;
          menusList.data[i].actions = actionItem;
          this.menus.push(menusList.data[i]);
        }
      }
      for (let i = 0; i < this.menus.length; i++) {

        let menuId = this.menus[i].id;
        console.warn(this.menus[i]);
        if (this.menus[i].actions.length > 0) {
          for (let l = 0; l < this.menus[i].actions.length; l++) {
            let actionId = menuId + "-" + this.menus[i].actions[l].id;
            let isSelected = this.selectedAction.includes(actionId);
            if (isSelected) {
              this.menus[i].actions[l].isChecked = "checked";
            }
            else {
              this.menus[i].actions[l].isChecked = "";
            }
          }
        }

        let isSelected = this.selectedItem.includes(menuId);
        if (isSelected) {
          this.menus[i].isChecked = "checked";
        }
        else {
          this.menus[i].isChecked = "";
        }

        if (this.menus[i].submenus.length > 0) {
          for (let j = 0; j < this.menus[i].submenus.length; j++) {
            let menuId = this.menus[i].submenus[j].id;
            let isSelected = this.selectedItem.includes(menuId);
            if (isSelected) {
              this.menus[i].submenus[j].isChecked = "checked";
            }
            else {
              this.menus[i].submenus[j].isChecked = "";
            }
            if (this.menus[i].submenus[j].submenus.length > 0) {
              for (let k = 0; k < this.menus[i].submenus[j].submenus.length; k++) {
                let menuId = this.menus[i].submenus[j].submenus[k].id;
                let isSelected = this.selectedItem.includes(menuId);
                if (isSelected) {
                  this.menus[i].submenus[j].submenus[k].isChecked = "checked";
                }
                else {
                  this.menus[i].submenus[j].submenus[k].isChecked = "";
                }

              }
            }
            if (this.menus[i].submenus[j].actions.length > 0) {
              for (let l = 0; l < this.menus[i].submenus[j].actions.length; l++) {
                let actionId = menuId + "-" + this.menus[i].submenus[j].actions[l].id;
                let isSelected = this.selectedAction.includes(actionId);
                if (isSelected) {
                  this.menus[i].submenus[j].actions[l].isChecked = "checked";
                }
                else {
                  this.menus[i].submenus[j].actions[l].isChecked = "";
                }
              }
            }
          }
        }
      }
      //console.warn("Menu Length ",this.menus);

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }

    });
  }

  get f() { return this.rolesPermissionFrm.controls; }

  saveRoles() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.rolesPermissionFrm.invalid) {
      return;
    }
    this._service.saveRoles(this.rolesPermissionFrm.value).subscribe((items: any) => {
      this.submitted = false;
      if (!items.data.success) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.data.message, life: 6000 }); //
      }
      else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: items.data.message, life: 6000 }); //
      }
      return false;
    });


    console.warn(this.rolesPermissionFrm.value);
  }

  onCheckChange(event) {

    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.selectedItem.push(event.target.value);
      this.rolesPermissionFrm.controls.selectedMenus.patchValue(this.selectedItem, { emitEvent: false });
    }
    else {
      this.selectedItem.splice(this.selectedItem.findIndex(item => item == event.target.value), 1)
      this.rolesPermissionFrm.controls.selectedMenus.patchValue(this.selectedItem, { emitEvent: false });
    }
  }
  onCheckActionChange(event) {

    let checkboxVal = event.target.id + "-" + event.target.value;
    if (event.target.checked) {
      // Add a new control in the arrayForm

      this.selectedAction.push(checkboxVal);
      this.rolesPermissionFrm.controls.selectedActions.patchValue(this.selectedAction, { emitEvent: false });
    }
    else {

      this.selectedAction.splice(this.selectedAction.findIndex(item => item == checkboxVal), 1)
      this.rolesPermissionFrm.controls.selectedActions.patchValue(this.selectedAction, { emitEvent: false });
    }
  }

  checkPermission(menuId) {
    console.warn("In here");
    return "checked";
  }
}
