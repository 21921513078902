import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ExportReviewService } from '../export-review.service';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-monthly-export-items',
  templateUrl: './monthly-export-items.component.html',
  styleUrls: ['./monthly-export-items.component.css']
})

export class MonthlyExportItemsComponent implements OnInit {

  reportTitle: string;
  reportFrm: FormGroup;
  sourceGroup: any;
  targetGroup: any;
  sourceItems: any;
  targetItems: any;
  addedGroups: any = [];
  isloader: boolean = false;
  displayReport: boolean = false;
  submitted: boolean = false;
  addedItems: any = [];
  reportData: any = [];
  reprttDate: any;
  data: any = {};
  detailReportData: any = [];
  displayDetailedReport: boolean = false;
  yearRange: any = [];
  variationsRange: any = [];
  headerLength: any;
  headerLength2: any;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;

  constructor(private formBuilder: FormBuilder, private _service: ExportReviewService, private primengConfig: PrimeNGConfig, private _router: Router, private messageService: MessageService, private commonService: CommonServiceService) { }

  ngOnInit(): void {
    this.reportTitle = "Month Wise Item";

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;


    this.reportFrm = this.formBuilder.group({
      dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      month: ['', Validators.required],
      selectedGroups: [''],
      reportType: ["items"],
      selectedItems: [''],
      viewDetailReport: [''],
    });

    this._service.getGroups().subscribe((items: any) => {
      this.sourceGroup = items.data;
    });

    this.targetGroup = [];
    this.sourceItems = [];
    this.targetItems = [];
    this.primengConfig.ripple = true;

    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }

  addItems(event) {

    for (let i = 0; i < event.items.length; i++) {
      this.addedItems.push(event.items[i]);
    }
    this.reportFrm.controls.selectedItems.patchValue(this.addedItems, { emitEvent: false });
  }
  addChecksums(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.addedGroups.push(event.items[i]);
    }
    this.reportFrm.controls.selectedGroups.patchValue(this.addedGroups, { emitEvent: false });
    this.getGroupsItems();
  }
  removeGroups(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.addedGroups.splice(this.addedGroups.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedGroups.patchValue(this.addedGroups, { emitEvent: false });
    this.getGroupsItems();
  }

  removeItems(event) {

    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.addedItems.splice(this.addedItems.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedItems.patchValue(this.addedItems, { emitEvent: false });
  }
  getGroupsItems() {

    if (this.addedGroups.length > 0) {
      this._service.getGroupItems(this.addedGroups).subscribe((items: any) => {
        this.sourceItems = items.data;
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });
    }
    else {
      this.sourceItems = [];
    }
  }
  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }
  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }



  getReport() {
    this.isloader = true;
    this.submitted = true;
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    var yearDifferent = dt2 - dt1;

    if (dt2 < dt1) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select correct year range", life: 6000 });
      this.isloader = false;
      return false;
    }


    if (yearDifferent > 4) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 5 Year", life: 6000 });
      this.isloader = false;
      return false;
    }



    if (this.reportFrm.value.selectedItems == "") {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Group Items", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }
    this._service.getItemwiseMonthlyReport(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      if (items.data.reportData.length > 0) {
        for (let i = 0; i < items.data.reportData.length; i++) {
          for (let j = 0; j < items.data.reportData[i].groupItems.length; j++) {
            items.data.reportData[i].groupItems[j].groupName = items.data.reportData[i].groupName;
            this.detailReportData.push(items.data.reportData[i].groupItems[j]);
          }
        }
        this.displayReport = true;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
      this.yearRange = items.data.reportData[0].groupItems[0].years;
      this.variationsRange = items.data.reportData[0].groupItems[0].variationsRange;
      this.headerLength = parseInt(items.data.reportData[0].groupItems[0].years.length) * 3 + 2;

      this.headerLength2 = parseInt(items.data.reportData[0].groupItems[0].years.length) + 2;

      console.log("Header Length :", this.headerLength);



      console.log("header Length :", items.data.reportData[0].groupItems[0].years.length);

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
  }
  getShare(itemQty, totalQty) {
    let percentage = itemQty * 100 / totalQty;
    return percentage ? percentage.toFixed(2) : 0;
  }

  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Region Data');
      // save to file
      xlsx.writeFile(workbook, "region_report.xlsx");

    });
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "item_report_detail.csv");

    });
  }
}
