<div class="loader2" *ngIf="isloaderExport"><img src="assets/img/loader.svg" alt=""></div>
<div class="db_selop">
    <span class="gr_tx">Export Quantity</span>
    <select class="form-control" #mySelectExport [(ngModel)]="exportGraphType"
        (change)="changeOptionExport(mySelectExport.value)">
        <option value="PieChart">Pie Chart </option>
        <option value="AreaChart">Area Chart </option>
        <option value="ColumnChart">Column Chart </option>
        <option value="LineChart">Line Chart </option>


    </select>
</div>
<ng-template [ngIf]="isShowMapExport">
    <google-chart [type]="exportGraphType" [title]="titleExport" [data]="dataExport" style="width: 100%; height: 700px;"
        [options]="optionsExport"></google-chart>
</ng-template>