<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} Report </h1>
                </div>
            </div>       
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">
       
        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>

                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4"  />
                        
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>

                    <!-- <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }" ></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <p-calendar  formControlName="dateEnd"  view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div> -->
                 </div>

                


               
                
                
                
                <div class="row">
                    <div class="btn_pan mb-4">
                            <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>    
                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                </div> -->


                <div *ngIf="page=='marin-product-export-from-india'">
                    <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                        <span><b>Q</b>: Quantity in Kg.</span>
                        <span><b>V</b>: Value in Rs.</span>
                        <span><b>$</b>: Value in doller.</span>
                    </div>
                </div>

                <div *ngIf="page=='percentage-marin-product-export-from-india'">
                    <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                        <span><b>Q</b>: Quantity in Kg.</span>
                        <span><b>V</b>: Value in Rs.</span>
                        <span><b>$</b>: Value in doller.</span>
                    </div>
                </div>

                <div *ngIf="page=='average-unit-value-realization'">
                    <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                        <span> Value in Rs / Kg</span>
                    </div>
                </div>

                
                <div class="card" *ngIf="displayDetailedReport">
                    <table #detailTable>  
                    <p-table [value]="detailReportData">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportDetailExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th colspan="1">&nbsp;</th>
                                <th colspan="1">&nbsp;</th>
                                
                                <th colspan="3">{{data.dateRange1}}</th>
                                
                                <th colspan="3">{{data.dateRange2}}</th>
                                
                                
                            </tr>
                            <tr>
                                <th >Region </th>
                                <th pSortableColumn="name">Country <p-sortIcon field="name"></p-sortIcon></th>
                                <th >Q </th>
                                <th >V </th>
                                <th >$ </th>
                        
                                <th pSortableColumn="Qtyintons">Q <p-sortIcon field="Qtyintons"></p-sortIcon></th>
                                <th pSortableColumn="FobRsCr">V <p-sortIcon field="FobRsCr"></p-sortIcon></th>
                                <th pSortableColumn="FobDolMil">$ <p-sortIcon field="FobDolMil"></p-sortIcon></th>
                        
                                
                                
                        </ng-template>
                        
                            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                            <tr>
                                <td ><strong>{{item.groupName}}</strong></td>
                                <td >{{item.itemName}}</td>
                                
                                <td>{{getNumber(item.Qtyintons, 2)}} </td>
                                <td>{{getNumber(item.FobRsCr, 2)}}   </td>
                                <td>{{getNumber(item.FobDolMil, 2)}} </td>
                                <td>{{getNumber(item.QtyPreTon, 2)}} </td>
                                <td>{{getNumber(item.FobRsPreCr, 2)}} </td>
                                <td>{{getNumber(item.FobDolPreMil, 2)}}</td>
                                
                            </tr>
                            </ng-template>
                            
                        
                        <ng-template pTemplate="footer">
                            <tr>
                                <td >Sub Total</td>
                                <td >&nbsp;</td>
                              
                                <td > {{getNumber(data.totalQuantity, 2)}} </td>
                                <td>  {{data.totalPriceRs}}  </td>
                                <td>  {{data.totalPriceDol}} </td>

                                <td > {{getNumber(data.totalQuantityPrev, 2)}} </td>
                                <td>{{data.totalPriceRsPrev}}  </td>
                                <td>{{data.totalPriceDolPrev}} </td>

                            </tr>
                            </ng-template>
                    </p-table>
                    </table>
                </div>



                                
                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage" *ngIf="page=='marin-product-export-from-india'">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">

                                <table #userTable *ngIf="dateRange==0"> 
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Country </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="3" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td > {{getNumber(item.Qtyintons, 2)}} </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td> {{getNumber(item.FobRsCr, 2)}} 
                                                        </td>
                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <td> {{getNumber(item.FobDolMil, 2)}} </td>
                                                </tr>
                                        
                                            
                                            </ng-template>


                                        
                                            
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td > {{getNumber(data.totalQuantity, 2)}} </td>
                                            
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>{{data.totalPriceRs}}</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>{{data.totalPriceDol}}</td>
                                            </tr>

                                        
                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==1"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Country </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="4" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td >{{getNumber(item.Qtyintons, 2)}} </td>
                                                    <td >{{getNumber(item.QtyPreTon, 2)}}</td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td> {{getNumber(item.FobRsCr, 2)}} </td>
                                                    <td>
                                                        {{getNumber(item.FobRsPreCr, 2)}} 
                                                    </td>
                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <td> {{getNumber(item.FobDolMil, 2)}} </td>
                                                    <td> {{getNumber(item.FobDolPreMil, 2)}}</td>
                                                </tr>
                                        
                                            
                                            </ng-template>


                                        
                                        
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td > {{getNumber(data.totalQuantity, 2)}}  </td>
                                                <td > {{getNumber(data.totalQuantityPrev, 2)}} </td>
                                                
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>{{data.totalPriceRs}}</td>
                                                <td>{{data.totalPriceRsPrev}}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>{{data.totalPriceDol}}</td>
                                                <td>{{data.totalPriceDolPrev}}</td>
                                            </tr>
                                            
                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==2"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Item </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                <th >{{data.dateRange3}}</th>
                                                
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="5" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                            
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td > {{getNumber(item.Qtyintons, 2)}} </td>
                                                    <td > {{getNumber(item.QtyPreTon, 2)}}</td>
                                                    <td > {{getNumber(item.QtyPre2Ton, 2)}}</td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td> {{getNumber(item.FobRsCr, 2)}} </td>
                                                    <td> {{getNumber(item.FobRsPreCr, 2)}}</td>
                                                    <td> {{getNumber(item.FobRsPre2Cr, 2)}}</td>
                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <td> {{getNumber(item.FobDolMil, 2)}} </td>
                                                    <td> {{getNumber(item.FobDolPreMil, 2)}}</td>
                                                    <td> {{getNumber(item.FobDolPre2Mil, 2)}} </td>
                                                    
                                                </tr>
                                            </ng-template>
                                        
                                                
                                                
                                        


                                            
                                        
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td > {{getNumber(data.totalQuantity, 2)}} </td>
                                                <td >  {{getNumber(data.totalQuantityPrev, 2)}} </td>
                                                <td > {{getNumber(data.totalQuantityPrev2, 2)}} </td>
                                                
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>{{data.totalPriceRs}}</td>
                                                <td>{{data.totalPriceRsPrev}}</td>
                                                <td>{{data.totalPriceRsPrev2}} </td>
                                                
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>{{data.totalPriceDol}}</td>
                                                <td>{{data.totalPriceDolPrev}}</td>
                                                <td>{{data.totalPriceDolPrev2}} </td>
                                                
                                            </tr>
                                        
                                        </ng-template>
                                    </p-table>
                                </table>
                                
                                
                            </div>
                        </div>
                    </section>

                    <section class="innerpage" *ngIf="page=='percentage-marin-product-export-from-india'">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">

                                <table #userTable *ngIf="dateRange==0"> 
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Country </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="3" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td >{{ getShare(item.Qtyintons,data.totalQuantity) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td >{{ getShare(item.FobRsCr,data.totalPriceRs) }}</td>
                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <td >{{ getShare(item.FobDolMil,data.totalPriceDol) }}</td>
                                                </tr>
                                        
                                            
                                            </ng-template>


                                        
                                            
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td >100</td>
                                            
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>100</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>100</td>
                                            </tr>

                                        
                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==1"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Country </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="4" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td >{{ getShare(item.Qtyintons,data.totalQuantity) }}</td>
                                                    <td >{{ getShare(item.QtyPreTon,data.totalQuantityPrev) }} </td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td >{{ getShare(item.FobRsCr,data.totalPriceRs) }}</td>
                                                    <td >{{ getShare(item.FobRsPreCr,data.totalPriceRsPrev) }} </td>

                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <!-- <td>{{item.FobDolMil.toFixed(2)}}</td>
                                                    <td>{{item.FobDolPreMil.toFixed(2)}}</td> -->
                                                    <td >{{ getShare(item.FobDolMil,data.totalPriceDol) }}</td>
                                                    <td >{{ getShare(item.FobDolPreMil,data.totalPriceDolPrev) }} </td>
                                                </tr>
                                        
                                            
                                            </ng-template>


                                        
                                        
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td >100</td>
                                                <td >100</td>
                                                
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>100</td>
                                                <td>100</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>100</td>
                                                <td>100</td>
                                            </tr>
                                            
                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==2"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Item </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                <th >{{data.dateRange3}}</th>
                                                
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="5" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                            
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td >{{ getShare(item.Qtyintons,data.totalQuantity) }}</td>
                                                    <td >{{ getShare(item.QtyPreTon,data.totalQuantityPrev) }} </td>
                                                    <td >{{ getShare(item.QtyPre2Ton,data.totalQuantityPrev2) }} </td>
                                                    <!-- <td >{{item.Qtyintons.toFixed(2)}}</td>
                                                    <td >{{item.QtyPreTon.toFixed(2)}}</td>
                                                    <td >{{item.QtyPre2Ton.toFixed(2)}}</td> -->
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td >{{ getShare(item.FobRsCr,data.totalPriceRs) }}</td>
                                                    <td >{{ getShare(item.FobRsPreCr,data.totalPriceRsPrev) }} </td>
                                                    <td >{{ getShare(item.FobRsPre2Cr,data.totalPriceRsPrev2) }} </td>

                                                    <!-- <td>{{item.FobRsCr.toFixed(2)}}</td>
                                                    <td>{{item.FobRsPreCr.toFixed(2)}}</td>
                                                    <td>{{item.FobRsPre2Cr.toFixed(2)}}</td> -->
                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <td >{{ getShare(item.FobDolMil,data.totalPriceDol) }}</td>
                                                    <td >{{ getShare(item.FobDolPreMil,data.totalPriceDolPrev) }} </td>
                                                    <td >{{ getShare(item.FobDolPre2Mil,data.totalPriceDolPrev2) }} </td>

                                                    <!-- <td>{{item.FobDolMil.toFixed(2)}}</td>
                                                    <td>{{item.FobDolPreMil.toFixed(2)}}</td>
                                                    <td>{{item.FobDolPre2Mil.toFixed(2)}}</td> -->
                                                    
                                                </tr>
                                            </ng-template>
                                        
                                                
                                                
                                        


                                            
                                        
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td >  {{getNumber(data.totalQuantity, 2)}}  </td>
                                                <td >  {{getNumber(data.totalQuantityPrev, 2)}}</td>
                                                <td >  {{getNumber(data.totalQuantityPrev2, 2)}}</td>
                                                
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>{{data.totalPriceRs}}</td>
                                                <td>{{data.totalPriceRsPrev}}</td>
                                                <td>{{data.totalPriceRsPrev2}} </td>
                                                
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>{{data.totalPriceDol}}</td>
                                                <td>{{data.totalPriceDolPrev}}</td>
                                                <td>{{data.totalPriceDolPrev2}} </td>
                                                
                                            </tr>
                                        
                                        </ng-template>
                                    </p-table>
                                </table>
                                
                                
                            </div>
                        </div>
                    </section>

                    <section class="innerpage" *ngIf="page=='average-unit-value-realization'">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">

                                <table #userTable *ngIf="dateRange==0"> 
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Country </th>
                                                <th >{{data.dateRange1}}</th>
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="2" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td >{{ getUnitPerKg(item.Qtyintons,item.FobRsCr) }}</td>
                                                </tr>
                                                
                                            </ng-template>


                                        
                                            
                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==1"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Country </th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="3" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    
                                                    <td > {{ getUnitPerKg(item.Qtyintons,item.FobRsCr) }}</td>
                                                    <td >  {{ getUnitPerKg(item.QtyPreTon,item.FobRsPreCr) }} </td>
                                                </tr>
                                            </ng-template>
                                        </ng-template>
                                       
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==2"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Item </th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                <th >{{data.dateRange3}}</th>
                                                
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            <tr>
                                                <td colspan="4" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                            </tr>   
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                            
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                   

                                                    <td > {{ getUnitPerKg(item.Qtyintons,item.FobRsCr) }}</td>
                                                    <td > {{ getUnitPerKg(item.QtyPreTon,item.FobRsPreCr) }} </td>
                                                    <td > {{ getUnitPerKg(item.QtyPre2Ton,item.FobRsPre2Cr) }} </td>

                                                </tr>

                                                
                                            </ng-template>
                                        
                                         </ng-template>
                                        
                                    </p-table>
                                </table>
                                
                                
                            </div>
                        </div>
                    </section>

                    <section class="innerpage" *ngIf="page=='marin-product-share-in-our-export'">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">

                                <table #userTable *ngIf="dateRange==0"> 
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Major Importers </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                            
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td >{{ getShare(item.Qtyintons,data.totalQuantity) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td >{{ getShare(item.FobRsCr,data.totalPriceRs) }}</td>
                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <td >{{ getShare(item.FobDolMil,data.totalPriceDol) }}</td>
                                                </tr>
                                        
                                            
                                            </ng-template>


                                        
                                            
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td >100</td>
                                            
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>100</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>100</td>
                                            </tr>

                                        
                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==1"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Major Importers </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                             
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                                <!-- Start Range - 1 Data -->
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td >{{ getShare(item.Qtyintons,data.totalQuantity) }}</td>
                                                    <td >{{ getShare(item.QtyPreTon,data.totalQuantityPrev) }} </td>
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td >{{ getShare(item.FobRsCr,data.totalPriceRs) }}</td>
                                                    <td >{{ getShare(item.FobRsPreCr,data.totalPriceRsPrev) }} </td>

                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <!-- <td>{{item.FobDolMil.toFixed(2)}}</td>
                                                    <td>{{item.FobDolPreMil.toFixed(2)}}</td> -->
                                                    <td >{{ getShare(item.FobDolMil,data.totalPriceDol) }}</td>
                                                    <td >{{ getShare(item.FobDolPreMil,data.totalPriceDolPrev) }} </td>
                                                </tr>
                                        
                                            
                                            </ng-template>


                                        
                                        
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td >100</td>
                                                <td >100</td>
                                                
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>100</td>
                                                <td>100</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>100</td>
                                                <td>100</td>
                                            </tr>
                                            
                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="dateRange==2"> 
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>

                                    

                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th >Major Importers </th>
                                                <th >&nbsp;</th>
                                                <th >{{data.dateRange1}}</th>
                                                <th >{{data.dateRange2}}</th>
                                                <th >{{data.dateRange3}}</th>
                                                
                                            </tr>
                                        </ng-template>

                                        
                                        <ng-template pTemplate="body" let-groupitem>
                                           
                                            <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                            
                                                <tr>
                                                    <td >{{item.itemName}} </td>
                                                    <td ><b>Q</b> </td>
                                                    <td >{{ getShare(item.Qtyintons,data.totalQuantity) }}</td>
                                                    <td >{{ getShare(item.QtyPreTon,data.totalQuantityPrev) }} </td>
                                                    <td >{{ getShare(item.QtyPre2Ton,data.totalQuantityPrev2) }} </td>
                                                    
                                                </tr>

                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>V</b> </td>
                                                    <td >{{ getShare(item.FobRsCr,data.totalPriceRs) }}</td>
                                                    <td >{{ getShare(item.FobRsPreCr,data.totalPriceRsPrev) }} </td>
                                                    <td >{{ getShare(item.FobRsPre2Cr,data.totalPriceRsPrev2) }} </td>

                                                   
                                                </tr>
                                        
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td><b>$</b> </td>
                                                    <td >{{ getShare(item.FobDolMil,data.totalPriceDol) }}</td>
                                                    <td >{{ getShare(item.FobDolPreMil,data.totalPriceDolPrev) }} </td>
                                                    <td >{{ getShare(item.FobDolPre2Mil,data.totalPriceDolPrev2) }} </td>

                                                    
                                                    
                                                </tr>
                                            </ng-template>
                                        
                                                
                                                
                                        


                                            
                                        
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td ><b>Grand Total</b></td>
                                                <td >Q:</td>
                                                <td >  {{getNumber(data.totalQuantity, 2)}}</td>
                                                <td >   {{getNumber(data.totalQuantityPrev, 2)}}</td>
                                                <td >  {{getNumber(data.totalQuantityPrev2, 2)}}  </td>
                                                
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <td>{{data.totalPriceRs}}</td>
                                                <td>{{data.totalPriceRsPrev}}</td>
                                                <td>{{data.totalPriceRsPrev2}} </td>
                                                
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <td>{{data.totalPriceDol}}</td>
                                                <td>{{data.totalPriceDolPrev}}</td>
                                                <td>{{data.totalPriceDolPrev2}} </td>
                                                
                                            </tr>
                                        
                                        </ng-template>
                                    </p-table>
                                </table>
                                
                                
                            </div>
                        </div>
                    </section>
                </section>
           
        </div>

        </div>
        
        
    </form>
</div>