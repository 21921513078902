import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { InvoiceService } from './invoice.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { TopNavigationComponent } from '../top-navigation/top-navigation.component';
import { CommonServiceService } from "../../common-service.service";
import jwt_decode from "jwt-decode";
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],

})
export class InvoicesComponent implements OnInit {

  loading: boolean = true;
  cols: any[];
  data: any;
  datasource: any;
  totalRecords: number;
  isLoggedIn: number;
  isPermissionAdd: boolean = false;
  isPermissionEdit: boolean = false;
  isPermissionDownload: boolean = false;
  isDeletePermission: boolean = false;
  pageName: string;
  branchId: number = 0;
  decodedToken: any = []
  quantityOption: number = 1;
  amountOption: number = 0;
  lastEvent: any;

  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;

  constructor(private InvoiceService: InvoiceService, private messageService: MessageService, private _router: Router, private _commonService: CommonServiceService) { }


  ngOnInit(): void {


    let token = JSON.parse(localStorage.getItem('token'));
    this.decodedToken = jwt_decode(token);
    //console.warn("Token =", decodedToken.result.branch_id);
    if (this.decodedToken.result.branch_id != null) {
      this.branchId = this.decodedToken.result.branch_id;
    }




    this.pageName = "Shipping Bill";
    let parentName = '';
    let buttons = this._commonService.getActionButton(parentName, this.pageName);
   // console.warn(buttons);
    let addBtn = buttons.filter(recordAction => recordAction.action_name === "Add");
    if (addBtn.length > 0) {
      this.isPermissionAdd = true;
    }
    let editBtn = buttons.filter(recordAction => recordAction.action_name === "Edit");
    if (editBtn.length > 0) {
      this.isPermissionEdit = true;
    }
    let downloadBtn = buttons.filter(recordAction => recordAction.action_name === "DownloadPdf");
    if (downloadBtn.length > 0) {
      this.isPermissionDownload = true;
    }
    let deleteBtn = buttons.filter(recordAction => recordAction.action_name === "Delete");
    if (deleteBtn.length > 0) {
      this.isDeletePermission = true;
    }
    this.isLoggedIn = 1;
    this.cols = [
      { field: 'Shi. Bill #', header: 'SB_No', width: '12%', type: 'text' },
      { field: 'Shi. Bill Date', header: 'SB_Date', width: '12%', type: 'text' },
      { field: 'Invoice #', header: 'BILL_NO', width: '12%', type: 'text' },
      { field: 'Date', header: 'Date', width: '10%', type: 'text' },
      { field: 'Exporter', header: 'EXP_NAME', width: '15%', type: 'text' },
      { field: 'Consignee', header: 'BUYER_NAME', width: '15%', type: 'text' },
      { field: 'Port Of Loading', header: 'PORT_NAME', width: '15%', type: 'text' },
      { field: 'Qty(KG)', header: 'Tot_Weight', width: '10%', type: 'text' },
      { field: 'Amount($)', header: 'Tot_Amount', width: '10%', type: 'text' },
      { field: 'Amount(₹)', header: 'tot_amount_rs', width: '10%', type: 'text' },
      { field: 'Status', header: 'status', width: '10%', type: 'text' },
      { field: 'Entry Date', header: 'EntryDate', width: '10%', type: 'text' },
      { field: 'Status', header: 'statusCode', width: '10%', type: 'hidden' },

    ];
  }

  loadInvoices(event: LazyLoadEvent) {
    this.loading = true;
    this.lastEvent = event;
    //console.warn(event.filters);
    // return false;
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {

      this.InvoiceService.getInvoices(event.first, event.rows, event.sortField, event.sortOrder, event.filters).subscribe((items: any) => {
        this.datasource = items.result;
        this.data = items.data;
        this.totalRecords = items.totalRecord;
        this.loading = false;
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });




    }, 1000);

  }

  public downloadAsPDF() {


    //var data = this.pdfTable.nativeElement;
    var data = document.getElementById('content');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      console.warn(canvas);
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      console.warn(contentDataURL);
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF   
    });

  }

  getQuantityOption($event) {
    this.quantityOption = $event.target.value;
  }
  getAmountOption($event) {
    this.amountOption = $event.target.value;
  }
  confirmDelete(id) {
    console.warn("Delete function", id);
    if (confirm("Are you sure to delete invoice")) {
      this.InvoiceService.deleteInvoice(id).subscribe((item: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: item.message, life: 6000 });
        this.loadInvoices(this.lastEvent);
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });
    }

  }
}

