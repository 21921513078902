    <div class="loader2" *ngIf="isloaderPort" ><img src="assets/img/loader.svg" alt=""></div>
    <div class="db_selop">
        <span class="gr_tx">Port</span>
        <select class="form-control"  #mySelect [(ngModel)]="portGraphType" (change)="changeOptionPort(mySelect.value)">
            <option value="BarChart">Bar Chart </option>
            <option value="PieChart">Pie Chart </option>
            <option value="AreaChart">Area Chart </option>
            <option value="ColumnChart">Column Chart </option>
            
        </select>
    </div>
    <ng-template [ngIf]="isShowMap" >
        <google-chart  [type]="portGraphType" [title]="titlePort" [data]="dataPort" style="width: 100%; height: 400px;"  [options]="optionsPort" ></google-chart>
    </ng-template>    
