import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExportReviewService {

  constructor(private http: HttpClient) { }
  url = `${apiUrl}`;

  getGrowth(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getGrowth", { data: data }, options);

  }
  getMarketGrowth(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getMarketGrowth", { data: data }, options);

  }

  getExportMarketGrowth(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/getMarketGrowth", { data: data }, options);
  }


  getExportMarketFrozenCrab(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/getMarketFrozenCrab", { data: data }, options);
  }

  getGroups() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url + "reports/getGroup");
  }

  getShrimpGroups() {

    return this.http.get(this.url + "export-review/getShrimpGroup");
  }

  getMonthlyTrends(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getMonthlyTrends", { data: data }, options);

  }
  getPorts() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url + "reports/getPorts", options);
  }


  getCountryWiseMarineProduct(data) {
    console.log("-------Api Call------", data);

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/getPortReport", { data: data }, options);

  }


  getUnion() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url + "reports/getUnions", options);
  }

  getMonthlyTrendsMarket(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/getMonthlyExportTrendsMarket", { data: data }, options);

  }


  getExportOtherItems(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/exportOtherItems", { data: data }, options);

  }


  getsrimpExport(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/srimpExport", { data: data }, options);

  }


  getsrimpExportItemWise(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/srimpExportItems", { data: data }, options);

  }




  getShrimpExportFrozenScampi(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/srimpExportFrozenScampi", { data: data }, options);

  }


  getCephalopodsGrowth(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/cephalopodsGrowth", { data: data }, options);
  }


  getLobsterGrowth(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/lobsterGrowth", { data: data }, options);
  }


  getGrowthItems(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/growthItems", { data: data }, options);
  }

  getFinFish(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/getFinFish", { data: data }, options);
  }


  getExportGrowth(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "export-review/exportGrowth", { data: data }, options);
  }





  getItemwiseReport(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let startDate = data.dateStart;
    let startDateNew = new Date(data.dateStart);
    return this.http.post(this.url + "export-review/getItemwiseReport", { data: data }, options);
  }



  getItemwiseMonthlyReport(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let startDate = data.dateStart;
    let startDateNew = new Date(data.dateStart);
    return this.http.post(this.url + "export-review/getItemwiseMonthlyReport", { data: data }, options);
  }


  getItemwiseAllMonthlyReport(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let startDate = data.dateStart;
    let startDateNew = new Date(data.dateStart);
    return this.http.post(this.url + "export-review/getItemwiseAllMonthlyReport", { data: data }, options);
  }


  getItemwiseAllMonthlyReport2(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let startDate = data.dateStart;
    let startDateNew = new Date(data.dateStart);
    return this.http.post(this.url + "export-review/getItemwiseAllMonthlyReport2", { data: data }, options);
  }



  getGroupItems(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getGroupItems", data, options);
  }

  getYearlyMonthlyReport(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    let startDate = data.dateStart;
    let startDateNew = new Date(data.dateStart);
    return this.http.post(this.url + "export-review/getItemYearlyReport", { data: data }, options);
  }





}
