import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { synccalendarRange } from 'src/environments/environment';
import { SetupServiceService } from '../setup-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-exporter-sync',
  templateUrl: './exporter-sync.component.html',
  styleUrls: ['./exporter-sync.component.css']
})
export class ExporterSyncComponent implements OnInit {

  reportFrm: FormGroup;
  isloader: boolean = false;
  submitted: boolean = false;
  calendarRange: string = synccalendarRange;
  exporterData: any = [];
  exporters: any = [];
  constructor(private formBuilder: FormBuilder, private _service: SetupServiceService, private _router: Router, private messageService: MessageService) { }
  get f() { return this.reportFrm.controls; }

  ngOnInit(): void {

    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      category: ['', Validators.required],
      subgroups: this.formBuilder.array([]),
    });
    this._service.getExporters().subscribe((expoters: any) => {
      this.exporters = expoters.data;
    });
  }

  syncExporters() {
    this.isloader = true;

    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    this._service.updateExporter(this.reportFrm.value).subscribe((items: any) => {
      this.isloader = false;
      if (!items.data.success) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.data.message, life: 6000 }); //
      }
      else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: items.data.message, life: 6000 }); //
      }
      return false;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }


  getExporters() {

    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    this.subgroups().controls = [];
    this._service.syncExporters(this.reportFrm.value).subscribe((responseData: any) => {
      this.exporterData = "";
      this.exporterData = responseData.data;
      //this.createform();
      this.isloader = false;

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }

    });
  }

  subgroups(): FormArray {
    return this.reportFrm.get("subgroups") as FormArray
  }
  newSubgroup(data): FormGroup {
    let expCode = '';
    if (data.exporterDetail) {
      expCode = data.exporterDetail.EXP_CODE
    }
    return this.formBuilder.group({
      registration_number: data.RegNo,
      registration_date: data.RegDate,
      expiry_date: data.ExpDate,
      ExporterName: data.ExporterName,
      contactName: data.contactName,
      mobile: data.mobile,
      email: data.email,
      IECode: data.IECode,
      exporterDetail: data.exporterDetail,
      expCode: expCode,
      expDetail: data,
    })
  }
  createform() {
    for (let i = 0; i < this.exporterData.length; i++) {
      this.subgroups().push(this.newSubgroup(this.exporterData[i]));
    }
  }




  onRowEditInit() {
    //this.cloned[product.id] = product;
  }
  onRowEditCancel(product, index: number) {

  }

  onRowEditSave(exporter) {
    console.warn(exporter);
    this._service.updateExporter(exporter).subscribe((responseData: any) => {
      if (responseData.data.success) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: responseData.data.message });
        let data = {
          "category": this.reportFrm.value.category,
          "dateStart": this.reportFrm.value.dateStart,
        }
        this._service.syncExporters(data).subscribe((responseData: any) => {
          this.exporterData = "";
          this.exporterData = responseData.data;
          // this.createform();
          this.isloader = false;

        }, error => {

          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this._router.navigate(['./']);
            }
          }

        });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error' });
      }
    });

  }
}
