import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StatisticsServiceService {

  url = `${apiUrl}`;
  constructor(private http: HttpClient) { }
  getAnnualGrowth(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getAmmualGrowth", { data: data }, options);
  }

  getStatisticsRegions(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getStatisticsRegion", { data: data }, options);
  }
  getStatisticsCountry(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getStatisticsCountry", { data: data }, options);
  }

  getGroups() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url + "reports/getGroup");
  }
  getStatisticsItems(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getStatisticsItems", { data: data }, options);
  }
  getStatisticsByItem(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getStatisticsByItem", { data: data }, options);
  }
  getStatisticsAverageUnit(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getStatisticsAverageUnit", { data: data }, options);
  }
  getItems() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getItems", '', options);
  }
  getAllRegionsStats(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getAllRegionsStats", { data: data }, options);
  }
  getAllPortStats(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getAllPortsStats", { data: data }, options);
  }
  getStatisticsItemGroup(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getStatisticsItemGroup", { data: data }, options);
  }

  getMarinProductExport(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }

    return this.http.post(this.url + "statistics/getMarinProductExport", { data: data }, options);
  }


  getsrimpExport(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/srimpExport", { data: data }, options);
  }

  getMonthlyTrends(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getMonthlyTrends", { data: data }, options);
  }


  getCountryWiseMarineProduct(data) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "statistics/getPortReport", { data: data }, options);

  }







}
