import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportReviewService } from '../export-review.service';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';

@Component({
  selector: 'app-export-growth',
  templateUrl: './export-growth.component.html',
  styleUrls: ['./export-growth.component.css']
})
export class ExportGrowthComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName: string;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService) { }


  ngOnInit(): void {
    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;

    this.reportFrm = this.formBuilder.group({
      dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      viewDetailReport: [''],
      reportType: [reportType],

    });
    this.reportTitle = "Export Growth of Marine Products";
    this.primengConfig.ripple = true;

    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });

  }

  get f() { return this.reportFrm.controls; }


  getUnitValueRs(totalRs, Qty) {
    let unitValue = (totalRs * 10000000) / (Qty * 1000);
    return unitValue.toFixed(2);
  }

  getUnitValueDol(totalDol, Qty) {
    let unitValueDol = (totalDol * 1000000) / (Qty * 1000);
    return unitValueDol.toFixed(2);
  }

  getNumberWithoutDecimal(n) {
    let qty = n ? n.toFixed() : 0;
    return qty;
  }

  getNumberWithTwoDecimal(n) {
    let qty = n ? n.toFixed(2) : 0;
    return qty;
  }




  getReport() {

    this.isloader = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }


    // Start Validation For calendar 
    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    var yearDifferent = dt2 - dt1;

    var fromYear = 1995;
    var d = new Date();
    var getFullYear = d.getFullYear();
    var toYear = getFullYear;
    if ((dt1 < fromYear) || (dt1 > toYear)) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select year between" + fromYear + ' and ' + toYear, life: 6000 });
      this.isloader = false;
      return false;
    }

    if (yearDifferent < 0) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Invalid date please enter correct date", life: 6000 });
      this.isloader = false;
      return false;
    }


    if (yearDifferent > 10) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 10", life: 6000 });
      this.isloader = false;
      return false;
    }

    // End Validation For calendar






    this._service.getGrowth(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      if (items.data.reportData.length > 0) {

        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report.xlsx");
    });
  }
}
