import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ItemmasterService {

  constructor(private http:HttpClient) { }
  url = `${apiUrl}`;
  getItems(startRow,rowNumber,sortField,sortOrder,filters)
  {
    
    
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url+"getItemsData",
      {
      "startRow":  startRow,
      "rowNumber": rowNumber,
      "sortField" : sortField,
      "sortOrder" : sortOrder,
      "filters" : filters
      },options);


      //return response.result
      
  }
  getItemDetail(itemId)
  {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url+"getItemDetail",{"itemId":itemId},options);
  }
  getGroupDetails()
  {
    return this.http.get(this.url+"getGroup");
  }
  getSubGroupDetails(groupId)
  {
    return this.http.post(this.url+"getSubGroup",{"Id":groupId});
  }
  saveItemMaster(data)
  {
    return this.http.post(this.url+"saveItem",{data:data}); 
  }
  removeGrade(id)
  {
    return this.http.post(this.url+"deleteItemGrade",{data:id}); 
  }
}
