<div class="pageheadingouter">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> Blocked IP List</h1>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0" *ngIf="isPermissionAdd">
                <a [routerLink]="['/setup/user/add']" [queryParams]= ""><button type="button" class="btn btn-success"><i class="fa fa-save"></i> Add </button></a>
            </div>


        </div>

    </div>
</div>
<section class="innerpage">           
    <div class="innershadow">
        <div class="container pt-4">
            <div class="table-responsive">            
            <p-table [value]="data" #dt [columns]="cols" [lazy]="true" (onLazyLoad)="loadUsers($event)" [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [loading]="loading"  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" pSortableColumn="{{col.header}}" [ngStyle]="{'width': col.width}" >{{col.field}} <p-sortIcon field="{{col.header}}"></p-sortIcon></th>
                        
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                           
                                <input pInputText type="text" (input)="dt.filter($event.target.value, col.header, 'startsWith')" placeholder="Search by {{col.field}}" class="p-column-filter" style="width: 100%;" *ngIf="col.isSearch=='1'" />
                        </th>
                        
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns">
                    <tr style="height:34px">
                        <td *ngFor="let col of columns">
                            <span *ngIf="col.header!='status'">{{data[col.header]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>



</section>
<br>