import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MasterService } from './master.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import * as XLSX from 'xlsx';
import { CommonServiceService } from "../../common-service.service";


@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.css']
})
export class MastersComponent implements OnInit {

  loading: boolean;
  first = 0;
  rows = 10;
  @ViewChild('dt') public table: Table;
  @ViewChild('detailTable') userTable: ElementRef;

  data: any;
  datasource: any;
  totalRecords: number;
  pageName: any;
  items: any;
  resposeApi: any;
  cols: any[];
  pageHeading: any;
  sortField: any;
  sortOrder: any;
  masterDialog: boolean;
  submitted: boolean;
  masterData: any;
  formHeading: any;
  masterFrmData: any;

  manufacturerType: any;
  plantState: any;
  plantManufacturer: any;
  countriesDDValues: any;
  staatesDDValues: any;
  unionsDDValues: any;
  groupsDDValues: any;
  lastEvent: any;
  registerForm: FormGroup;
  exportRecords: any = [];
  isPermissionAdd: boolean = false
  isPermissionEdit: boolean = false
  constructor(private route: ActivatedRoute, private masterService: MasterService, private messageService: MessageService, private formBuilder: FormBuilder, private _router: Router, private _commonService: CommonServiceService) {

  }

  ngOnInit(): void {

    this.pageHeading = "";
    this.route.queryParams.subscribe(params => {
      this.pageName = params.page;
      this.sortOrder = -1;
      let parentName = 'Masters';
      let buttons = this._commonService.getActionButton(parentName, this.pageName);
      this.isPermissionAdd = false;
      this.isPermissionEdit = false;
      let addBtn = buttons.filter(recordAction => recordAction.action_name === "Add");
      if (addBtn.length > 0) {
        this.isPermissionAdd = true;
      }
      let editBtn = buttons.filter(recordAction => recordAction.action_name === "Edit");
      if (editBtn.length > 0) {
        this.isPermissionEdit = true;
      }

      if (this.pageName == "exporter") {
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden', width: '8%' },
          { field: 'Code ', header: 'EXP_CODE', type: 'text', width: '10%' },
          { field: 'Registration Number ', header: 'registration_number', type: 'text', width: '15%' },
          { field: 'Exporter Name ', header: 'EXP_NAME', type: 'text', width: '10%' },
          { field: 'Address ', header: 'ADDRESS', type: 'textarea', width: '10%' },
          { field: 'Place', header: 'PLACE', type: 'text', width: '10%' },
          { field: 'Imp Exp Code', header: 'Imp_Exp_No', type: 'text', width: '10%' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '10%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '10%' }
        ];

        this.registerForm = this.formBuilder.group({
          EXP_CODE: ['', Validators.required],
          registration_number: ['', Validators.required],
          EXP_NAME: ['', Validators.required],
          ADDRESS: ['', Validators.required],
          PLACE: ['', Validators.required],
          Imp_Exp_No: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
        });
        this.pageHeading = "Exporter Master";
        this.formHeading = "Exporter";
        this.sortField = "id";

      }
      else if (this.pageName == "manufacturer") {
        this.manufacturerType = {
          "F": "F",
          'B': "B",
          "C": "C"
        };
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden', width: '8%' },
          { field: 'Code ', header: 'MAN_CODE', type: 'text', width: '10%' },
          { field: 'Name ', header: 'MAN_NAME', type: 'text', width: '20%' },
          { field: 'Address ', header: 'MAN_ADDR', type: 'textarea', width: '20%' },
          { field: 'Place', header: 'PLACE', type: 'text', width: '10%' },
          { field: 'Type', header: 'MAN_TYPE', type: 'radio', detailValues: this.manufacturerType, width: '10%' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '10%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '10%' }

        ];
        this.registerForm = this.formBuilder.group({
          MAN_CODE: ['', Validators.required],
          MAN_NAME: ['', Validators.required],
          MAN_ADDR: ['', Validators.required],
          PLACE: ['', Validators.required],
          MAN_TYPE: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
        });
        this.pageHeading = "Manufacturer Master";
        this.sortField = "MAN_CODE";
        this.formHeading = "Manufacturer ";
      }
      else if (this.pageName == "plant") {

        this.masterService.getStateData().subscribe((states) => {
          this.plantState = states;
          this.masterService.getManufacturers().subscribe((manufacturers) => {
            this.plantManufacturer = manufacturers;
            let plantCategory: any;
            plantCategory = [
              { "code": "EU", "name": "European" },
              { "code": "NONEU", "name": "Non European" }
            ];
            this.cols = [
              { field: 'Id', header: 'ID', type: 'hidden', width: '8%' },
              { field: 'Code ', header: 'Plant_Code', type: 'text', width: '7%' },
              { field: 'Reg Number ', header: 'Plant_RegNo', type: 'text', width: '10%' },
              { field: 'EIA Code ', header: 'eia_code', type: 'text', width: '10%' },
              { field: 'Category  ', header: 'plant_category', type: 'dropdown', dropdoenName: "plant_category", detailValues: plantCategory, width: '10%' },
              { field: 'Address ', header: 'Addr', type: 'textarea', width: '15%' },
              { field: 'State', header: 'Stname', type: 'dropdown', dropdoenName: "State_Code", detailValues: this.plantState, width: '10%' },
              { field: 'Manufacturer Name ', header: 'MAN_NAME', type: 'dropdown', dropdoenName: "Man_Code", detailValues: this.plantManufacturer, width: '15%' },
              { field: 'Added Date', header: 'added_date', type: 'calender', width: '10%' },
              { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '13%' }

            ];
            this.registerForm = this.formBuilder.group({
              Plant_Code: ['', Validators.required],
              Plant_RegNo: ['', Validators.required],
              eia_code: ['', Validators.required],
              plant_category: ['', Validators.required],
              Addr: ['', Validators.required],
              Stname: ['', Validators.required],
              MAN_NAME: ['', Validators.required],
              added_date: ['', Validators.required],
              action: [''],
              ID: [''],
            });

          });

          //  this.loading = false;

        });

        this.pageHeading = "Plant  Master";
        this.sortField = "Plant_Code";
        this.formHeading = "Plant ";
      }
      else if (this.pageName == "buyer") {
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden', width: '8%' },
          { field: 'Code ', header: 'BUYER_CODE', type: 'text', width: '10%' },
          { field: 'Name ', header: 'BUYER_NAME', type: 'text', width: '30%' },
          { field: 'Address ', header: 'ADDRESS', type: 'textarea', width: '10%' },
          { field: 'Place', header: 'PLACE', type: 'text', width: '10%' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '10%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '10%' }

        ];
        this.registerForm = this.formBuilder.group({
          BUYER_CODE: ['', Validators.required],
          BUYER_NAME: ['', Validators.required],
          ADDRESS: ['', Validators.required],
          PLACE: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
        });
        this.pageHeading = "Buyer  Master";
        this.sortField = "BUYER_CODE";
      }
      else if (this.pageName == "location") {
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden' },
          { field: 'Location Code ', header: 'Loc_Code', type: 'text' },
          { field: 'Location Name ', header: 'Loc_Name', type: 'text' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '15%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '16%' }
        ];
        this.registerForm = this.formBuilder.group({
          Loc_Code: ['', Validators.required],
          Loc_Name: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
        });
        this.pageHeading = "Location Master";
        this.sortField = "id";
      }
      else if (this.pageName == "currency") {
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden' },
          { field: 'Currency Code ', header: 'Currency', type: 'text' },
          { field: 'Currency Description ', header: 'Description', type: 'text' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '15%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '16%' }
        ];

        this.registerForm = this.formBuilder.group({
          Currency: ['', Validators.required],
          Description: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
        });
        this.pageHeading = "Currency Master";
        this.sortField = "id";
        this.formHeading = "Currency ";
      }
      else if (this.pageName == "port") {
        this.masterService.getCountry().subscribe((countries) => {
          this.countriesDDValues = countries;
          this.masterService.getStates().subscribe((states) => {
            this.staatesDDValues = states;
            this.cols = [
              { field: 'Id', header: 'ID', type: 'hidden', width: '8%' },
              { field: 'Code ', header: 'PORT_CODE', type: 'text', width: '10%' },
              { field: 'Name ', header: 'PORT_NAME', type: 'text', width: '10%' },
              { field: 'Country ', header: 'COUNTRY_NAME', type: 'dropdown', dropdoenName: "COUNTRY_CODE", detailValues: this.countriesDDValues, width: '10%' },
              { field: 'State', header: 'REGION_NAME', type: 'dropdown', dropdoenName: "STATE_CODE", detailValues: this.staatesDDValues, width: '10%' },
              { field: 'Added Date', header: 'added_date', type: 'calender', width: '10%' },
              { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '10%' }
            ];
            this.registerForm = this.formBuilder.group({
              PORT_CODE: ['', Validators.required],
              PORT_NAME: ['', Validators.required],
              COUNTRY_NAME: ['', Validators.required],
              REGION_NAME: ['', Validators.required],
              added_date: ['', Validators.required],
              action: [''],
              ID: [''],
            });
          });
        });
        this.pageHeading = "Port  Master";
        this.sortField = "id";
        this.formHeading = "Port ";
      }
      else if (this.pageName == "country") {
        this.masterService.getUnion().subscribe((unions) => {
          this.unionsDDValues = unions;
          this.cols = [
            { field: 'Id', header: 'ID', type: 'hidden', width: '8%' },
            { field: 'Code ', header: 'COUNTRY_CODE', type: 'text', width: '12%' },
            { field: 'Name ', header: 'COUNTRY_NAME', type: 'text', width: '30%' },
            { field: 'Region Name ', header: 'REG_CODE', type: 'dropdown', dropdoenName: "REG_CODE", detailValues: this.unionsDDValues, width: '30%' },
            { field: 'Added Date', header: 'added_date', type: 'calender', width: '15%' },
            { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '16%' },
            { field: 'Favorite', header: 'favorite', type: 'checkbox', width: '16%' }

          ];


          this.registerForm = this.formBuilder.group({
            COUNTRY_CODE: ['', Validators.required],
            COUNTRY_NAME: ['', Validators.required],
            REG_CODE: ['', Validators.required],
            added_date: ['', Validators.required],
            action: [''],
            ID: [''],
            favorite: [''],

          });


        });
        this.pageHeading = "Country Master";
        this.sortField = "id";
        this.formHeading = "Country ";
      }
      else if (this.pageName == "state") {
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden' },
          { field: 'State Code ', header: 'REGION_CODE', type: 'text' },
          { field: 'State Name ', header: 'REGION_NAME', type: 'text' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '15%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '16%' }

        ];
        this.registerForm = this.formBuilder.group({
          REGION_CODE: ['', Validators.required],
          REGION_NAME: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
        });
        this.pageHeading = "State Master";
        this.sortField = "id";
        this.formHeading = "State ";
      }
      else if (this.pageName == "grade") {
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden' },
          { field: 'Grade Code ', header: 'Size_Code', type: 'text' },
          { field: 'Grade ', header: 'Size_Name', type: 'text' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '15%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '16%' }
        ];
        this.registerForm = this.formBuilder.group({
          Size_Code: ['', Validators.required],
          Size_Name: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
        });
        this.pageHeading = "Grade Master";
        this.sortField = "id";
        this.formHeading = "Grade ";
      }
      else if (this.pageName == "group") {
        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden' },
          { field: 'Group Code ', header: 'GROUP_CODE', type: 'text' },
          { field: 'Group Name ', header: 'GROUP_NAME', type: 'text' },
          { field: 'Added Date', header: 'added_date', type: 'calender', width: '15%' },
          { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '16%' },
          { field: 'Favorite', header: 'favorite', type: 'checkbox', width: '16%' },


        ];

        this.registerForm = this.formBuilder.group({
          GROUP_CODE: ['', Validators.required],
          GROUP_NAME: ['', Validators.required],
          added_date: ['', Validators.required],
          action: [''],
          ID: [''],
          favorite: [''],
        });
        this.pageHeading = "Group Master";
        this.sortField = "id";
        this.formHeading = "Group ";
      }
      else if (this.pageName == "subgroup") {
        this.masterService.getAllGroups().subscribe((groupds) => {
          this.groupsDDValues = groupds;
          this.cols = [
            { field: 'Id', header: 'ID', type: 'hidden' },
            { field: 'Group Name ', header: 'GROUP_NAME', type: 'dropdown', dropdoenName: "Group_Code", detailValues: this.groupsDDValues },
            { field: 'Sub Group Code ', header: 'SubGroupCode', type: 'text' },
            { field: 'Sub Group Name ', header: 'SubGroupName', type: 'text' },
            { field: 'Added Date', header: 'added_date', type: 'calender', width: '15%' },
            { field: 'Modified Date', header: 'modified_date', type: 'hideForm', width: '16%' }
          ];
          this.registerForm = this.formBuilder.group({
            GROUP_NAME: ['', Validators.required],
            SubGroupCode: ['', Validators.required],
            SubGroupName: ['', Validators.required],
            added_date: ['', Validators.required],
            action: [''],
            ID: [''],
          });
        });
        this.pageHeading = "Sub Group Master";
        this.sortField = "id";
        this.formHeading = "Sub Group ";
      }
      else {

      }
      this.loading = true;
      //
      //this.loadData(this.lastEvent);
      //this.reset();

      this.masterService.getMastersData(this.pageName, 0, 10, this.sortField, this.sortOrder, '').subscribe((items: any) => {

        // this.datasource = items.result;
        // this.data = items.data;
        // this.totalRecords = items.totalRecord;
        this.table.reset();
        //  this.loading = false;
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });

    });

    /*
this.masterService.getTotalNumber(this.pageName).subscribe(items => {

  console.log("Vikas ="+[items]);
  this.totalRecords = Object.keys(items).length;
});

this.masterService.getMastersData().subscribe(items => {
    //this.data = items;
    this.datasource = items;
    this.totalRecords = Object.keys(items).length;;
  });
  */
  }

  loadData(event: LazyLoadEvent) {
    //this.loading = true;
    this.lastEvent = event;
    // console.log(event.filters);
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network

    setTimeout(() => {

      this.masterService.getMastersData(this.pageName, event.first, event.rows, event.sortField, event.sortOrder, event.filters).subscribe((items: any) => {
        this.datasource = items.result;
        this.data = items.data;
        this.totalRecords = items.totalRecord;
        this.loading = false;
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });




    }, 1000);
  }
  editMasters(masterData) {
    this.masterDialog = true;
    this.masterFrmData = masterData;
    this.masterFrmData.action = "Update";
    this.registerForm.controls.action.patchValue("Update", { emitEvent: false });
  }
  openNew() {
    this.masterDialog = true;
    this.masterFrmData = {};
    this.masterFrmData.action = "New";
    this.registerForm.controls.action.patchValue("new", { emitEvent: false });
  }

  closeForm() {
    this.masterDialog = false;
    this.masterFrmData = "";
    this.masterFrmData.action = "";
  }
  saveMaster(masterFrm: NgForm) {
    this.submitted = true;
    if (masterFrm.invalid) {
      return;
    }

    if (masterFrm.valid) {
      this.masterData = masterFrm.value;
      this.masterService.saveMaster(this.masterData, this.pageName).subscribe((items: any) => {
        if (items.success) {
          this.datasource = items.result;
          this.data = items.data;
          this.totalRecords = items.totalRecord;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //success,info,warn,error
          this.masterDialog = false;
          this.masterFrmData = "";
        }
        else {
          console.log(items.success);
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.failed.sqlMessage, life: 6000 }); //success,info,warn,error
        }
        this.submitted = false;
      });


    }


  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
  }
  reset() {
    console.log("test");
    this.table.reset()
  }

  exportCSV() {
    this.loading = true;
    this.masterService.getExportData(this.pageName, this.lastEvent).subscribe((items: any) => {
      this.loading = false;
      import("xlsx").then(xlsx => {
        const ws = xlsx.utils.json_to_sheet(items.data);
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, ws, this.pageName + " Data");
        xlsx.writeFile(workbook, this.pageName + "_" + new Date().getTime() + ".csv");
      });
    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
  }

  exportExcel() {
    this.loading = true;
    //this.getExportData();
    this.masterService.getExportData(this.pageName, this.lastEvent).subscribe((items: any) => {
      import("xlsx").then(xlsx => {
        const ws = xlsx.utils.json_to_sheet(items.data);
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, ws, this.pageName + " Data");
        xlsx.writeFile(workbook, this.pageName + "_" + new Date().getTime() + ".xls");
      });
      this.loading = false;
    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
  }
}
