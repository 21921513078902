<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>

                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">{{ getCustomMessage('dateStart.required')}}
                            </div>
                            <div *ngIf="f.dateStart.errors.pattern">{{ getCustomMessage('dateStart.pattern') }}</div>
                            <div *ngIf="f.dateStart.errors.min">{{ getCustomMessage('dateStart.min') }}</div>
                            <div *ngIf="f.dateStart.errors.max">{{ getCustomMessage('dateStart.max') }}</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">{{ getCustomMessage('dateEnd.required')}}</div>
                            <div *ngIf="f.dateEnd.errors.pattern">{{ getCustomMessage('dateEnd.pattern')}}</div>
                            <div *ngIf="f.dateEnd.errors.min">{{ getCustomMessage('dateEnd.min')}}</div>
                            <div *ngIf="f.dateEnd.errors.max">{{ getCustomMessage('dateEnd.max')}}
                            </div>
                        </div>
                    </div>





                    <div class="col form-group" *ngIf="page=='export-item-country-wise'">
                        <span class="label_sub" style="color: #999;">Select Group </span>
                        <p-dropdown formControlName="selectedItem" [options]="sourceGroup" optionLabel="name"
                            optionValue="code" placeholder="Select Group"></p-dropdown>
                    </div>

                    <div class="col form-group" *ngIf="page=='market-wise-export-of-item-group'">
                        <span class="label_sub" style="color: #999;">Select Group </span>
                        <p-dropdown formControlName="selectedItem" [options]="sourceGroup" optionLabel="name"
                            optionValue="code" placeholder="Select Group"></p-dropdown>
                    </div>


                    <div class="col form-group" *ngIf="page=='frozen-fin-fish-In-Diff-Market'">
                        <span class="label_sub" style="color: #999;">Select Group </span>
                        <p-dropdown formControlName="selectedItem" [options]="sourceGroup" optionLabel="name"
                            optionValue="code" placeholder="Select Group"
                            [ngClass]="{ 'is-invalid': submitted && f.selectedItem.errors }"></p-dropdown>

                        <div *ngIf="submitted && f.selectedItem.errors" class="invalid-feedback">
                            <div *ngIf="f.selectedItem.errors.required">Select group is required</div>
                        </div>
                    </div>


                </div>


                <div class="row">
                    <div class="btn_pan mb-4">
                        <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>

                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>Q</b>: Quantity in Tons,</span>


                    <span *ngIf="page=='export-item-country-wise'"><b>V</b>: Value in Rs. Crore,</span>
                    <span *ngIf="page!='export-item-country-wise'"><b>V</b>: Value in Rs. Lakh,</span>
                    <span><b>$</b>: USD Million</span>

                </div>


                <div class="card" *ngIf="displayDetailedReport">



                    <table #detailTable>
                        <p-table [value]="reportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o"
                                        (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportDetailExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="1">Regions</th>
                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                        <th colspan="3">{{itemVal.Range}}</th>
                                        <th colspan="3">Share %</th>
                                    </ng-template>
                                </tr>
                                <tr>
                                    <th> </th>
                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                        <th>Q</th>
                                        <th>V</th>
                                        <th>$</th>

                                        <th>Q</th>
                                        <th>V</th>
                                        <th>$</th>
                                    </ng-template>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{item.name}}</td>

                                    <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                        <td>{{itemVal.Qtyintons}}</td>
                                        <td *ngIf="page=='export-item-country-wise'">
                                            {{getRsInCrore(itemVal.FobRsCr.toFixed(2))}}</td>
                                        <td *ngIf="page!='export-item-country-wise'">{{getNumber(itemVal.FobRsCr, 2)}}
                                        </td>

                                        <td>{{getNumber(itemVal.FobDolMil, 2)}}</td>

                                        <td>{{getShare(itemVal.Qtyintons,reportDateRange[i].totalQuantity.toFixed(2))}}
                                        </td>
                                        <td>{{getShare(itemVal.FobRsCr,reportDateRange[i].totalPriceRs.toFixed(2))}}
                                        </td>
                                        <td>{{getShare(itemVal.FobDolMil,reportDateRange[i].totalPriceDol.toFixed(2))}}
                                        </td>
                                    </ng-template>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="footer">
                                <tr>
                                    <td><b>Total</b></td>
                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                        <td>{{getNumber(itemVal.totalQuantity, 2)}}</td>
                                        <td *ngIf="page=='export-item-country-wise'">
                                            {{getRsInCrore(itemVal.totalPriceRs)}}</td>
                                        <td *ngIf="page!='export-item-country-wise'">{{getNumber(itemVal.totalPriceRs,
                                            2)}}
                                        </td>
                                        <!-- <td >{{itemVal.totalPriceRs.toFixed(2)}}</td> -->
                                        <td>{{getNumber(itemVal.totalPriceDol, 2)}}</td>

                                        <td>100</td>
                                        <td>100</td>
                                        <td>100</td>
                                    </ng-template>
                                </tr>

                            </ng-template>

                        </p-table>
                    </table>
                </div>



                <!-- Report Block -->

                


                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable>
                                   

                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th width="17%">Regions</th>
                                                <th width="15%">&nbsp;</th>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <th width="20%">{{itemVal.Range}}</th>
                                                    <th width="10%" *ngIf="page!='frozen-fin-fish-In-Diff-Market'">Share
                                                        %</th>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                            <tr>

                                                <td width="17%">{{item.name}}</td>
                                                <td width="15%"><b>Q</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td width="20%">{{itemVal.Qtyintons}}</td>
                                                    <td width="10%" *ngIf="page!='frozen-fin-fish-In-Diff-Market'">
                                                        {{getShare(itemVal.Qtyintons,reportDateRange[i].totalQuantity.toFixed(2))}}
                                                    </td>
                                                </ng-template>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><b>V</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <!-- <td>{{itemVal.FobRsCr.toFixed(2)}}</td> -->
                                                    <td *ngIf="page=='export-item-country-wise'">
                                                        {{getRsInCrore(itemVal.FobRsCr)}}</td>
                                                    <td *ngIf="page!='export-item-country-wise'">
                                                        {{getNumber(itemVal.FobRsCr,2)}}</td>

                                                    <!-- <td>{{itemVal.rsVarPer.toFixed(2)}}</td> -->
                                                    <td *ngIf="page!='frozen-fin-fish-In-Diff-Market'">
                                                        {{getShare(itemVal.FobRsCr,reportDateRange[i].totalPriceRs.toFixed(2))}}
                                                    </td>
                                                </ng-template>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><b>$</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td>{{itemVal.FobDolMil.toFixed(2)}}</td>
                                                    <!-- <td>{{itemVal.dolVarPer.toFixed(2)}}</td> -->
                                                    <td *ngIf="page!='frozen-fin-fish-In-Diff-Market'">
                                                        {{getShare(itemVal.FobDolMil,reportDateRange[i].totalPriceDol.toFixed(2))}}
                                                    </td>
                                                </ng-template>
                                            </tr>

                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td width="17%"><b>Total</b></td>
                                                <td width="15%">Q:</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <td width="20%">{{getNumber(itemVal.totalQuantity,2)}}</td>
                                                    <!-- <td width="10%">{{itemVal.totalQtyPer.toFixed(2)}}</td> -->
                                                    <td width="10%" *ngIf="page!='frozen-fin-fish-In-Diff-Market'">100
                                                    </td>
                                                </ng-template>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <!-- <td width="20%">{{itemVal.totalPriceRs.toFixed(2)}}</td> -->
                                                    <td *ngIf="page=='export-item-country-wise'">
                                                        {{getRsInCrore(itemVal.totalPriceRs)}}</td>
                                                    <td *ngIf="page!='export-item-country-wise'">
                                                        {{getNumber(itemVal.totalPriceRs,2)}}</td>


                                                    <td width="10%" *ngIf="page!='frozen-fin-fish-In-Diff-Market'">100
                                                    </td>
                                                </ng-template>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <td width="20%">{{getNumber(itemVal.totalPriceDol,2)}}</td>
                                                    <td width="10%" *ngIf="page!='frozen-fin-fish-In-Diff-Market'">100
                                                    </td>
                                                </ng-template>
                                            </tr>

                                        </ng-template>
                                    </p-table>
                                </table>

                            </div>
                        </div>
                    </section>
                </section>

                <p-messages severity="error" *ngIf="dataNotFound">
                    <ng-template pTemplate>
                        <div class="p-ml-2">Record not available for the requested year.</div>
                    </ng-template>
                </p-messages>


                



            </div>

        </div>
        <input type="hidden" formControlName="reportType" />
    </form>
</div>