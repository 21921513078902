<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <div class="pageheadingouter">
        <div class="container container2">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/report-icons.png" alt=""> Dashboard </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template [ngIf]="isShowMap">
        <div class="container container2">
            <div class="row my-4">
                <div class="col-sm-12 text-center dsb">
                    <span class="tx01">Financial Year &nbsp;</span>
                    <select class="form-control d-inline-block form2" #mySelectYear [(ngModel)]="financialYear"
                        (change)="childPort.getGraph(mySelectYear.value);childItem.getGraph(mySelectYear.value);childCountry.getGraph(mySelectYear.value);childExport.getGraph(mySelectYear.value)">
                        <option *ngFor="let fYear of finnancialYear; let i = index" value="{{fYear.year}}">
                            {{fYear.year}}</option>
                    </select>
                </div>

            </div>
            <div class="row db_grph">
                <div class="col-md-6 text-center">
                    <div class="dbox">
                        <app-exporter-port-graph #childPort [financialYear]="financialYear"></app-exporter-port-graph>
                    </div>

                </div>

                <div class="col-md-6 text-center">
                    <div class="dbox">
                        <app-exporter-item-graph #childItem [financialYear]="financialYear"></app-exporter-item-graph>
                    </div>
                </div>

            </div>
            <div class="row db_grph my-4">
                <div class="col-md-6 text-center">

                    <div class="dbox">
                        <app-exporter-country-graph #childCountry [financialYear]="financialYear">
                        </app-exporter-country-graph>
                    </div>

                </div>
            </div>
            <div class="row db_grph my-4">
                <div class="col-md-12">
                    <div class="dbox">
                        <app-exporter-export-graph #childExport [financialYear]="financialYear">
                        </app-exporter-export-graph>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</section>