import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from "../../../common-service.service";


@Component({
  selector: 'app-blocked-ip',
  templateUrl: './blocked-ip.component.html',
  styleUrls: ['./blocked-ip.component.css']
})


export class BlockedIpComponent implements OnInit {

  loading: boolean=true;
  cols: any[];
  data:any;
  datasource:any;
  totalRecords: number;  
  isLoggedIn:number;
  isPermissionAdd:boolean=false
  isPermissionEdit:boolean=false
  constructor(private _router:Router,private _commonService:CommonServiceService) { }

  ngOnInit(): void {
    this.cols = [
      {field: 'Id', header: 'id',width: '5%',isSearch:"1"},
      {field: 'IP', header: 'ip',width: '15%',isSearch:"1"},
      {field: 'Blocked Time', header: 'blockedTime',width: '8%',isSearch:"1"},
      {field: 'Release Time', header: 'releaseTime',width: '8%',isSearch:"1"},
    ];
  }

  loadUsers(event: LazyLoadEvent) {  
    this.loading = true;
   
    setTimeout(() => {  

      this._commonService.getBlockedIp(event.first,event.rows,event.sortField,event.sortOrder,event.filters).subscribe((items: any) => {
        this.datasource = items.result;
        this.data = items.data.data;
        this.totalRecords = items.data.totalRecord;
        this.loading = false;
      },error=>{
         
        if(error instanceof HttpErrorResponse)
        {
          if(error.status===401){
            this._router.navigate(['./']);
          }
        }
      });
    }, 1000);
    
} 
}

