<p-toast></p-toast>
<form [formGroup]="userFrm" name="userFrm" (ngSubmit)="saveUser()"  >
    <div class="pageheadingouter">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> {{pageHeading}} </h1>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <a  [routerLink]="['/setup/user']"><button type="button" class="btn btn-light"> <i class="fa fa-times"></i> Cancel</button></a>
                    <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>

                </div>


            </div>

        </div>
    </div>
    <div class="container pt-4">
        <div class="card">
            <div class="card-header" id="headingOne">
                <a > User Detail</a>
            </div>
            <div id="item_details" >
                <div class="card-body px-0">
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> User Type </label>
                            <select class="form-control" id="userType" formControlName="userType" name="userType" [ngClass]="{ 'is-invalid': submitted && f.userType.errors }" (change)="validateBranch($event)">
                                <option value="">Select Type </option>
                                <option value="1">HO Office</option>
                                <option value="2">Branch</option>
                                
                            </select>  
                            <div *ngIf="submitted && f.userType.errors" class="invalid-feedback">
                                <div *ngIf="f.userType.errors.required">User Type is required</div>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Branch </label>
                            <select class="form-control" id="branch_id" formControlName="branch_id" name="branch_id" [ngClass]="{ 'is-invalid': submitted && f.branch_id.errors }"  >
                                <option value="">Select Branch</option>
                                <option value="0">All Branch</option>
                                <option *ngFor="let item of branchList | keyvalue" value="{{item.value.id}}" >{{item.value.name}}</option>
                            </select>                              
                            <div *ngIf="submitted && f.branch_id.errors" class="invalid-feedback">
                                <div *ngIf="f.branch_id.errors.required">Branch is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> User Role  </label>
                            <select class="form-control" id="role_id" formControlName="role_id" name="user_type_id" [ngClass]="{ 'is-invalid': submitted && f.role_id.errors }" >
                                <option value="">Select User Role</option>
                                <option *ngFor="let item of userType | keyvalue" value="{{item.value.id}}" >{{item.value.name}}</option>
                            </select>              
                            <div *ngIf="submitted && f.role_id.errors" class="invalid-feedback">
                                <div *ngIf="f.role_id.errors.required">User Role is required</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> First Name </label>
                            <input type="text" pInputText formControlName="first_name" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" value="" />
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">First Name  is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Last Name </label>
                            <input type="text" pInputText formControlName="last_name" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" value="">
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Last Name  is required</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Email </label>
                            <input type="text" pInputText formControlName="email" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" value="" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Mobile </label>
                            <input type="text" pInputText formControlName="mobile" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" value="">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Password </label>
                            
                            
                            <input type="password" pInputText formControlName="password" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" value="" />
                            <span class="label_note">Note : Password should be combination of lowercase,uppercase,numeric,special characters and minimum length should be 8</span>
                            
                           <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password  is required</div>

                                <!-- <div *ngIf="f.password.errors.pattern">Password should be combination of lowercase,uppercase,numeric,special characters and minimum length should be 8</div> -->
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Confirm Password </label>
                            <input type="password" pInputText formControlName="cpassword" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.cpassword.errors }" value="">
                            <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.cpassword.errors.required">Please confirm password </div>
                            </div>
                            <div *ngIf="submitted && (f.cpassword.errors || userFrm.errors?.mismatch)" >
                                Password doesn't match
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Status</label>
                            <select class="form-control" id="status" formControlName="status" name="status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }" >
                                <option value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">inActive</option>
                            </select>  
                        </div>
                                         
                    </div>
                </div>
            </div>
        </div>

    </div>
    <input type="hidden" pInputText formControlName="userId" class="form-control" value="">
</form>