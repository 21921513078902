import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { apiUrl } from 'src/environments/environment';
import { CommonServiceService } from "../../common-service.service";
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-invoice-upload-excel',
  templateUrl: './invoice-upload-excel.component.html',
  styleUrls: ['./invoice-upload-excel.component.css']
})
export class InvoiceUploadExcelComponent implements OnInit {

  isloader: boolean = false;
  submitted: boolean = false;
  totalExporter: number = 0;
  fileUrl: string = '';
  isUpdateFile: boolean = false;
  showResult: boolean = false;
  uploadResult: string = "";
  fileName: string = "";
  constructor(private formBuilder: FormBuilder, private messageService: MessageService, private _service: CommonServiceService, private _router: Router) { }

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),

    fileSource: new FormControl('', [Validators.required]),
    updateExisting: new FormControl(''),
  });

  ngOnInit(): void {
    this.fileUrl = `${apiUrl}` + "exporter/forceDownload";
  }
  get f() {
    return this.myForm.controls;
  }


  downloadExcel() {
    this.isloader = true;
    this._service.downloadExcel().subscribe((items: any) => {
      if (items.data != '') {
        this.fileName = items.data;
        this.fileUrl = `${apiUrl}` + "exporter/forceDownload/?file=" + this.fileName;
        this.isUpdateFile = true;
        this.isloader = false;
      }
    });

  }



  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.myForm.invalid) {
      return;
    }
    const formData = new FormData();
    this.isloader = false;
    formData.append('file', this.myForm.get('fileSource').value);
    formData.append('updateExisting', this.myForm.get('updateExisting').value);
    this._service.uploadExcel(formData).subscribe((items: any) => {

      if (items.status == 0) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
        this.isloader = false;
        return;
      }
      if (items.success) {
        this.showResult = true
        this.uploadResult = items.message;
        this.isloader = false;
      }
      else {
        this.isloader = false;
      }
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }

}
