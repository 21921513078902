import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { calendarRange } from 'src/environments/environment';
import { ReportServiceService } from '../report-service.service';
import { Table } from 'primeng/table';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-dynamic-repot',
  templateUrl: './dynamic-repot.component.html',
  styleUrls: ['./dynamic-repot.component.css']
})
export class DynamicRepotComponent implements OnInit {

  reportFrm: FormGroup;
  sourceGroup: any;
  targetGroup: any;
  reportTitle: string;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  data: any;
  reportData: any = [];
  showTextBox: boolean = false;
  reportFilters: any = [];
  searchFrm: FormGroup;
  display: boolean = false;
  filterData: any = [];
  tableColumns: any = [];
  selectedFilters: any = [];
  relatedDataHeader: string;
  filterRowData: any = [];
  openPopupValue: number = 0;
  displayField: any = [];
  saveSearches: any = [];

  reportColumn: string = '';
  reportColumnArray: any = [];
  grpType: string = "";
  showGrpOption: boolean = false;
  sNumber: number = 1;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  calendarRange: string = calendarRange;
  @ViewChild('dt') public table: Table;


  constructor(private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ReportServiceService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      selectedPort: [''],
      viewDetailReport: [''],
      reportType: ["port"],
      topRecordNum: ['0'],
      topRecordNumOther: ['1'],
      displayField: ['', Validators.required],
      sortColumn: ['FobDolMil'],
      sortType: ['DESC'],
      searchFilters: this.formBuilder.array([]),
      isgroupItem: [''],
      searchName: [''],
      isSaveSearch: ['0'],
      saveSearches: [''],
      dateRangeOption: ['']
    });
    this._service.getReportFilters().subscribe((filters: any) => {
      this.reportFilters = filters.data.filterData;
      this.sourceGroup = filters.data.columnData;
      this.displayField = filters.data.displayField;
      this.saveSearches = filters.data.saveSearch;


    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
    this.primengConfig.ripple = true;
  }


  searchFilters(): FormArray {
    return this.reportFrm.get("searchFilters") as FormArray
  }
  newFilter(): FormGroup {
    return this.formBuilder.group({
      filterId: ['', Validators.required],
      filterType: ['', Validators.required],
      filterValues: ['', Validators.required],
      filterCondition: ['', Validators.required],
    });
  }

  addRow() {
    this.searchFilters().push(this.newFilter());
  }

  removeSearchFilter(i: number) {
    this.searchFilters().removeAt(i);
  }

  showDialog(k) {
    let filterId = this.searchFilters().controls[k].get('filterId').value;
    this.table.reset();
    this.selectedFilters = [];
    if (filterId != "") {
      let filterObj = this.reportFilters.filter(dataObj => dataObj.id == filterId);
      if (filterObj[0].is_dependent == 1) {
        this.filterData = "";
        this.isloader = true;
        let data = {
          "id": filterId
        }
        this._service.getFilterDependentData(data).subscribe((filtersData: any) => {
          this.filterData = filtersData.data;
          var jsonObj = this.filterData[0];
          this.tableColumns = Object.keys(jsonObj);
          this.isloader = false;
          this.relatedDataHeader = "Please Select " + filterObj[0].name;
          this.display = true;
          this.openPopupValue = k;
        });
      }
    }
  }
  selectFilters() {
    var jsonObj = this.selectedFilters[0];
    let filterColumn = Object.keys(jsonObj);
    let selectedData = "";
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if (i == (this.selectedFilters.length - 1)) {
        selectedData += this.selectedFilters[i][filterColumn[0]];
      }
      else {
        selectedData += this.selectedFilters[i][filterColumn[0]] + ",";
      }
    }
    let k = this.openPopupValue;
    this.searchFilters().controls[k].get('filterValues').setValue(selectedData);
    this.display = false;
  }
  getFilterOption(event, k) {

    let item = this.reportFilters.filter(record => record.id == event.target.value);
    console.warn("Item = ", item)


  }
  get f() { return this.reportFrm.controls; }

  getReport(buttonText) {

    //console.warn("Event = ", buttonText);
    if (buttonText == "saveSearch") {
      this.reportFrm.controls.isSaveSearch.patchValue('1', { emitEvent: false });

    }
    else {
      this.reportFrm.controls.isSaveSearch.patchValue('0', { emitEvent: false });
    }
   // this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //

    this.submitted = true;

    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    else {
      if (buttonText == "saveSearch" && this.reportFrm.value.searchName == "") {


        this.isloader = false;
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Enter the name of search  ", life: 6000 });
        return;
      }
    }

    this.reportColumn = "";
    this.reportData = "";
    this.isloader = true;
    this._service.getDynamicReport(this.reportFrm.value).subscribe((items: any) => {
      this.reportColumn = items.data.reportColumn;
      this.reportData = items.data.reportData;
      this.displayDetailedReport = true;
      this.isloader = false;

    })
  }
  unsorted() { }

  getTotal(key) {

    let totalQuantity = 0;
    for (let i = 0; i < this.reportData.length; i++) {
      if (key == "totalQty") {
        totalQuantity = totalQuantity + this.reportData[i].totalQty;
      }
      if (key == "fobDol") {
        totalQuantity = totalQuantity + this.reportData[i].fobDol;
      }
      if (key == "FobRs") {
        totalQuantity = totalQuantity + this.reportData[i].FobRs;
      }
    }
    return totalQuantity.toFixed(2);
  }

  getGroupOption($event) {
    let item = this.displayField.filter(record => record.id == $event.target.value);
    if (item[0].is_group_option == 1) {
      this.showGrpOption = true;
      this.grpType = item[0].name
    }
    else {
      this.showGrpOption = false;
      this.grpType = '';
    }
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report_detail.csv");

    });
  }

  getDateRange($event) {
    console.warn($event.target.value);
    let startDate
    if ($event.target.value == 1) {
      let date = new Date();
      startDate = this.datepipe.transform(date, 'dd-MM-yyyy');
      this.reportFrm.controls['dateStart'].setValue(startDate);
      this.reportFrm.controls['dateEnd'].setValue(startDate);
    }
    if ($event.target.value == 2) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      startDate = this.datepipe.transform(yesterday, 'dd-MM-yyyy');
      this.reportFrm.controls['dateStart'].setValue(startDate);
      this.reportFrm.controls['dateEnd'].setValue(startDate);
    }
    if ($event.target.value == 3) {
      let end_date = new Date();
      let dateEnd = this.datepipe.transform(end_date, 'dd-MM-yyyy');
      this.reportFrm.controls['dateEnd'].setValue(dateEnd);
      var start_date = new Date();
      //date.setDate(0);
      let startDate = start_date.setDate(1);
      let dateStart = this.datepipe.transform(startDate, 'dd-MM-yyyy');

      this.reportFrm.controls['dateStart'].setValue(dateStart);

    }
    if ($event.target.value == 4) {
      let end_date = new Date();
      let dateEnd = this.datepipe.transform(end_date, 'dd-MM-yyyy');
      this.reportFrm.controls['dateEnd'].setValue(dateEnd);
      var start_date = new Date();
      //date.setDate(0);
      let startDate = start_date.setMonth(0);
      let dateStart = this.datepipe.transform(startDate, 'dd-MM-yyyy');

      this.reportFrm.controls['dateStart'].setValue(dateStart);

    }


  }
  getSearchDetail($event) {
    if ($event.target.value > 0) {
      this.isloader = false;;
      // console.warn("Filter Length = ", this.searchFilters().length);
      this.searchFilters().clear();
      let saveSearchName = $event.target.options[$event.target.options.selectedIndex].text;

      this._service.getReportDetail($event.target.value).subscribe((items: any) => {
        let searchDetail = JSON.parse(items.data[0].search_content);


        if (searchDetail.dateRangeOption == 5) {
          let startDate = searchDetail.dateStart.toString().split("-").reverse().join("-");
          let endDate = searchDetail.dateEnd.toString().split("-").reverse().join("-");
          this.reportFrm.controls['dateStart'].setValue(startDate);
          this.reportFrm.controls['dateEnd'].setValue(endDate);
        }
        else {
          if (searchDetail.dateRangeOption == 1) {
            let date = new Date();
            let startDate = this.datepipe.transform(date, 'dd-MM-yyyy');
            this.reportFrm.controls['dateStart'].setValue(startDate);
            this.reportFrm.controls['dateEnd'].setValue(startDate);
          }
          if (searchDetail.dateRangeOption == 2) {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            let startDate = this.datepipe.transform(yesterday, 'dd-MM-yyyy');
            this.reportFrm.controls['dateStart'].setValue(startDate);
            this.reportFrm.controls['dateEnd'].setValue(startDate);
          }
          if (searchDetail.dateRangeOption == 3) {
            let end_date = new Date();
            let dateEnd = this.datepipe.transform(end_date, 'dd-MM-yyyy');
            this.reportFrm.controls['dateEnd'].setValue(dateEnd);
            var start_date = new Date();
            //date.setDate(0);
            let startDate = start_date.setDate(1);
            let dateStart = this.datepipe.transform(startDate, 'dd-MM-yyyy');

            this.reportFrm.controls['dateStart'].setValue(dateStart);

          }
          if (searchDetail.dateRangeOption == 4) {
            let end_date = new Date();
            let dateEnd = this.datepipe.transform(end_date, 'dd-MM-yyyy');
            this.reportFrm.controls['dateEnd'].setValue(dateEnd);
            var start_date = new Date();
            //date.setDate(0);
            let startDate = start_date.setMonth(0);
            let dateStart = this.datepipe.transform(startDate, 'dd-MM-yyyy');

            this.reportFrm.controls['dateStart'].setValue(dateStart);

          }

        }
        this.reportFrm.controls['dateRangeOption'].setValue(searchDetail.dateRangeOption);
        //this.reportFrm.controls['topRecordNumOther'].setValue(searchDetail.sortColumn);
        this.reportFrm.controls['sortColumn'].setValue(searchDetail.sortColumn);
        this.reportFrm.controls['sortType'].setValue(searchDetail.sortType);
        this.reportFrm.controls['displayField'].setValue(searchDetail.displayField);
        let item = this.displayField.filter(record => record.id == searchDetail.displayField);

        this.reportFrm.controls['isgroupItem'].setValue(searchDetail.isgroupItem);
        this.reportFrm.controls['searchName'].setValue(saveSearchName);


        if (item[0].is_group_option == 1) {
          this.showGrpOption = true;
          this.grpType = item[0].name
        }
        else {
          this.showGrpOption = false;
          this.grpType = '';
        }
        for (let i = 0; i < searchDetail.searchFilter.length; i++) {
          this.addRow();
          this.searchFilters().controls[i].get('filterId').setValue(searchDetail.searchFilter[i].filterId);
          this.searchFilters().controls[i].get('filterType').setValue(searchDetail.searchFilter[i].filterType);
          this.searchFilters().controls[i].get('filterValues').setValue(searchDetail.searchFilter[i].filterValues);
          this.searchFilters().controls[i].get('filterCondition').setValue(searchDetail.searchFilter[i].filterCondition);
        }
        //this.addRow();

        //  this.searchFilters().push(searchDetail.searchFilter);
        //this.reportFrm.controls['filterId'].setValue(searchDetail.displayField);
        //  this.searchFilters().controls[0].get('filterId').setValue("8");
        // this.searchFilters().controls[0].get('filterValues').setValue("01,02,03,04,05,06,07,08,09,10,11,OTH");

        //this.reportFrm.controls.dateStart.patchValue(searchDetail.dateStart, { emitEvent: false });
        this.isloader = false;
      });

    }

  }
}
