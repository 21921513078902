import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from "../../../common-service.service";

@Component({
  selector: 'app-list-statistics',
  templateUrl: './list-statistics.component.html',
  styleUrls: ['./list-statistics.component.css']
})
export class ListStatisticsComponent implements OnInit {

  submenu:any = [];
  constructor(private _commonService:CommonServiceService) { }

  ngOnInit(): void {
    this.submenu = this._commonService.getsubMenus();
  }

}
