<div class="pageheadingouter">
    <div class="container container2">
        <div class="row btn_gr_blk">
            <div class="col-md-5 text-md-left text-sm-left">

                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> Shipping Bill </h1>
                </div>
            </div>

            <div class="col-md-7 text-right">
                <!--
                <a [routerLink]="['/addinvoicepdf']" [queryParams]=""><button type="button" class="btn btn-success"><i
                            class="fa fa-save"></i> Upload Pdf </button></a>
                            
                <a [routerLink]="['/exporter/uploadPdfSez']" [queryParams]=""><button type="button"
                        class="btn btn-success"><i class="fa fa-cloud-upload"></i> Upload SEZ Pdf </button></a>
                        -->
                            
                <a [routerLink]="['/exporter/uploadPdf']" [queryParams]=""><button type="button"
                        class="btn btn-success"><i class="fa fa-cloud-upload"></i> Upload Pdf </button></a>
                <a [routerLink]="['/exporter/uploadExcel']" [queryParams]=""><button type="button"
                        class="btn btn-success"><i class="fa fa-cloud-upload"></i> Upload Excel </button></a>

                <a [routerLink]="['/exporter/addinvoice']" [queryParams]=""><button type="button"
                        class="btn btn-success"><i class="fa fa-plus"></i> Manual Entry  </button></a>
            </div>
        </div>
    </div>
</div>
<section class="innerpage">
    <div class="innershadow">
        <div class="container pt-4">
            <div class="table-responsive">
                <p-table [value]="data" #dt [columns]="cols" [lazy]="true" (onLazyLoad)="loadInvoices($event)"
                    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [loading]="loading"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[10,25,50]">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th pSortableColumn="{{col.header}}" [ngStyle]="{'width': col.width}">{{col.field}}
                                    <p-sortIcon field="{{col.header}}">
                                    </p-sortIcon>
                                </th>
                            </ng-template>
                            <th style="width:5%"></th>
                        </tr>
                        <tr>
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <th [ngStyle]="{'width': col.width}">
                                    <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, col.header, 'startsWith')"
                                        placeholder="Search by {{col.field}}" class="p-column-filter"
                                        style="width: 100%;" />
                                </th>
                            </ng-template>
                            <th style="width:5%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-columns="columns">
                        <tr style="height:34px">
                            <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                <td>
                                    <ng-template [ngIf]="col.header=='status'">
                                        <ng-template [ngIf]="data['statusCode']==1">
                                            <div style="color:green">{{data[col.header]}}</div>
                                        </ng-template>
                                        <ng-template [ngIf]="data['statusCode']!=1">
                                            <strong>{{data[col.header]}}</strong>
                                        </ng-template>
                                    </ng-template>
                                    <ng-template [ngIf]="col.header!='status'">
                                        {{data[col.header]}}
                                    </ng-template>

                                </td>
                            </ng-template>
                            <td class="tableicons">
                                <ng-template [ngIf]="data['statusCode']==1 || data['statusCode']==3">
                                    <a [routerLink]="['/exporter/viewinvoice']" [queryParams]="{id:data.id}">
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-success p-mr-2"></button>
                                    </a>
                                </ng-template>
                                <ng-template [ngIf]="data['statusCode']==1 || data['statusCode']==3">
                                    <a (click)=confirmDelete(data.id);>
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-success p-mr-2"></button>
                                    </a>
                                </ng-template>
                                <!--
                                    <a href='http://mpedastat.broadwayinfotech.net.au/mpedapdf/index.php?type=pdf&id={{data.id}}'
                                        title="Download invoice" alt="Download invoice">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </a>
                                -->
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            {{totalRecords}} Shipping Bill Found.
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</section>
<br>