import { Component, OnInit,Input } from '@angular/core';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-country-graph',
  templateUrl: './country-graph.component.html',
  styleUrls: ['./country-graph.component.css']
})
export class CountryGraphComponent implements OnInit {
  @Input() financialYear: string

  isloaderCountry:boolean=false;
  isShowMapCountry:boolean=false;
  dataCountry:any;
  countryData:any = []; 
  countryGraphType:string;
  titleCountry:string;
  optionsCountry:any;
  constructor(private _service:CommonServiceService) { }

  ngOnInit(): void {


    let data = {
      "financialyear":this.financialYear
    }

    this.isloaderCountry = true;
    this._service.getCountryData(data).subscribe((countryData: any) => {
      this.countryData = countryData.data.reportObject[0].countryData;
      let countryArray = [];
      for(let i=0;i<this.countryData.length;i++){
        let qty = (this.countryData[i].totalQty/1000).toFixed(2);
        countryArray[i] = [this.countryData[i].COUNTRY_NAME,parseFloat(qty)]
      }
      this.countryGraphType = 'BarChart';
      this.dataCountry = countryArray;
      this.dataCountry = countryArray;
      this.titleCountry = "Top 5 Country";
      this.optionsCountry = {
        hAxis: {
           title: 'Quantiy in tons'
        },
        vAxis:{
           title:'Country'
        }  
      };
      

      this.isShowMapCountry = true;
      this.isloaderCountry = false;
    });
  }
  changeOptionCountry(val){
    if(val=="AreaChart" || val=="ColumnChart"){
      this.optionsCountry = {
        hAxis: {
          title: 'Country'
        },
        vAxis:{
          title:'Quantiy in tons'
        }  
      };
    }
    else{
      this.optionsCountry = {
        hAxis: {
          title: 'Quantiy in tons'
        },
        vAxis:{
          title:'Country'
        }  
      };
    }
  }
  getGraph(financialYear) {
    this.isloaderCountry = true;
    let data = {
      "financialyear":financialYear
    }
    this._service.getCountryData(data).subscribe((countryData: any) => {
      this.countryData = countryData.data.reportObject[0].countryData;
      let countryArray = [];
      for(let i=0;i<this.countryData.length;i++){
        let qty = (this.countryData[i].totalQty/1000).toFixed(2);
        countryArray[i] = [this.countryData[i].COUNTRY_NAME,parseFloat(qty)]
      }
      this.dataCountry = countryArray;
      this.isloaderCountry = false;

    });
  }
}
