import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { apiUrl } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class MasterService {

  constructor(private http: HttpClient) { }
  url = `${apiUrl}`;

  getExportData(pageName, data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    data.pageName = pageName;
    return this.http.post(this.url + "masters/getExportData", data, options);

  }

  getMastersData(pageName, startRow, rowNumber, sortField, sortOrder, filters) {
    //  console.warn("Is token =",!!localStorage.getItem('token'));


    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "masters/getData",
      {
        "pageName": pageName,
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);


    //return response.result

  }

  saveMaster(data, pageName) {
    console.log(data);
    console.log(pageName);

    return this.http.post(this.url + "saveMaster", {
      "pageName": pageName,
      "data": data
    });
  }

  getStateData() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.get(this.url + "masters/getStateMaster");
  }
  getManufacturers() {
    return this.http.get(this.url + "masters/getAllManufacturers");
  }
  getCountry() {
    return this.http.get(this.url + "getAllCountries");
  }
  getStates() {
    return this.http.get(this.url + "getAllStates");
  }

  getUnion() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "masters/getAllUnion", {}, options);
  }
  getAllGroups() {
    return this.http.get(this.url + "getAllGroups");
  }
  refreshTable() {
    return true;
  }

  getRegion(startRow, rowNumber, sortField, sortOrder, filters) {


    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "getRegions",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);


    //return response.result

  }
  getRegionDetail(Id) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "getRegionDetail", { "id": Id }, options);
  }
  removeCountry(countryId) {
    return this.http.post(this.url + "removeRegionCountry", { "Id": countryId });
  }
  getCountries() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "getAllCountries", {}, options);
  }
  saveRegion(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "saveRegion", { data: data }, options);
  }
  /*
  getTotalNumber(pageName)
  {
    let url = 'http://localhost:5000/api/getTotalRecord';

    return this.http.post(url,
      {
      "pageName":  pageName
      });
  }
  */
}
