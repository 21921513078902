import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { calendarRange } from 'src/environments/environment';
import { StatisticsServiceService } from '../statistics-service.service';

@Component({
  selector: 'app-statistics-port-export',
  templateUrl: './statistics-port-export.component.html',
  styleUrls: ['./statistics-port-export.component.css']
})
export class StatisticsPortExportComponent implements OnInit {

  reportFrm: FormGroup;
  reportData:any=[];
  reportTitle:string;
  isloader:boolean=false;
  submitted:boolean=false;
  data:any;
  displayReport:boolean = false;
  @ViewChild('userTable') userTable: ElementRef;
  calendarRange:string = calendarRange;


  constructor(private formBuilder: FormBuilder,private primengConfig: PrimeNGConfig,private messageService: MessageService,private _router:Router,private _service:StatisticsServiceService) { }

  ngOnInit(): void {

    this.reportFrm = this.formBuilder.group({
      dateStart:['',Validators.required],
      dateEnd:['',Validators.required],
      reportType:["port"],          
      
    }); 
    this.reportTitle = "Portwise Export Marine products from India for a year";
  }

  get f() { return this.reportFrm.controls; }

  getReport()
  {
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    this._service.getStatisticsByItem(this.reportFrm.value).subscribe((items: any) =>{
      this.data = items.data;
      if(items.data.length>0)
        {
          this.displayReport=true;
          this.isloader = false;
        }
        else{
          this.displayReport=false;
          this.isloader = false;
        }      
      this.reportData = items.data;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });

  }

  getTotalQuantity(){
    let totalQty = 0;
    for(let i=0;i<this.reportData.length;i++){
      totalQty = totalQty+this.reportData[i].totalQty;
    }
    return totalQty.toFixed(0);
  }
  getTotalFobRs(){
    let total = 0;
    for(let i=0;i<this.reportData.length;i++){
      total = total+this.reportData[i].FobRs;
    }
    return total.toFixed(2);
  }
  getTotalfobDol(){
    let total = 0;
    for(let i=0;i<this.reportData.length;i++){
      total = total+this.reportData[i].fobDol;
    }
    return total.toFixed(2);
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
    // save to file
     xlsx.writeFile(workbook, "port_report.csv");
      
    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
    // save to file
     xlsx.writeFile(workbook, "port_report.xlsx");
 
    });
  }
}
