<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} Report </h1>
                </div>
            </div>       
        </div>
    </div>
</div>
<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()"  >
       
        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }" ></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <p-calendar  formControlName="dateEnd"  view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>

                </div>


               
                
                
                <div class="row">
                    <div class="btn_pan mb-4">
                            <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>    
                <div class="txhed_rp" *ngIf="displayReport">
                    <span><b>Q</b>: Quantity in Tons,</span>
                    <span><b>V</b>: Value in Rs. Lakhs,</span>
                    <span><b>$</b>: USD Million</span>
                    
                </div>
                

                <!-- Report Block -->

                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                
                                <table #userTable>         
                                    <p-table [value]="reportData" >
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th width="17%">Port</th>
                                                <th width="15%">Quantity(Tons)</th>
                                                <th width="20%">Value (Rs. Lakhs)</th>
                                                <th width="20%">Value($ m) </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                            <tr >
                                                <td width="17%">{{item.name}}</td>
                                                <td width="15%">{{item.totalQty.toFixed(0)}} </td>
                                                <td width="15%">{{item.FobRs.toFixed(2)}} </td>
                                                <td width="15%">{{item.fobDol.toFixed(2)}} </td>
                                            </tr>
                                            
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td width="17%">Total</td>
                                                <td width="100">{{getTotalQuantity()}}</td>
                                                <td width="15%">{{getTotalFobRs()}}</td>
                                                <td width="15%">{{getTotalfobDol()}}</td>

                                               </tr>
                                            
                                        </ng-template>
                                    </p-table>       
                                </table>
                            </div>
                        </div>
                    </section>
                </section>
           
        </div>

        </div>
        <input type="hidden" formControlName="reportType" />
    </form>
</div>