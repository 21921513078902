<p-toast></p-toast>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <form [formGroup]="requestFrm" name="requestFrm" (ngSubmit)="saveRequest()">

        <div class="pageheadingouter">
            <div class="container container2">

                <div class="row">
                    <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                        <div class="pageheading ">
                            <h1> <img src="assets/img/file_icons.png" alt=""> Request / Reply </h1>
                        </div>
                    </div>



                    <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0"
                        *ngIf="userType=='EXPORTER'">
                        <a [routerLink]="['/exporter/requestList']"><button type="button" class="btn btn-light"> <i
                                    class="fa fa-arrow-left"></i> Back</button></a>
                    </div>


                    <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0"
                        *ngIf="userType=='HOMODULE'">
                        <a [routerLink]="['/requestList']"><button type="button" class="btn btn-light"> <i
                                    class="fa fa-arrow-left"></i> Back</button></a>
                    </div>

                </div>
            </div>
        </div>





        <div class="container pt-4">

            <div class="card-body px-0">
                <div class="row">

                    <div class="col-xl-12 col-md-12 form-group">
                        <strong> Title </strong> :
                        {{requestTitle}}
                    </div>

                    <div class="col-xl-12 col-md-12 form-group">
                        <strong> Description </strong> :
                        {{requestDescription}}
                    </div>



                    <div class="col-xl-12 col-md-12 form-group" *ngIf="getAttachmentName!=null">
                        <label> Attachment </label> :
                        <a href="{{requestAttachment}}" target="_blank">View Attachment</a>
                    </div>


                </div>
            </div>
            <p-accordion>
                <p-accordionTab header="Reply" [selected]="true">

                    <div class="card-body px-0">
                        <div class="row">



                            <input type="hidden" formControlName="requestId" [(ngModel)]="requestId">

                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Message </label>

                                <textarea rows="10" cols="10" formControlName="message" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.message.errors }" [(ngModel)]="message"
                                    maxlength="2000">
                                </textarea>

                                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required">The Message field is required </div>
                                </div>
                            </div>


                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Attachment </label>
                                <input formControlName="file" id="file" type="file" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.file.errors }"
                                    (change)="onFileChange($event)">
                            </div>



                            <div class="col-xl-12 col-md-12 form-group">
                                <label> Status </label>

                                <select class="form-control" id="status" formControlName="status" name="status"
                                    [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                    <option value="">Select Status </option>
                                    <option [value]="1" [selected]="status ==1">Open</option>
                                    <option [value]="2" [selected]="status ==2">In-progress</option>
                                    <option [value]="3" [selected]="status ==3">Close</option>
                                </select>
                                <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                    <div *ngIf="f.status.errors.required">The Status is required</div>
                                </div>
                            </div>






                            <div class="col-xl-12 col-md-12 form-group">

                                <a [routerLink]="['/exporter/requestList']" *ngIf="userType=='EXPORTER'">
                                    <button type="button" class="btn btn-light">
                                        <i class="fa fa-arrow-left"></i> Back
                                    </button>
                                </a>

                                <a [routerLink]="['/requestList']" *ngIf="userType=='HOMODULE'">
                                    <button type="button" class="btn btn-light">
                                        <i class="fa fa-arrow-left"></i> Back
                                    </button>
                                </a>
                                <span> </span>

                                <button class="btn btn-success saveBtn"><i class="fa fa-save"></i>Save</button>

                            </div>








                            <div class="col-xl-12 col-md-12 form-group">
                                <h4> Replies </h4>
                            </div>



                            <div *ngFor="let item of replies" class="col-xl-12 col-md-12 form-group">
                                <div class="message exporter ">
                                    <span class="replyBy"> ( {{item.type}} ) </span>
                                    <p>{{item.message}}</p>

                                    <p *ngIf="item.attachment!=null">
                                        <a href="{{baseUrl}}{{item.attachment}}" target="_blank">View Attachment</a>
                                    </p>

                                    <span class="date">{{item.date}}</span>
                                </div>
                            </div>

                            <div class="col-xl-12 col-md-12 form-group" *ngIf="replies.length==0">
                                <div class="notFound"> Replies not found </div>
                            </div>


                            <!-- <div class="col-xl-12 col-md-12 form-group">
                                <div class="message darker">
                                    <span class="replyBy"> (HO Module) Vikas Mathur </span>
                                    <p>Hey! I'm fine. Thanks for asking!</p>
                                    <span class="date">06-04-2021 11:01</span>
                                </div>
                            </div> -->

                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>

            <br />







        </div>

    </form>
</section>