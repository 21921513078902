<p-toast></p-toast>
<form [formGroup]="recocileBatchFrm" name="recocileBatchFrm">
    <div class="pageheadingouter">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> Reconcile Shipping Bill </h1>
                    </div>
                </div>
                <!--
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <a [routerLink]="['/setup/reconcile']"><button type="button" class="btn btn-light"> <i
                                class="fa fa-times"></i> Cancel</button></a>
                    <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>

                </div>
-->

            </div>

        </div>
    </div>
    <div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>

    <div class="container pt-4">
        <div class="card bordered">
            <div class="card-header" id="headingOne">
                <a> Reconcile Shipping Bill</a>
            </div>
            <div class="pad" id="item_details">
                <div class="card-body px-0 ">
                    <div class="row ">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Batch Name </label>
                            <input type="text" pInputText formControlName="batchName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.batchName.errors }" value="" />
                            <div *ngIf="submitted && f.batchName.errors" class="invalid-feedback">
                                <div *ngIf="f.batchName.errors.required">Batch Name is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            &nbsp;
                        </div>

                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Date Range </label>
                            <span class="label_sub" style="color: #999;">From </span>
                            <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy"
                                [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true"
                                inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }">
                            </p-calendar>
                            <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                                <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> &nbsp; </label>
                            <span class="label_sub" style="color: #999;">To </span>
                            <p-calendar formControlName="dateEnd" view="month" dateFormat="mm/yy" [yearNavigator]="true"
                                yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker"
                                [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                            <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                                <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <button class="btn btn-success" (click)="getShippingBill()"><i class="fa fa-save"></i>Get
                                Shipping Bill</button>
                        </div>
                    </div>
                    <ng-template [ngIf]="totalRecords>0">


                        <div class="row">
                            <section class="innerpage">
                                <div class="innershadow">

                                    <div class="">
                                        <div class="table-responsive">
                                            <table width="100%">
                                                <tr>
                                                    <td width="5%">Quantity In</td>
                                                    <td width="20%">
                                                        <select class="form-control" style="width: 20%;"
                                                            (change)="getQuantityOption($event)">
                                                            <option value="1">KG </option>
                                                            <option value="2">MT </option>
                                                        </select>
                                                    </td>
                                                    <td width="5%">Amount In</td>
                                                    <td width="20%">
                                                        <select class="form-control" style="width: 30%;"
                                                            (change)="getAmountOption($event)">
                                                            <option value="0">Select </option>
                                                            <option value="1">Lakh </option>
                                                            <option value="2">Crore </option>
                                                        </select>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="table-responsive">
                                            <table #detailTable>
                                                <p-table [value]="data" [columns]="cols" [paginator]="true" [rows]="10"
                                                    [totalRecords]="totalRecords" [loading]="loading"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                                    [rowsPerPageOptions]="[10,25,50]">
                                                    <ng-template pTemplate="caption">
                                                        <div class="p-d-flex">
                                                            <button type="button" pButton pRipple icon="pi pi-file-o"
                                                                (click)="exportShippingBillCSV()" class="p-mr-2"
                                                                pTooltip="CSV" tooltipPosition="bottom"></button>
                                                            <button type="button" pButton pRipple
                                                                icon="pi pi-file-excel" (click)="exportDetailExcel()"
                                                                class="p-button-success p-mr-2" pTooltip="XLS"
                                                                tooltipPosition="bottom"></button>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr>
                                                            <ng-template *ngFor="let col of columns"
                                                                [ngIf]="col.type!='hidden'">
                                                                <th pSortableColumn="{{col.header}}"
                                                                    [ngStyle]="{'width': col.width}">
                                                                    <ng-template [ngIf]="col.field=='Qty(KG)'">
                                                                        <ng-template [ngIf]="quantityOption==1 ">
                                                                            {{col.field}}
                                                                        </ng-template>
                                                                        <ng-template [ngIf]="quantityOption==2">Qty(MT)
                                                                        </ng-template>
                                                                    </ng-template>
                                                                    <ng-template [ngIf]="col.field!='Qty(KG)'">
                                                                        {{col.field}}
                                                                    </ng-template>
                                                                    <p-sortIcon field="{{col.header}}">
                                                                    </p-sortIcon>
                                                                </th>
                                                            </ng-template>
                                                        </tr>
                                                        <tr>
                                                            <ng-template *ngFor="let col of columns"
                                                                [ngIf]="col.type!='hidden'">
                                                                <th [ngStyle]="{'width': col.width}">
                                                                    <input pInputText type="text"
                                                                        (input)="dt.filter($event.target.value, col.header, 'startsWith')"
                                                                        placeholder="Search by {{col.field}}"
                                                                        class="p-column-filter" style="width: 100%;" />
                                                                </th>
                                                            </ng-template>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-data let-columns="columns">
                                                        <tr style="height:34px">
                                                            <ng-template *ngFor="let col of columns"
                                                                [ngIf]="col.type!='hidden' && col.field!='Qty(MT)'">
                                                                <td>
                                                                    <ng-template [ngIf]="col.header=='status'">
                                                                        <ng-template [ngIf]="data['statusCode']==1">
                                                                            <div style="color:green">
                                                                                {{data[col.header]}}
                                                                            </div>
                                                                        </ng-template>
                                                                        <ng-template [ngIf]="data['statusCode']!=1">
                                                                            <strong>{{data[col.header]}}</strong>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                    <ng-template [ngIf]="col.header=='EXP_NAME'">
                                                                        <div>
                                                                            {{data[col.header]}}-({{data['regNumber']}})
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template
                                                                        [ngIf]="col.header!='status' && (col.header!='EXP_NAME' &&  col.field!='Qty(KG)' &&  col.field!='Amount($)' &&  col.field!='Amount(₹)') ">
                                                                        {{data[col.header]}}
                                                                    </ng-template>
                                                                    <ng-template
                                                                        [ngIf]="col.header!='status' && col.field=='Amount($)' ">
                                                                        <ng-template [ngIf]="amountOption==0 ">
                                                                            {{data[col.header]| number}}
                                                                        </ng-template>
                                                                        <ng-template [ngIf]="amountOption==1">
                                                                            {{data[col.header]/100000 | number}}
                                                                        </ng-template>
                                                                        <ng-template [ngIf]="amountOption==2">
                                                                            {{data[col.header]/10000000 | number}}
                                                                        </ng-template>
                                                                    </ng-template>
                                                                    <ng-template
                                                                        [ngIf]="col.header!='status' && col.field=='Amount(₹)' ">
                                                                        <ng-template [ngIf]="amountOption==0 ">
                                                                            {{data[col.header]| number}}
                                                                        </ng-template>
                                                                        <ng-template [ngIf]="amountOption==1">
                                                                            {{data[col.header]/100000 | number}}
                                                                        </ng-template>
                                                                        <ng-template [ngIf]="amountOption==2">
                                                                            {{data[col.header]/10000000 | number}}
                                                                        </ng-template>
                                                                    </ng-template>
                                                                    <ng-template
                                                                        [ngIf]="col.header!='status' && (col.header!='EXP_NAME' &&  col.field=='Qty(KG)') ">
                                                                        <ng-template [ngIf]="quantityOption==1 ">
                                                                            {{data[col.header]| number}}
                                                                        </ng-template>
                                                                        <ng-template [ngIf]="quantityOption==2">
                                                                            {{data[col.header]/1000 | number}}
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </td>
                                                            </ng-template>

                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="footer">
                                                        <tr>
                                                            <td colspan="6"></td>
                                                            <td>Totals</td>
                                                            <td>
                                                                <ng-template [ngIf]="quantityOption==1 ">
                                                                    {{quantityTotal| number}}
                                                                </ng-template>
                                                                <ng-template [ngIf]="quantityOption==2">
                                                                    {{quantityTotal/1000 | number}}
                                                                </ng-template>
                                                            </td>
                                                            <td>
                                                                <ng-template [ngIf]="amountOption==0 ">
                                                                    {{amountTotal| number}}
                                                                </ng-template>
                                                                <ng-template [ngIf]="amountOption==1">
                                                                    {{amountTotal/100000 | number}}
                                                                </ng-template>
                                                                <ng-template [ngIf]="amountOption==2">
                                                                    {{amountTotal/10000000 | number}}
                                                                </ng-template>
                                                            </td>
                                                            <td>
                                                                <ng-template [ngIf]="amountOption==0 ">
                                                                    {{amountTotalRs| number}}
                                                                </ng-template>
                                                                <ng-template [ngIf]="amountOption==1">
                                                                    {{amountTotalRs/100000 | number}}
                                                                </ng-template>
                                                                <ng-template [ngIf]="amountOption==2">
                                                                    {{amountTotalRs/10000000 | number}}
                                                                </ng-template>
                                                            </td>
                                                            <td colspan="2"></td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="summary">
                                                        <div class="p-d-flex p-ai-center p-jc-between">
                                                            {{totalRecords}} Shipping Bill Found.
                                                        </div>
                                                    </ng-template>
                                                </p-table>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12 col-md-12 form-group">
                                        &nbsp;
                                    </div>
                                    <div class="col-xl-12 col-md-12 form-group">
                                        <input type="checkbox" formControlName="declaration" value="1" />&nbsp;I have
                                        reviewed
                                        all the shipping Bills as per supporting documents submitted by
                                        Exporter
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-xl-5 col-md-6 text-md-left text-sm-left text-center">
                                    </div>
                                    <div
                                        class="col-xl-7 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0 ng-star-inserted">
                                        <a [routerLink]="['/setup/reconcile']"><button type="button"
                                                class="btn btn-light"> <i class="fa fa-times"></i> Cancel</button></a>
                                        <button class="btn btn-success" (click)="saveRecocile('2')"><i
                                                class="fa fa-save"></i>Save &
                                            Submit</button>
                                        &nbsp; <button class="btn btn-success" (click)="saveRecocile('1')"><i
                                                class="fa fa-save"></i>Save As Draft</button>

                                    </div>
                                </div>

                            </section>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>
    <input type="hidden" pInputText formControlName="totalQuantity" class="form-control" value="" />
    <input type="hidden" pInputText formControlName="totaAmountDollar" class="form-control" value="" />
    <input type="hidden" pInputText formControlName="totaAmountRs" class="form-control" value="" />
    <input type="hidden" formControlName="status" class="form-control" />
    <input type="hidden" formControlName="invoiceIds" class="form-control" />
    <input type="hidden" pInputText formControlName="batchId" class="form-control" value="">
    <input type="text" formControlName="exporterId" />
</form>