import { Component, OnInit } from '@angular/core';

import { ExporterServiceService } from '../exporter-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { MessageService } from 'primeng/api';
import { fileAllowedExtensions, fileAllowedSize,poundToKg } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import jwt_decode from "jwt-decode";
import { isTypeNode } from 'typescript';
import { publicUrl } from 'src/environments/environment';




@Component({
  selector: 'app-request-reply',
  templateUrl: './request-reply.component.html',
  styleUrls: ['./request-reply.component.css']
})
export class RequestReplyComponent implements OnInit {


  requestFrm: FormGroup;
  submitted = false;
  isloader: boolean = false;
  requestTitle: any;
  requestDescription: any;
  requestAttachment:any;
  getAttachmentName:any;
  baseUrl:any;
  requestId: any;
  replies: [];
  message: any;
  file:any;
  userType: any;

  status : any;
  
  constructor(private primengConfig: PrimeNGConfig, private route: ActivatedRoute, private _service: ExporterServiceService, private formBuilder: FormBuilder, private messageService: MessageService, private _router: Router) { }

  ngOnInit(): void {

    // this.baseUrl = 'http://localhost:5000/request/';
    this.baseUrl = publicUrl+'request/';
    console.log("Base Url", this.baseUrl);

    this.requestFrm = this.formBuilder.group({
      requestId: ['', Validators.required],
      message: ['', Validators.required],
      status: [''],
      file: [''],
      fileSource: [''],
    });




    this.route.params.subscribe(params => {
      if (params['id'] != "") {

        this._service.getRequestDetail(params['id']).subscribe((item: any) => {
          console.log(" get data 123:", item);
          this.requestTitle = item.data.title;
          this.requestDescription = item.data.description;
          this.getAttachmentName = item.data.attachment;
          this.requestAttachment = this.baseUrl+''+item.data.attachment;
          this.requestId = item.data.id;
          this.userType = item.data.userType;
          this.status = item.data.status;
          
          this.requestFrm.setValue({
            // title:  item.data.title,
            // message: item.data.title,
            // status : item.data.status,
          });
        });

        // Get Replies history 

        this._service.getReplyesDetail(params['id']).subscribe((item: any) => {
          console.log(" get replyes details ", item);
          this.replies = item.data;
        });

      }
    });

  }

  get f() { return this.requestFrm.controls; }

  saveRequest() {

    this.submitted = true;
    if (this.requestFrm.invalid) {
      return;
    }

    
      const formData = new FormData();
      formData.append('file', this.requestFrm.get('fileSource').value);
      formData.append('message', this.requestFrm.get('message').value);
      formData.append('requestId', this.requestFrm.get('requestId').value);
      // formData.append('requestId', this.requestId);
      
      if(this.requestFrm.get('status').value){
        formData.append('status', this.requestFrm.get('status').value);
      }else{
        formData.append('status', this.status);
      }


     this._service.saveReplyRequest(formData).subscribe((items: any) => {
        if (items.success) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }
          ); //
  
          // this.requestFrm.reset();
          this.message = '';
         
          this.route.params.subscribe(params => {
            this._service.getReplyesDetail(params['id']).subscribe((item: any) => {
              console.log(" get replyes details 1234", item);
              this.replies = item.data;
              console.log("aaaaaa", this.replies);
            });
          });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
        }
        this.submitted = false;
        return false;
      });
  }


  onFileChangeOld(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.requestFrm.patchValue({
        fileSource: file,
        file:""
      });
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let maxUploadSize = `${fileAllowedSize}`;
      let filesizeMb: any = 0;
      filesizeMb = (file.size / 1024) / 1024;
      let fileExtensionArray = file.name.split('.');
      //console.warn("Extension Length", fileExtensionArray.length);

      let fileExtension = file.name.split('.').pop();
      // console.warn(fileExtension);
      let allowedExtension = `${fileAllowedExtensions}`;
      if (fileExtensionArray.length == 2) {
        if (allowedExtension.indexOf(fileExtension.toLowerCase()) > -1) {
          if (filesizeMb <= maxUploadSize) {
            this.requestFrm.patchValue({
              fileSource: file
            });
          }
          else {
            this.requestFrm.patchValue({
              fileSource: "",
              file:""
            });
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: "File size is not greater than  " + maxUploadSize + " MB", life: 6000 }); //success,info,warn,error
          }

        }
        else {
          this.requestFrm.patchValue({
            fileSource: "",
            file:""
          });
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); //success,info,warn,error
        }
      }
      else {
        this.requestFrm.patchValue({
          fileSource: "",
          file:""
        });
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Upload Valid File ", life: 6000 }); //success,info,warn,error
      }

    }
  }




}
