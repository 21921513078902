import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AuthService } from '../../components/user-login/auth.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { encryptSecretKey } from 'src/environments/environment';


@Component({
  selector: 'app-exporter-login',
  templateUrl: './exporter-login.component.html',
  styleUrls: ['./exporter-login.component.css']
})
export class ExporterLoginComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private messageService: MessageService, private router: Router) { }

  loginFrom: FormGroup;
  submitted: boolean;
  isLogin: number;
  fieldTextType: boolean;
  siteKey: string;

  ngOnInit(): void {
    this.loginFrom = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      recaptcha: ['', [Validators.required]]

    });
    this.authService.getdataBeforeLogin().subscribe((items: any) => {
      if (items.status == 1) {
        this.siteKey = items.data[0].siteKey;
      } else {
      }
    });
  }

  get f() { return this.loginFrom.controls; }

  submitLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginFrom.invalid) {
      return;
    }
    let time = Math.floor(Date.now() / 1000);
    let encrptionKey = encryptSecretKey + "_" + time;
    let cipherText = CryptoJS.AES.encrypt(this.loginFrom.value.password, encrptionKey).toString();

    let logindata = {
      "email": this.loginFrom.value.email,
      "password": cipherText,
      "time": time
    }


    this.authService.loginExpoter(logindata).subscribe((items: any) => {
      if (items.data.status == 0) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.data.message, life: 6000 }); //success,info,warn,error
      }
      else {
        localStorage.setItem('token', JSON.stringify(items.data.token));
        localStorage.setItem('isLogin', "1");
        this.redirectTo('exporter/dashboard');
      }
    });

  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
