import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { Router, ActivatedRoute } from '@angular/router'
import * as CryptoJS from 'crypto-js';
import { encryptSecretKey } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { apiUrl } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  url = `${apiUrl}`;
  isloader: boolean = false;
  submitted: boolean = false

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private http: HttpClient, private messageService: MessageService) { }

  getsubMenus() {


    let url = this.router.url;
    let data = localStorage.getItem('userLevel');
    let bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
    let decoded = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


    for (let i = 0; i < decoded.length; i++) {
      if ("/" + decoded[i].link == url) {
        return decoded[i].submenus;
      }
    }

  }
  getActionButton(parentName, pageName = '') {

    let data = localStorage.getItem('userLevel');
    let bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
    let decoded = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let url = this.router.url;
    let submenusItem: any;

    if (parentName != "") {
      submenusItem = decoded.find(recordAction => recordAction.name === parentName);
    }
    else {
      submenusItem = decoded.find(recordAction => recordAction.name === pageName);
    }

    if (pageName != '') {
      if (submenusItem.actions.length > 0) {
        for (let i = 0; i < submenusItem.actions.length; i++) {
          if (submenusItem.name == pageName) {
            return submenusItem.actions
          }
        }
      }
      if (submenusItem.submenus.length > 0) {
        for (let i = 0; i < submenusItem.submenus.length; i++) {
          if (submenusItem.submenus[i].link == pageName) {
            return submenusItem.submenus[i].actions
          }
        }
      }
    }
    else {
      for (let i = 0; i < submenusItem.submenus.length; i++) {
        if ("/" + submenusItem.submenus[i].link == url) {
          return submenusItem.submenus[i].actions
        }
      }
    }
  }

  getDashboardData() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getDashboardData", '', options);
  }
  getPortData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getDashboardPortData", { "data": data }, options);
  }
  getCountryData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getDashboardCountryData", { "data": data }, options);
  }
  getItemData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getDashboardItemData", { "data": data }, options);
  }
  getExportData(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getDashboardExportData", { "data": data }, options);
  }
  uploadInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadInvoice", data, options);
  }

  validateCalendar(fromDate, toDate) {

    var yearDifferent = toDate - fromDate;
    var fromYear = 1995;
    var d = new Date();
    var getFullYear = d.getFullYear();
    var toYear = getFullYear;



    if (fromDate < fromYear) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select year between" + fromYear + ' and ' + toYear, life: 6000 });
      this.isloader = false;

      return false;
    }

    if (toDate < fromDate) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "To  is always greater then or equal to from ", life: 6000 });
      this.isloader = false;

      return false;
    }


    if ((fromDate < fromYear) || (fromDate > toYear)) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select year between" + fromYear + ' and ' + toYear, life: 6000 });
      this.isloader = false;

      return false;
    }


    if ((toDate > toYear) || (toDate < fromYear)) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select year between" + fromYear + ' and ' + toYear, life: 6000 });
      this.isloader = false;

      return false;
    }

    if (yearDifferent < 0) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Invalid date please enter correct date", life: 6000 });
      this.isloader = false;

      return false;
    }


    if (yearDifferent > 10) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 10", life: 6000 });
      this.isloader = false;
      return false;
    }

  }


  validateCalendar2(fromDate, toDate) {

    var yearDifferent = toDate - fromDate;
    var fromYear = 1995;
    var d = new Date();
    var getFullYear = d.getFullYear();
    var toYear = getFullYear;



    if (fromDate < fromYear) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select year between" + fromYear + ' and ' + toYear, life: 6000 });
      this.isloader = false;
      return false;
    }

    if (toDate < fromDate) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "To  is always greater then or equal to from ", life: 6000 });
      this.isloader = false;

      return false;
    }


    if ((fromDate < fromYear) || (fromDate > toYear)) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select year between" + fromYear + ' and ' + toYear, life: 6000 });
      this.isloader = false;

      return false;
    }


    if ((toDate > toYear) || (toDate < fromYear)) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select year between" + fromYear + ' and ' + toYear, life: 6000 });
      this.isloader = false;

      return false;
    }

    if (yearDifferent < 0) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Invalid date please enter correct date", life: 6000 });
      this.isloader = false;
      return false;
    }


    if (yearDifferent > 2) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3 Year", life: 6000 });
      this.isloader = false;
      return false;
    }

  }

  getNumber(n, d) {
    let qty;
    if (d == 1) {
      qty = n ? n.toFixed() : 0;
    }
    else {
      qty = n ? n.toFixed(2) : 0;
    }
    return qty;
  }


  getShare(itemQty, totalQty) {
    let percentage = itemQty * 100 / totalQty;
    return percentage.toFixed(2);
  }

  getCustomMessage(field) {

    if (field == 'dateStart.required') {
      return "Start Year is required";
    }
    else if (field == 'dateStart.pattern') {
      return "Enter only number";
    }
    else if (field == 'dateStart.min') {
      return "Invalid Year ( It should be greater then or equal to 1995)";
    }
    else if (field == 'dateStart.max') {
      return "Invalid Year ( It should less then or equal to 2021 )";
    }
    else if (field == 'dateEnd.required') {
      return "End Year is required";
    }
    else if (field == 'dateEnd.pattern') {
      return "Enter only number";
    }
    else if (field == 'dateEnd.min') {
      return "Invalid Year ( It should be greater then or equal to 1995)";
    }
    else if (field == 'dateEnd.max') {
      return "Invalid Year ( It should less then or equal to 2021 )";
    }


  }
  downloadExcel() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/downloadExcel", '', options);

  }
  uploadExcel(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadExcel", data, options);
  }
  saveInvoice(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/saveInvoice", { data: data }, options);
  }
  uploadInvoiceFiles(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/uploadInvoiceFiles", data, options);
  }
  getBuyerSearch(query) {
    return this.http.post(this.url + "invoices/getBuyerSearch", { query: query });
  }
  getPortSearch(query) {
    return this.http.post(this.url + "invoices/getPortSearch", { query: query });
  }
  getCountrySearch(query) {
    return this.http.post(this.url + "invoices/getCountrySearch", { query: query });
  }
  getExporters(query) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/getExportersSearch", { query: query }, options);
  }
  getExporterPlants(expCode) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "exporter/getExporterPlants", { "data": expCode }, options);
  }
  getItemGrades(query, str) {
    let data = {
      "query": query,
      "str": str,
    }
    return this.http.post(this.url + "invoices/getItemGrades", { data: data });
  }
  getItemSearch(val, hscode) {
    let data = {
      "hsCode": hscode,
      "query": val
    }
    return this.http.post(this.url + "invoices/getItemSearch", { query: data });
  }
  getSetupsInvoice() {
    return this.http.post(this.url + "invoices/getSetups", {});
  }
  getSaveReports() {
    let token = JSON.parse(localStorage.getItem('token'));
    let data = {

    };
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "reports/getCustomReports", { data: data }, options);
  }
  checkSBillDate(data) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/validateSbillSDate", data, options);
  }
  getExchangeRate(data) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/getExchangeRate", data, options);
  }
  checkSbNumber(data) {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "invoices/validateSBNumber", data, options);
  }
  getUsers(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "users/getLogs",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }

  getBlockedIp(startRow, rowNumber, sortField, sortOrder, filters) {

    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "users/getBlockedIp",
      {
        "startRow": startRow,
        "rowNumber": rowNumber,
        "sortField": sortField,
        "sortOrder": sortOrder,
        "filters": filters
      }, options);

  }
  getIndianPortSearch(query) {
    return this.http.post(this.url + "invoices/getIndianPortSearch", { query: query });
  }

  getData() {
    let token = JSON.parse(localStorage.getItem('token'));
    let options = {
      headers: new HttpHeaders({ 'x-access-token': token })
    }
    return this.http.post(this.url + "common/getData", '', options);
  }

}
