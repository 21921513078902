
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { StatisticsServiceService } from '../statistics-service.service';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';

@Component({
  selector: 'app-monthly-export-trend',
  templateUrl: './monthly-export-trend.component.html',
  styleUrls: ['./monthly-export-trend.component.css']
})
export class MonthlyExportTrendComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  page: any;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName: string;
  reportDateRange: any = [];
  sourceGroup: any = [];

  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: StatisticsServiceService) { }

  ngOnInit(): void {

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;
    this.page = reportType;

    if (reportType == "item-group") {
      this.reportTitle = "Item Group";
      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        selectedItem: ['', Validators.required],
        viewDetailReport: [''],
        reportType: [reportType],

      });
    }
    else if (reportType == "cephalopods") {

      this.reportTitle = "Cephalopods";
      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        viewDetailReport: [''],
        reportType: [reportType],

      });
    }


    this._service.getGroups().subscribe((itemGrp: any) => {
      this.sourceGroup = itemGrp.data;
    });


    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });
  }

  get f() { return this.reportFrm.controls; }


  getReport() {

    this.isloader = true;
    this.submitted = true;
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    var yearDifferent = parseInt(dt2) - parseInt(dt1);

    if (yearDifferent > 2) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3 year", life: 6000 });
      this.isloader = false;
      return false;
    }



    this._service.getMonthlyTrends(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      if (items.data.reportData.length > 0) {
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
      this.reportDateRange = items.data.reportTime;
    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
  }


  getQuantity(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.Qtyintons;
  }
  getQuantityGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.qtyVariationPercent.toFixed(2);
  }


  getTotalQty(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.totalQty.toFixed();
  }

  getTotalFobRs(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.totalFobRs.toFixed(2);
  }

  getTotalDol(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.totalDol.toFixed(2);
  }


  getValueRs(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobRsCr;
  }
  getValueRsGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.rsVarPer.toFixed(2);
  }
  getValueDollar(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobDolMil;
  }
  getValueDollarGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.dolVarPer.toFixed(2);
  }

  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }
}
