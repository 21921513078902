import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { StatisticsServiceService } from '../statistics-service.service';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-marin-product-eport-from-india',
  templateUrl: './marin-product-eport-from-india.component.html',
  styleUrls: ['./marin-product-eport-from-india.component.css']
})

export class MarinProductEportFromIndiaComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  targetGroup: any;
  sourceGroup: any;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  page: any;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  showTextBox: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  dateRange: number;
  fragment = '';
  primary: UrlSegmentGroup;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: StatisticsServiceService, private commonService: CommonServiceService) { }


  ngOnInit(): void {


    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;

    this.page = reportType;

    if(reportType=='marin-product-export-from-india'){
      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        reportType:[reportType],
        viewDetailReport: [''],
        topRecordNum: ['0'],
        topRecordNumOther: ['1'],
        includeBlankData: ['0'],
        sortColumn: ['FobDolMil'],
        sortType: ['DESC'],
      });

      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Composition Of Marin Product Export From India";
    }
    else if(reportType=='percentage-marin-product-export-from-india'){

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        reportType:['marin-product-export-from-india'],
        viewDetailReport: [''],
        topRecordNum: ['0'],
        topRecordNumOther: ['1'],
        includeBlankData: ['0'],
        sortColumn: ['FobDolMil'],
        sortType: ['DESC'],
      });

      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Percentage Composition Of Marin Product Export From India";
    }

    else if(reportType=='average-unit-value-realization'){

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        reportType:[reportType],
        viewDetailReport: [''],
        topRecordNum: ['0'],
        topRecordNumOther: ['1'],
        includeBlankData: ['0'],
        sortColumn: ['FobDolMil'],
        sortType: ['DESC'],
      });

      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Average Unit Value Realisation";
    }

    else if(reportType=='marin-product-share-in-our-export'){

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        reportType:[reportType],
        viewDetailReport: [''],
        topRecordNum: ['0'],
        topRecordNumOther: ['1'],
        includeBlankData: ['0'],
        sortColumn: ['FobDolMil'],
        sortType: ['DESC'],
      });

      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Major Importer of Indian Marin Product and their Share in our Export";
    }



    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }





  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(item,total){
     let percentage = item*100/total;
     return percentage.toFixed(2);
  }

  getUnitPerKg(itemQty, itemPriceInRs){
    let unit = itemPriceInRs/itemQty;
    return unit.toFixed(2);
  }
  


  getReport() {

    this.isloader = true;

    this.submitted = true;

    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = new Date(this.reportFrm.value.dateStart).getFullYear();
    var dt2 = new Date(this.reportFrm.value.dateEnd).getFullYear();
    var yearDifferent = dt2 - dt1;
    this.dateRange = yearDifferent;

    if (yearDifferent > 2) {

      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3", life: 6000 });
      this.isloader = false;
      return false;
    }






    this._service.getMarinProductExport(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;

      console.log(' get country data in console ', items.data);
      if (items.data.reportData.length > 0) {
        for (let i = 0; i < items.data.reportData.length; i++) {
          for (let j = 0; j < items.data.reportData[i].groupItems.length; j++) {
            items.data.reportData[i].groupItems[j].groupName = items.data.reportData[i].name;
            this.detailReportData.push(items.data.reportData[i].groupItems[j]);
          }
        }
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }




  exportDetailExcel() {
    import("xlsx").then(xlsx => {

      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {

      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }

}
