<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} Report </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">

    

    
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details">
          







            <div class="card-body px-0">

                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Select Year </label>
                    </div>


                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">Start Date is required</div>
                            <div *ngIf="f.dateEnd.errors.pattern">Enter only number.</div>
                        </div>
                    </div>


                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>

                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors}" class="form-control"
                            maxlength="4" />
                        
                            <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">End Date is required</div>
                            <div *ngIf="f.dateStart.errors.pattern">Enter only number.</div>

                        </div>
                    </div>

                <!--                     
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From Year </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                    </div>

                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To Year </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                    </div> -->

                </div>

                <div class="row">
                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Groups </label>
                        <p-pickList [source]="sourceGroup" [target]="targetGroup" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeGroups($event)"
                            (onMoveToTarget)="addChecksums($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                    <div class="col-xl-6 col-md-6 form-group">
                        <label class="f18"> Items </label>
                        <p-pickList [source]="sourceItems" [target]="targetItems" sourceHeader="Available"
                            targetHeader="Selected" dragdrop="true" [responsive]="true"
                            [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false"
                            [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name" (onMoveToSource)="removeItems($event)"
                            (onMoveToTarget)="addItems($event)">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </div>
                
                <div class="btn_pan mb-4">
                    <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                </div>
               

                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> Show Variation and Growth Percentage </label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                </div> -->

                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span>Q: Quantity in Tons,</span>
                    <span>V: Value in Rs. Crores,</span>
                    <span>$: USD Million</span>
                </div>


                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">

                                <div class="card">
                                    <p-table [columns]="scrollableCols" [frozenColumns]="frozenCols" [value]="reportData" scrollHeight="400px"  [scrollable]="true"  frozenWidth="300px">


                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="generateExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                                    tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>


                                        <ng-template pTemplate="colgroup" let-columns>
                                            <colgroup>
                                                <col *ngFor="let col of columns" style="width:300px">
                                            </colgroup>
                                        </ng-template>
                                        <ng-template pTemplate="header" let-columns>
                                            <tr>
                                                <th *ngFor="let col of columns">
                                                    {{col.header}}
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-customer let-columns="columns">
                                            <tr>
                                                <td *ngFor="let col of columns">
                                                    {{customer[col.field]}}
                                                </td>
                                            </tr>
                                        </ng-template>
                
                                        
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </div>




        </div>
        <input type="hidden" formControlName="selectedGroups" />
        <input type="hidden" formControlName="selectedItems" />
        <input type="hidden" formControlName="reportType" />
    </form>
</div>