import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportReviewService } from '../export-review.service';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-monthly-export-trends',
  templateUrl: './monthly-export-trends.component.html',
  styleUrls: ['./monthly-export-trends.component.css']
})
export class MonthlyExportTrendsComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName: string;
  reportDateRange: any = [];
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService, private commonService: CommonServiceService) { }

  ngOnInit(): void {

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;


    this.reportFrm = this.formBuilder.group({
      dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      viewDetailReport: [''],
      reportType: [reportType],

    });

    if (reportType == "export") {
      this.reportTitle = "Monthly Export Trends";
    }
    if (reportType == "shrimp") {
      this.reportTitle = "Monthly Export Trend of Frozen Shrimp";
    }
    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });
  }

  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(itemQty, totalQty) {
    return this.commonService.getShare(itemQty, totalQty);
  }

  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }



  getReport() {

    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;

    // Make validation by common service
    this.commonService.validateCalendar2(dt1, dt2);

    this._service.getMonthlyTrends(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      if (items.data.reportData.length > 0) {
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
      this.reportDateRange = items.data.reportTime;
    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
  }

  getQuantity(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.Qtyintons ? filterObject.Qtyintons.toFixed(1) : 0;
  }
  getQuantityGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.qtyVariationPercent ? filterObject.qtyVariationPercent.toFixed(2) : 0;
  }
  getValueRs(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobRsCr ? filterObject.FobRsCr : 0;
  }
  getValueRsGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.rsVarPer.toFixed(2);
  }
  getValueDollar(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobDolMil;
  }
  getValueDollarGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.dolVarPer.toFixed(2);
  }

  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }
}
