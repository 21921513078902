<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>


                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">{{ getCustomMessage('dateStart.required')}}
                            </div>
                            <div *ngIf="f.dateStart.errors.pattern">{{ getCustomMessage('dateStart.pattern') }}</div>
                            <div *ngIf="f.dateStart.errors.min">{{ getCustomMessage('dateStart.min') }}</div>
                            <div *ngIf="f.dateStart.errors.max">{{ getCustomMessage('dateStart.max') }}</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">{{ getCustomMessage('dateEnd.required')}}</div>
                            <div *ngIf="f.dateEnd.errors.pattern">{{ getCustomMessage('dateEnd.pattern')}}</div>
                            <div *ngIf="f.dateEnd.errors.min">{{ getCustomMessage('dateEnd.min')}}</div>
                            <div *ngIf="f.dateEnd.errors.max">{{ getCustomMessage('dateEnd.max')}}
                            </div>
                        </div>
                    </div>

                </div>



                <div class="row">
                    <div class="btn_pan mb-4">
                        <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>

                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>Q</b>: Quantity in M Tons,</span>
                    <span><b>V</b>: Value in Rs. Lakh,</span>
                    <span><b>$</b>: USD Million</span>

                </div>


                <div class="card" *ngIf="displayDetailedReport">



                    <table #detailTable>
                        <p-table [value]="reportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o"
                                        (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportDetailExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="2">Items</th>

                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                        <th colspan="3">{{itemVal.Name}}</th>
                                    </ng-template>

                                </tr>
                                <tr>
                                    <th colspan="2"> </th>

                                    <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                        <th>Q</th>
                                        <th>V</th>
                                        <th>$</th>
                                    </ng-template>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td colspan="2">{{item.name}}</td>

                                    <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                        <td>{{getNumber(itemVal.totalQty, 1)}}</td>
                                        <td>{{getNumber(itemVal.fobDol, 2)}}</td>
                                        <td>{{getNumber(itemVal.FobRs, 2)}}</td>

                                    </ng-template>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="footer">
                                <tr>
                                    <td colspan="2"> Grand Total </td>

                                    <ng-template ngFor let-itemVal [ngForOf]="data.subTotal" let-i="index">
                                        <td width="20%">{{getNumber(itemVal.totalQty, 1)}}</td>
                                        <td width="20%">{{getNumber(itemVal.fobDol, 2)}}</td>
                                        <td width="20%">{{getNumber(itemVal.FobRs, 2)}}</td>
                                    </ng-template>
                                </tr>
                            </ng-template>

                        </p-table>
                    </table>
                </div>



                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable>
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th width="17%">Items</th>
                                                <th width="15%">&nbsp;</th>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <th width="20%">{{itemVal.Name}}</th>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                            <tr>

                                                <td>{{item.name}}</td>
                                                <td><b>Q</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td width="20%">{{getNumber(itemVal.totalQty,1)}}</td>
                                                </ng-template>

                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><b>V</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td width="20%">{{getNumber(itemVal.fobDol, 2)}}</td>
                                                </ng-template>

                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><b>$</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td width="20%">
                                                        {{getNumber(itemVal.FobRs, 2)}}</td>
                                                </ng-template>

                                            </tr>

                                        </ng-template>

                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td> Grand Total </td>
                                                <td> Q </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="data.subTotal" let-i="index">
                                                    <td width="20%">{{getNumber(itemVal.totalQty, 1)}}</td>
                                                </ng-template>
                                            </tr>
                                            <tr>
                                                <td> </td>
                                                <td> $ </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="data.subTotal" let-i="index">
                                                    <td width="20%">{{getNumber(itemVal.fobDol, 2)}}</td>
                                                </ng-template>
                                            </tr>

                                            <tr>
                                                <td> </td>
                                                <td> V </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="data.subTotal" let-i="index">
                                                    <td width="20%">{{getNumber(itemVal.FobRs, 2)}}</td>
                                                </ng-template>
                                            </tr>
                                        </ng-template>




                                    </p-table>
                                </table>

                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </div>
        <input type="hidden" formControlName="reportType" />
    </form>
</div>