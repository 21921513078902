import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MastersComponent } from './components/masters/masters.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService } from 'primeng/api';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { ViewinvoiceComponent } from './components/viewinvoice/viewinvoice.component';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { ItemmasterComponent } from './components/itemmaster/itemmaster.component';
import { EditItemMasterComponent } from './components/edit-item-master/edit-item-master.component';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { AuthGuard } from './auth.guard';
import { SummaryReportComponent } from './components/reports/summary-report/summary-report.component';
import { PickListModule } from 'primeng/picklist';
import { ReportItemComponent } from './components/reports/summary/report-item/report-item.component';
import { ReportPortComponent } from './components/reports/summary/report-port/report-port.component';
import { ReportRegionComponent } from './components/reports/summary/report-region/report-region.component';
import { ReportCountryComponent } from './components/reports/summary/report-country/report-country.component';
import { CheckboxModule } from 'primeng/checkbox';
import { BranchListComponent } from './components/setup/branch/branch-list/branch-list.component';
import { BranchCreateComponent } from './components/setup/branch/branch-create/branch-create.component';
import { UserListComponent } from './components/setup/user/user-list/user-list.component';
import { UserCreateComponent } from './components/setup/user/user-create/user-create.component';
import { ListComponent } from './components/reports/list/list.component';
import { RoleListComponent } from './components/setup/roles/role-list/role-list.component';
import { RolePermissionComponent } from './components/setup/roles/role-permission/role-permission.component';
import { PortMarketComponent } from './components/reports/port-wise/port-market/port-market.component';
import { PortItemComponent } from './components/reports/port-wise/port-item/port-item.component';
import { ItemWiseReportComponent } from './components/reports/item-wise/item-wise-report/item-wise-report.component';
import { MarketWiseReportComponent } from './components/reports/market-wise/market-wise-report/market-wise-report.component';
import { ExpoterWiseReportComponent } from './components/reports/expoter-wise/expoter-wise-report/expoter-wise-report.component';
import { BuyerWiseReportComponent } from './components/reports/buyer-wise/buyer-wise-report/buyer-wise-report.component';
import { ManufacturerWiseReportComponent } from './components/reports/manufacturer-wise/manufacturer-wise-report/manufacturer-wise-report.component';
import { PlantWiseReportComponent } from './components/reports/plant-wise/plant-wise-report/plant-wise-report.component';
import { StateWiseReportComponent } from './components/reports/state-wise/state-wise-report/state-wise-report.component';
import { ExportReviewListComponent } from './components/export-review/export-review-list/export-review-list.component';
import { ExportGrowthComponent } from './components/export-review/export-growth/export-growth.component';
import { MarketGrowthComponent } from './components/export-review/market-growth/market-growth.component';
import { MonthlyExportTrendsComponent } from './components/export-review/monthly-export-trends/monthly-export-trends.component';
import { CountryWiseMarketTrendComponent } from './components/export-review/country-wise-market-trend/country-wise-market-trend.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { InvoiceReportComponent } from './components/reports/invoice-report/invoice-report.component';
import { PortGraphComponent } from './components/dashboard/port-graph/port-graph.component';
import { CountryGraphComponent } from './components/dashboard/country-graph/country-graph.component';
import { ItemGraphComponent } from './components/dashboard/item-graph/item-graph.component';
import { ExportGraphComponent } from './components/dashboard/export-graph/export-graph.component';
import { CreateReportComponent } from './components/reports/create-report/create-report.component';
import { ListStatisticsComponent } from './components/statistics/list-statistics/list-statistics.component';
import { AnnualGrowthRateComponent } from './components/statistics/annual-growth-rate/annual-growth-rate.component';
import { StatisticsRegionComponent } from './components/statistics/statistics-region/statistics-region.component';
import { StatisticsCountryComponent } from './components/statistics/statistics-country/statistics-country.component';
import { StatisticsItemWiseComponent } from './components/statistics/statistics-item-wise/statistics-item-wise.component';
import { MessagesModule } from 'primeng/messages';
import { StatisticsPortExportComponent } from './components/statistics/statistics-port-export/statistics-port-export.component';
import { StatisticsAverageUnitComponent } from './components/statistics/statistics-average-unit/statistics-average-unit.component';
import { StatisticsRegionWiseComponent } from './components/statistics/statistics-region-wise/statistics-region-wise.component';
import { StatisticsPortWiseComponent } from './components/statistics/statistics-port-wise/statistics-port-wise.component';
import { DynamicRepotComponent } from './components/reports/dynamic-repot/dynamic-repot.component';
import { StatisticsItemGroupWiseComponent } from './components/statistics/statistics-item-group-wise/statistics-item-group-wise.component';

// Arvind Singh
import { MonthlyExportTrentsMarketComponent } from './components/export-review/monthly-export-trents-market/monthly-export-trents-market.component';
import { OtherComponent } from './components/export-review/other/other.component';
import { VarietyWiseReportComponent } from './components/export-review/variety-wise-report/variety-wise-report.component';
import { ExportInSrimpInDifferentProductFormsComponent } from './components/export-review/variety-wise-report/export-in-srimp-in-different-product-forms/export-in-srimp-in-different-product-forms.component';
import { ExportOfFrozenScampiComponent } from './components/export-review/variety-wise-report/export-of-frozen-scampi/export-of-frozen-scampi.component';
import { VarietyWiseExportDetailOfShrimpComponent } from './components/export-review/variety-wise-report/variety-wise-export-detail-of-shrimp/variety-wise-export-detail-of-shrimp.component';
import { ExportGrowthOfCephalopodsComponent } from './components/export-review/variety-wise-report/export-growth-of-cephalopods/export-growth-of-cephalopods.component';
import { ExportOfLobsterInDifferentProductFormComponent } from './components/export-review/variety-wise-report/export-of-lobster-in-different-product-form/export-of-lobster-in-different-product-form.component';
import { ExportGrowthOfItemGroupsComponent } from './components/export-review/export-growth-of-item-groups/export-growth-of-item-groups.component';
import { FrozenFinFishInDifferentFormsComponent } from './components/export-review/frozen-fin-fish-in-different-forms/frozen-fin-fish-in-different-forms.component';
import { AquariumFishComponent } from './components/export-review/export-growth-of-varieties/aquarium-fish/aquarium-fish.component';
import { MarketWiseAquariumFishComponent } from './components/export-review/market-wise-export/market-wise-aquarium-fish/market-wise-aquarium-fish.component';
import { FrozenCrabComponent } from './components/export-review/market-wise-export/frozen-crab/frozen-crab.component';
import { FrozenShrimpToContriesComponent } from './components/export-review/export-growth-of-varieties/frozen-shrimp-to-contries/frozen-shrimp-to-contries.component';
import { ExportOfItemGroupsVariousFormComponent } from './components/export-review/export-of-item-groups-various-form/export-of-item-groups-various-form.component';
import { GrowthOfItemGroupToRegionsComponent } from './components/export-review/growth-of-item-group-to-regions/growth-of-item-group-to-regions.component';
import { UnitValueOfMarinProductComponent } from './components/export-review/annexure/unit-value-of-marin-product/unit-value-of-marin-product.component';
import { MonthlyExportItemsComponent } from './components/export-review/monthly-export-items/monthly-export-items.component';
import { AllMonthlyWiseReportComponent } from './components/export-review/all-monthly-wise-report/all-monthly-wise-report.component';



import { InvoiceUploadComponent } from './components/invoice-upload/invoice-upload.component';
import { ExporterLoginComponent } from './exporter/exporter-login/exporter-login.component';
import { ExporterDashboardComponent } from './exporter/exporter-dashboard/exporter-dashboard.component';
import { ExporterPortGraphComponent } from './exporter/exporter-dashboard/exporter-port-graph/exporter-port-graph.component';
import { ExporterItemGraphComponent } from './exporter/exporter-dashboard/exporter-item-graph/exporter-item-graph.component';
import { ExporterCountryGraphComponent } from './exporter/exporter-dashboard/exporter-country-graph/exporter-country-graph.component';
import { ExporterExportGraphComponent } from './exporter/exporter-dashboard/exporter-export-graph/exporter-export-graph.component';
import { ExporterInvoicesComponent } from './exporter/exporter-invoices/exporter-invoices.component';
import { ExporterViewinvoicesComponent } from './exporter/exporter-viewinvoices/exporter-viewinvoices.component';
import { ExporterReportListComponent } from './exporter/reports/exporter-report-list/exporter-report-list.component';
import { ExporterSyncComponent } from './components/setup/exporter-sync/exporter-sync.component';
import { PlantSyncComponent } from './components/setup/plant-sync/plant-sync.component';
// Statatistics components
import { MarinProductEportFromIndiaComponent } from './components/statistics/marin-product-eport-from-india/marin-product-eport-from-india.component';
import { ShareOfShrimpInMarinProductComponent } from './components/statistics/share-of-shrimp-in-marin-product/share-of-shrimp-in-marin-product.component';
import { MonthlyExportTrendComponent } from './components/statistics/monthly-export-trend/monthly-export-trend.component';
import { PortItemWiseExportMarinProductComponent } from './components/statistics/port-item-wise-export-marin-product/port-item-wise-export-marin-product.component';
import { PlantDdComponent } from './components/setup/plant-dd/plant-dd.component';
import { ExporterUploadInvoiceComponent } from './exporter/exporter-upload-invoice/exporter-upload-invoice.component';
import { CountryWiseExportOfFrozenFinFishComponent } from './components/export-review/country-wise-export-of-frozen-fin-fish/country-wise-export-of-frozen-fin-fish.component';
import { ExporterUploadExcelComponent } from './exporter/exporter-upload-excel/exporter-upload-excel.component';
import { ExporterUploadSezpdfComponent } from './exporter/exporter-upload-sezpdf/exporter-upload-sezpdf.component';
import { RequestComponent } from './exporter/request/request.component';
import { RequestListComponent } from './exporter/request-list/request-list.component';
import { RequestReplyComponent } from './exporter/request-reply/request-reply.component';
import { InvoiceUploadExcelComponent } from './components/invoice-upload-excel/invoice-upload-excel.component';
import { RequestEditComponent } from './exporter/request-edit/request-edit.component';
import { YearlyWiseReportComponent } from './components/export-review/yearly-wise-report/yearly-wise-report.component';
import { ViewReportsComponent } from './components/reports/view-reports/view-reports.component';
import { BranchReconcileComponent } from './components/setup/branch-reconcile/branch-reconcile.component';
import { BranchReconcileListComponent } from './components/setup/branch-reconcile-list/branch-reconcile-list.component';
import { ReconcileFinalListComponent } from './components/setup/reconcile-final-list/reconcile-final-list.component';
import { ReconcileFinalAddComponent } from './components/setup/reconcile-final-add/reconcile-final-add.component';
import { RegionMasterListComponent } from './components/region-master/region-master-list/region-master-list.component';
import { RegionMasterEditComponent } from './components/region-master/region-master-edit/region-master-edit.component';
import { ReconcilationListComponent } from './exporter/reconcilation/reconcilation-list/reconcilation-list.component';
import { ReconcilationAddComponent } from './exporter/reconcilation/reconcilation-add/reconcilation-add.component';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { UsersComponent } from './components/logs/users/users.component';
import { BlockedIpComponent } from './components/logs/blocked-ip/blocked-ip.component';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { NgxCaptchaModule } from 'ngx-captcha';
import { ChangePasswordComponent } from './components/setup/user/change-password/change-password.component';

@NgModule({
  declarations: [
    AppComponent,
    MastersComponent,
    InvoicesComponent,
    ViewinvoiceComponent,
    ItemmasterComponent,
    EditItemMasterComponent,
    UserLoginComponent,
    TopNavigationComponent,
    SummaryReportComponent,
    ReportItemComponent,
    ReportPortComponent,
    ReportRegionComponent,
    ReportCountryComponent,
    BranchListComponent,
    BranchCreateComponent,
    UserListComponent,
    UserCreateComponent,
    ListComponent,
    RoleListComponent,
    RolePermissionComponent,
    PortMarketComponent,
    PortItemComponent,
    ItemWiseReportComponent,
    MarketWiseReportComponent,
    ExpoterWiseReportComponent,
    BuyerWiseReportComponent,
    ManufacturerWiseReportComponent,
    PlantWiseReportComponent,
    StateWiseReportComponent,
    ExportReviewListComponent,
    ExportGrowthComponent,
    MarketGrowthComponent,
    MonthlyExportTrendsComponent,
    CountryWiseMarketTrendComponent,
    DashboardComponent,
    InvoiceReportComponent,
    PortGraphComponent,
    CountryGraphComponent,
    ItemGraphComponent,
    ExportGraphComponent,
    CreateReportComponent,
    ListStatisticsComponent,
    AnnualGrowthRateComponent,
    StatisticsRegionComponent,
    StatisticsCountryComponent,
    StatisticsItemWiseComponent,
    StatisticsPortExportComponent,
    StatisticsAverageUnitComponent,
    StatisticsRegionWiseComponent,
    StatisticsPortWiseComponent,
    DynamicRepotComponent,
    StatisticsItemGroupWiseComponent,
    MonthlyExportTrentsMarketComponent,
    OtherComponent,
    VarietyWiseReportComponent,
    ExportInSrimpInDifferentProductFormsComponent,
    ExportOfFrozenScampiComponent,
    VarietyWiseExportDetailOfShrimpComponent,
    ExportGrowthOfCephalopodsComponent,
    ExportOfLobsterInDifferentProductFormComponent,
    ExportGrowthOfItemGroupsComponent,
    FrozenFinFishInDifferentFormsComponent,
    AquariumFishComponent,
    MarketWiseAquariumFishComponent,
    FrozenCrabComponent,
    FrozenShrimpToContriesComponent,
    ExportOfItemGroupsVariousFormComponent,
    GrowthOfItemGroupToRegionsComponent,
    InvoiceUploadComponent,
    ExporterLoginComponent,
    ExporterDashboardComponent,
    ExporterPortGraphComponent,
    ExporterItemGraphComponent,
    ExporterCountryGraphComponent,
    ExporterExportGraphComponent,
    ExporterInvoicesComponent,
    ExporterViewinvoicesComponent,
    ExporterReportListComponent,
    UnitValueOfMarinProductComponent,
    ExporterSyncComponent,
    PlantSyncComponent,
    UnitValueOfMarinProductComponent,
    MarinProductEportFromIndiaComponent,
    ShareOfShrimpInMarinProductComponent,
    MonthlyExportTrendComponent,
    PortItemWiseExportMarinProductComponent,
    PlantDdComponent,
    ExporterUploadInvoiceComponent,
    CountryWiseExportOfFrozenFinFishComponent,
    MonthlyExportItemsComponent,
    AllMonthlyWiseReportComponent,
    ExporterUploadExcelComponent,
    ExporterUploadSezpdfComponent,
    RequestComponent,
    RequestListComponent,
    RequestReplyComponent,
    InvoiceUploadExcelComponent,
    RequestEditComponent,
    YearlyWiseReportComponent,
    ViewReportsComponent,
    BranchReconcileComponent,
    BranchReconcileListComponent,
    ReconcileFinalListComponent,
    ReconcileFinalAddComponent,
    RegionMasterListComponent,
    RegionMasterEditComponent,
    ReconcilationListComponent,
    ReconcilationAddComponent,
    UsersComponent,
    BlockedIpComponent,
    ChangePasswordComponent

  ],
  imports: [
    GoogleChartsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    TableModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ToastModule,
    InputTextModule,
    HttpClientModule,
    ToolbarModule,
    FormsModule,
    AccordionModule,
    ConfirmDialogModule,
    CalendarModule,
    InputTextareaModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    PickListModule,
    CheckboxModule,
    MessagesModule,
    TooltipModule,
    RippleModule,
    AutoCompleteModule,
    NgxCaptchaModule,
    RouterModule.forChild([]),
  ],
  providers: [MessageService, AuthGuard, DatePipe, BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
