import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ExporterServiceService } from '../exporter-service.service';
import jwt_decode from "jwt-decode";
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../common-service.service';

@Component({
  selector: 'app-exporter-upload-invoice',
  templateUrl: './exporter-upload-invoice.component.html',
  styleUrls: ['./exporter-upload-invoice.component.css']
})
export class ExporterUploadInvoiceComponent implements OnInit {

  isloader: boolean = false;
  submitted: boolean = false;
  pdfDetail: any = [];
  invoiceFrm: FormGroup;
  totalExporter: number = 0;
  expDetail: any = [];
  expName: string = '';
  exporterAll: any = [];
  buyer: any;
  filteredConsinee: any;


  itemForm: FormGroup;
  submittedItem = false;
  invoiceId: {};
  invoiceFrmData: any;

  invoiceDateTime: any;
  invoiceDetail: any = [];
  manufacturer: any;
  filteredExporters: any;
  keyword = 'name';
  exporters: any;
  exportername: any;
  filteredManufacturer: any;
  filteredPort: any;
  filteredCountry: any;
  port: any;
  country: any;
  manufacturerPlants: any;
  plantState: string;
  displayAddItem: boolean;
  groups: any;
  itemListDD: any;
  itemGradeDD: any;
  currencies: any;
  totalQuantity: any;
  totalPrice: any = 0;
  itemGroupDetail: any;
  totalFOBPrice: any;

  itemHsDD: any;
  itemSNameDD: any;

  position: string;
  deleteItemId: number = 0;
  selectedItemDelete: any = [];
  totalQuantityUnit: any;
  totalAmountDollar: any;
  totalAmountRs: any;
  totalConsignee: any = [];
  totalPort: any = [];
  totalPortDischarge: any = [];
  totalFinalPort: any = [];
  filteredGrade: any = [];
  selectedExporter: any = [];
  showForm: boolean = false;
  fileName: string = "";
  added_from: string = "";
  status: string = "";
  medium: string = "";
  added_by: string = "";
  filteredItems: any = [];
  comments: any = [];
  allConsinee: any = [];
  allPorts: any = [];
  allCountry: any = [];
  allItems: any = [];
  filteredPortIndia: any = [];
  allPortsIndia: any = [];

  constructor(private _service: ExporterServiceService, private formBuilder: FormBuilder, private messageService: MessageService, private _router: Router, private _commonService: CommonServiceService) { }
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),

    fileSource: new FormControl('', [Validators.required])
  });


  ngOnInit(): void {

    this._service.getBuyerSearch("").subscribe((buyerList) => {
      let filtered: any[] = [];
      this.buyer = buyerList;
      for (let i = 0; i < this.buyer.length; i++) {
        let buyer = this.buyer[i];
        if (buyer.name != null) {
          var buyerData = {
            id: buyer.code,
            name: buyer.name
          }
          filtered.push(buyerData);
        }
      }
      this.allConsinee = filtered;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
    //Below code to get all port
    this._service.getPortSearch("").subscribe((portList) => {
      let filtered: any[] = [];

      this.port = portList;
      for (let i = 0; i < this.port.length; i++) {
        let port = this.port[i];
        if (port.name != null) {
          var portData = {
            id: port.code,
            name: port.name,
            countryCode: port.COUNTRY_CODE,
            countryName: port.COUNTRY_NAME,
            detail: port.name + "-" + port.code,

          }
          filtered.push(portData);

        }
      }
      this.allPorts = filtered;


    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

    this._commonService.getIndianPortSearch("").subscribe((portList) => {
      let filteredIndia: any[] = [];
      this.port = portList;
      for (let i = 0; i < this.port.length; i++) {
        let port = this.port[i];
        if (port.name != null) {

          if (port.COUNTRY_CODE == 999) {
            var portDataIndia = {
              id: port.code,
              name: port.name,
              countryCode: port.COUNTRY_CODE,
              countryName: port.COUNTRY_NAME,
              detail: port.name + "-" + port.code,
            }
            filteredIndia.push(portDataIndia);
          }
        }
      }
      this.allPortsIndia = filteredIndia;
    });
    //Code to get all countries
    this._service.getCountrySearch("").subscribe((countryList) => {
      let filtered: any[] = [];
      this.country = countryList;
      for (let i = 0; i < this.country.length; i++) {
        let country = this.country[i];
        if (country.name != null) {
          var countryData = {
            id: country.code,
            name: country.name
          }
          filtered.push(countryData);
        }
      }
      this.allCountry = filtered;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

    //Below code to get all exporters

    let token = JSON.parse(localStorage.getItem('token'));
    let decodedToken = jwt_decode(token);
    this.totalExporter = decodedToken['result'].results.length;
    if (this.totalExporter == 1) {
      this.expDetail = {
        "id": decodedToken['result'].results[0].exp_code,
        "name": decodedToken['result'].results[0].exp_name,
      }
      this.expName = decodedToken['result'].results[0].exp_name;
      //code to get exporter plants 
      this._service.getExporterPlants(decodedToken['result'].results[0].exp_code).subscribe((exporterPlants: any) => {
        this.manufacturerPlants = exporterPlants.data;
        if (this.manufacturerPlants.length == 1) {

          this.invoiceFrm.controls.Plant_Code.patchValue(this.manufacturerPlants[0].code);
          this.plantState = this.manufacturerPlants[0].Stname;
          this.invoiceFrm.controls.plantState.patchValue(this.manufacturerPlants[0].Stcode);
          this.invoiceFrm.controls.plantStateName.patchValue(this.manufacturerPlants[0].Stname);
        }
      });

    }
    if (this.totalExporter > 1) {
      for (let i = 0; i < this.totalExporter; i++) {
        this.exporterAll = decodedToken['result'].results;
      }
    }
    this._service.getInvoiceSetups().subscribe((setupList: any) => {
      this.groups = setupList.groupDetails;
      this.currencies = setupList.curreciesDetails;
    });

    this.invoiceFrm = this.formBuilder.group({
      SB_No: ['', [Validators.required, Validators.minLength(7)]],
      SB_Date: ['', Validators.required],
      Ent_date: ['', Validators.required],
      BILL_NO: ['', Validators.required],
      Date: ['', Validators.required],
      EXP_NAME: ['',],
      EXP_CODE: ['', Validators.required],
      BUYER_NAME: ['',],
      BUYER_CODE: ['', Validators.required],
      Plant_Code: ['', Validators.required],
      plantState: "",
      plantStateName: "",
      Vessel_No: "",
      PORT_NAME: "",
      PORT_CODE: ['', Validators.required],
      Port_DischargeName: ['', Validators.required],
      Port_Discharge: ['', Validators.required],
      Port_Final_NAME: '',
      Port_Final: ['', Validators.required],
      Country_Origin_Name: '',
      Country_Origin: '',
      Imp_Exp_No: "",
      E_Under: "",
      S_Type: "",
      Custom: "",
      Currency: ['', Validators.required],
      Nature: "",
      DOL_RATE: ['', Validators.required],
      CurToDol: ['', Validators.required],
      FOB_Value: ['', Validators.required],
      Duty_Rate: 0,
      Amount: '',
      AmountR: '',
      AP_CessR: 0,
      AP_Cess: 0,
      Duty_CessR: 0,
      Duty_Cess: 0,
      Tot_Freight: 0,
      C_Insure: 0,
      R_Insure: 0,
      C_Comm: 0,
      R_Comm: 0,
      C_Disc: 0,
      R_Disc: 0,
      C_Other: 0,
      R_Other: 0,
      Gross: [0, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
      Tot_Weight: [0, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
      Net_Weight: 0,
      R_Freight: 0,
      FKG: 0,
      COUNTRY_CODE_NAME: '',
      COUNTRY_CODE: '',
      itemDetails: '',
      R_FOB: 0,
      invoiceId: '',
      Tot_Amount: '',
      tot_amount_rs: '',
      FOB_Value_invoice: ['', Validators.required],
      totalConsigneeDD: '',
      totalPortDD: '',
      totalPortDischargeDD: '',
      totalFinalPortDD: '',
      fileName: '',
      added_from: '3',
      status: '1',
      medium: '2',
      added_by: '',
      rateIn: '',
      SBfile: [''],
      fileSource: [''],
      Invfile: [''],
      InvfileSource: [''],
      exporter_declaration: [false],
      invoiceComment: [''],
    });

    this.invoiceFrm.valueChanges.subscribe(val => {
      this.totalPrice = this.getotalAmount();
      if (val.C_Insure >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let RInsurence = val.C_Insure * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Insure.patchValue(RInsurence.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });
        }
      }
      else {
        this.invoiceFrm.controls.R_Insure.patchValue(0, { emitEvent: false });
      }

      //Code to calculate commission  

      if (val.C_Comm >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let R_Comm = val.C_Comm * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Comm.patchValue(R_Comm.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
      }
      else {
        this.invoiceFrm.controls.R_Comm.patchValue(0, { emitEvent: false });
      }
      //Code to calculate discounts  

      if (val.C_Disc >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
        if (val.DOL_RATE > 0) {
          let R_dis = val.C_Disc * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Disc.patchValue(R_dis.toFixed(2), { emitEvent: false });

          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });
      }
      else {
        this.invoiceFrm.controls.R_Disc.patchValue(0, { emitEvent: false });

      }

      //Code to calculate other discount  
      if (val.C_Other >= 0) {
        let otherTotal = this.getOtherTotal();
        let fobValue = this.totalPrice - otherTotal;
        if (val.DOL_RATE > 0) {
          let o_dis = val.C_Other * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_Other.patchValue(o_dis.toFixed(2), { emitEvent: false });
          let totalFobPriceRs = fobValue * parseFloat(val.DOL_RATE);
          this.invoiceFrm.controls.R_FOB.patchValue(totalFobPriceRs.toFixed(2), { emitEvent: false });

        }
        this.invoiceFrm.controls.FOB_Value.patchValue(fobValue.toFixed(2), { emitEvent: false });

      }
      else {
        this.invoiceFrm.controls.R_Other.patchValue(0, { emitEvent: false });

      }
      //Code to calulate freight
      if (val.Tot_Freight > 0) {
        const totalFright = Number.parseFloat(val.Tot_Freight).toFixed(2);
        if (val.DOL_RATE > 0) {
          let totalFrightRs = parseFloat(val.Tot_Freight) * parseFloat(val.DOL_RATE)
          this.invoiceFrm.controls.R_Freight.patchValue(totalFrightRs.toFixed(2), { emitEvent: false });
        }
        this.invoiceFrm.controls.Tot_Freight.patchValue(val.Tot_Freight, { emitEvent: false });
        this.calculateItemsFOB();
      }

      //Calculate Total Freight 
      if (val.Tot_Freight > 0 && val.Tot_Weight > 0) {

        let frightPerKg = val.Tot_Freight / val.Tot_Weight;
        this.invoiceFrm.controls.FKG.patchValue(frightPerKg.toFixed(2), { emitEvent: false });
      }
      if (val.SB_Date != '') {
        this.invoiceDateTime = val.SB_Date;
      }
    });
  }
  get f() {
    return this.myForm.controls;
  }
  get invf() {
    return this.invoiceFrm.controls;
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.myForm.invalid) {
      return;
    }
    const formData = new FormData();
    this.isloader = true;
    formData.append('file', this.myForm.get('fileSource').value);
    this._service.uploadInvoice(formData).subscribe((items: any) => {

      if (items.status == 0) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
        this.isloader = false;
        return;
      }

      this.invoiceDetail = items[0];
      this.totalConsignee = items[0].totalBuyers;
      this.totalPort = items[0].totalPort;
      this.totalPortDischarge = items[0].totalDischargePort;
      this.totalFinalPort = items[0].totalFinalPort;


      let itemExp = {
        "id": this.expDetail.id,
        "name": this.expDetail.name,
      }
      //console.warn("Total Exporter =", this.totalExporter);
      let added_by = 0;
      if (this.totalExporter == 1) {
        added_by = this.expDetail.id;
      }
      this.invoiceFrm.setValue({
        SB_No: this.invoiceDetail.SB_No,
        SB_Date: this.invoiceDetail.SB_Date,
        Ent_date: this.invoiceDetail.todayDate,
        BILL_NO: this.invoiceDetail.BILL_NO,
        Date: this.invoiceDetail.Date,
        EXP_NAME: this.selectedExporter,
        EXP_CODE: this.selectedExporter,
        Plant_Code: '',
        plantState: '',
        plantStateName: "",
        Port_Final: '',
        Port_Final_NAME: '',
        BUYER_NAME: '',
        BUYER_CODE: '',
        Vessel_No: this.invoiceDetail.Vessel_No,
        PORT_NAME: '',
        PORT_CODE: '',
        Port_DischargeName: this.invoiceDetail.PORT_OF_DISCHARGE,
        Port_Discharge: '',
        Imp_Exp_No: this.invoiceDetail.Imp_Exp_No,
        E_Under: '',
        S_Type: '',
        Custom: this.invoiceDetail.Custom,
        Currency: this.invoiceDetail.Currency,
        Nature: '',
        DOL_RATE: this.invoiceDetail.DOL_RATE,
        CurToDol: this.invoiceDetail.CurToDol,
        FOB_Value: this.invoiceDetail.FOB_Value,
        FOB_Value_invoice: this.invoiceDetail.FOB_Value,
        Duty_Rate: 0,
        Amount: this.invoiceDetail.Amount,
        AmountR: this.invoiceDetail.AmountR,
        AP_CessR: 0,
        AP_Cess: 0,
        Duty_CessR: 0,
        Duty_Cess: 0,
        Tot_Freight: this.invoiceDetail.Tot_Freight,
        C_Insure: this.invoiceDetail.C_Insure,
        R_Insure: this.invoiceDetail.R_Insure,
        C_Comm: this.invoiceDetail.C_Comm,
        R_Comm: this.invoiceDetail.R_Comm,
        C_Disc: this.invoiceDetail.C_Disc,
        R_Disc: this.invoiceDetail.R_Disc,
        C_Other: this.invoiceDetail.C_Other,
        R_Other: this.invoiceDetail.R_Other,
        Gross: this.invoiceDetail.GROSS_WEIGHT,
        Tot_Weight: this.invoiceDetail.Tot_Weight,
        Net_Weight: this.invoiceDetail.Tot_Weight,
        R_Freight: '',
        FKG: '',
        Country_Origin_Name: '',
        Country_Origin: '',
        COUNTRY_CODE_NAME: '',
        COUNTRY_CODE: '',
        itemDetails: this.invoiceDetail.itemsDetail,
        R_FOB: '',
        invoiceId: '',
        Tot_Amount: this.invoiceDetail.Tot_Amount,
        tot_amount_rs: this.invoiceDetail.tot_amount_rs,
        totalConsigneeDD: '',
        totalPortDD: '',
        totalPortDischargeDD: '',
        totalFinalPortDD: '',
        fileName: this.invoiceDetail.fileName,
        added_from: '3',
        status: '1',
        medium: '2',
        added_by: added_by,
        rateIn: '',
        SBfile: '',
        fileSource: '',
        Invfile: '',
        InvfileSource: '',
        exporter_declaration: false,
        invoiceComment: ['']
      });

      if (this.totalConsignee.length == 1) {
        let item = {
          "id": this.totalConsignee[0].BUYER_CODE,
          "name": this.totalConsignee[0].BUYER_NAME,
        }
        let otionItem = this.totalConsignee[0].BUYER_CODE + "--" + this.totalConsignee[0].BUYER_NAME;
        this.invoiceFrm.controls.BUYER_NAME.patchValue(item);
        this.invoiceFrm.controls.BUYER_CODE.patchValue(item);
        this.invoiceFrm.controls.totalConsigneeDD.setValue(otionItem);

      }
      if (this.totalPort.length == 1) {
        let item = {
          "id": this.totalPort[0].code,
          "name": this.totalPort[0].name,
        }
        let otionItem = this.totalPort[0].code + "--" + this.totalPort[0].name;
        this.invoiceFrm.controls.PORT_CODE.patchValue(item);
        this.invoiceFrm.controls.PORT_NAME.patchValue(item);

        this.invoiceFrm.controls.totalPortDD.setValue(otionItem);
        let itemCountry = {
          "id": this.totalPort[0].COUNTRY_CODE,
          "name": this.totalPort[0].COUNTRY_NAME,
        }
        this.invoiceFrm.controls.Country_Origin_Name.patchValue(this.totalPort[0].COUNTRY_NAME, { emitEvent: false });
        this.invoiceFrm.controls.Country_Origin.patchValue(itemCountry, { emitEvent: false });

      }
      if (this.totalPortDischarge.length == 1) {
        let item = {
          "id": this.totalPortDischarge[0].PORT_CODE,
          "name": this.totalPortDischarge[0].PORT_NAME,
        }
        let otionItem = this.totalPortDischarge[0].PORT_CODE + "--" + this.totalPortDischarge[0].PORT_NAME;
        this.invoiceFrm.controls.Port_Discharge.patchValue(item);
        this.invoiceFrm.controls.Port_DischargeName.patchValue(item);

        this.invoiceFrm.controls.totalPortDischargeDD.setValue(otionItem);
      }
      if (this.totalFinalPort.length == 1) {
        let item = {
          "id": this.totalFinalPort[0].code,
          "name": this.totalFinalPort[0].name,
        }
        let itemDestination = {
          "id": this.totalFinalPort[0].COUNTRY_CODE,
          "name": this.totalFinalPort[0].COUNTRY_NAME
        }
        this.invoiceFrm.controls.COUNTRY_CODE_NAME.patchValue(this.totalFinalPort[0].name, { emitEvent: false });

        this.invoiceFrm.controls.COUNTRY_CODE.patchValue(itemDestination, { emitEvent: false });


        let otionItem = this.totalFinalPort[0].code + "--" + this.totalFinalPort[0].name;
        this.invoiceFrm.controls.Port_Final.patchValue(item);
        this.invoiceFrm.controls.Port_Final_NAME.patchValue(item);


        this.invoiceFrm.controls.totalFinalPortDD.setValue(otionItem);
      }
      if (this.invoiceDetail.C_Insure > 0) {
        this.invoiceFrm.controls.Nature.setValue("CIF");
        this.invoiceFrm.controls.rateIn.setValue("C&F");

      }
      else {
        if (this.invoiceDetail.Tot_Freight > 0) {
          this.invoiceFrm.controls.Nature.setValue("CFR");
          this.invoiceFrm.controls.rateIn.setValue("C&F");
        }
        else {
          this.invoiceFrm.controls.Nature.setValue("FOB");
          this.invoiceFrm.controls.rateIn.setValue("FOB");
        }
      }
      this.showForm = true;
      this.isloader = false;
      this.checkBillNumber();

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    })
    if (this.totalExporter == 1) {
      let item = {
        "id": this.expDetail.id,
        "name": this.expDetail.name,
      }
      this.selectedExporter = item;
    }


  }
  selectEventConsinee(item) {
    //    console.warn("Item = ", item);
    this.invoiceFrm.controls.BUYER_CODE.patchValue(item, { emitEvent: false });
  }
  onChangeSearchConsinee(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allConsinee.length; i++) {
      let country = this.allConsinee[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }

    this.filteredConsinee = filtered;
    this.filteredConsinee = this.filteredConsinee.slice(0, 10);
    /*    
        this._service.getBuyerSearch(val).subscribe((buyerList) => {
          let filtered: any[] = [];
          this.buyer = buyerList;
          for (let i = 0; i < this.buyer.length; i++) {
            let buyer = this.buyer[i];
            if (buyer.name != null) {
              var buyerData = {
                id: buyer.code,
                name: buyer.name
              }
              filtered.push(buyerData);
            }
          }
          this.filteredConsinee = filtered;
        });
        */
  }

  onFocused(e) {
    // do something when input is focused
  }

  getState(event) {
    const value = event.target.value;
    let filteredData: any;
    filteredData = this.manufacturerPlants.find(item => item.code == value);
    this.plantState = filteredData.Stname;
    this.invoiceFrm.controls.plantState.patchValue(filteredData.Stcode);
    this.invoiceFrm.controls.plantStateName.patchValue(filteredData.Stname);


  }
  selectEventPort(item) {
    this.invoiceFrm.controls.PORT_CODE.patchValue(item, { emitEvent: false });
    let itemOrigin = {
      "id": item.countryCode,
      "name": item.countryName
    }
    this.invoiceFrm.controls.Country_Origin_Name.patchValue(item.countryName, { emitEvent: false });
    this.invoiceFrm.controls.Country_Origin.patchValue(itemOrigin, { emitEvent: false });
  }

  //Code to get Port 
  onChangeSearchPort(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allPorts.length; i++) {
      let item = this.allPorts[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredPort = filtered;
    this.filteredPort = this.filteredPort.slice(0, 10);
    /*

    this._service.getPortSearch(val).subscribe((portList) => {
      let filtered: any[] = [];
      this.port = portList;
      for (let i = 0; i < this.port.length; i++) {
        let port = this.port[i];
        if (port.name != null) {
          var portData = {
            id: port.code,
            name: port.name,
            countryCode: port.COUNTRY_CODE,
            countryName: port.COUNTRY_NAME,

          }
          filtered.push(portData);
        }
      }
      this.filteredPort = filtered;
    });
    */
  }
  selectEventPortDischarge(item) {
    this.invoiceFrm.controls.Port_Discharge.patchValue(item, { emitEvent: false });
  }
  selectEventFinalPort(item) {
    this.invoiceFrm.controls.Port_Final.patchValue(item, { emitEvent: false });
    // this.invoiceFrm.controls.COUNTRY_CODE.patchValue(item.countryName, {emitEvent: false});
    this.invoiceFrm.controls.COUNTRY_CODE_NAME.patchValue(item.countryName, { emitEvent: false });
    let itemDestination = {
      "id": item.countryCode,
      "name": item.countryName
    }
    this.invoiceFrm.controls.COUNTRY_CODE.patchValue(itemDestination, { emitEvent: false });

  }
  selectEventCountryOrigin(item) {
    this.invoiceFrm.controls.Country_Origin.patchValue(item, { emitEvent: false });
  }
  //Code to get country 
  onChangeSearchCountry(val: string) {
    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allCountry.length; i++) {
      let item = this.allCountry[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredCountry = filtered;
    this.filteredCountry = this.filteredCountry.slice(0, 10);
    /*    
        this._service.getCountrySearch(val).subscribe((countryList) => {
          let filtered: any[] = [];
          this.country = countryList;
          for (let i = 0; i < this.country.length; i++) {
            let country = this.country[i];
            if (country.name != null) {
              var countryData = {
                id: country.code,
                name: country.name
              }
              filtered.push(countryData);
            }
          }
          this.filteredCountry = filtered;
        });
        */
  }
  selectEventCountryCode(item) {
    this.invoiceFrm.controls.COUNTRY_CODE.patchValue(item, { emitEvent: false });
  }
  calculateItemsFOB() {
    //Code to calculate the Items FOB     
    if (this.invoiceDetail.itemsDetail.length > 0) {
      FobRate: Number;
      let netWeight = 0;
      let netFright = 0;
      let frightPerKg = 0;
      if (this.invoiceFrm.value.Tot_Weight > 0) {
        netWeight = this.invoiceFrm.value.Tot_Weight;
      }
      if (this.invoiceFrm.value.Tot_Weight > 0) {
        netFright = this.invoiceFrm.value.Tot_Freight;
      }
      if (netWeight > 0) {
        frightPerKg = netFright / netWeight;
      }
      frightPerKg = parseFloat(this.invoiceFrm.value.FKG);
      for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
        const FOBRateKg = this.invoiceDetail.itemsDetail[i].RATE - frightPerKg;
        const FobRate = this.invoiceDetail.itemsDetail[i].Qty * FOBRateKg;
        const FobRateRs = FobRate * this.invoiceFrm.value.DOL_RATE;

        const fobVal = FobRate * this.invoiceFrm.value.DOL_RATE;
        const ItemFright = (netFright / netWeight) * parseFloat(this.invoiceDetail.itemsDetail[i].itemQuantity);
        this.invoiceDetail.itemsDetail[i].Freight = ItemFright.toFixed(2);
        this.invoiceDetail.itemsDetail[i].TOTPRICE_RS = FobRate.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Dol = FobRate.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Rs = FobRateRs.toFixed(2);
        this.invoiceDetail.itemsDetail[i].FOB_Value = fobVal;
      }
      //Code to get sum of quantity
      this.totalQuantity = this.getotalQuantity();
      this.totalQuantityUnit = this.getotalQuantityUnit();
      this.totalPrice = this.getotalAmount();
      this.totalAmountDollar = this.getotalAmountDollar()
      this.totalAmountRs = this.getotalAmountRs()
      //Update Total Quantity and price 

      //Coe to bind  Item Group Summary 
      this.itemGroupDetail = this.getItemGroupSummary();

      //code to update all items 
      this.updateInvoiceItem()
    }
  }
  showAddItem() {
    this.displayAddItem = true;
    this.itemForm.controls.itemId.patchValue('', { emitEvent: false });

  }
  updateInvoiceItem() {
    let frightPerKg = this.invoiceFrm.value.Tot_Freight / this.invoiceFrm.value.Tot_Weight;

    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      let fob = this.invoiceDetail.itemsDetail[i].TAILQTY * frightPerKg;
      this.invoiceDetail.itemsDetail[i].Freight = fob;
      this.invoiceDetail.itemsDetail[i].FOB_Dol = this.invoiceDetail.itemsDetail[i].TOTPRICE - fob;
      this.invoiceDetail.itemsDetail[i].TOTPRICE_RS = this.invoiceDetail.itemsDetail[i].TOTPRICE - fob;
    }
  }
  getItemGroupSummary() {
    let groupItem: any[] = [];
    let quantity = 0;
    let amount: number = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      if (groupItem.find(item => item.GroupCode == this.invoiceDetail.itemsDetail[i].GROUP_CODE)) {
        quantity = parseFloat(groupItem[0].GroupQuantity);
        quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].Qty);
        amount = parseFloat(groupItem[0].amount);
        amount = amount + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);

        groupItem[0].GroupQuantity = quantity;
        groupItem[0].QuantityKg = quantity;
        groupItem[0].amount = amount.toFixed(2);
        groupItem[0].amountFob = amount.toFixed(2);

      }
      else {
        quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].Qty);
        amount = amount + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE_RS);
        var itemDetail = {
          "GroupCode": this.invoiceDetail.itemsDetail[i].GROUP_CODE,
          "GroupName": this.invoiceDetail.itemsDetail[i].GROUP_NAME,
          "GroupQuantity": this.invoiceDetail.itemsDetail[i].Qty,
          "GroupUnit": this.invoiceDetail.itemsDetail[i].KILLBS,
          "QuantityKg": this.invoiceDetail.itemsDetail[i].Qty,
          "amount": this.invoiceDetail.itemsDetail[i].TOTPRICE,
          "amountFob": amount,
        }
        groupItem.push(itemDetail);
      }


    }
    return groupItem;
  }
  getotalQuantity() {
    let quantity = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].TAILQTY);
    }
    return quantity.toFixed(2);
  }

  getotalQuantityUnit() {
    let quantity = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      quantity = quantity + parseFloat(this.invoiceDetail.itemsDetail[i].Qty);
    }
    return quantity.toFixed(2);
  }
  getotalAmount() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
    }
    return totalprice.toFixed(2);
  }
  getotalAmountDollar() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + parseFloat(this.invoiceDetail.itemsDetail[i].TOTPRICE);
    }
    return totalprice.toFixed(2);
  }
  getotalAmountRs() {
    let totalprice = 0;
    for (let i = 0; i < this.invoiceDetail.itemsDetail.length; i++) {
      totalprice = totalprice + parseFloat(this.invoiceDetail.itemsDetail[i].FOB_Rs);
    }
    return totalprice.toFixed(2);
  }
  assignConsigneeDD(event) {

    if (event.target.value == 0) {
      this.totalConsignee = [];
      this.invoiceFrm.controls.BUYER_CODE.patchValue('', { emitEvent: false });
    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      this.invoiceFrm.controls.BUYER_CODE.patchValue(item, { emitEvent: false });
    }
  }
  assignPortDD(event) {

    if (event.target.value == 0) {
      this.totalPort = [];
      this.invoiceFrm.controls.PORT_NAME.patchValue('', { emitEvent: false });

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      this.invoiceFrm.controls.PORT_CODE.patchValue(item, { emitEvent: false });

      let filteredData = this.totalPort.find(item => item.code == valueArray[0]);

      let itemOrigin = {
        "id": filteredData.COUNTRY_CODE,
        "name": filteredData.COUNTRY_NAME
      }
      this.invoiceFrm.controls.Country_Origin_Name.patchValue(filteredData.COUNTRY_NAME, { emitEvent: false });
      this.invoiceFrm.controls.Country_Origin.patchValue(itemOrigin, { emitEvent: false });
    }
  }
  assignPortDischargeDD(event) {

    if (event.target.value == 0) {
      this.totalPortDischarge = [];
      this.invoiceFrm.controls.Port_DischargeName.patchValue('', { emitEvent: false });

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      this.invoiceFrm.controls.Port_Discharge.patchValue(item, { emitEvent: false });
    }
  }
  assignFinalPortDD(event) {

    if (event.target.value == 0) {
      this.totalFinalPort = [];

      this.invoiceFrm.controls.Port_Final_NAME.patchValue('', { emitEvent: false });

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }

      let filteredData: any;

      filteredData = this.totalFinalPort.find(item => item.code == valueArray[0]);

      let itemDestination = {
        "id": filteredData.COUNTRY_CODE,
        "name": filteredData.COUNTRY_NAME
      }
      this.invoiceFrm.controls.COUNTRY_CODE_NAME.patchValue(filteredData.COUNTRY_NAME, { emitEvent: false });
      this.invoiceFrm.controls.COUNTRY_CODE.patchValue(itemDestination, { emitEvent: false });

      this.invoiceFrm.controls.Port_Final.patchValue(item, { emitEvent: false });

    }
  }
  getOtherTotal() {
    let Tot_Freight: any = 0;
    if (this.invoiceFrm.value.Tot_Freight > 0) {
      Tot_Freight = this.invoiceFrm.value.Tot_Freight;
    }
    let C_Insure: any = 0;
    if (this.invoiceFrm.value.C_Insure > 0) {
      C_Insure = this.invoiceFrm.value.C_Insure;
    }
    let C_Disc: any = 0;
    if (this.invoiceFrm.value.C_Disc > 0) {
      C_Disc = this.invoiceFrm.value.C_Disc;
    }
    let C_Comm: any = 0;
    if (this.invoiceFrm.value.C_Comm > 0) {
      C_Comm = this.invoiceFrm.value.C_Comm;
    }
    let C_Other: any = 0;
    if (this.invoiceFrm.value.C_Other > 0) {
      C_Other = this.invoiceFrm.value.C_Other;
    }
    //let total = parseFloat(Tot_Freight) + parseFloat(C_Insure) + parseFloat(C_Disc) + parseFloat(C_Comm) + parseFloat(C_Other);
    let total = parseFloat(Tot_Freight) + parseFloat(C_Insure) + parseFloat(C_Disc) + parseFloat(C_Other);
    return total;
  }

  updateItems($event, i) {
    //this.isloader = true;
    let selectedItem = this.invoiceDetail.invoiceItems.find(item => item.ITEM_CODE == $event.target.value);

    if ($event.target.value == 0) {
      this.invoiceDetail.itemsDetail[i]['showItem'] = 0;
      this.invoiceDetail.itemsDetail[i]['ITEM_CODE'] = ''
      this.invoiceDetail.itemsDetail[i]['GROUP_CODE'] = '';
      this.invoiceDetail.itemsDetail[i]['GROUP_NAME'] = '';
      this.invoiceDetail.itemsDetail[i]['ITEM_NAME'] = '';
      this.invoiceDetail.itemsDetail[i]['scientific_name'] = '';
      this.itemGroupDetail = this.getItemGroupSummary();
    }
    else {
      this.invoiceDetail.itemsDetail[i]['ITEM_CODE'] = selectedItem.ITEM_CODE;
      this.invoiceDetail.itemsDetail[i]['GROUP_CODE'] = selectedItem.GROUP_CODE;
      this.invoiceDetail.itemsDetail[i]['GROUP_NAME'] = selectedItem.GROUP_NAME;
      this.invoiceDetail.itemsDetail[i]['ITEM_NAME'] = selectedItem.ITEM_NAME;
      this.invoiceDetail.itemsDetail[i]['scientific_name'] = selectedItem.scientific_name;
      this.itemGroupDetail = this.getItemGroupSummary();
    }
    /*
        this._service.getItemGrades(selectedItem.ITEM_CODE).subscribe((gradeList) => {
          this.isloader = false;
          console.warn(gradeList)
        });
        */
  }
  onChangeSearchGrade(event, i) {
    if (this.invoiceDetail.itemsDetail[i].ITEM_CODE != "") {
      this._service.getItemGrades(this.invoiceDetail.itemsDetail[i].ITEM_CODE).subscribe((gradeList) => {
        this.isloader = false;
        this.filteredGrade = gradeList[0].gradeDetail;
      });
    }
  }
  onFocusedGrade(event) {
    this.filteredGrade = [];
  }
  selectEventGrades(event, i) {
    this.invoiceDetail.itemsDetail[i]['GRADE_CODE'] = event.code;
    this.invoiceDetail.itemsDetail[i]['grade_name'] = event.name;
    this.invoiceDetail.itemsDetail[i]['Size_Name'] = event.name;

  }
  //Below code to save invoice form 
  saveInvoice() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.invoiceFrm.invalid) {
      console.warn("Invalid");
      return;
    }
    if (this.invoiceFrm.value.SB_No.length != 7) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Shipping Bill number can not be greater than 7 digit ", life: 6000 }); //
      return;
    }
    let itemError = false;
    let pdfItems: any = [] = this.invoiceFrm.value.itemDetails;
    for (let i = 0; i < pdfItems.length; i++) {
      if (pdfItems[i].ITEM_CODE == "") {
        itemError = true;
        break;
      }
    }
    if (itemError) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select items ", life: 6000 }); //
      return false;
    }

    //Below code to add files on forms 
    if (this.invoiceFrm.value.status == 2 && !this.invoiceFrm.value.exporter_declaration) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select declaration ", life: 6000 }); //
      return false;
    }

    const formData = new FormData();
    this.isloader = false;
    formData.append('sBfile', this.invoiceFrm.get('fileSource').value);
    formData.append('invoiceFile', this.invoiceFrm.get('InvfileSource').value);


    // display form values on success
    if (parseFloat(this.invoiceFrm.value.FOB_Value_invoice) != parseFloat(this.invoiceFrm.value.FOB_Value)) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Invoice can't save beacuse FOB Value and FOB Value as per Invoice are not same  ", life: 6000 }); //success,info,warn,error
      return;

    }
    let data = {
      "sbDate": this.invoiceFrm.value.SB_Date
    }
    this._commonService.checkSBillDate(data).subscribe((checkData: any) => {
      if (checkData.data == 0) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: checkData.messagess, life: 6000 }); //
        return;
      }
      else {
        this._service.saveInvoice(this.invoiceFrm.value).subscribe((items: any) => {
          if (items.success) {
            let sbId = items.SbId;
            formData.append('invoiceId', sbId);
            this._service.uploadInvoiceFiles(formData).subscribe((returnData: any) => {

            });
            this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //success,info,warn,error
          }
          this.submitted = false;
          return false;
        });
      }
    });

  }
  updateCartons(event, i) {
    this.invoiceDetail.itemsDetail[i]['cartons_number'] = event.target.value;

  }
  selectEventItem(item, i) {
    console.warn(item);

    this.invoiceDetail.itemsDetail[i]['ITEM_CODE'] = item.code;
    this.invoiceDetail.itemsDetail[i]['GROUP_CODE'] = item.GROUP_CODE;
    this.invoiceDetail.itemsDetail[i]['GROUP_NAME'] = item.GROUP_NAME;
    this.invoiceDetail.itemsDetail[i]['ITEM_NAME'] = item.name;
    this.invoiceDetail.itemsDetail[i]['scientific_name'] = item.scientific_name;
    this.itemGroupDetail = this.getItemGroupSummary();
  }
  onChangeSearchItems(val: string, i) {
    let hsCode = this.invoiceDetail.itemsDetail[i].hs_code;

    this._service.getItemSearch(val, hsCode).subscribe((itemList) => {
      this.filteredItems = itemList;

    })

  }
  onFocusedItem(e) {
    this.filteredItems = [];
    // do something when input is focused
  }
  onSbFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.invoiceFrm.patchValue({
        fileSource: file
      });
    }
  }
  onInvoiceFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.invoiceFrm.patchValue({
        InvfileSource: file
      });
    }
  }
  changeStatus(statusVal) {
    this.invoiceFrm.controls.status.patchValue(statusVal);

  }
  assignExporterDD(event) {

    if (event.target.value == 0) {

    }
    else {
      let value = event.target.value;
      let valueArray = value.split("--");
      // console.warn("Value Array ", valueArray);
      let item = {
        "id": valueArray[0],
        "name": valueArray[1]
      }
      this.invoiceFrm.controls.added_by.patchValue(valueArray[0], { emitEvent: false });

      //Below code to get exporter plants 
      //this.expName = decodedToken['result'].results[0].exp_name;
      //code to get exporter plants 
      this._service.getExporterPlants(valueArray[0]).subscribe((exporterPlants: any) => {
        this.manufacturerPlants = exporterPlants.data;
      });

      this.invoiceFrm.controls.EXP_CODE.patchValue(item, { emitEvent: false });
    }
  }

  onChangeSearchPortIndia(val: string) {

    let filtered: any[] = [];
    let query = val;
    for (let i = 0; i < this.allPortsIndia.length; i++) {
      let item = this.allPortsIndia[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(item);
      }
    }

    this.filteredPortIndia = filtered;
    this.filteredPortIndia = this.filteredPortIndia.slice(0, 10);
  }
  checkBillNumber() {
    let data = {
      "sbNumber": this.invoiceFrm.value.SB_No,
      "id": this.invoiceFrm.value.invoiceId
    }
    if (this.invoiceFrm.value.SB_No != "") {
      this._commonService.checkSbNumber(data).subscribe((item: any) => {
        if (item.success == false) {
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: item.message, life: 6000 }); //success,info,warn,error

        }
      });
    }
  }
}


