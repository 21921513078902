import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportServiceService } from '../report-service.service';


@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html',
  styleUrls: ['./view-reports.component.css']
})
export class ViewReportsComponent implements OnInit {

  loading: boolean = true;
  cols: any[];
  data: any;
  datasource: any;
  totalRecords: number;
  isLoggedIn: number;
  isPermissionAdd: boolean = false;
  isPermissionEdit: boolean = false;
  isPermissionDownload: boolean = false;
  pageName: string;
  branchId: number = 0;
  decodedToken: any = []
  quantityOption: number = 1;
  amountOption: number = 0;
  searchId: number = 0;


  constructor(private _service: ReportServiceService, private _router: Router, private route: ActivatedRoute) { }


  ngOnInit(): void {

    this.isLoggedIn = 1;

    this.route.params.subscribe(params => {
      if (params['id'] != "") {
        this.searchId = params['id'];
      }
    });

  }



  loadInvoices(event: LazyLoadEvent) {
    this.loading = true;
    //console.warn(event.filters);
    // return false;
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    if (this.searchId > 0) {
      setTimeout(() => {

        this._service.getSaveSearchDetail(event.first, event.rows, event.sortField, event.sortOrder, event.filters, this.searchId).subscribe((items: any) => {


          console.warn("response = ", items.data.selectedColumns);



          let searchCloumns = [];
          for (let i = 0; i < items.data.selectedColumns.length; i++) {
            console.warn("response = ", items.data.selectedColumns[i]);
            let item = {
              field: items.data.selectedColumns[i].name, header: items.data.selectedColumns[i].column_name, width: '12%', type: 'text'
            }
            searchCloumns[i] = item;
          }

          this.cols = searchCloumns;
          this.datasource = items.data.result;
          this.data = items.data.data;
          this.totalRecords = items.data.totalRecord;
          this.loading = false;
        }, error => {

          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this._router.navigate(['./']);
            }
          }
        });




      }, 1000);
    }


  }
}
