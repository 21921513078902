import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { StatisticsServiceService } from '../statistics-service.service';


@Component({
  selector: 'app-statistics-item-wise',
  templateUrl: './statistics-item-wise.component.html',
  styleUrls: ['./statistics-item-wise.component.css']
})
export class StatisticsItemWiseComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  isloader: boolean = false;
  submitted: boolean = false;
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  data: any;
  reportData: any = [];
  reportDateRange: any = [];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName: string;
  sourceGroup: any = [];
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private _service: StatisticsServiceService, private _router: Router) { }
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;

  ngOnInit(): void {

    this._service.getGroups().subscribe((itemGrp: any) => {
      this.sourceGroup = itemGrp.data;
    });

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;

    if (reportType == "region") {
      this.reportTitle = "Major Markets for Indian Products - Item-wise - Region-Wise";
    }
    if (reportType == "country") {
      this.reportTitle = "Major Markets for Indian Products - Item-wise - Country Wise";

    }

    if (reportType == "state-wise-annual-marine-product") {
      this.reportTitle = "State Wise Annual Marine Products Landing In India";
    }

    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      itemGroupId: ['', Validators.required],
      viewDetailReport: [''],
      reportType: [reportType],
    });
  }


  get f() { return this.reportFrm.controls; }

  getReport() {
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    this._service.getStatisticsItems(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      if (items.data.reportData.length > 0) {
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
      this.reportDateRange = items.data.reportTime;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
  }

  getQuantity(itemObject, year) {

    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.Qtyintons;
  }
  getQuantityGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.qtyVariationPercent.toFixed(2);
  }
  getValueRs(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobRsCr;
  }
  getValueRsGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.rsVarPer.toFixed(2);
  }
  getValueDollar(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.FobDolMil;
  }
  getValueDollarGrowth(itemObject, year) {
    let filterObject: any = [];
    for (let i = 0; i < itemObject.length; i++) {
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if (key == year) {
          filterObject = value;
        }
      }
    }

    return filterObject.dolVarPer.toFixed(2);
  }

  getTotalQuantity(year) {
    let filterObject: any = [];
    let totalQuantity = 0;
    for (let i = 0; i < this.reportData.length; i++) {
      let objectItems = this.reportData[i].items;
      for (let j = 0; j < objectItems.length; j++) {
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if (key == year) {
            filterObject = value;
            totalQuantity = totalQuantity + filterObject.Qtyintons;
          }
        }
      }
    }
    return totalQuantity.toFixed(2);
  }
  getTotalValue(year) {
    let filterObject: any = [];
    let totalValue = 0;
    for (let i = 0; i < this.reportData.length; i++) {
      let objectItems = this.reportData[i].items;
      for (let j = 0; j < objectItems.length; j++) {
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if (key == year) {
            filterObject = value;
            totalValue = totalValue + filterObject.FobRsCr;
          }
        }
      }
    }
    return totalValue.toFixed(2);
  }
  getTotalValueDoller(year) {
    let filterObject: any = [];
    let totalValue = 0;
    for (let i = 0; i < this.reportData.length; i++) {
      let objectItems = this.reportData[i].items;
      for (let j = 0; j < objectItems.length; j++) {
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if (key == year) {
            filterObject = value;
            totalValue = totalValue + filterObject.FobDolMil;
          }
        }
      }
    }
    return totalValue.toFixed(2);
  }

  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
      // save to file
      xlsx.writeFile(workbook, "growth_report.xlsx");
    });
  }
}
