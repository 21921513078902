import { Component, OnInit } from '@angular/core';
import { ItemmasterService } from './itemmaster.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-itemmaster',
  templateUrl: './itemmaster.component.html',
  styleUrls: ['./itemmaster.component.css']
})
export class ItemmasterComponent implements OnInit {

  constructor(private route: ActivatedRoute,private ItemmasterService : ItemmasterService,private router: Router) { }

  pageHeading:string;
  lastEvent:any;
  datasource:any;
  totalRecords: number;   
  data:any;
  loading: boolean;
  cols: any[];

  ngOnInit(): void {
    this.pageHeading = "Item Master";
    this.cols = [
      {field: 'Id', header: 'ID',type:'hidden',width: '8%'},
      {field: 'Code ', header: 'ITEM_CODE',type:'text',width: '10%'},
      {field: 'ITEM NAME ', header: 'ITEM_NAME',type:'text',width: '10%'},
      {field: 'GROUP CODE ', header: 'GROUP_CODE',type:'textarea',width: '10%'},
      {field: 'Scientific Name ', header: 'scientific_name',type:'text',width: '10%'},
      {field: 'HS Code ', header: 'hs_code',type:'textarea',width: '10%'},

      {field: 'Subgroup Code', header: 'Subgroup_Code',type:'text',width: '10%' },
      {field: 'Added Date', header: 'added_date',type:'calender',width: '10%'},
      {field: 'Modified Date', header: 'modified_date',type:'hideForm',width: '10%'}
    ];
  }

  editItemMasters(data){
    console.warn(data);
    this.router.navigate(['/itemmaster', { id: data.id }]);
  }

  //Code to load item master data 
  loadData(event: LazyLoadEvent) {  
    //this.loading = true;
    this.lastEvent = event;
   // console.log(event.filters);
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
 
    setTimeout(() => {
      
      this.ItemmasterService.getItems(event.first,event.rows,event.sortField,event.sortOrder,event.filters).subscribe((items: any) => {
        //console.log(items);
        this.datasource = items.result;
        this.data = items.data;
        this.totalRecords = items.totalRecord;
        this.loading = false;
      });
      
     
   

    }, 1000);
}    
}
