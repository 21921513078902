import { Component, OnInit } from '@angular/core';
import { ReportServiceService } from '../report-service.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.css']
})
export class CreateReportComponent implements OnInit {


  reportFilters: any = [];
  searchFrm: FormGroup;
  display: boolean = false;
  filterData: any = [];
  tableColumns: any = [];
  selectedFilters: any = [];
  isloader: boolean = false;
  relatedDataHeader: string;
  filterRowData: any = [];
  openPopupValue: number = 0;
  targetGroup: any;
  sourceGroup: any;
  sortColumns: any;
  selectedItem: any = [];
  submitted = false;

  constructor(private _service: ReportServiceService, private formBuilder: FormBuilder, private _router: Router, private primengConfig: PrimeNGConfig,) { }

  ngOnInit(): void {


    this.searchFrm = this.formBuilder.group({
      sortColumn: ['', Validators.required],
      selectedColumn: ['', Validators.required],
      sortColumnType: ['', Validators.required],
      searchName: ['', Validators.required],
      searchFilters: this.formBuilder.array([]),
      dateRangeOption: ['']
    });


    this._service.getReportFilters().subscribe((filters: any) => {
      this.reportFilters = filters.data.filterData;
      this.sourceGroup = filters.data.columnData;
      this.sortColumns = filters.data.columnData;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });
    this.targetGroup = [];
    this.primengConfig.ripple = true;

  }

  get f() { return this.searchFrm.controls; }


  searchFilters(): FormArray {
    return this.searchFrm.get("searchFilters") as FormArray
  }
  newFilter(): FormGroup {
    return this.formBuilder.group({
      filterId: ['', Validators.required],
      filterType: ['', Validators.required],
      filterValues: ['', Validators.required],
      filterCondition: ['', Validators.required],
    });
  }

  addRow() {
    this.searchFilters().push(this.newFilter());
  }

  removeSearchFilter(i: number) {
    this.searchFilters().removeAt(i);
  }

  getFilterOption(event, k) {

    console.warn(event.target.value);
    console.warn(this.reportFilters);
  }

  showDialog(k) {
    let filterId = this.searchFilters().controls[k].get('filterId').value;
    this.selectedFilters = [];
    if (filterId != "") {
      let filterObj = this.reportFilters.filter(dataObj => dataObj.id == filterId);
      if (filterObj[0].is_dependent == 1) {
        this.filterData = "";
        this.isloader = true;
        let data = {
          "id": filterId
        }
        this._service.getFilterDependentData(data).subscribe((filtersData: any) => {
          this.filterData = filtersData.data;
          var jsonObj = this.filterData[0];
          this.tableColumns = Object.keys(jsonObj);
          this.isloader = false;
          this.relatedDataHeader = "Please Select " + filterObj[0].name;
          this.display = true;
          this.openPopupValue = k;
        });
      }
    }
  }
  selectFilters() {
    var jsonObj = this.selectedFilters[0];
    let filterColumn = Object.keys(jsonObj);
    let selectedData = "";
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if (i == (this.selectedFilters.length - 1)) {
        selectedData += this.selectedFilters[i][filterColumn[0]];
      }
      else {
        selectedData += this.selectedFilters[i][filterColumn[0]] + ",";
      }
    }
    let k = this.openPopupValue;
    this.searchFilters().controls[k].get('filterValues').setValue(selectedData);
    this.display = false;
  }
  addColumn(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.searchFrm.controls.selectedColumn.patchValue(this.selectedItem, { emitEvent: false });
  }
  removeColumn(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.searchFrm.controls.selectedColumn.patchValue(this.selectedItem, { emitEvent: false });
  }
  columnReorder(event) {
    this.searchFrm.controls.selectedColumn.patchValue(this.targetGroup, { emitEvent: false });
  }
  saveSearch() {
    this.submitted = true;
    if (this.searchFrm.invalid) {
      return;
    }
    console.warn("In herte", this.searchFrm.value);
    this._service.saveCustomReport(this.searchFrm.value).subscribe((items: any) => {
    });
  }

  getDateRange($event) {
    
  }
}
