import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetupServiceService } from '../../setup-service.service';
import { ActivatedRoute,Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MasterService } from '../../../masters/master.service';

@Component({
  selector: 'app-branch-create',
  templateUrl: './branch-create.component.html',
  styleUrls: ['./branch-create.component.css']
})
export class BranchCreateComponent implements OnInit {

  branchFrm: FormGroup;
  pageHeading:string;
  submitted:boolean = false;
  countriesDDValues:any=[];
  statesDDValues:any=[];
  constructor(private formBuilder: FormBuilder,private _service:SetupServiceService,private route: ActivatedRoute,private messageService: MessageService,private _serviceMaster:MasterService) { }

  ngOnInit(): void {
    this.pageHeading = "Create Field Office";
    this.branchFrm = this.formBuilder.group({
      branchId:'',
      name: ['', Validators.required],
      code: ['', Validators.required],
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      address_line1: ['', Validators.required],
      address_line2: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      post_code: ['', Validators.required],
      country: ['', Validators.required],
      status: ['', Validators.required],
    });
    this.route.params.subscribe(params => {
      if(params['id']!="")
      {
        this.pageHeading = "Edit Field Office";
        this._service.getBranchDetail(params['id']).subscribe((item: any) => {
            this.branchFrm.setValue({
              name: item.data.name,
              branchId:item.data.id,
              code: item.data.code,
              email: item.data.email,
              address_line1: item.data.address_line1,
              address_line2: item.data.address_line2,
              city: item.data.city,
              state: item.data.state,
              post_code: item.data.post_code,
              country: item.data.country,
              status: item.data.status,
            });
        });
      }
    });
    this._serviceMaster.getCountry().subscribe((countries) => {
      this.countriesDDValues = countries;
      this._serviceMaster.getStates().subscribe((states) => {
        this.statesDDValues = states;
      });
    });
  }


  //Below code to save branch form 
  get f() { return this.branchFrm.controls; }

  saveBranch(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.branchFrm.invalid) {
        return;
    }
    this._service.saveBranch(this.branchFrm.value).subscribe((items: any) =>{      
      this.submitted = false;
      console.warn(items);
      if(!items.status)
      {
        this.messageService.add({severity:'error', summary: 'Fail', detail: items.message, life: 6000}); //
      }
      else{
        this.messageService.add({severity:'success', summary: 'Success', detail: items.message, life: 6000}); //
      }
      return false;
    });


  }

}
