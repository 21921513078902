import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router'
import { AuthService } from '../user-login/auth.service';
import jwt_decode from "jwt-decode";
import * as CryptoJS from 'crypto-js';
import { encryptSecretKey } from 'src/environments/environment';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css']
})
export class TopNavigationComponent implements OnInit {

  firstname: string;
  lastname: string;
  constructor(private router: Router, private authService: AuthService, private activatedRoute: ActivatedRoute) { }
  isLogin: boolean;
  routerUrl: string;
  menus: any;
  menuItem: any = [];
  isloaded: boolean = false;
  constuctername: string;
  tree: UrlTree;
  primary: UrlSegmentGroup;
  //primary:any = [];
  isExporter: boolean = false;

  ngOnInit(): void {
    //console.warn("Vikas ");
    this.tree = this.router.parseUrl(this.router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    if (this.primary) {  //this.primary
      const primarySegments: UrlSegment[] = this.primary.segments;
      let length = primarySegments.length;
      if (primarySegments[0].path == "exporter") {
        this.isExporter = true;
      }
      else {
        this.isExporter = false;
      }
    }
    if (this.isloaded) {
      // console.warn("Vikas Narain ");
      if (!this.isExporter) {
        let data = localStorage.getItem('userLevel');
        if (data != '') {
          let bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);

          this.menus = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      let token = JSON.parse(localStorage.getItem('token'));
      let decodedToken = jwt_decode(token);

    }

    //console.warn("Vikas Narain Mathur");

    let data = localStorage.getItem('userLevel');
    if (data != null) {
      let bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);

      this.menus = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    let token = JSON.parse(localStorage.getItem('token'));
    let decodedToken = jwt_decode(token);
  }
  logOut() {
    //  this.isloaded = false;
    //  this.authService.logOut();
    var token = JSON.parse(localStorage.getItem('token'));
    var decoded = jwt_decode(token);
    var data = {
      'email': decoded['result'].email
    };

    this.authService.logOut(data).subscribe((items: any) => {
      localStorage.removeItem("token");
      // this.isLogin = 0;
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userLevel");
      this.router.navigate([""]);

    });

  }

  exporterlogOut() {
    // this.authService.exporterlogOut();

    var token = JSON.parse(localStorage.getItem('token'));
    var decoded = jwt_decode(token);

    console.log("Exporter detail", decoded);
    var data = {
      'email': decoded['result']['results'][0].email
    };

    this.authService.logOut(data).subscribe((items: any) => {

      console.log("Exporter Logout successfully");
      localStorage.removeItem("token");
      // this.isLogin = 0;
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userLevel");
      this.router.navigate([""]);

    });
  }
  exporterlogOut_old() {
    // this.authService.exporterlogOut();

    var token = JSON.parse(localStorage.getItem('token'));
    var decoded = jwt_decode(token);
    var data = {
      'email': decoded['result'].email
    };

    this.authService.logOut(data).subscribe((items: any) => {
      localStorage.removeItem("token");
      // this.isLogin = 0;
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userLevel");
      this.router.navigate([""]);

    });
  }
  exporterlogOut_25072021() {
    this.isloaded = false;

    this.authService.exporterlogOut();
  }
  checkLogin() {

    this.routerUrl = this.router.url;
    //console.warn("Router URL = ",this.routerUrl)
    this.isLogin = this.authService.isLoggedIn();
    // console.warn("This Login ", this.isLogin);
    if (this.isLogin) {
      var token = JSON.parse(localStorage.getItem('token'));
      var decoded = jwt_decode(token);
      //console.warn(decoded);
      this.firstname = decoded['result'].first_name;
      this.lastname = decoded['result'].last_name;

      if (!this.isloaded) {
        this.isloaded = true;
        this.ngOnInit();
      }
      return this.authService.isLoggedIn();

    }
    else {
      //this.router.navigate(["./"]);

      return 0;

    }

  }
}
