import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from "../../../common-service.service";


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  loading: boolean=true;
  cols: any[];
  data:any;
  datasource:any;
  totalRecords: number;  
  isLoggedIn:number;
  isPermissionAdd:boolean=false
  isPermissionEdit:boolean=false
  constructor(private _router:Router,private _commonService:CommonServiceService) { }

  ngOnInit(): void {
    this.cols = [
      {field: 'Id', header: 'id',width: '5%',isSearch:"1"},
      {field: 'User Name', header: 'username',width: '15%',isSearch:"1"},
      {field: 'User Type', header: 'user_type',width: '8%',isSearch:"1"},
      {field: 'IP', header: 'server_ip',width: '8%',isSearch:"1"},
      {field: 'Login Time', header: 'loginDate',width: '10%',isSearch:"1"},
      {field: 'Logout Time', header: 'logOutDate',width: '10%',isSearch:"1"},
      {field: 'Login Status', header: 'is_valid_login',width: '10%',isSearch:"1"},
    ];
  }

  loadUsers(event: LazyLoadEvent) {  
    this.loading = true;
   
    setTimeout(() => {  

      this._commonService.getUsers(event.first,event.rows,event.sortField,event.sortOrder,event.filters).subscribe((items: any) => {
        this.datasource = items.result;
        this.data = items.data.data;
        this.totalRecords = items.data.totalRecord;
        this.loading = false;
      },error=>{
         
        if(error instanceof HttpErrorResponse)
        {
          if(error.status===401){
            this._router.navigate(['./']);
          }
        }
      });
    }, 1000);
    
} 
}
