import { Component, OnInit } from '@angular/core';
import { SetupServiceService } from '../setup-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';


@Component({
  selector: 'app-reconcile-final-list',
  templateUrl: './reconcile-final-list.component.html',
  styleUrls: ['./reconcile-final-list.component.css']
})
export class ReconcileFinalListComponent implements OnInit {

  constructor(private _service: SetupServiceService, private _router: Router) { }
  loading: boolean = false;
  cols: any[];
  data: any;
  datasource: any;
  totalRecords: number;
  isLoggedIn: number;
  isPermissionAdd: boolean = false;
  isPermissionEdit: boolean = false;
  decodedToken: any = [];
  branchId: number = 0;

  ngOnInit(): void {
    this.cols = [
      { field: 'Id', header: 'id', width: '5%', isSearch: "0" },
      { field: 'Batch Name', header: 'title', width: '15%', isSearch: "1" },
      { field: 'Start Date', header: 'startDate', width: '10%', isSearch: "1" },
      { field: 'End Date', header: 'endDate', width: '10%', isSearch: "1" },
      { field: 'Quantity(KG)', header: 'total_quantity', width: '10%', isSearch: "1" },
      { field: 'Amount(RS)', header: 'total_amount_rs', width: '10%', isSearch: "1" },
      { field: 'Amount($)', header: 'total_amount_dollar', width: '10%', isSearch: "1" },
      { field: 'Status', header: 'status', width: '10%', isSearch: "0" },
      { field: 'Added Date', header: 'createdDate', width: '10%', isSearch: "0" },
      { field: 'Modify Date', header: 'modifyDate', width: '10%', isSearch: "0" },
    ];


  }
  loadData(event: LazyLoadEvent) {
    this.loading = true;
    //console.warn(event.filters);
    // return false;
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {

      this._service.getFinalReconcileList(event.first, event.rows, event.sortField, event.sortOrder, event.filters).subscribe((items: any) => {
        this.datasource = items.result;
        this.data = items.data.data;
        this.totalRecords = items.data.totalRecord;
        this.loading = false;
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });
    }, 1000);

  }
}
