import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ExporterServiceService } from '../exporter-service.service';
import jwt_decode from "jwt-decode";
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-exporter-upload-sezpdf',
  templateUrl: './exporter-upload-sezpdf.component.html',
  styleUrls: ['./exporter-upload-sezpdf.component.css']
})
export class ExporterUploadSezpdfComponent implements OnInit {

  isloader: boolean = false;
  submitted: boolean = false;
  pdfDetail: any = [];

  myForm = this.formBuilder.group({
    sbFile: new FormControl('', [Validators.required]),
    invFile: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
    invFileSource: new FormControl('', [Validators.required]),
    BILL_NO: ['', Validators.required],
    SB_No: ['', [Validators.required, Validators.minLength(7)]],

  });
  constructor(private _service: ExporterServiceService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  get f() {
    return this.myForm.controls;
  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }
  oninvFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        invFileSource: file
      });
    }
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.myForm.invalid) {
      return;
    }
    const formData = new FormData();
    this.isloader = false;
    formData.append('sbFile', this.myForm.get('fileSource').value);
    formData.append('invFile', this.myForm.get('invFileSource').value);

    this._service.uploadSezInvoice(formData).subscribe((items: any) => {
      this.pdfDetail = items.data;
    });
  }
}
