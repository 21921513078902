import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ExporterServiceService } from '../../exporter-service.service';
import { calendarRange } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import jwt_decode from "jwt-decode";


@Component({
  selector: 'app-reconcilation-add',
  templateUrl: './reconcilation-add.component.html',
  styleUrls: ['./reconcilation-add.component.css']
})
export class ReconcilationAddComponent implements OnInit {


  cols: any[];

  recocileBatchFrm: FormGroup;
  pageHeading: string = "";
  submitted: boolean = false;
  branchList: any;
  userType: any;
  readonly: boolean = false;
  branchExporters: any;
  calendarRange: string = calendarRange;
  data: any;
  totalRecords: any;
  quantityOption: number = 1;
  amountOption: number = 0;
  quantityTotal: any;
  amountTotal: any;
  amountTotalRs: any;
  exportedData: any;
  isloader: boolean = false;
  totalExporter: number = 0;
  exporterCode: number = 0;

  constructor(private formBuilder: FormBuilder, private _service: ExporterServiceService, private messageService: MessageService, private route: ActivatedRoute, private _router: Router) { }
  @ViewChild('detailTable') detailTable: ElementRef;

  ngOnInit(): void {

    let token = JSON.parse(localStorage.getItem('token'));
    let decodedToken = jwt_decode(token);
    console.warn("Decoded Token - ", decodedToken)
    this.totalExporter = decodedToken['result'].results.length;
    this.exporterCode = decodedToken['result'].results[0].exp_code,

      this.cols = [
        { field: 'Shi. Bill #', header: 'SB_No', width: '12%', type: 'text' },
        { field: 'Shi. Bill Date', header: 'SB_Date', width: '12%', type: 'text' },
        { field: 'Invoice #', header: 'BILL_NO', width: '12%', type: 'text' },
        { field: 'Date', header: 'Date', width: '10%', type: 'text' },
        { field: 'Exporter', header: 'EXP_NAME', width: '15%', type: 'text' },
        { field: 'Consignee', header: 'BUYER_NAME', width: '15%', type: 'text' },
        { field: 'Port Of Loading', header: 'PORT_NAME', width: '15%', type: 'text' },
        { field: 'Qty(KG)', header: 'Tot_Weight', width: '10%', type: 'text' },
        { field: 'Amount($)', header: 'Tot_Amount', width: '10%', type: 'text' },
        { field: 'Amount(₹)', header: 'tot_amount_rs', width: '10%', type: 'text' },
        { field: 'Status', header: 'status', width: '10%', type: 'text' },
        { field: 'Entry Date', header: 'EntryDate', width: '10%', type: 'text' },
        { field: 'Status', header: 'statusCode', width: '10%', type: 'hidden' },

      ];

    this.recocileBatchFrm = this.formBuilder.group({
      batchId: '',
      batchName: ['', Validators.required],
      exporterId: [this.exporterCode, Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      totalQuantity: [''],
      totaAmountDollar: [''],
      totaAmountRs: [''],
      status: '1',
      invoiceIds: [''],
      declaration: [false],


    });
  }


  get f() { return this.recocileBatchFrm.controls; }


  getShippingBill() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.recocileBatchFrm.invalid) {
      return;
    }
    this.isloader = true;

    this._service.getExporterInvoice(this.recocileBatchFrm.value).subscribe((item: any) => {
      this.data = item.data;
      this.quantityTotal = 0;
      this.amountTotal = 0;
      this.amountTotalRs = 0;
      this.exportedData = [];
      let invoicesIds = '';
      for (let i = 0; i < this.data.length; i++) {
        this.quantityTotal = this.quantityTotal + this.data[i].Tot_Weight;
        this.amountTotal = this.amountTotal + this.data[i].Tot_Amount;
        this.amountTotalRs = this.amountTotalRs + this.data[i].tot_amount_rs;

        let item = {
          "Shpipping Bill Number": this.data[i].SB_No,
          "Shpipping Bill Date": this.data[i].SB_Date,
          "Invoice #": this.data[i].BILL_NO,
          "Date": this.data[i].Date,
          "Exporter": this.data[i].EXP_NAME,
          "Consignee": this.data[i].BUYER_NAME,
          "Port Of Loading": this.data[i].PORT_NAME,
          "Qty(KG)": this.data[i].Tot_Weight,
          "Amount($)": this.data[i].Tot_Amount,
          "Amount(₹)": this.data[i].tot_amount_rs,
          "Status": this.data[i].status,
          "Entry Date": this.data[i].EntryDate
        }
        this.exportedData.push(item);
        invoicesIds = invoicesIds + this.data[i].id + ",";
      }
      //   console.warn()

      this.totalRecords = item.data.length;
      this.recocileBatchFrm.controls.totalQuantity.patchValue(this.quantityTotal);
      this.recocileBatchFrm.controls.totaAmountDollar.patchValue(this.quantityTotal);
      this.recocileBatchFrm.controls.totaAmountRs.patchValue(this.amountTotalRs);
      this.recocileBatchFrm.controls.invoiceIds.patchValue(invoicesIds);

      this.isloader = false;

    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./exporter/login']);
        }
      }
    });
  }

  getQuantityOption($event) {
    this.quantityOption = $event.target.value;
  }
  getAmountOption($event) {
    this.amountOption = $event.target.value;
  }
  exportShippingBillCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      // const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      const ws = xlsx.utils.json_to_sheet(this.exportedData);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "reconcileSB.csv");

    });
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.json_to_sheet(this.exportedData);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Item');
      // save to file
      xlsx.writeFile(workbook, "reconcileSB.xlsx");

    });
  }
  saveRecocile(statusVal) {
    this.recocileBatchFrm.controls.status.patchValue(statusVal);
    // stop here if form is invalid
    this.isloader = true;

    if (this.recocileBatchFrm.invalid) {
      this.isloader = false;
      return;
    }
    if (this.recocileBatchFrm.value.status == 2 && !this.recocileBatchFrm.value.declaration) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please select declaration ", life: 6000 }); //
      this.isloader = false;

      return false;
    }
    this._service.saveBatch(this.recocileBatchFrm.value).subscribe((items: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //
      this.isloader = false;



    })
  }
}
