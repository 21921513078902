import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ReportSummaryService } from '../report-summary.service';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportServiceService } from '../../report-service.service';
import { calendarRange } from 'src/environments/environment';


@Component({
  selector: 'app-report-port',
  templateUrl: './report-port.component.html',
  styleUrls: ['./report-port.component.css']
})
export class ReportPortComponent implements OnInit {

  reportFrm: FormGroup;
  sourceGroup: any;
  targetGroup: any;
  reportTitle: string;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  data: any;
  reportData: any = [];
  showTextBox: boolean = false;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  calendarRange: string = calendarRange;

  constructor(private formBuilder: FormBuilder, private _service: ReportSummaryService, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _reportService: ReportServiceService) { }


  ngOnInit(): void {
    this.reportFrm = this.formBuilder.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      selectedPort: [''],
      viewDetailReport: [''],
      reportType: ["port"],
      topRecordNum: ['0'],
      topRecordNumOther: ['1'],
      includeBlankData: ['0'],
      sortColumn: ['FobDolMil'],
      sortType: ['DESC'],

    });
    this.reportTitle = "Port Wise";
    this.targetGroup = [];
    this.primengConfig.ripple = true;


    this._reportService.getPorts().subscribe((ports: any) => {
      this.sourceGroup = ports.data;
    });
    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });
    this.reportFrm.valueChanges.subscribe(val => {

      if (val.topRecordNum == "-1") {
        console.warn(val);
        this.showTextBox = true;
      }
      else {
        this.showTextBox = false;
      }
    });

  }
  get f() { return this.reportFrm.controls; }

  addPort(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  removePort(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }


  getReport() {
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }


    if (this.reportFrm.value.selectedPort == "") {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Port", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }
    this._service.getPortWiseReport(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      if (items.data.reportData.length > 0) {
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
      }
      this.reportData = items.data.reportData;
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }
  getShare(itemQty, totalQty) {
    let percentage = itemQty * 100 / totalQty;
    return percentage.toFixed(2);
  }
  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
      /*
      let exportedData = [{
        "":"THE MARINE PRODUCTS EXPORT DEVELOPMENT AUTHORITY, COCHIN",        
      },
      {
        "":"Item Wise Export Compilation during April - 2019 to March - 2020"
      },
      {
        "":"Q: Quantity in Tons,  V: Value in Rs. Crores, $: USD Million, UV$: Unit Value (US$/kg)"
      },{
        "Ports":"",
        "":"",
        "Share %":"",
        "Apr-2019 - Mar-2020":"",
        "Apr-2018 - Mar-2019":"",
        "(%)":""
      },
      ,{
        "Ports":"",
        "":"",
        "Share %":"",
        "Apr-2019 - Mar-2020":"",
        "Apr-2018 - Mar-2019":"",
        "(%)":""
      }
    ];
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },{ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },{ s: { r: 2, c: 0 }, e: { r: 2, c: 5 } },{ s: { r: 3, c: 0 }, e: { r: 3, c: 5 } }
      ];
      console.warn(this.reportData);
      const worksheet = xlsx.utils.json_to_sheet(exportedData);
      worksheet["!merges"] = merge;
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "products");
      */
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  getRounded(number) {
    return Math.round(number).toFixed(2);
  }
}
