import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemmasterService } from '../itemmaster/itemmaster.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-edit-item-master',
  templateUrl: './edit-item-master.component.html',
  styleUrls: ['./edit-item-master.component.css']
})
export class EditItemMasterComponent implements OnInit {

  pageHeading: string;
  lastEvent: any;
  datasource: any;
  totalRecords: number;
  itemDetail: any;
  loading: boolean;
  itemFrm: FormGroup;
  submitted = false;
  itemGroup: any;
  itemSubGroup: any;
  grades: any;
  constructor(private route: ActivatedRoute, private router: Router, private ItemmasterService: ItemmasterService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
    this.itemFrm = this.formBuilder.group({
      ITEM_CODE: ['', [Validators.required, Validators.maxLength(8)]],
      ITEM_NAME: ['', Validators.required],
      GROUP_CODE: ['', Validators.required],
      scientific_name: ['', Validators.required],
      hs_code: ['', Validators.required],
      id: [''],
      Subgroup_Code: [''],
      added_date: ['', Validators.required],
      subgroups: this.formBuilder.array([]),
    });


    this.route.params.subscribe(params => {
      if (params['id'] == "add") {
        this.ItemmasterService.getGroupDetails().subscribe((item: any) => {
          this.itemGroup = item.data;
          this.grades = item.data[0].gradeDetails
          this.pageHeading = "Add Item Master";
        });
      }
      if (params['id'] != "" && params['id'] != "add") {
        this.pageHeading = "Edit Item Master";
        this.ItemmasterService.getItemDetail(params['id']).subscribe((item: any) => {

          console.warn(item.data[0].added_date);
          this.itemDetail = item.data[0].itemsDetail;
          this.itemGroup = item.data[0].groupDetail;
          this.grades = item.data[0].gradeDetails;
          this.itemFrm.setValue({
            ITEM_CODE: item.data[0].ITEM_CODE,
            id: item.data[0].id,
            ITEM_NAME: item.data[0].ITEM_NAME,
            GROUP_CODE: item.data[0].GROUP_CODE,
            scientific_name: item.data[0].scientific_name,
            hs_code: item.data[0].hs_code,
            added_date: item.data[0].added_date,
            Subgroup_Code: item.data[0].Subgroup_Code,
            subgroups: [],
          });
        });
      }
    });

  }


  get f() { return this.itemFrm.controls; }

  saveItem() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.itemFrm.invalid) {
      return;
    }
    this.ItemmasterService.saveItemMaster(this.itemFrm.value).subscribe((items: any) => {

      console.warn(items);
      if (!items.success) {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.message, life: 6000 }); //
      }
      else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: items.message, life: 6000 }); //
      }

    });
    // console.warn('SUCCESS!! :-)\n\n' + JSON.stringify(this.itemFrm.value, null, 4));
  }

  getSubgroup(event) {
    let group = event.target.value;
    if (group != "") {
      this.ItemmasterService.getSubGroupDetails(group).subscribe((subGroup: any) => {
        this.itemSubGroup = subGroup.data;
      });
    }
  }


  newSubgroup(): FormGroup {

    return this.formBuilder.group({
      grade_code: ['', Validators.required],
      grade_name: '',
    })

  }
  subgroups(): FormArray {

    return this.itemFrm.get("subgroups") as FormArray

  }
  addRow() {
    this.subgroups().push(this.newSubgroup());
  }

  removeSubgroup(i: number) {
    this.subgroups().removeAt(i);
  }
  getGradeCode(event, k) {
    this.subgroups().controls[k].get('grade_name').setValue(event.target.value);
  }
  remove(id: number) {
    if (id > 0) {
      this.ItemmasterService.removeGrade(id).subscribe((item: any) => {
        //this.itemSubGroup = subGroup.data;

        if (item.success) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: item.message, life: 6000 }); //
          this.itemDetail = item.data[0].itemsDetail;

        }
      });
    }
    return false;
  }

}
