<p-toast></p-toast>
<form [formGroup]="branchFrm" name="branchFrm" (ngSubmit)="saveBranch()"  >
    <div class="pageheadingouter">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> {{pageHeading}} </h1>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <a  [routerLink]="['/setup/branch']"><button type="button" class="btn btn-light"> <i class="fa fa-times"></i> Cancel</button></a>
                    <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>

                </div>


            </div>

        </div>
    </div>
    <div class="container pt-4">


        <div class="card">
            <div class="card-header" id="headingOne">
                <a > Field Office Detail</a>
            </div>

            <div id="item_details" >
                <div class="card-body px-0">

                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Name </label>
                            <input type="text" pInputText formControlName="name" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }" value="" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name  is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Code </label>
                            <input type="text" pInputText formControlName="code" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.code.errors }" value="">
                            <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                                <div *ngIf="f.code.errors.required">Code  is required</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Email</label>
                            <input type="text" pInputText formControlName="email" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" value="" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                        </div>
                                            
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Address Line 1</label>
                            <input type="text" pInputText formControlName="address_line1" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.address_line1.errors }" value="" />
                            <div *ngIf="submitted && f.address_line1.errors" class="invalid-feedback">
                                <div *ngIf="f.address_line1.errors.required">Address Line 1 is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Address Line 2 </label>
                            <input type="text" pInputText formControlName="address_line2" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.address_line2.errors }" value="">
                            <div *ngIf="submitted && f.address_line2.errors" class="invalid-feedback">
                                <div *ngIf="f.address_line2.errors.required">Address Line 2 is required</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> City</label>
                            <input type="text" pInputText formControlName="city" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.city.errors }" value="" />
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">City is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> State </label>
                            <select class="form-control" id="state" formControlName="state" name="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" >
                                <option value="">Select State</option>
                                <option *ngFor="let item of statesDDValues | keyvalue" value="{{item.value.code}}" >{{item.value.name}}</option>
                            </select>                              
                            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                <div *ngIf="f.state.errors.required">State is required</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Post Code</label>
                            <input type="text" pInputText formControlName="post_code" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.post_code.errors }" value="" />
                            <div *ngIf="submitted && f.post_code.errors" class="invalid-feedback">
                                <div *ngIf="f.post_code.errors.required">Post Code is required</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Country </label>

                            <select class="form-control" id="country" formControlName="country" name="country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }" >
                                <option value="">Select Country</option>
                                <option *ngFor="let item of countriesDDValues | keyvalue" value="{{item.value.code}}" >{{item.value.name}}</option>
                            </select>                           
                            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                <div *ngIf="f.country.errors.required">Country is required</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-md-12 form-group">
                            <label> Status</label>
                            <select class="form-control" id="status" formControlName="status" name="status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }" >
                                <option value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">inActive</option>
                            </select>  
                        </div>
                                         
                    </div>
                </div>
            </div>
        </div>

    </div>
    <input type="hidden" pInputText formControlName="branchId" class="form-control" value="">
</form>