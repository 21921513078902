import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router,UrlTree,UrlSegment,PRIMARY_OUTLET,UrlSegmentGroup } from '@angular/router';
import { StatisticsServiceService } from '../statistics-service.service';


@Component({
  selector: 'app-statistics-region-wise',
  templateUrl: './statistics-region-wise.component.html',
  styleUrls: ['./statistics-region-wise.component.css']
})
export class StatisticsRegionWiseComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle:string;
  isloader:boolean=false;
  submitted:boolean=false;
  displayReport:boolean=false;
  displayDetailedReport:boolean=false;
  data:any;
  reportData:any=[];
  reportDateRange:any=[];
  tree: UrlTree;
  fragment = '';
  primary: UrlSegmentGroup;
  reportTypeName:string;
  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,private _service:StatisticsServiceService,private _router:Router) { }
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;


  ngOnInit(): void {
    this.reportTitle = "Region-wise Exports of Marine Products";
    this.reportFrm = this.formBuilder.group({
      dateStart:['',Validators.required],
      dateEnd:['',Validators.required],
      viewDetailReport:[''],    
      reportType:[''],    
    }); 
    this.reportFrm.valueChanges.subscribe(val=>{
      if(this.reportData.length>0){
        if(this.reportFrm.value.viewDetailReport){      
            this.displayReport=false;
            this.displayDetailedReport=true;
        }
        else{
          this.displayReport=true;
          this.displayDetailedReport=false;
        }
      }
        
    });
  }

  get f() { return this.reportFrm.controls; }

  getReport(){
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }
    this._service.getAllRegionsStats(this.reportFrm.value).subscribe((items: any) =>{
      this.data = items.data;
      if(items.data.reportData.length>0)
        {
          this.displayReport=true;
          this.displayDetailedReport=false;
          this.isloader = false;
        }
        else{
          this.displayReport=false;
          this.displayDetailedReport=false;
          this.isloader = false;
        } 
        this.reportData = items.data.reportData;
        this.reportDateRange = items.data.reportTime;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });
  }

  getQuantity(itemObject,year){

    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.Qtyintons.toFixed(0);
  }
  getValueRs(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.FobRsCr.toFixed(0);
  }
  getValueDollar(itemObject,year){
    let filterObject:any = [];
    for(let i=0;i<itemObject.length;i++){
      let object = itemObject[i];
      for (const [key, value] of Object.entries(object)) {
        if(key==year){
          filterObject = value;
        }
      }  
    }

    return filterObject.FobDolMil.toFixed(0);
  }
  getTotalQuantity(year){
    let filterObject:any = [];
    let totalQuantity = 0;
    for(let i=0;i<this.reportData.length;i++){
      let objectItems = this.reportData[i].items;
      for(let j=0;j<objectItems.length;j++){
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if(key==year){
            filterObject = value;
            totalQuantity = totalQuantity+filterObject.Qtyintons;
          }
        }
      }     
    }
    return totalQuantity.toFixed(0);
  }
  getTotalValue(year){
    let filterObject:any = [];
    let totalValue = 0;
    for(let i=0;i<this.reportData.length;i++){
      let objectItems = this.reportData[i].items;
      for(let j=0;j<objectItems.length;j++){
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if(key==year){
            filterObject = value;
            totalValue = totalValue+filterObject.FobRsCr;
          }
        }
      }     
    }
    return totalValue.toFixed(0);
  }
  getTotalValueDoller(year){
    let filterObject:any = [];
    let totalValue = 0;
    for(let i=0;i<this.reportData.length;i++){
      let objectItems = this.reportData[i].items;
      for(let j=0;j<objectItems.length;j++){
        let object = objectItems[j];
        for (const [key, value] of Object.entries(object)) {
          if(key==year){
            filterObject = value;
            totalValue = totalValue+filterObject.FobDolMil;
          }
        }
      }     
    }
    return totalValue.toFixed(0);
  }

  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report_detail.xlsx");
 
    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report_detail.csv");
 
    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.csv");
      
    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.xlsx");
    });
  }
}
