<p-toast></p-toast>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<section class="innerpage">
    <div class="pageheadingouter">
        <div class="container container2">

            <div class="row">
                <div class="col-xl-6 col-md-6 text-md-left text-sm-left text-center">

                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> Shipping Bill </h1>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">


                    <a><button type="button" class="btn btn-success" (click)="downloadExcel();"><i
                                class="fa fa-save"></i>
                            Download Excel </button></a>
                    <a href="{{fileUrl}}" target="_blank" *ngIf="isUpdateFile"> Click Here to download </a>

                    <a [routerLink]="['/exporter/invoices']"><button type="button" class="btn btn-light"> <i
                                class="fa fa-times"></i> Cancel</button></a>

                </div>
            </div>
        </div>
    </div>

    <div class="container pt-4">
        <p-accordion [multiple]="true">
            <p-accordionTab header="Upload Excel" [selected]="true">
                <div class="card-body px-0">
                    <form [formGroup]="myForm" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-xl-3 col-md-12 form-group">
                                <label for="file"> <strong>Upload Excel</strong> </label>
                            </div>
                            <div class="form-group">
                                <input formControlName="file" id="file" type="file" class="input-file"
                                    [ngClass]="{ 'is-invalid': submitted && f.file.errors }"
                                    (change)="onFileChange($event)">
                                <div *ngIf="submitted && f.file.errors" class="invalid-feedback">
                                    <div *ngIf="f.file.errors.required">File is required.</div>
                                </div>
                            </div>





                        </div>
                        <div class="row">
                            <div class="col-xl-3 col-md-12 form-group">
                                <label for="file"> <strong>Update Existing Records </strong> </label>
                            </div>
                            <div class="form-group">
                                <input formControlName="updateExisting" type="checkbox" class="input-file" />&nbsp; Yes
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 col-md-12 form-group">
                                <label for="file">&nbsp;</label>
                            </div>
                            <button class="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                    <div class="row">
                        <div class="col-xl-3 col-md-12 form-group">

                        </div>
                        <div class="reslt" *ngIf="showResult" innerHTML="{{ uploadResult }}">

                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</section>
<br>