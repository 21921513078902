<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} Report </h1>
                </div>
            </div>       
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()"  >
       
        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <p-calendar formControlName="dateStart" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }" ></p-calendar>
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                        </div>
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <p-calendar  formControlName="dateEnd"  view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true" inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }"></p-calendar>
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                        </div>
                    </div>

                </div>
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Sort By </label>
                    </div>
                    <div class="col form-group">
                        <select name="topRecordNum" formControlName="topRecordNum"  class="form-control">
                            <option value="0">All Records </option>
                            <option value="5">Top 5 Records </option>
                            <option value="10">Top 10 Records </option>
                            <option value="-1">Top n Records </option>
                        </select>                        
                    </div>
                    
                    <div class="col form-group" *ngIf="showTextBox" >
                        <input type="number" class="form-control" formControlName="topRecordNumOther" />
                    </div>
                    <div class="col form-group">
                        <select name="sortColumn" formControlName="sortColumn"  class="form-control">
                            <option value="Qtyintons">Quantity</option>
                            <option value="FobRsCr">Rupees</option>
                            <option value="FobDolMil">US$</option>
                            <option value="uvDol">Unit Value</option>
                        </select>                        
                    </div>
                    <div class="col form-group">
                        <select name="sortColumn" formControlName="sortType"  class="form-control">
                            <option value="ASC">Assending</option>
                            <option value="DESC">Desending</option>
                        </select>                        
                    </div>
                </div>
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Include No Data </label>
                    </div>
                    <div class="col form-group">
                        
                        <select name="includeBlankData" formControlName="includeBlankData"  class="form-control">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-xl-12 col-md-12 form-group">
                        <p-pickList [source]="sourceGroup"  [target]="targetGroup" sourceHeader="Available" targetHeader="Selected" dragdrop="true"   [responsive]="true" [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false" [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name" (onMoveToSource)="removePort($event)" (onMoveToTarget)="addPort($event)" >
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>                                   
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>                   
                </div>
                
                
                
                <div class="row">
                    <div class="btn_pan mb-4">
                            <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>    
                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                    
                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>Q</b>: Quantity in Tons,</span>
                    <span><b>V</b>: Value in Rs. Crores,</span>
                    <span><b>$</b>: USD Million</span>
                    
                </div>
                
                <div class="card" *ngIf="displayDetailedReport">
                    <table #detailTable>  
                    <p-table [value]="detailReportData">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportDetailExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th colspan="1">&nbsp;</th>
                                <th colspan="1">&nbsp;</th>
                                <th colspan="3">Share % </th>
                                <th colspan="3">{{data.dateStart}}</th>
                                <th colspan="3">{{data.endDate}}</th>
                                <th colspan="3">Variation</th>
                                <th colspan="3">Variation (%)</th>
                            </tr>
                            <tr>
                                <th >Region </th>
                                <th pSortableColumn="name">Country <p-sortIcon field="name"></p-sortIcon></th>
                                <th >Q </th>
                                <th >V </th>
                                <th >$ </th>
                        
                                <th pSortableColumn="Qtyintons">Q <p-sortIcon field="Qtyintons"></p-sortIcon></th>
                                <th pSortableColumn="FobRsCr">V <p-sortIcon field="FobRsCr"></p-sortIcon></th>
                                <th pSortableColumn="FobDolMil">$ <p-sortIcon field="FobDolMil"></p-sortIcon></th>
                        
                                <th pSortableColumn="QtyPreTon">Q <p-sortIcon field="QtyPreTon"></p-sortIcon></th>
                                <th pSortableColumn="FobRsPreCr">V <p-sortIcon field="FobRsPreCr"></p-sortIcon></th>
                                <th pSortableColumn="FobDolPreMil">$ <p-sortIcon field="FobDolPreMil"></p-sortIcon></th>
                        
                                <th pSortableColumn="qtyVariation">Q <p-sortIcon field="qtyVariation"></p-sortIcon></th>
                                <th pSortableColumn="rsVar">V <p-sortIcon field="rsVar"></p-sortIcon></th>
                                <th pSortableColumn="dolVar">$ <p-sortIcon field="dolVar"></p-sortIcon></th>
                                
                                <th pSortableColumn="qtyVariationPercent">Q <p-sortIcon field="qtyVariationPercent"></p-sortIcon></th>
                                <th pSortableColumn="rsVarPer">V <p-sortIcon field="rsVarPer"></p-sortIcon></th>
                                <th pSortableColumn="reportData.dolVarPer">$ <p-sortIcon field="reportData.dolVarPer"></p-sortIcon></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                            <tr >
                                <td ><strong>{{item.groupName}}</strong></td>
                                <td >{{item.itemName}}</td>
                                <td >{{getShare(item.Qtyintons,data.totalQuantity)}}</td>
                                <td>{{getShare(item.FobRsCr,data.totalPriceRs)}}</td>
                                <td>{{getShare(item.FobDolMil,data.totalPriceDol)}}</td>
                                <td >{{item.Qtyintons.toFixed(2)}}</td>
                                <td>{{item.FobRsCr.toFixed(2)}}</td>
                                <td>{{item.FobDolMil.toFixed(2)}}</td>

                                <td >{{item.QtyPreTon.toFixed(2)}}</td>
                                <td>{{item.FobRsPreCr.toFixed(2)}}</td>
                                <td>{{item.FobDolPreMil.toFixed(2)}}</td>

                                <td>{{item.qtyVariation.toFixed(2)}}</td>
                                <td>{{item.rsVar.toFixed(2)}}</td>
                                <td>{{item.dolVar.toFixed(2)}}</td>

                                <td>{{item.qtyVariationPercent.toFixed(2)}}</td>                     
                                <td>{{item.rsVarPer.toFixed(2)}}</td>                     
                                <td>{{item.dolVarPer.toFixed(2)}}</td>                     



                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td >Total</td>
                                <td >&nbsp;</td>
                                <td >100</td>
                                <td >100</td>
                                <td >100</td>
                                <td >{{data.totalQuantity.toFixed(2)}}</td>
                                <td>{{data.totalPriceRs}}</td>
                                <td>{{data.totalPriceDol}}</td>

                                <td >{{data.totalQuantityPrev.toFixed(2)}}</td>
                                <td>{{data.totalPriceRsPrev}}</td>
                                <td>{{data.totalPriceDolPrev}}</td>

                                <td >{{data.totalQtyVar}}</td>
                                <td>{{data.totalPrRsVar}}</td>
                                <td>{{data.totalPrDolVar}}</td>

                                <td >{{data.totalQtyPer}}</td>  
                                <td>{{data.totalPrRsVarPer}}</td>     
                                <td>{{data.totalPrDolPer}}</td> 
                            </tr>
                            </ng-template>
                    </p-table>
                    </table>
                </div>



                                
                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                <section class="innerpage">
                    <div class="innershadow">
                        <div class="itm_gr_rprt">
                            <table #userTable> 
                                <p-table [value]="reportData" >
                                    <ng-template pTemplate="caption">
                                        <div class="p-d-flex">
                                            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th width="17%">Item</th>
                                            <th width="100">Share % </th>
                                            <th width="15%">&nbsp;</th>
                                            <th width="20%">{{data.dateStart}}</th>
                                            <th width="20%">{{data.endDate}}</th>
                                            <th width="10%">Variation</th>
                                            <th width="10%">(%)</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-groupitem>
                                        <tr>
                                            <td colspan="7" class="grpitm_hed"><strong>{{groupitem.name}}</strong></td>
                                        </tr>   
                                        <ng-template ngFor let-item [ngForOf]="groupitem.groupItems">
                                            <tr >
                                                <td width="17%">{{item.itemName}}</td>
                                                <td width="100">{{getShare(item.Qtyintons,data.totalQuantity)}}</td>
                                                <td width="15%"><b>Q</b> </td>
                                                <td width="20%">{{item.Qtyintons.toFixed(2)}}</td>
                                                <td width="20%">{{item.QtyPreTon.toFixed(2)}}</td>
                                                <td width="10%">{{item.qtyVariation.toFixed(2)}}</td>
                                                <td width="10%">{{item.qtyVariationPercent.toFixed(2)}}</td>                     
                                            </tr>
                                       
                                            <tr >
                                                <td>&nbsp;</td>
                                                <td>{{getShare(item.FobRsCr,data.totalPriceRs)}}</td>
                                                <td><b>V</b> </td>
                                                <td>{{item.FobRsCr.toFixed(2)}}</td>
                                                <td>{{item.FobRsPreCr.toFixed(2)}}</td>
                                                <td>{{item.rsVar.toFixed(2)}}</td>
                                                <td>{{item.rsVarPer.toFixed(2)}}</td>                     
                                            </tr>
                                       
                                            <tr >
                                                <td>&nbsp;</td>
                                                <td>{{getShare(item.FobDolMil,data.totalPriceDol)}}</td>
                                                <td><b>$</b> </td>
                                                <td>{{item.FobDolMil.toFixed(2)}}</td>
                                                <td>{{item.FobDolPreMil.toFixed(2)}}</td>
                                                <td>{{item.dolVar.toFixed(2)}}</td>
                                                <td>{{item.dolVarPer.toFixed(2)}}</td>                     
                                            </tr>
                                        </ng-template>
                                        <tr>
                                            <td width="17%"><strong>Region Total</strong></td>
                                            <td width="100">{{getShare(groupitem.groupTotalQuantity,data.totalQuantity)}}</td>
                                            <td width="15%">Q:</td>
                                            <td width="20%">{{groupitem.groupTotalQuantity}}</td>
                                            <td width="20%">{{groupitem.groupTotalQuantityPrevious}}</td>
                                            <td width="10%">{{groupitem.groupTotalQuantityVariation}}</td>
                                            <td width="10%">{{groupitem.groupTotalQuantityVariationPer}}</td>                     
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>{{getShare(groupitem.grpTotalPriceRs,data.totalPriceRs)}}</td>
                                            <td>V:</td>
                                            <td>{{groupitem.grpTotalPriceRs}}</td>
                                            <td>{{groupitem.grpTotalPriceRsPrev}}</td>
                                            <td>{{groupitem.grpTotalPrRsVar}}</td>
                                            <td>{{groupitem.grpTotalPrRsVarPer}}</td>                     
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>{{getShare(groupitem.grpTotalPriceDol,data.totalPriceDol)}}</td>
                                            <td>$</td>
                                            <td>{{groupitem.grpTotalPriceDol}}</td>
                                            <td>{{groupitem.grpTotalPriceDolPrev}}</td>
                                            <td>{{groupitem.grpTotalPriceDolVar}}</td>
                                            <td>{{groupitem.grpTotalPriceDolPer}}</td>                     
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="footer">
                                        <tr>
                                            <td width="17%"><b>Total</b></td>
                                            <td width="100">100</td>
                                            <td width="15%">Q:</td>
                                            <td width="20%">{{data.totalQuantity.toFixed(2)}}</td>
                                            <td width="20%">{{data.totalQuantityPrev.toFixed(2)}}</td>
                                            <td width="10%">{{data.totalQtyVar.toFixed(2)}}</td>
                                            <td width="10%">{{data.totalQtyPer.toFixed(2)}}</td>                     
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>100</td>
                                            <td>V:</td>
                                            <td>{{data.totalPriceRs}}</td>
                                            <td>{{data.totalPriceRsPrev}}</td>
                                            <td>{{data.totalPrRsVar}}</td>
                                            <td>{{data.totalPrRsVarPer}}</td>                     
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>100</td>
                                            <td>$</td>
                                            <td>{{data.totalPriceDol}}</td>
                                            <td>{{data.totalPriceDolPrev}}</td>
                                            <td>{{data.totalPrDolVar}}</td>
                                            <td>{{data.totalPrDolPer}}</td>                     
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </table>                              
                            </div>
                        </div>
                    </section>
                </section>
           
        </div>

        </div>
        <input type="hidden" formControlName="selectedPort" />
        <input type="hidden" formControlName="reportType" />
    </form>
</div>