import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ExporterServiceService } from '../exporter-service.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-exporter-invoices',
  templateUrl: './exporter-invoices.component.html',
  styleUrls: ['./exporter-invoices.component.css']
})
export class ExporterInvoicesComponent implements OnInit {

  loading: boolean = true;
  cols: any[];
  data: any;
  datasource: any;
  totalRecords: number;
  lastEvent: any;

  constructor(private _service: ExporterServiceService, private _router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'Shi. Bill #', header: 'SB_No', width: '12%', type: 'text' },
      { field: 'Shi. Bill Date', header: 'SB_Date', width: '12%', type: 'text' },
      { field: 'Invoice #', header: 'BILL_NO', width: '12%', type: 'text' },
      { field: 'Date', header: 'Date', width: '10%', type: 'text' },
      { field: 'Exporter', header: 'EXP_NAME', width: '15%', type: 'text' },
      { field: 'Consignee', header: 'BUYER_NAME', width: '15%', type: 'text' },
      { field: 'Port Of Loading', header: 'PORT_NAME', width: '15%', type: 'text' },
      { field: 'Qty ( kg)', header: 'Tot_Weight', width: '10%', type: 'text' },
      { field: 'Amount', header: 'Tot_Amount', width: '10%', type: 'text' },
      { field: 'Status', header: 'status', width: '10%', type: 'text' },
      { field: 'Entry Date', header: 'EntryDate', width: '10%', type: 'text' },
      { field: 'Status', header: 'statusCode', width: '10%', type: 'hidden' },
    ];
  }

  loadInvoices(event: LazyLoadEvent) {
    this.loading = true;
    this.lastEvent = event;

    //console.warn(event.filters);
    // return false;
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {

      this._service.getExporterInvoices(event.first, event.rows, event.sortField, event.sortOrder, event.filters).subscribe((items: any) => {
        console.warn(items);
        this.data = items.data.data;
        this.totalRecords = items.data.totalRecord;
        this.loading = false;
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./exporter/login']);
          }
        }
      });
    }, 1000);

  }
  confirmDelete(id) {
    console.warn("Delete function", id);
    if (confirm("Are you sure to delete invoice")) {
      this._service.deleteInvoice(id).subscribe((item: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: item.message, life: 6000 });
        this.loadInvoices(this.lastEvent);
      }, error => {

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this._router.navigate(['./']);
          }
        }
      });
    }

  }
}
