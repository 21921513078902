<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> Dynamic Report </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm">

        <div id="item_details" class="item_details my-4">
            <div class="card-body px-0">
                <div class="border px-3 py-3 mb-3">
                    <div class="row align-items-center col-w mb-2">
                        <div class="col label01">
                            <label class="f18"> Saved Searches </label>
                        </div>
                        <div class="col form-group">
                            <select class="form-control" formControlName="saveSearches"
                                (change)="getSearchDetail($event)">
                                <option value="">Select</option>
                                <option *ngFor="let Searches of saveSearches" value="{{Searches.id}}">
                                    {{Searches.search_name}}</option>
                            </select>
                        </div>

                    </div>
                    <div class="row align-items-center col-w mb-2">
                        <div class="col label01">
                            <label class="f18"> Date Range </label>
                        </div>
                        <div class="col form-group">
                            <span class="label_sub" style="color: #999;">Select option</span>
                            <select class="form-control" formControlName="dateRangeOption"
                                (change)="getDateRange($event)">
                                <option value="">Select</option>
                                <option value="1">Today</option>
                                <option value="2">Yesterday</option>
                                <option value="3">This Month</option>
                                <option value="4">This Year </option>
                                <option value="5">Custom Range </option>
                            </select>

                        </div>


                        <div class="col form-group">
                            <span class="label_sub" style="color: #999;">From </span>
                            <p-calendar formControlName="dateStart" dateFormat="dd-mm-yy" [yearNavigator]="true"
                                yearRange="{{calendarRange}}" [monthNavigator]="true" [readonlyInput]="true"
                                inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateStart.errors }">
                            </p-calendar>
                            <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                                <div *ngIf="f.dateStart.errors.required">Start Date is required</div>
                            </div>

                        </div>
                        <div class="col form-group">
                            <span class="label_sub" style="color: #999;">To </span>
                            <p-calendar formControlName="dateEnd" [monthNavigator]="true" dateFormat="dd-mm-yy"
                                [yearNavigator]="true" yearRange="{{calendarRange}}" [readonlyInput]="true"
                                inputId="monthpicker" [ngClass]="{ 'is-invalid': submitted && f.dateEnd.errors }">
                            </p-calendar>
                            <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                                <div *ngIf="f.dateEnd.errors.required">End Date is required</div>
                            </div>
                        </div>

                    </div>
                    <div class="row align-items-center col-w mb-2">
                        <div class="col label01">
                            <label class="f18"> Sort By </label>
                        </div>

                        <div class="col form-group" *ngIf="showTextBox">
                            <input type="number" class="form-control" formControlName="topRecordNumOther" />
                        </div>
                        <div class="col form-group">
                            <select name="sortColumn" formControlName="sortColumn" class="form-control">
                                <option value="Qtyintons">Quantity</option>
                                <option value="FobRsCr">Rupees</option>
                                <option value="FobDolMil">US$</option>
                                <option value="name">Name</option>
                                <option value="code">Code</option>
                            </select>
                        </div>
                        <div class="col form-group">
                            <select name="sortColumn" formControlName="sortType" class="form-control">
                                <option value="ASC">Assending</option>
                                <option value="DESC">Desending</option>
                            </select>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col form-group">
                        <table width='100%' class="table bordered searchFilters" formArrayName="searchFilters">
                            <thead class="thead-light">
                                <tr>
                                    <th width="10%">&nbsp;</th>
                                    <th width="20%">Field Name</th>
                                    <th width="20%">Operator</th>
                                    <th width="20%">Expression</th>
                                    <th width="15%">Connector</th>
                                    <th width="15%" align="right" class="text-right">
                                        <button type="button" class="btn btn-success" (click)="addRow()">Add
                                            New</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let quantity of searchFilters().controls; let k=index" [formGroupName]="k">
                                    <td>
                                        {{k+1}}
                                    </td>
                                    <td>
                                        <select formControlName="filterId" class="form-control"
                                            (change)="getFilterOption($event,k)"
                                            [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterId').errors }">
                                            <option value="">Select</option>
                                            <option *ngFor="let filter of reportFilters" value="{{filter.id}}">
                                                {{filter.name}}</option>
                                        </select>


                                    </td>
                                    <td>
                                        <select formControlName="filterType" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterType').errors }">
                                            <option value="">Select</option>
                                            <option value="=">Equal</option>
                                            <option value="!=">Not Equal</option>
                                            <option value=">">Greater Than</option>
                                            <option value=">=">Greater Than Equal</option>
                                            <option value="<">Less Than</option>
                                            <option value="<=">Less Than Equal</option>
                                        </select>
                                    </td>
                                    <td class="new_blck">
                                        <input type="text" class="form-control inline-block"
                                            formControlName="filterValues"
                                            [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterValues').errors }" />
                                        <span>
                                            <button type="button" pButton pRipple label="New" icon="pi pi-plus"
                                                class="p-button-success p-mr-2" (click)="showDialog(k)"></button>
                                        </span>
                                    </td>
                                    <td>
                                        <select formControlName="filterCondition" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && searchFilters().controls[k].get('filterCondition').errors }">
                                            <option value="">Select</option>
                                            <option value="AND">AND</option>
                                            <option value="OR">OR</option>
                                            <option value="0">None</option>
                                        </select>

                                    </td>
                                    <td align="right">
                                        <button type="button" (click)="removeSearchFilter(k)"
                                            class="btn btn-danger">Remove</button>

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </div>
                </div>
                <div class="row align-items-center col-w mb-2">
                    <div class="col label01">
                        <label class="f18"> Field To be displayed </label>
                    </div>
                    <div class="col form-group">
                        <select name="displayField" formControlName="displayField" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.displayField.errors }"
                            (change)="getGroupOption($event)">
                            <option value="">Select</option>
                            <option *ngFor="let displayField of displayField" value="{{displayField.id}}">
                                {{displayField.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.displayField.errors" class="invalid-feedback">
                            <div *ngIf="f.displayField.errors.required">Please Select</div>
                        </div>
                    </div>
                    <div class="col form-group" *ngIf="showGrpOption">
                        <input type="checkbox" formControlName="isgroupItem" value="1" /> Group {{grpType}}
                    </div>

                </div>
                <div class="row align-items-center col-w mb-2">
                    <div class="col label01">
                        <label class="f18"> Save this search as </label>
                    </div>
                    <div class="col form-group">
                        <input type="text" class="form-control inline-block" formControlName="searchName" />
                        <input type="hidden" class="form-control inline-block" formControlName="isSaveSearch" />
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col form-group">
                        <br>
                        <div class="btn_pan mb-4">
                            <button class="btn btn-success" (click)="getReport('getReport')"><i
                                    class="fa fa-download"></i>Get Report</button>
                            &nbsp;&nbsp;&nbsp;
                            <button class="btn btn-success" (click)="getReport('saveSearch')"><i
                                    class="fa fa-save"></i>Save & Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="displayDetailedReport">



                <table #detailTable>
                    <p-table [value]="reportData">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportDetailCSV()"
                                    class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                    (click)="exportDetailExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                    tooltipPosition="bottom"></button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngFor="let column of reportColumn | keyvalue: unsorted"
                                    pSortableColumn="{{column.key}}">{{column.value}}<p-sortIcon field="{{column.key}}"
                                        *ngIf="column.key!='SN'"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item let-ri="rowIndex">
                            <tr>
                                <td *ngFor="let column of reportColumn | keyvalue: unsorted; ">
                                    <span
                                        *ngIf="column.key!='SN' && column.key!='totalQty' && column.key!='FobRs' && column.key!='fobDol'">
                                        {{item[column.key]}}
                                    </span>
                                    <span *ngIf="column.key=='totalQty'">
                                        {{item[column.key].toFixed(2)}}
                                    </span>
                                    <span *ngIf="column.key=='FobRs'">
                                        {{item[column.key].toFixed(2)}}
                                    </span>
                                    <span *ngIf="column.key=='fobDol'">
                                        {{item[column.key].toFixed(2)}}
                                    </span>
                                    <span *ngIf="column.key=='SN'">
                                        {{ri+1}}
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>Grand Total</td>
                                <td>{{getTotal("totalQty")}}</td>
                                <td>{{getTotal("FobRs")}}</td>
                                <td>{{getTotal("fobDol")}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </table>
            </div>
        </div>
    </form>
</div>
<p-dialog [(visible)]="display" [style]="{width: '850px'}">
    <ng-template pTemplate="header">
        {{relatedDataHeader}}
    </ng-template>
    <p-table #dt [value]="filterData" [rows]="10" [columns]="tableColumns" [paginator]="true"
        [(selection)]="selectedFilters" [rowHover]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns" pSortableColumn="{{col}}">{{col}} <p-sortIcon field="{{col}}">
                    </p-sortIcon>
                </th>
            </tr>
            <tr>
                <th style="width: 3rem">&nbsp;</th>
                <th *ngFor="let col of columns">
                    <input pInputText type="text" (input)="dt.filter($event.target.value, col, 'contains')"
                        [value]="dt.filters[col]?.value" placeholder="Search by {{col}}" class="p-column-filter">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-filterRowData let-columns="columns">
            <tr>
                <td>
                    <p-tableCheckbox [value]="filterRowData"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns">{{filterRowData[col]}}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button pButton pRipple label="Confirm" icon="pi pi-check" (click)="selectFilters()"
            [disabled]="!selectedFilters || !selectedFilters.length" class="p-button-success p-mr-2"></button>

    </p-footer>
</p-dialog>