<p-toast></p-toast>
<div class="pageheadingouter">

    <div class="container container2">

        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">

                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{pageHeading}} </h1>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0" *ngIf="isPermissionAdd">

                <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2"
                    (click)="openNew()"></button>
            </div>


        </div>

    </div>
</div>
<section class="innerpage">


    <div class="innershadow">
        <div class="container pt-4">
            <!--
            <p-button icon="fa fa-refresh" iconPos="left" (click)="reset()"></p-button>
            -->
            <div class="table-responsive">
                <table #detailTable class="t100">
                    <p-table [value]="data" [style]="{'width':'100%'}" [columns]="cols" #dt [lazy]="true"
                        (onLazyLoad)="loadData($event)" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
                        [loading]="loading"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10,25,50]">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()"
                                    class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                                    class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                    <th pSortableColumn="{{col.header}}" [ngStyle]="{'width': col.width}">{{col.field}}
                                        <p-sortIcon field="{{col.header}}"></p-sortIcon>
                                    </th>
                                </ng-template>
                                <th style="width:5%"></th>
                            </tr>
                            <tr>
                                <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                    <th>

                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, col.header, 'startsWith')"
                                            placeholder="Search by {{col.field}}" class="p-column-filter"
                                            style="width:100%" />
                                    </th>
                                </ng-template>
                                <th style="width:5%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-columns="columns">
                            <tr style="height:34px">
                                <ng-template *ngFor="let col of columns" [ngIf]="col.type!='hidden'">
                                    <td>
                                        {{data[col.header]}}
                                    </td>
                                </ng-template>
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-success p-mr-2" (click)="editMasters(data)"
                                        *ngIf="isPermissionEdit"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <!--
                    <ng-template pTemplate="header">
                        <tr>
                            <td *ngFor="let col of columns">
                                {{col.field}}
                            </td>
                            <th pSortableColumn="EXP_CODE">Exporter Code <p-sortIcon field="EXP_CODE"></p-sortIcon></th>
                            <th pSortableColumn="EXP_NAME">Exporter Name <p-sortIcon field="EXP_NAME"></p-sortIcon></th>
                            <th pSortableColumn="ADDRESS">Address<p-sortIcon field="ADDRESS"></p-sortIcon></th>
                            <th pSortableColumn="PLACE">Place<p-sortIcon field="PLACE"></p-sortIcon></th>
                            <th pSortableColumn="Imp_Exp_No">Imp_Exp_No<p-sortIcon field="Imp_Exp_No"></p-sortIcon></th>

                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'EXP_CODE', 'startsWith')" placeholder="Search by Code" class="p-column-filter">
                            </th>
                            <th>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'EXP_NAME', 'startsWith')" placeholder="Search by Name" class="p-column-filter">
                            </th>
                            <th>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'ADDRESS', 'startsWith')" placeholder="Search by Address" class="p-column-filter">
                            </th>      
                            <th>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'PLACE', 'startsWith')" placeholder="Search by Place" class="p-column-filter">
                            </th>   
                            <th>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'Imp_Exp_No', 'startsWith')" placeholder="Search by Imp_Exp_No" class="p-column-filter">
                            </th>                                                                                     
                        </tr>
                    </ng-template>
                    
                    <ng-template pTemplate="body" let-data>
                        <tr>
                            <td>{{data.EXP_CODE}}</td>
                            <td>{{data.EXP_NAME}}</td>
                            <td>{{data.ADDRESS}}</td>
                            <td>{{data.PLACE}}</td>
                            <td>{{data.Imp_Exp_No}}</td>
                        </tr>
                    </ng-template>
                    -->
                        <ng-template pTemplate="summary">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                Total = {{totalRecords}} Records
                            </div>
                        </ng-template>
                    </p-table>
                </table>

            </div>
        </div>

    </div>




    <p-dialog [(visible)]="masterDialog" [style]="{width: '650px'}" header="{{formHeading}} Details" [modal]="true"
        styleClass="p-fluid">
        <ng-template pTemplate="content">
            <form #masterFrm="ngForm" [formGroup]="registerForm" (ngSubmit)="saveMaster(masterFrm)">
                <input type="hidden" name="action" formControlName="action" [(ngModel)]="masterFrmData.action" />
                <div class="p-field form-group" *ngFor="let col of cols">
                    <ng-template [ngIf]="col.type!='hidden' && col.type!='hideForm'">
                        <label for="{{col.header}}">{{col.field}} </label>
                    </ng-template>
                    <ng-template [ngIf]="col.type=='text'">
                        <input type="text" class="form-control" formControlName="{{col.header}}"
                            [ngClass]="{ 'is-invalid': submitted}" class="form-control"
                            [(ngModel)]="masterFrmData[col.header]" />
                    </ng-template>
                    <ng-template [ngIf]="col.type=='textarea'">
                        <textarea class="form-control" [ngClass]="{ 'is-invalid': submitted}"
                            formControlName="{{col.header}}" name="{{col.header}}"
                            [(ngModel)]="masterFrmData[col.header]"></textarea>
                    </ng-template>
                    <ng-template [ngIf]="col.type=='calender'">
                        <p-calendar [(ngModel)]="masterFrmData[col.header]" dateFormat="dd/mm/yy" name="{{col.header}}"
                            formControlName="{{col.header}}" [showTime]="false"></p-calendar>
                    </ng-template>
                    <ng-template [ngIf]="col.type=='hidden'">
                        <input type="hidden" class="form-control" formControlName="{{col.header}}" name="{{col.header}}"
                            [(ngModel)]="masterFrmData[col.header]" />
                    </ng-template>
                    <ng-template [ngIf]="col.type=='dropdown'">
                        <select class="form-control" id="{{col.header}}" formControlName="{{col.header}}"
                            name="{{col.dropdoenName}}" [ngClass]="{ 'is-invalid': submitted}"
                            [(ngModel)]="masterFrmData[col.dropdoenName]">
                            <option value="">Select {{col.header}}</option>
                            <option *ngFor="let item of col.detailValues | keyvalue" value="{{item.value.code}}">
                                {{item.value.name}}</option>
                        </select>
                    </ng-template>
                    <ng-template [ngIf]="col.type=='radio'" *ngFor="let item of col.detailValues | keyvalue">
                        <input type="radio" formControlName="{{col.header}}" class="form-control" name="{{col.header}}"
                            [(ngModel)]="masterFrmData[col.header]" value="{{item.value}}" /> {{item.value}}
                    </ng-template>

                    <ng-template [ngIf]="col.type=='checkbox'">
                        <input type="checkbox" formControlName="{{col.header}}" class="form-control"
                            name="{{col.header}}" [(ngModel)]="masterFrmData[col.header]" value="" />
                    </ng-template>

                </div>


                <div class="col-xl-12 col-md-12 xl-text-right py-xl-5 text-center pt-4 pb-3">
                    <button type="button" class="btn btn-light" (click)="closeForm()"> <i class="fa fa-times"></i>
                        Cancel</button>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
                </div>

            </form>
        </ng-template>
    </p-dialog>
</section>
<br>