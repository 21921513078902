<section class="innerpage">
    <div class="pageheadingouter">
        <div class="container container2">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/report-icons.png" alt=""> Reports </h1>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div class="container pt-4">
        <div class="reportouter report_list my-4">
            <div class="grid-sizer"></div>
            <div class="gutter-sizer"></div>
            <div class="report_inner ng-star-inserted">
                <h3> Summary Report </h3>
                <ul class="ng-star-inserted">
                    <li class="ng-star-inserted"><a [routerLink]="['/exporter/reports/summary/items']"> Item
                            Wise</a></li>

                    <li class="ng-star-inserted"><a [routerLink]="['/exporter/reports/summary/item-group']">
                            Item Group Wise </a></li>
                    <li class="ng-star-inserted"><a [routerLink]="['/exporter/reports/summary/country']"> Country
                            Wise </a></li>
                    <li class="ng-star-inserted"><a [routerLink]="['/exporter/reports/summary/region']"> Region Wise
                        </a></li>

                    <li class="ng-star-inserted"><a [routerLink]="['/exporter/reports/summary/port']"> Port Wise </a>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</section>