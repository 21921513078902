<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader"><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()">

        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>


                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">{{ getCustomMessage('dateStart.required')}}
                            </div>
                            <div *ngIf="f.dateStart.errors.pattern">{{ getCustomMessage('dateStart.pattern') }}</div>
                            <div *ngIf="f.dateStart.errors.min">{{ getCustomMessage('dateStart.min') }}</div>
                            <div *ngIf="f.dateStart.errors.max">{{ getCustomMessage('dateStart.max') }}</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">{{ getCustomMessage('dateEnd.required')}}</div>
                            <div *ngIf="f.dateEnd.errors.pattern">{{ getCustomMessage('dateEnd.pattern')}}</div>
                            <div *ngIf="f.dateEnd.errors.min">{{ getCustomMessage('dateEnd.min')}}</div>
                            <div *ngIf="f.dateEnd.errors.max">{{ getCustomMessage('dateEnd.max')}}
                            </div>
                        </div>
                    </div>









                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;"> Select Region </span>
                        <p-dropdown formControlName="selectedRegion" [options]="sourceUnion" optionLabel="name"
                            optionValue="code" placeholder="Select Region "> </p-dropdown>
                    </div>

                    <!-- <p-pickList [source]="sourceUnion"  [target]="targetUnion" sourceHeader="Available" targetHeader="Selected" dragdrop="true"   [responsive]="true" [sourceStyle]="{'height':'150px'}" [showSourceControls]="false" [showTargetControls]="false" [targetStyle]="{'height':'150px'}" filterBy="name" sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name" (onMoveToSource)="removeUnion($event)" (onMoveToTarget)="addUnion($event)" >
                        <ng-template let-product pTemplate="item">
                            <div class="product-item">
                                <div class="product-list-detail">
                                    <h6 class="listitem01">{{product.code}}-{{product.name}}</h6>                                   
                                </div>
                            </div>
                        </ng-template>
                    </p-pickList> -->


                </div>

                <div class="col form-group">
                    <span class="label_sub" style="color: #999;">Select Group </span>
                    <p-dropdown formControlName="selectedItem" [options]="sourceGroup" optionLabel="name"
                        optionValue="code" placeholder="Select Group"></p-dropdown>


                </div>




                <div class="row">
                    <div class="btn_pan mb-4">
                        <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                </div> -->



                <div *ngIf="page=='country-wise'">
                    <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                        <span><b>Q</b>: Quantity in M Tons,</span>
                        <span><b>V</b>: Value in Rs. Crores,</span>
                        <span><b>$</b>: USD Million</span>

                    </div>
                </div>

                <div *ngIf="page=='item-wise'">
                    <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                        <span><b>Q</b>: Quantity in M Tons,</span>
                    </div>
                </div>






                <div class="card" *ngIf="displayDetailedReport">
                    <table #detailTable>
                        <p-table [value]="reportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o"
                                        (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                                        (click)="exportDetailExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="1">&nbsp;</th>
                                    <th colspan="3">{{data.dateStart}}</th>
                                    <th colspan="3">Growth</th>
                                    <th colspan="3">Growth (%)</th>
                                </tr>
                                <tr>
                                    <th pSortableColumn="itemName">Year <p-sortIcon field="itemName"></p-sortIcon>
                                    </th>


                                    <th pSortableColumn="Qtyintons">Q <p-sortIcon field="Qtyintons"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobRsCr">V <p-sortIcon field="FobRsCr"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="FobDolMil">$ <p-sortIcon field="FobDolMil"></p-sortIcon>
                                    </th>


                                    <th pSortableColumn="qtyVariation">Q <p-sortIcon field="qtyVariation"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="rsVar">V <p-sortIcon field="rsVar"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dolVar">$ <p-sortIcon field="dolVar"></p-sortIcon>
                                    </th>


                                    <th pSortableColumn="qtyVariationPercent">Q <p-sortIcon field="qtyVariationPercent">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="rsVarPer">V <p-sortIcon field="rsVarPer"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dolVarPer">$ <p-sortIcon field="dolVarPer"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{item.itemName}}</td>

                                    <td>{{getNumber(item.Qtyintons,2)}}</td>
                                    <td>{{getNumber(item.FobRsCr,2)}}</td>
                                    <td>{{getNumber(item.FobDolMil,2)}}</td>

                                    <td>{{getNumber(item.qtyVariation,2)}}</td>
                                    <td>{{getNumber(item.rsVar,2)}}</td>
                                    <td>{{getNumber(item.dolVar,2)}}</td>

                                    <td>{{getNumber(item.qtyVariationPercent,2)}}</td>
                                    <td>{{getNumber(item.rsVarPer,2)}}</td>
                                    <td>{{getNumber(item.dolVarPer,2)}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </table>
                </div>



                <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                    <section class="innerpage">
                        <div class="innershadow">
                            <div class="itm_gr_rprt">
                                <table #userTable *ngIf="page=='country-wise'">
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th></th>
                                                <th>&nbsp;</th>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <th>{{itemVal.Range}}</th>

                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                            <tr>

                                                <td>{{item.name}}</td>
                                                <td><b>Q</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td>{{getNumber(itemVal.Qtyintons,1)}}</td>
                                                </ng-template>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><b>V</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td> {{getQuantity(itemVal.FobRsCr)}}</td>
                                                </ng-template>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><b>$</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td>{{getNumber(itemVal.FobDolMil,2)}}</td>

                                                </ng-template>
                                            </tr>

                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td>Q:</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <td>{{getNumber(itemVal.totalQuantity,1)}}</td>

                                                </ng-template>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>V:</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <td> {{getQuantity(itemVal.totalPriceRs)}} </td>

                                                </ng-template>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>$</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <td>{{getNumber(itemVal.totalPriceDol)}}</td>

                                                </ng-template>
                                            </tr>

                                        </ng-template>
                                    </p-table>
                                </table>

                                <table #userTable *ngIf="page=='item-wise'">
                                    <p-table [value]="reportData">
                                        <ng-template pTemplate="caption">
                                            <div class="p-d-flex">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    (click)="exportCSV()" class="p-mr-2" pTooltip="CSV"
                                                    tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    (click)="exportExcel()" class="p-button-success p-mr-2"
                                                    pTooltip="XLS" tooltipPosition="bottom"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th></th>
                                                <th>&nbsp;</th>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <th>{{itemVal.Range}}</th>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                            <tr>

                                                <td>{{item.name}}</td>
                                                <td><b>Q</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td>{{getNumber(itemVal.Qtyintons,1)}}</td>
                                                </ng-template>
                                            </tr>

                                            <tr>

                                                <td><b>Sub Total</b></td>
                                                <td><b>Q</b> </td>
                                                <ng-template ngFor let-itemVal [ngForOf]="item.items" let-i="index">
                                                    <td>{{getNumber(itemVal.Qtyintons,1)}}</td>
                                                </ng-template>
                                            </tr>

                                        </ng-template>

                                        <ng-template pTemplate="footer">
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td>Q:</td>
                                                <ng-template ngFor let-itemVal [ngForOf]="reportDateRange">
                                                    <td>{{getNumber(itemVal.totalQuantity,1)}}</td>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </table>


                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </div>

        <input type="hidden" formControlName="reportType" />
        <!-- <input type="text"  value="{{page}}"> -->


    </form>
</div>