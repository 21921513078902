<p-toast></p-toast>
<div class="pageheadingouter">
    <div class="container container2">
        <div class="row">
            <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                <div class="pageheading ">
                    <h1> <img src="assets/img/file_icons.png" alt=""> {{reportTitle}} </h1>
                </div>
            </div>       
        </div>
    </div>
</div>

<div id="loader" *ngIf="isloader" ><img src="assets/img/loader.svg" alt=""></div>
<div class="container container2">
    <form [formGroup]="reportFrm" name="reportFrm" (ngSubmit)="getReport()"  >
       
        <div id="item_details" class="item_details">
            <div class="card-body px-0">
                <div class="row align-items-center date_range">
                    <div class="col label01">
                        <label class="f18"> Date Range </label>
                    </div>

                    
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"
                            [ngClass]="{'is-invalid': submitted && f.dateStart.errors }" class="form-control"
                            maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">{{ getCustomMessage('dateStart.required')}}
                            </div>
                            <div *ngIf="f.dateStart.errors.pattern">{{ getCustomMessage('dateStart.pattern') }}</div>
                            <div *ngIf="f.dateStart.errors.min">{{ getCustomMessage('dateStart.min') }}</div>
                            <div *ngIf="f.dateStart.errors.max">{{ getCustomMessage('dateStart.max') }}</div>
                        </div>

                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"
                            [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control"
                            maxlength="4" />

                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">{{ getCustomMessage('dateEnd.required')}}</div>
                            <div *ngIf="f.dateEnd.errors.pattern">{{ getCustomMessage('dateEnd.pattern')}}</div>
                            <div *ngIf="f.dateEnd.errors.min">{{ getCustomMessage('dateEnd.min')}}</div>
                            <div *ngIf="f.dateEnd.errors.max">{{ getCustomMessage('dateEnd.max')}}
                            </div>
                        </div>
                    </div>

                    <!--
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">From </span>
                        <input type="text" formControlName="dateStart"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4" />
                        <div *ngIf="submitted && f.dateStart.errors" class="invalid-feedback">
                            <div *ngIf="f.dateStart.errors.required">Start Year is required</div>
                        </div>
                        
                       
                    </div>
                    <div class="col form-group">
                        <span class="label_sub" style="color: #999;">To </span>
                        <input type="text" formControlName="dateEnd"  [ngClass]="{'is-invalid': submitted && f.dateEnd.errors }" class="form-control" maxlength="4"  />
                        
                        <div *ngIf="submitted && f.dateEnd.errors" class="invalid-feedback">
                            <div *ngIf="f.dateEnd.errors.required">End Year is required</div>
                        </div>
                    </div>
                    -->

                </div>                               

                
                <div class="row">
                    <div class="btn_pan mb-4">
                            <button class="btn btn-success"><i class="fa fa-save"></i>Get Report</button>
                    </div>
                </div>    
                <div class="row" *ngIf="displayReport || displayDetailedReport">
                    <label class="f18"> View Detail Report</label>&nbsp;&nbsp;&nbsp;                            
                    <p-checkbox formControlName="viewDetailReport" binary="true" inputId="binary"></p-checkbox>
                    
                </div>
                <div class="txhed_rp" *ngIf="displayReport || displayDetailedReport">
                    <span><b>Q</b>: Quantity in Tonnes,</span>
                    <span><b>V</b>: Value in Rs '000,</span>
                    <span><b>$</b>: USD Million</span>
                    
                </div>
                <div class="card" *ngIf="displayDetailedReport">
                    


                    <table #detailTable>                   
                        <p-table [value]="reportData">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex">
                                    <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportDetailCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportDetailExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="1">&nbsp;</th>
                                    <th colspan="3">{{data.dateStart}}</th>
                                    <th colspan="3">Growth</th>
                                    <th colspan="3">Growth  (%)</th>
                                </tr>
                                <tr>
                                    <th pSortableColumn="itemName">Year <p-sortIcon field="itemName"></p-sortIcon></th>
                                    

                                    <th pSortableColumn="Qtyintons">Q <p-sortIcon field="Qtyintons"></p-sortIcon></th>
                                    <th pSortableColumn="FobRsCr">V <p-sortIcon field="FobRsCr"></p-sortIcon></th>
                                    <th pSortableColumn="FobDolMil">$ <p-sortIcon field="FobDolMil"></p-sortIcon></th>
                                    

                                    <th pSortableColumn="qtyVariation">Q <p-sortIcon field="qtyVariation"></p-sortIcon></th>
                                    <th pSortableColumn="rsVar">V <p-sortIcon field="rsVar"></p-sortIcon></th>
                                    <th pSortableColumn="dolVar">$ <p-sortIcon field="dolVar"></p-sortIcon></th>

                                    
                                    <th pSortableColumn="qtyVariationPercent">Q <p-sortIcon field="qtyVariationPercent"></p-sortIcon></th>
                                    <th pSortableColumn="rsVarPer">V <p-sortIcon field="rsVarPer"></p-sortIcon></th>
                                    <th pSortableColumn="dolVarPer">$ <p-sortIcon field="dolVarPer"></p-sortIcon></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{item.itemName}}</td>

                                    <td> {{getNumber(item.Qtyintons, 1)}} </td>
                                    <td> {{getNumber(item.FobRsCr, 1)}}</td>
                                    <td> {{getNumber(item.FobDolMil, 2)}}</td>

                                    <td> {{getNumber(item.qtyVariation, 1)}} </td>
                                    <td> {{getNumber(item.rsVar, 1)}} </td>
                                    <td> {{getNumber(item.dolVar, 2)}} </td>

                                    <td>{{getNumber(item.qtyVariationPercent, 2)}}</td>
                                    <td>{{getNumber(item.rsVarPer, 2)}} </td>
                                    <td>{{getNumber(item.dolVarPer, 2)}} </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </table>
                </div>
               </div>
               <!-- Report Block -->
                <section class="wrapper" *ngIf="displayReport">
                <section class="innerpage">
                    <div class="innershadow">
                        <div class="itm_gr_rprt">
                            <table #userTable> 
                                <p-table [value]="reportData" >
                                    <ng-template pTemplate="caption">
                                        <div class="p-d-flex">
                                            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th width="17%">Year</th>
                                            <th width="15%">&nbsp;</th>
                                            <th width="20%">Export</th>
                                            <th width="10%">Growth</th>
                                            <th width="10%">Growth(%)</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr >
                                            <td width="17%">{{item.itemName}}</td>
                                            <td width="15%"><b>Q</b> </td>
                                            <td width="20%"> {{getNumber(item.Qtyintons, 1)}} </td>
                                            <td width="10%"> {{getNumber(item.qtyVariation, 1)}} </td>
                                            <td width="10%"> {{getNumber(item.qtyVariationPercent, 2)}} </td>                     
                                        </tr>
                                        <tr >
                                            <td>&nbsp;</td>
                                            <td><b>V</b> </td>
                                            <td> {{getNumber(item.FobRsCr, 1)}} </td>
                                            <td>{{getNumber(item.rsVar, 1)}} </td>
                                            <td>{{getNumber(item.rsVarPer, 2)}} </td>                     
                                        </tr>
                                        <tr >
                                            <td>&nbsp;</td>
                                            <td><b>$</b> </td>
                                            <td> {{getNumber(item.FobDolMil, 2)}} </td>
                                            <td> {{getNumber(item.dolVar, 2)}} </td>
                                            <td> {{getNumber(item.dolVarPer, 2)}} </td>                     
                                        </tr>
                                        
                                    </ng-template>
                                    <ng-template pTemplate="footer">
                                        
                                    </ng-template>
                                </p-table>
                            </table>                              
                            </div>
                        </div>
                    </section>
                </section>

            </div>
        </form>
    </div>