import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { ExportReviewService } from '../export-review.service';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-country-wise-market-trend',
  templateUrl: './country-wise-market-trend.component.html',
  styleUrls: ['./country-wise-market-trend.component.css']
})
export class CountryWiseMarketTrendComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  targetGroup: any;
  sourceGroup: any;
  sourceUnion :any;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  dataNotFound : boolean = false;
  data: any;
  page: string;
  reportData: any = [];
  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  showTextBox: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  dateRange: number;
  fragment = '';
  primary: UrlSegmentGroup;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService,private commonService: CommonServiceService) { }


  ngOnInit(): void {

    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;



    this.page = reportType;

    console.log("page name", this.page);


    if (reportType == 'country') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        selectedPort: [''],
        viewDetailReport: [''],
        reportType: [reportType],
        topRecordNum: ['0'],
        topRecordNumOther: ['1'],
        includeBlankData: ['0'],
        sortColumn: ['FobDolMil'],
        sortType: ['DESC'],

      });
      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Country-Wise Export of Marine Products through Ports of India";
      this._service.getPorts().subscribe((ports: any) => {
        this.sourceGroup = ports.data;
      });

    }

    if (reportType == 'region-wise') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        selectedItem: ['', Validators.required],
        selectedRegion: ['', Validators.required],
        viewDetailReport: [''],
        reportType: [reportType],
        topRecordNum: ['0'],
        topRecordNumOther: ['1'],
        includeBlankData: ['0'],
        sortColumn: ['FobDolMil'],
        sortType: ['DESC'],

      });
      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Region Wise";


      this._service.getGroups().subscribe((groups: any) => {
        this.sourceGroup = groups.data;
      });

      this._service.getUnion().subscribe((ports: any) => {
        this.sourceUnion = ports.data;
      });


    }

    if (reportType == 'item-wise-export') {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', Validators.required],
        dateEnd: ['', Validators.required],
        selectedPort: [''],
        viewDetailReport: [''],
        reportType: [reportType],
      });
      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Item Wise Export Of Marine Product Through Indian Port";


      this._service.getPorts().subscribe((ports: any) => {
        this.sourceGroup = ports.data;
      });


    }



    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }

  addPort(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  removePort(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(itemQty, totalQty) {
    return this.commonService.getShare(itemQty, totalQty);
  }

  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }


  getReport() {


    this.isloader = true;
    this.submitted = true;

    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = new Date(this.reportFrm.value.dateStart).getFullYear();
    var dt2 = new Date(this.reportFrm.value.dateEnd).getFullYear();
    var yearDifferent = dt2 - dt1;
    this.dateRange = yearDifferent;

    if (yearDifferent > 2) {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }



    if ((this.page == 'country') || (this.page == 'region-wise')) {
      if (this.reportFrm.value.selectedPort == "") {
        this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Port", life: 6000 }); //success,info,warn,error
        this.isloader = false;
        return false;
      }
    }



    this._service.getCountryWiseMarineProduct(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;

      console.log(' get country data in console ', items.data);
      if (items.data.reportData.length > 0) {
        for (let i = 0; i < items.data.reportData.length; i++) {
          for (let j = 0; j < items.data.reportData[i].groupItems.length; j++) {
            items.data.reportData[i].groupItems[j].groupName = items.data.reportData[i].name;
            this.detailReportData.push(items.data.reportData[i].groupItems[j]);
          }
        }
        this.displayReport = true;
        this.displayDetailedReport = false;
        this.isloader = false;
        this.dataNotFound = false;
      }
      else {
        this.displayReport = false;
        this.displayDetailedReport = false;
        this.isloader = false;
        this.dataNotFound = true;
      }

      this.reportData = items.data.reportData;

      console.log("----get report data 2 ----", this.reportData);
    }, error => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });

  }


  // getShare(itemQty,totalQty){
  //   let percentage = itemQty*100/totalQty;
  //   return percentage.toFixed(2);
  // }


  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }

}
