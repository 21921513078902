<p-toast></p-toast>

<div class="loginbg">
    <div class="loginwrap">
        <div class="row">
            <div class="col-xl-6 col-sm-6 d-flex align-items-center justify-content-center pt-3 pt-xl-0">
                <img class="img-fluid" src="assets/img/logo.png" alt="Marine Products Exports Development Authority">
            </div>
            <div class="col-xl-6 col-sm-6 pt-xl-4 pt-4 text-center text-xl-left">
                <form [formGroup]="loginFrom" name="loginFrom" (ngSubmit)="submitLogin()">

                    <div class="masterlogin">
                        <h1> Let’s get started. </h1>
                        <p> This will be an amazing experience </p>

                        <div class="form_inner">

                            <h2> Member Login </h2>

                            <div class="form-group">
                                <input type="text" placeholder="Email" formControlName="email" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" value="" />
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                    placeholder="Password" formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                <!-- <i class="fa fa-unlock-alt"></i>-->
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                                <i class="fa" [ngClass]="{
                                    'fa-eye-slash': !fieldTextType,
                                    'fa-eye': fieldTextType
                                  }" (click)="toggleFieldTextType()" style="float: right;display: block;"></i>
                            </div>
                            <div class="form-group"> 
                                
                                <ngx-recaptcha2 #captchaElem
                                    [siteKey]="siteKey"
                                    [useGlobalDomain]="false"
                                    formControlName="recaptcha"
                                    [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }">
                                </ngx-recaptcha2>

                                <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
                                    <div *ngIf="f.recaptcha.errors.required">Captcha is required</div>
                                </div>

                                
                                
                                <!-- <input type="checkbox"> <span> Remember Password?</span> -->
                            </div>

                            <!--
                            <div class="form-group text-left pt-2 pt-xl-0"> 
                                <input type="checkbox"> <span> Remember Password?</span>
                            </div>
-->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary w-100 mb-3">Login </button>
                                <!-- <button type="button" class="btn btn-link w-100">Reset Password? </button> -->
                            </div>
                            <div class="form-group">
                                <a [routerLink]="['/exporter/login']" class="btn btn-bor w-100 mb-3"> Exporter Login
                                </a>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    </div>
</div>