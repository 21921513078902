<p-toast></p-toast>
<form [formGroup]="rolesPermissionFrm" name="rolesPermissionFrm" (ngSubmit)="saveRoles()"  >
    <div class="pageheadingouter">
        <div class="container container2">
            <div class="row">
                <div class="col-xl-9 col-md-6 text-md-left text-sm-left text-center">
                    <div class="pageheading ">
                        <h1> <img src="assets/img/file_icons.png" alt=""> {{pageHeading}} </h1>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 text-md-right text-sm-right text-center pt-3 pt-md-0">
                    <a  [routerLink]="['/setup/roles']"><button type="button" class="btn btn-light"> <i class="fa fa-times"></i> Cancel</button></a>
                    <button class="btn btn-success"><i class="fa fa-save"></i>Save</button>

                </div>


            </div>

        </div>
    </div>
    <div class="container pt-4">
        <div class="card">
            <div class="card-header" id="headingOne">
                <a > Roles And Permission </a>
            </div>
            <div id="item_details" >
                <div class="card-body px-0">

                    <div class="row">
                        <div class="col-xl-12 col-md-12 form-group">
                            <label> Name </label>
                            <input type="text" pInputText formControlName="name" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }" value="" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name  is required</div>
                                <div *ngIf="f.name.errors.maxlength">Charecter Length Error</div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 form-group">
                            <label> Description </label>
                            <textarea  pInputText formControlName="description" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                <div *ngIf="f.description.errors.required">Description  is required</div>
                                <div *ngIf="f.name.errors.maxlength">Charecter Length Error</div>
                            </div>
                        </div>                     
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-md-12 form-group">
                            <h4 class="text-center my-3"> Menus</h4>
                            <div class="menu_blk">
                                <ng-template [ngIf]="menus" *ngFor="let menu of menus">
                                    <div class="lev01" *ngIf="menu.parent_id==0">
                                        <div class="lev02">
                                            <input type="checkbox" name="menu[]" [value]="menu.id" (change)="onCheckChange($event)" [checked]="menu.isChecked" />
                                            &nbsp;<strong class="htx2">{{menu.name}}</strong>
                                        </div>
                                        <!--Code for action buttons -->
                                        <ng-template [ngIf]="menu.actions">           
                                            <div class="lev03" *ngFor="let action of menu.actions">
                                                <input type="checkbox" name="action[]" [value]="action.id" (change)="onCheckActionChange($event)" id="{{menu.id}}" [checked]="action.isChecked"  /> 
                                                {{action.action_name}}
                                            </div>
                                        </ng-template>
                                        <ng-template [ngIf]="menu.submenus" *ngFor="let submenus of menu.submenus">
                                        <div class="sub_grp">
                                            <div class="lev03">
                                                <input type="checkbox" name="menu[]" [value]="submenus.id" (change)="onCheckChange($event)" [checked]="submenus.isChecked" /> 
                                                {{submenus.name}}
                                            </div>
                                            <!--Code for action buttons -->
                                            <ng-template [ngIf]="submenus.actions" >
                                                <div class="lev05 addedit" *ngFor="let action of submenus.actions">
                                                    <input type="checkbox" name="action[]" [value]="action.id" (change)="onCheckActionChange($event)" id="{{submenus.id}}" [checked]="action.isChecked"  /> 
                                                    {{action.action_name}}
                                                </div>
                                            </ng-template>
                                            <ng-template [ngIf]="subsubmenus" *ngFor="let subsubmenus of submenus.submenus">
                                                <div class="lev04">
                                                    <input type="checkbox" name="menu[]" [value]="subsubmenus.id" (change)="onCheckChange($event)" [checked]="subsubmenus.isChecked" /> 
                                                    {{subsubmenus.name}}
                                                </div>
                                                <!--Code for action buttons -->
                                                <ng-template [ngIf]="subsubmenus.actions" > 
                                                    <div class="cc" *ngFor="let action of subsubmenus.actions">
                                                        <input type="checkbox" name="action[]" [value]="action.id" (change)="onCheckActionChange($event)" id="{{subsubmenus.id}}" /> 
                                                        {{action.action_name}}
                                                    </div>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        </ng-template>
                                    </div>
                                </ng-template> 
                            </div>
                        </div>           
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-md-12 form-group">
                            <label> Status</label>
                            <select class="form-control" id="status" formControlName="status" name="status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }" >
                                <option value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">inActive</option>
                            </select> 
                        </div>
                                         
                    </div>
                </div>
            </div>
        </div>

    </div>
    <input type="hidden" pInputText formControlName="roleId" class="form-control" value="">
    <input type="hidden" pInputText formControlName="selectedMenus" class="form-control" value="">
    <input type="hidden" pInputText formControlName="selectedActions" class="form-control" value="">
   
</form>