import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router,UrlTree,UrlSegment,PRIMARY_OUTLET,UrlSegmentGroup } from '@angular/router';
import { StatisticsServiceService } from '../statistics-service.service';
import { CommonServiceService } from '../../../common-service.service';


@Component({
  selector: 'app-annual-growth-rate',
  templateUrl: './annual-growth-rate.component.html',
  styleUrls: ['./annual-growth-rate.component.css']
})
export class AnnualGrowthRateComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle:string;
  isloader:boolean=false;
  submitted:boolean=false;
  displayReport:boolean=false;
  displayDetailedReport:boolean=false;
  data:any;
  reportData:any=[];
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;

  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,private _service:StatisticsServiceService,private _router:Router, private commonService: CommonServiceService) { }

  ngOnInit(): void {

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;

    this.reportTitle = "Annual Growth Rate of Indian Marine Products Export";
    this.reportFrm = this.formBuilder.group({
      dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
      viewDetailReport:[''],      
    }); 
    this.reportFrm.valueChanges.subscribe(val=>{
      if(this.reportData.length>0){
        if(this.reportFrm.value.viewDetailReport){      
            this.displayReport=false;
            this.displayDetailedReport=true;
        }
        else{
          this.displayReport=true;
          this.displayDetailedReport=false;
        }
      }
        
    });

  }
  get f() { return this.reportFrm.controls; }

  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(itemQty, totalQty) {
    return this.commonService.getShare(itemQty, totalQty);
  }

  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }

  getReport(){
    this.isloader = true;
    //this.messageService.add({severity:'success', summary: 'Success', detail: "Test Message ", life: 6000}); //
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    // Make validation by common service
     this.commonService.validateCalendar(dt1, dt2);

    this._service.getAnnualGrowth(this.reportFrm.value).subscribe((items: any) =>{
      this.data = items.data;
      if(items.data.reportData.length>0)
        {
          this.displayReport=true;
          this.displayDetailedReport=false;
          this.isloader = false;
        }
        else{
          this.displayReport=false;
          this.displayDetailedReport=false;
          this.isloader = false;
        }      
        this.reportData = items.data.reportData;
    },error=>{
         
      if(error instanceof HttpErrorResponse)
      {
        if(error.status===401){
          this._router.navigate(['./']);
        }
      }
    });
  }

  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report_detail.xlsx");
 
    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report_detail.csv");
 
    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.csv");
      
    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
    // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Growth Data');
    // save to file
     xlsx.writeFile(workbook, "growth_report.xlsx");
    });
  }
}
