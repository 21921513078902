import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { apiUrl } from 'src/environments/environment';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  routerUrl: string;

  constructor(private http: HttpClient, private router: Router) { }
  logedIn: number;
  isLogin: number;
  login(data): Observable<any> {
    return this.http.post(`${apiUrl}users/login`, data);
  }

  loginExpoter(data): Observable<any> {
    return this.http.post(`${apiUrl}exporter/login`, data);
  }

  isLoggedIn() {
    this.routerUrl = this.router.url;
    //console.warn("Router URL in auth service = ",this.routerUrl)
    return !!localStorage.getItem('isLogin');
  }
  logOut(data): Observable<any> {

    localStorage.removeItem("token");
    this.isLogin = 0;
    localStorage.removeItem("isLogin");
    localStorage.removeItem("userLevel");
    this.router.navigate([""]);

    return this.http.post(`${apiUrl}users/logout`, data);
  }
  logOut_25072021() {
    localStorage.removeItem("token");
    this.isLogin = 0;
    localStorage.removeItem("isLogin");
    localStorage.removeItem("userLevel");

    this.router.navigate([""]);

    return false;

  }
  exporterlogOut() {
    localStorage.removeItem("token");
    this.isLogin = 0;
    localStorage.removeItem("isLogin");
    localStorage.removeItem("userLevel");

    this.router.navigate(["/exporter/login"]);

    return false;
  }
  exporterlogOut_250752021() {
    localStorage.removeItem("token");
    this.isLogin = 0;
    localStorage.removeItem("isLogin");
    localStorage.removeItem("userLevel");

    this.router.navigate(["/exporter/login"]);

    return false;
  }
  getdataBeforeLogin() {
    return this.http.get(`${apiUrl}common/getDataBeforeLogin`);
  }
}
