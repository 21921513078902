// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true
};

//export const apiUrl = "http://103.127.42.118:5000/api/";
//export const apiUrl = "https://mpedastat.broadwayinfotech.net.au:5000/api/";
//export const apiUrl = "https://mpedastatver2.broadwayinfotech.net.au:2000/api/";
export const apiUrl = "https://estat.mpeda.gov.in:5000/api/";
//export const apiUrl = "http://localhost:5000/api/";
//export const publicUrl = "http://localhost:5000/";
//export const publicUrl = "https://mpedastatver2.broadwayinfotech.net.au:2000/";
export const publicUrl = "https://estat.mpeda.gov.in:5000/";
export const encryptSecretKey = "MPEDAWEB";
export const poundToKg = 0.454;
let currentYear = new Date().getFullYear() + 1;
export const calendarRange = "1996:" + currentYear;
export const synccalendarRange = "1970:" + currentYear;
export const fileAllowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "JFIF", "BMP", "SVG", "pdf", "xls", "XLS", "csv", "CSV", "xlsx", "XLSX"];
export const fileAllowedSize = 20; //20MB
export const invoiceFrezeDate = "2021-03-31"


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
