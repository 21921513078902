<section class="wrapper">
  <app-top-navigation></app-top-navigation>


  <section class="innerpage">
    <router-outlet></router-outlet>
  </section>

  <footer class="px-5 py-3 d-flex justify-content-between">
    <p class="text-white"> Copyright © {{year}} MPEDA. All Rights Reserved. </p>
    <p class="text-white"> Powered By <a class="text-white" href="https://www.broadwayinfotech.com/"
        target="_blank">Broadway Infotech</a> </p>
  </footer>


</section>