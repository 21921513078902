import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CommonServiceService } from '../../../common-service.service';
import { PortGraphComponent } from '../port-graph/port-graph.component'
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetupServiceService } from '../../setup/setup-service.service';
import { MessageService } from 'primeng/api';
import { encryptSecretKey } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";
import { AuthService } from '../../user-login/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild(PortGraphComponent) child: PortGraphComponent;

  myType: string;
  yearlyType: string;
  dataPort: any;
  portData: any = [];
  columnNames: any = [];
  titlePort: string = "";
  optionsPort: any = [];
  itemData: any = [];
  dataItem: any;
  titleItem: string = "";
  optionsItem: any = [];
  isloader: boolean = false;
  isShowMap: boolean = false;
  countryData: any = [];
  dataCountry: any;
  titleCountry: string = "";
  optionsCountry: any = [];

  yearlyData: any = [];
  dataYearly: any;
  titleYearly: string = "";
  optionsYearly: any = [];
  yearlycolumnNames: any;

  mapData: any = [];
  mapType: string;
  dataMap: any;
  titleMap: string = "";
  optionsMap: any = [];
  mapcolumnNames: any;
  finnancialYear: any = [];
  selectedX: string;
  financialYear: string;
  userLoggedInCounter: number;
  pageName: string;
  cols: any[];
  pageHeading: any;
  sortField: any;
  sortOrder: any;
  registerForm: FormGroup;
  masterDialog: boolean;
  submitted: boolean;
  masterFrmData: any;
  masterData: any;
  id: any;

  exporters: any = [];
  @Output() testVar = new EventEmitter<any>();
  constructor(private _service: CommonServiceService, private formBuilder: FormBuilder, private _setupService: SetupServiceService, private messageService: MessageService, private authService: AuthService) { }

  ngOnInit(): void {
/*
    this.isloader = true;
    this._service.getData().subscribe((portData: any) => {
      this.isloader = false
    })
*/
    var token = JSON.parse(localStorage.getItem('token'));
    var decoded = jwt_decode(token);
    if (decoded['result'].loginCounter) {
      this.userLoggedInCounter = decoded['result'].loginCounter;
    } else {
      this.userLoggedInCounter = 2;
    }




    this.pageName = "dashboard";
    this.isloader = false;
    this.isShowMap = true;

    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      let diffYear = currentYear - i;
      let lastYear = diffYear - 1;

      let item = {
        "year": lastYear + "-" + diffYear
      }
      if (i == 0) {
        this.financialYear = lastYear + "-" + diffYear;
      }
      this.finnancialYear.push(item);
    }



    if (this.userLoggedInCounter == 1) {
      if (this.pageName == "dashboard") {


        var token = JSON.parse(localStorage.getItem('token'));
        var decoded = jwt_decode(token);
        this.id = decoded['result'].id;




        this.cols = [
          { field: 'Id', header: 'ID', type: 'hidden' },
          { field: 'Old Password ', header: 'oldPassword', type: 'text' },
          { field: 'New Password', header: 'newPassword', type: 'text' },
          { field: 'Confirm Password', header: 'confirmPassword', type: 'text', width: '15%' },
        ];
        this.registerForm = this.formBuilder.group({
          oldPassword: ['', Validators.required],
          newPassword: ['', [
            Validators.required,
            Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
          ]],
          confirmPassword: ['', Validators.required],
          ID: [''],
        }, { validator: this.passwordMatchValidator });

        this.pageHeading = "Change Password";
        this.sortField = "id";

        this.masterDialog = true;
        this.masterFrmData = {};




      }
    }

  }


  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['newPassword'].value === frm.controls['confirmPassword'].value ? null : { 'mismatch': true };
  }

  get f() { return this.registerForm.controls; }


  saveMaster(masterFrm: NgForm) {


    this.submitted = true;
    if (masterFrm.invalid) {
      return false;
    } else {




      this.masterData = masterFrm.value;

      let time = Math.floor(Date.now() / 1000);
      let encrptionKey = encryptSecretKey + "_" + time;

      let oldPassword = CryptoJS.AES.encrypt(this.masterData.oldPassword, encrptionKey).toString();
      let newPassword = CryptoJS.AES.encrypt(this.masterData.newPassword, encrptionKey).toString();

      let data = {
        "oldPassword": oldPassword,
        "password": newPassword,
        "confirmPassword": newPassword,
        "ID": this.id,
        "time": time,
      }



      this._setupService.updatePassword(data).subscribe((items: any) => {
        this.submitted = false;
        console.warn(items);
        if (items.data.success == false) {
          alert(items.data.message);
          this.messageService.add({ severity: 'error', summary: 'Fail', detail: 'You have already set the password please choose other one', life: 6000 });
          return false;

        }
        else {
          if (items.data.success) {
            alert("Password updated successfully");
            this.logOut();
            this.messageService.add({ severity: 'success', summary: 'Success', detail: items.data.message, life: 6000 });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Fail', detail: items.data.message, life: 6000 });
          }

        }
        return false;
      });


    }
  }

  logOut() {
    //  this.isloaded = false;
    //  this.authService.logOut();
    var token = JSON.parse(localStorage.getItem('token'));
    var decoded = jwt_decode(token);
    console.log("----Local Storage----", decoded);

    var data = {
      'email': decoded['result'].email
    };

    this.authService.logOut(data).subscribe((items: any) => {
      localStorage.removeItem("token");
      // this.isLogin = 0;
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userLevel");

    });

  }

  closeForm() {

  }

  changeYear(value) {
    console.warn("Value = ", value);
    this.selectedX = value;
  }

}
