import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, UrlTree, UrlSegment, PRIMARY_OUTLET, UrlSegmentGroup } from '@angular/router';
import { ExportReviewService } from '../export-review.service';
import { CommonServiceService } from '../../../common-service.service';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.css']
})
export class OtherComponent implements OnInit {

  reportFrm: FormGroup;
  reportTitle: string;
  targetGroup: any;
  sourceGroup: any;
  sourceUnion: any;
  selectedItem: any = [];
  isloader: boolean = false;
  submitted: boolean = false;
  data: any;
  reportData: any = [];
  page: any;
  message: any;

  arrays: any = [];

  displayReport: boolean = false;
  displayDetailedReport: boolean = false;
  showTextBox: boolean = false;
  detailReportData: any = [];
  tree: UrlTree;
  dateRange: number;
  fragment = '';
  reportDateRange: '';
  primary: UrlSegmentGroup;
  @ViewChild('userTable') userTable: ElementRef;
  @ViewChild('detailTable') detailTable: ElementRef;
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private primengConfig: PrimeNGConfig, private messageService: MessageService, private _router: Router, private _service: ExportReviewService, private commonService: CommonServiceService) { }

  ngOnInit(): void {




    this.tree = this._router.parseUrl(this._router.url);
    this.primary = this.tree.root.children[PRIMARY_OUTLET];
    const primarySegments: UrlSegment[] = this.primary.segments;
    let length = primarySegments.length;
    let reportType = primarySegments[length - 1].path;



    this.page = reportType;

    var d = new Date();
    var getCurrentYear = d.getFullYear();
    var fromYear = 1995;


    if (reportType == "other-items") {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        selectedPort: [''],
        reportType: [reportType],
        viewDetailReport: [''],
        topRecordNum: ['0'],
        topRecordNumOther: ['1'],
        includeBlankData: ['0'],
        sortColumn: ['FobDolMil'],
        sortType: ['DESC'],

      });
      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Export Of Other Items Of Marine Products";

    } else if (reportType == "export-shrimp-market") {

      this.reportFrm = this.formBuilder.group({
        dateStart: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        dateEnd: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(fromYear), Validators.max(getCurrentYear)]],
        selectedPort: [''],
        selectedRegion: [''],
        reportType: [reportType],
        viewDetailReport: [''],


      });
      this.targetGroup = [];
      this.primengConfig.ripple = true;
      this.reportTitle = "Variety Wise Export Details Of Shrimp To Market";
    }

    this._service.getGroups().subscribe((groups: any) => {
      this.sourceGroup = groups.data;
    });

    this._service.getUnion().subscribe((unions: any) => {
      this.sourceUnion = unions.data;
    });





    this.reportFrm.valueChanges.subscribe(val => {
      if (this.reportData.length > 0) {
        if (this.reportFrm.value.viewDetailReport) {
          this.displayReport = false;
          this.displayDetailedReport = true;
        }
        else {
          this.displayReport = true;
          this.displayDetailedReport = false;
        }
      }

    });


  }

  addPort(event) {
    for (let i = 0; i < event.items.length; i++) {
      this.selectedItem.push(event.items[i]);
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  removePort(event) {
    for (let i = 0; i < event.items.length; i++) {
      // this.selectedItem.splice(event.items[i].code);
      // console.warn(this.selectedItem.findIndex(item => item.code == event.items[i].code));
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.code == event.items[i].code), 1)
    }
    this.reportFrm.controls.selectedPort.patchValue(this.selectedItem, { emitEvent: false });
  }

  get f() { return this.reportFrm.controls; }



  getNumber(n, d) {
    return this.commonService.getNumber(n, d);
  }

  getShare(itemQty, totalQty) {
    return this.commonService.getShare(itemQty, totalQty);
  }

  getCustomMessage(field) {
    return this.commonService.getCustomMessage(field);
  }

  getReport() {

    this.isloader = true;
    this.submitted = true;

    if (this.reportFrm.invalid) {
      this.isloader = false;
      return;
    }

    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;

    // Make validation by common service
    this.commonService.validateCalendar(dt1, dt2);



    var dt1 = this.reportFrm.value.dateStart;
    var dt2 = this.reportFrm.value.dateEnd;
    var yearDifferent = parseInt(dt2) - parseInt(dt1);
    this.dateRange = yearDifferent;

    if (yearDifferent > 2) {

      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Year can not exceed 3", life: 6000 });
      this.isloader = false;
      return false;

    }


    if (this.reportFrm.value.selectedPort == "") {
      this.messageService.add({ severity: 'error', summary: 'Fail', detail: "Please Select Item group", life: 6000 }); //success,info,warn,error
      this.isloader = false;
      return false;
    }

    this._service.getExportOtherItems(this.reportFrm.value).subscribe((items: any) => {
      this.data = items.data;
      console.log('get other Items ', this.data);


      if (this.data.status) {

        this.isloader = false;
        this.arrays = this.data.latestData;
        // console.log("get array record ", this.arrays[0][0].totalQty);
        this.displayReport = true;

      }

    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._router.navigate(['./']);
        }
      }
    });


  }





  exportDetailExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.xlsx");

    });
  }
  exportDetailCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.detailTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report_detail.csv");

    });
  }
  exportCSV() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.csv");

    });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      //const worksheet = xlsx.utils.json_to_sheet(exportedData);
      const ws = xlsx.utils.table_to_sheet(this.userTable.nativeElement);
      // generate workbook and add the worksheet
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, ws, 'Port Data');
      // save to file
      xlsx.writeFile(workbook, "port_report.xlsx");
    });
  }

}
